import { Typography } from '@mui/material';
import { ReactComponent as IconNeuroflashLogoSVG } from 'assets/neuroflashLogo.svg';
import FlexContainer from 'components/FlexContainer';
import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

export const onboardingMobileThreshold = '922px';
export const onboardingContentWidth = '400px';

type Props = PropsWithChildren<{
  withLogo?: boolean;
  title: ReactNode;
  subtitle?: ReactNode;
}>;

export const OnboardingRoot = ({ children, title, subtitle, withLogo = true }: Props) => (
  <Root>
    {withLogo && <NeuroflashLogo />}

    <Heading>
      <Title>{title}</Title>
      <Subtitle variant="body1" color="textSecondary">
        {subtitle}
      </Subtitle>
    </Heading>
    {children}
  </Root>
);

const Root = styled(FlexContainer).attrs({
  alignItems: 'center',
  gap: 'four'
})`
  height: 100%;
  margin-top: 10%;

  @media (max-width: ${onboardingMobileThreshold}) {
    margin-top: ${({ theme }) => theme.spacings.small};
    gap: ${({ theme }) => theme.spacings.medium};
  }
`;

const Heading = styled(FlexContainer)`
  max-width: 450px;
  gap: ${({ theme }) => theme.spacings.one};
`;

const NeuroflashLogo = styled(IconNeuroflashLogoSVG)`
  position: absolute;
  top: ${({ theme }) => theme.spacings.medium};
  left: ${({ theme }) => theme.spacings.medium};
  margin-bottom: 5vh;

  @media (max-width: ${onboardingMobileThreshold}) {
    margin-top: 0;
    margin-bottom: 0;
    position: static;
  }
`;

const Title = styled(Typography).attrs({
  variant: 'h6'
})`
  text-align: center;

  @media (max-width: ${onboardingMobileThreshold}) {
    font-size: 20px;
    font-weight: 500;
  }
`;

const Subtitle = styled(Typography)`
  text-align: center;
`;
