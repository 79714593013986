import { Typography } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import { Container } from 'components/Container';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { InviteFriendsCard } from 'pages/bonusReferral/InviteFriendsCard';
import {
  BlogArticleCard,
  BootcampCard,
  CapterraCard,
  G2Card,
  InstagramCard,
  LinkedinCard,
  OmrCard,
  TiktokCard,
  TrustpilotCard,
  YoutubeCard
} from 'pages/bonusReferral/referralCards';
import { ReactElement } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

export const BonusReferralPage = (): ReactElement => {
  useNavigateToOnboarding();

  useEnableSidebarOnMount();

  const appLanguage = useAppSelector(getCurrentLanguage);

  const isGermanLanguage = appLanguage === AppLanguage.German;
  return (
    <Container size="lg">
      <Header>
        <Typography variant="h4">
          <FormattedMessage id="bonus_referral.headline" />
        </Typography>

        <Subline>
          <ColoredTypography variant="body1" color="textSecondary">
            <FormattedHTMLMessage id="bonus_referral.subline" />
          </ColoredTypography>
        </Subline>
      </Header>

      <ReferralsSection>
        <InviteFriendsCard />
      </ReferralsSection>

      <ReferralsSection>
        <ReferralsSectionHeading variant="h5">
          <FormattedMessage id="bonus_referral.sections.review.heading" />
        </ReferralsSectionHeading>
        <CardList>
          <OmrCard />
          <TrustpilotCard />
          <G2Card />
          <CapterraCard />
        </CardList>
      </ReferralsSection>
      <ReferralsSection>
        <ReferralsSectionHeading variant="h5">
          <FormattedMessage id="bonus_referral.sections.social.heading" />
        </ReferralsSectionHeading>
        <CardList>
          <LinkedinCard />
          <InstagramCard />
          <YoutubeCard />
          <TiktokCard />
        </CardList>
      </ReferralsSection>
      <ReferralsSection>
        <ReferralsSectionHeading variant="h5">
          <FormattedMessage id="bonus_referral.sections.neuroflash.heading" />
        </ReferralsSectionHeading>
        <CardList>
          <BlogArticleCard />
          {isGermanLanguage && <BootcampCard />}
        </CardList>
      </ReferralsSection>
    </Container>
  );
};

const Header = styled.div`
  text-align: center;
`;

const Subline = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacings.two};
`;

const ReferralsSection = styled.section`
  margin-top: ${({ theme }) => theme.spacings.six};
  margin-bottom: ${({ theme }) => theme.spacings.six};
`;

const ReferralsSectionHeading = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacings.six};
`;

const CardList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${({ theme }) => theme.spacings.three};

  @media (max-width: ${mobileWindowWidthCSS}) {
    grid-template-columns: 1fr;
  }
`;
