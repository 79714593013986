import { FormValues } from 'features/workflowBlogPostBuilder/types';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/store';

export function useSyncWithInspirationPanel() {
  const { setFieldValue } = useFormikContext<FormValues>();
  const { title, outline } = useAppSelector((state: RootState) => state.aiWriter.builder.blogPost);

  useEffect(() => {
    setFieldValue('title', title);
  }, [title, setFieldValue]);

  useEffect(() => {
    setFieldValue('outline', outline);
  }, [outline, setFieldValue]);
}
