import { EditBriefValues } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/EditBriefSubStep';
import { getActiveTab, getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { getStoredUserTonality } from 'features/aiWriter/tonality/getStoredUserTonality';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import { getFieldPrefillValue } from 'features/textGenerator/utils/getFieldPrefillValue';
import { getNTextItems, maxTextItemCount } from 'features/textGenerator/utils/getNTextItems';
import {
  useGetDefaultOutputTypeByLanguageMemoized,
  useGetPromptConfigByLanguageCountryAndOutputTypeCached
} from 'features/textGenerator/utils/useGetPromptsOthers';
import { useSelector } from 'react-redux';

export default function useInitialBriefValues(): EditBriefValues {
  const { generateTextConfig } = useSelector(getActiveTab);
  const { currentModelLanguage, currentModelCountry } = useSelector(
    getCurrentModelLanguageAndCountry
  );

  const defaultOutputType = useGetDefaultOutputTypeByLanguageMemoized(
    currentModelLanguage,
    currentModelCountry
  );
  const outputType = generateTextConfig?.outputType || defaultOutputType;

  /**
   * #tech-debt https://app.clickup.com/t/862hy4n1w
   * When output type changes we need to override
   * config with prefilled values. We have this logic
   * in multiple places. I don't like it but I don't know how to
   * make it better without a complete rewrite.
   */
  const prompt = useGetPromptConfigByLanguageCountryAndOutputTypeCached(
    currentModelCountry,
    currentModelLanguage,
    outputType
  );

  return {
    text: generateTextConfig?.text || getFieldPrefillValue(prompt, 'text'),
    keywords: generateTextConfig?.keywords || getFieldPrefillValue(prompt, 'keywords'),
    keywords2: generateTextConfig?.brand || getFieldPrefillValue(prompt, 'keywords2'),
    outputType,
    systemTonality: (generateTextConfig?.systemTonality || []) as SystemTonality[],
    userTonality: getStoredUserTonality(),
    nTextItems: Math.min(maxTextItemCount, generateTextConfig?.nTextItems || getNTextItems(prompt)),
    length: null
  };
}
