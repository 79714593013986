import LocalStorageKey from 'config/localStorageKey';
import getMapperInitValue from 'features/aiTester/modals/setup/getMapperInitValue';
import { TesterSetupValues } from 'features/aiTester/modals/setup/SetupModal';
import { getGetAudienceByLanguage } from 'features/audiences/store/selectors';
import getAudienceInitValue from 'features/audiences/utils/getAudienceInitValue';
import {
  getEmbeddingModelsByLanguage,
  getGetEmbeddingModelLanguageAndCountryById,
  getGetEmbeddingModelMappersWithFallbackById
} from 'features/embeddingModels/store/selectors';
import getModelInitValue from 'features/embeddingModels/store/utils/getModelInitValue';
import {
  useGetDefaultOutputTypeByLanguageMemoized,
  useGetPrefillValueByLanguageAndOutputType
} from 'features/textGenerator/utils/useGetPromptsOthers';
import { useSelector } from 'react-redux';

export default function useInitialValues(): TesterSetupValues {
  const models = useSelector(getEmbeddingModelsByLanguage);
  const getAudiencesByLanguage = useSelector(getGetAudienceByLanguage);
  const getModelMappersWithFallbackById = useSelector(getGetEmbeddingModelMappersWithFallbackById);

  const getModelLanguageAndCountryById = useSelector(getGetEmbeddingModelLanguageAndCountryById);

  const defaultModel = getModelInitValue(models, LocalStorageKey.TesterModel);
  const { language: modelLanguage, country: modelCountry } = getModelLanguageAndCountryById(
    defaultModel?.id || ''
  );
  const defaultOutputType = useGetDefaultOutputTypeByLanguageMemoized(modelLanguage, modelCountry);

  const getPrefillValue = useGetPrefillValueByLanguageAndOutputType(modelLanguage);

  if (defaultModel) {
    const modelMappers = getModelMappersWithFallbackById(defaultModel.id);

    const audiences = getAudiencesByLanguage(defaultModel.language);
    const defaultMapper = getMapperInitValue(modelMappers);
    const defaultAudience = getAudienceInitValue(audiences, LocalStorageKey.TesterAudience);

    const defaultPrefilledValues: TesterSetupValues = {
      model: '',
      mapper: '',
      audience: 0,
      outputType: defaultOutputType,
      keywords2: getPrefillValue(defaultOutputType, 'keywords2'),
      keywords: getPrefillValue(defaultOutputType, 'keywords'),
      textBody: getPrefillValue(defaultOutputType, 'text'),
      userTonality: [],
      systemTonality: [],
      nTextItems: 2,
      personalityId: undefined,
      information: undefined
    };

    if (defaultMapper && defaultAudience) {
      return {
        ...defaultPrefilledValues,
        model: defaultModel.id,
        mapper: defaultMapper,
        audience: defaultAudience
      };
    }

    if (defaultMapper) {
      return {
        ...defaultPrefilledValues,
        model: defaultModel.id,
        mapper: defaultMapper
      };
    }

    if (defaultAudience) {
      return {
        ...defaultPrefilledValues,
        model: defaultModel.id,
        audience: defaultAudience
      };
    }

    return { ...defaultPrefilledValues, model: defaultModel.id };
  }

  return {
    model: '',
    mapper: '',
    audience: 0,
    outputType: defaultOutputType,
    keywords2: '',
    keywords: '',
    textBody: '',
    userTonality: [],
    systemTonality: [],
    nTextItems: 2,
    personalityId: undefined,
    information: undefined
  };
}
