import { findKey } from 'lodash';
import { CreateDimensionResponse } from 'services/api/dimension/types';
import {
  CreateListResponse,
  DeleteListResponse,
  GetAllListsResponse,
  List,
  UpdateListResponse
} from 'services/api/list/types';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { getType } from 'typesafe-actions';
import { reportErrors } from 'utils/reportErrors';
import { removeDuplicates } from 'utils/utils';

import Toast from '../../../components/toasts/Toast';
import DimensionAPI from '../../../services/api/dimension';
import ListAPI from '../../../services/api/list';
import { getDimensions } from '../../dimensions/store/actions';
import { getExplorerTabs } from '../../explorer/store/selectors';
import { closeTab, setCurrentTab } from '../../explorer/store/slice';
import { rateWordsThunk } from '../../explorer/store/thunks';
import * as actions from './actions';

function* getListsSaga() {
  try {
    const response: GetAllListsResponse = yield* call(ListAPI.getAll);

    if (response.status) {
      yield* put(actions.getLists.success(response.data));
    } else {
      yield* put(actions.getLists.failure());
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(actions.getLists.failure());
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* addListSaga({ payload, meta }: ReturnType<typeof actions.addList.request>) {
  try {
    const response: CreateListResponse = yield* call(ListAPI.create, payload);

    if (response.status) {
      yield* put(actions.addList.success(response.data.id, meta));
      Toast.success('lists.created_list', { name: payload.label });
      yield* put(actions.getLists.request());
    } else {
      yield* put(actions.addList.failure(undefined, meta));
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(actions.addList.failure(undefined, meta));
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* addToExistingListSaga({
  payload,
  meta
}: ReturnType<typeof actions.addToExistingList.request>) {
  const { words, label, language, description }: List = yield* select(
    state => state.lists.lists[payload.id]
  );
  const newList = {
    label,
    language,
    words: removeDuplicates(payload.words.concat(words)),
    description: description || ''
  };

  try {
    const response: UpdateListResponse = yield* call(ListAPI.update, payload.id, newList);

    if (response.status) {
      yield* put(actions.addToExistingList.success(payload.id, meta));

      Toast.success('lists.list_updated', { name: label });
      yield* put(actions.getLists.request());
    } else {
      yield* put(actions.addToExistingList.failure(undefined, meta));
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(actions.addToExistingList.failure(undefined, meta));
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* updateListSaga({ payload, meta }: ReturnType<typeof actions.updateList.request>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id: _, ...oldList }: List = yield* select(state => state.lists.lists[payload.id]);
  const newList = {
    ...oldList,
    description: oldList.description || '',
    ...payload
  };

  try {
    const response: UpdateListResponse = yield* call(ListAPI.update, payload.id, newList);

    if (response.status) {
      yield* put(actions.updateList.success(payload.id, meta));

      Toast.success('lists.list_updated', { name: oldList.label });
      yield* put(actions.getLists.request());
    } else {
      yield* put(actions.updateList.failure(undefined, meta));
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(actions.updateList.failure(undefined, meta));
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* combineListsSaga({ payload, meta }: ReturnType<typeof actions.combineLists.request>) {
  const { words, label: sourceLabel }: List = yield* select(
    state => state.lists.lists[payload.sourceId]
  );
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id: _,
    words: targetWords,
    description,
    ...oldTargetList
  }: List = yield* select(state => state.lists.lists[payload.targetId]);
  const newList = {
    ...oldTargetList,
    description: description || '',
    words: removeDuplicates(words.concat(targetWords))
  };

  try {
    const requests = [call(ListAPI.update, payload.targetId, newList)];

    if (payload.deleteSource) {
      requests.push(call(ListAPI.delete, payload.sourceId));
    }

    const responses = yield* all(requests);

    if (responses.every(response => response.status)) {
      yield* put(actions.combineLists.success(payload.targetId, meta));

      Toast.success('lists.lists_merged', {
        sourceList: sourceLabel,
        targetList: oldTargetList.label
      });

      yield getListsSaga();

      const tabs = yield* select(getExplorerTabs);
      const sourceTabId = findKey(tabs, tab => tab.listId === payload.sourceId);
      const targetTabId = findKey(tabs, tab => tab.listId === payload.targetId);
      if (sourceTabId && payload.deleteSource) {
        yield* put(closeTab(sourceTabId));
      }
      if (targetTabId) {
        yield* put(setCurrentTab(targetTabId));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        yield* put(rateWordsThunk({ listId: payload.targetId }) as any);
      }
    } else {
      yield* put(actions.combineLists.failure(undefined, meta));
      responses.forEach(response => {
        if (!response.status) {
          Toast.backendError(handleCommonApiError(response.data.message));
        }
      });
    }
  } catch (error) {
    yield* put(actions.combineLists.failure(undefined, meta));
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* exportToDimensionSaga({
  payload: { id, ...details },
  meta
}: ReturnType<typeof actions.exportToDimension.request>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id: _, ...list }: List = yield* select(state => state.lists.lists[id]);
  try {
    const response: CreateDimensionResponse = yield* call(DimensionAPI.create, {
      ...list,
      ...details
    });

    if (response.status) {
      yield* put(actions.exportToDimension.success(undefined, meta));
      Toast.success('lists.exported_to_dimension', { name: list.label });
      yield* put(getDimensions.request());
    } else {
      yield* put(actions.exportToDimension.failure(undefined, meta));
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(actions.exportToDimension.failure(undefined, meta));
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* removeListSaga({ payload, meta }: ReturnType<typeof actions.removeList.request>) {
  const { label }: List = yield* select(state => state.lists.lists[payload]);
  try {
    const response: DeleteListResponse = yield* call(ListAPI.delete, payload);

    if (response.status) {
      const tabs = yield* select(getExplorerTabs);
      const tabId = findKey(tabs, tab => tab.listId === payload);
      if (tabId) {
        yield* put(closeTab(tabId));
      }

      yield* put(actions.removeList.success(undefined, meta));
      Toast.success('lists.list_removed', { name: label });
      yield* put(actions.getLists.request());
    } else {
      yield* put(actions.removeList.failure(undefined, meta));
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(actions.removeList.failure(undefined, meta));
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

export const listsSagas = [
  takeLatest(getType(actions.getLists.request), getListsSaga),
  takeLatest(getType(actions.addList.request), addListSaga),
  takeLatest(getType(actions.addToExistingList.request), addToExistingListSaga),
  takeLatest(getType(actions.updateList.request), updateListSaga),
  takeLatest(getType(actions.combineLists.request), combineListsSaga),
  takeLatest(getType(actions.exportToDimension.request), exportToDimensionSaga),
  takeLatest(getType(actions.removeList.request), removeListSaga)
];
