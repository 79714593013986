import { create } from 'zustand';

type GlobalErrorLogContextStoreType = {
  currentAiWriterDocumentId?: string;
  currentAiTesterDocumentId?: string;
  currentExplorerDocumentId?: string;
  customerHasTeam?: boolean;
  customerIsApiCustomer?: boolean;
  customerPricingId?: number | string;
  customerPricingType?: string;
};

const initialState: GlobalErrorLogContextStoreType = {};

export const globalErrorLogContextStore = create<GlobalErrorLogContextStoreType>(() => ({
  ...initialState
}));
