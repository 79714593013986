import Toast from 'components/toasts/Toast';
import { useCallback } from 'react';
import CustomerAPI from 'services/api/customer';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { useAppDispatch } from 'store/hooks';
import useRunInTask from 'utils/hooks/useRunInTask';

import { logout } from '../store/actions';

export default function useDeleteUser() {
  const [isLoading, runInTask] = useRunInTask();
  const dispatch = useAppDispatch();

  const deleteUser = useCallback(async () => {
    try {
      const response = await runInTask(() => CustomerAPI.delete());
      if (response.status) {
        dispatch(logout.request());
      } else {
        const errorId = handleCommonApiError(response.data.message);

        Toast.backendError(errorId);
      }
    } catch (e) {
      Toast.apiError();
    }
  }, [dispatch, runInTask]);

  return { deleteUser, isLoading };
}
