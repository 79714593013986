import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Chip, IconButton, Menu, MenuItem } from '@mui/material';
import { TagIndicator } from 'features/information/apply-information/TagIndicator';
import { useGetInformationCategoriesQuery } from 'features/information/CopyPasteView';
import { InformationCategoryDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';
import useTr from 'utils/hooks/useTr';

type CategoryFilterProps = {
  value: InformationCategoryDto | undefined;
  selectedInformationList: InformationDto[] | undefined;
  setValue: (value: InformationCategoryDto | undefined) => void;
};

const VISIBLE_TAGS_COUNT = 4;

export function CategoryFilter(props: CategoryFilterProps) {
  const { anchorElement, isOpen, close, open } = useDropdown();

  const queryResult = useGetInformationCategoriesQuery({ has_information: 1 });
  const tags = queryResult.data?.pages.map(page => page.data).flat(1);

  const visibleTags = tags?.splice(0, VISIBLE_TAGS_COUNT);

  const tr = useTr();

  const handleSelectTag = (value: InformationCategoryDto | undefined) => {
    props.setValue(value);

    if (isOpen) {
      close();
    }
  };

  return (
    <FilterBox>
      <ChipList>
        <SizeLimitedChip
          label={tr('common.all')}
          onClick={() => handleSelectTag(undefined)}
          variant={props.value === undefined ? 'filled' : 'outlined'}
          color={props.value === undefined ? 'primary' : undefined}
        />

        {visibleTags?.map(tag => (
          <SizeLimitedChip
            key={tag.id}
            label={
              <TagIndicator tag={tag} selectedInformationList={props.selectedInformationList} />
            }
            onClick={() => handleSelectTag(tag)}
            variant={props.value?.id === tag.id ? 'filled' : 'outlined'}
            color={props.value?.id === tag.id ? 'primary' : undefined}
          />
        ))}

        {tags && tags?.length > 0 && (
          <StyledIconButton onClick={open}>
            <MoreVertIcon />
          </StyledIconButton>
        )}

        <StyledMenu
          anchorEl={anchorElement}
          open={isOpen}
          onClose={close}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {tags?.map(tag => (
            <MenuItem
              key={tag.id}
              onClick={() => handleSelectTag(tag)}
              selected={props.value?.id === tag.id}
            >
              <TruncatedText>
                <TagIndicator tag={tag} selectedInformationList={props.selectedInformationList} />
              </TruncatedText>
            </MenuItem>
          ))}
        </StyledMenu>
      </ChipList>
    </FilterBox>
  );
}

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    max-height: 18.75rem;
    padding: ${({ theme }) => theme.spacings.two};
  }
`;

const TruncatedText = styled.div`
  max-width: 12.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FilterBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChipList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.two};

  overflow-x: scroll;
`;

const SizeLimitedChip = styled(Chip)`
  max-width: 8.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: auto;
`;
