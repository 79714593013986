import { getGenerateTextConfig } from 'features/aiWriter/store/selectors';
import useAudienceConfig from 'features/audiences/hooks/useAudienceConfig';
import { getGetAudienceById } from 'features/audiences/store/selectors';
import { setWordsLimitReached } from 'features/customer/store/actions';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { useGetNTimesByOutputType } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { assertNonNullable } from 'utils/typescript/nonNullable';

const useGenerateAiWriterTexts = () => {
  const dispatch = useDispatch();

  const { audienceId } = useAppSelector(getGenerateTextConfig);
  const getAudienceById = useAppSelector(getGetAudienceById);

  const { audienceModelId } = useAudienceConfig({ audienceId });
  const audienceModel = getAudienceById(audienceId);

  assertNonNullable(audienceModel, 'Audience model not found');
  assertNonNullable(audienceModel.language, 'Audience language falsy');
  assertNonNullable(audienceModel.country, 'Audience country falsy');

  const { country: modelCountry, language: modelLanguage } = audienceModel;
  const getNTimesByOutputType = useGetNTimesByOutputType(modelLanguage, modelCountry);

  return async ({
    text,
    outputType,
    systemTonality,
    userTonality
  }: {
    text: string;
    outputType: string;
    systemTonality: string[];
    userTonality: string[];
  }) => {
    const nTimes = getNTimesByOutputType(outputType);

    try {
      // Awaiting here to allow catching errors
      // Note: If you remove the await here, you HAVE to catch the errors in the caller!
      const result = await generateTexts(
        { audienceModelId, nTimes, outputType },
        { text, systemTonality, userTonality },
        {
          onCustomError: [
            'ERROR_ALL_WORDS_USED',
            () => {
              dispatch(setWordsLimitReached(true));
            }
          ]
        }
      );

      return result;
      // The error case is already managed at top-level
      // eslint-disable-next-line no-empty
    } catch {}

    return undefined;
  };
};

export default useGenerateAiWriterTexts;
