import { fetchWordSynonyms } from 'features/aiTester/store/actions/words/actions';
import { FetchWordSynonymsRequestPayload } from 'features/aiTester/store/actions/words/types';
import { getTesterActiveTab } from 'features/aiTester/store/selectors';
import { TokenData } from 'features/aiTester/store/types';
import { getCurrentTabTextWord } from 'features/aiTester/store/utils/getters/getCurrentTabTextWord';
import { getGetAudienceById } from 'features/audiences/store/selectors';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { textsFromGenerateTextOutputEntries } from 'features/textGenerator/utils/generateTextEntryMapper';
import getScoreTextConfig from 'features/textGenerator/utils/getScoreTextConfig';
import { fetchPromptConfigNTimes } from 'features/textGenerator/utils/useGetPromptsByCountryAndLanguage';
import { isValidAudienceModel } from 'services/api/audience/types';
import WordEmbeddingAPI from 'services/api/wordEmbedding';
import { ScoredWord } from 'services/api/wordEmbedding/types';
import { AppThunk } from 'store/store';

export const fetchWordSynonymsThunk =
  (payload: FetchWordSynonymsRequestPayload): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();

    const {
      id: tabId,
      embeddingModelId,
      mapperId,
      wordAttributes,
      dimensions,
      manualDimensions,
      generateTextConfig
    } = getTesterActiveTab(state);

    const audienceModel = generateTextConfig?.audienceId
      ? getGetAudienceById(state)(generateTextConfig.audienceId)
      : undefined;

    const word = getCurrentTabTextWord({
      state: state.tester,
      textId: payload.textId,
      wordId: payload.wordId
    })?.word;

    if (audienceModel?.model_id && word) {
      dispatch(fetchWordSynonyms.request(payload));

      try {
        const { goalConfig, apiDimensions, mappers } = getScoreTextConfig({
          mapperId,
          wordAttributes,
          dimensions,
          manualDimensions
        });

        const outputType = 'synonyms';
        const nTimes = isValidAudienceModel(audienceModel)
          ? await fetchPromptConfigNTimes(audienceModel.language, audienceModel.country, outputType)
          : undefined;

        const { outputs } = await generateTexts(
          { audienceModelId: audienceModel?.model_id, outputType, nTimes },
          { keywords: word }
        );

        const scoreWordsResponse = await WordEmbeddingAPI.scoreWords({
          embeddingModelId: embeddingModelId,
          dimensions: apiDimensions,
          mappers,
          goalConfig: {
            ...goalConfig,
            seed_score: 0,
            normalization: 'none'
          },
          seedWords: textsFromGenerateTextOutputEntries(outputs),
          whitelistWords: textsFromGenerateTextOutputEntries(outputs),
          limit: outputs.length
        });

        const tokenData = scoreWordsResponse.status
          ? rateSynonyms({ scoredWords: scoreWordsResponse.data.scored_words })
          : getDefaultSynonymsRating({
              words: textsFromGenerateTextOutputEntries(outputs)
            });

        dispatch(
          fetchWordSynonyms.success({
            tabId,
            synonyms: textsFromGenerateTextOutputEntries(outputs),
            tokenData,
            ...payload
          })
        );
      } catch (e) {
        dispatch(fetchWordSynonyms.failure({ tabId, ...payload }));
      }
    }

    return Promise.resolve();
  };

const rateSynonyms = ({ scoredWords }: { scoredWords: ScoredWord[] }) => {
  return scoredWords.reduce<Record<string, TokenData[]>>((dict, word) => {
    dict[word.word] = createGoalScoreEntry(word.goal_score);

    return dict;
  }, {});
};

const getDefaultSynonymsRating = ({ words }: { words: string[] }) => {
  return words.reduce<Record<string, TokenData[]>>((dict, word) => {
    dict[word] = createGoalScoreEntry(0);

    return dict;
  }, {});
};

const createGoalScoreEntry = (value: number) => [{ id: 'goal_score', label: null, value }];
