import { TextareaAutosize } from '@mui/material';
import styled from 'styled-components';

// Sorry for the fixed values, copied them from MUI and
// some of them doesn't have a variable in our styled components theme :(
export const StyledTextareaAutosize = styled(TextareaAutosize)<{ $hasError?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 1.4375em;
  padding: 16.5px 14px;
  border-radius: ${({ theme }) => theme.borderRadius.one};
  color: ${({ theme, $hasError }) => ($hasError ? theme.colors.error : '#444')};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ theme, $hasError }) => ($hasError ? theme.colors.error : theme.colors.componentsInputfieldOutlinedEnabledborder)};
  resize: vertical;

  &:hover {
    border-color: ${({ theme, $hasError }) =>
      $hasError ? theme.colors.error : theme.colors.black};
  }

  &:focus {
    border-color: ${({ theme, $hasError }) =>
      $hasError ? theme.colors.error : theme.colors.primary};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`;
