import { useQuery } from '@tanstack/react-query';
import { promptFromApi } from 'features/textGenerator/store/promptMapper';
import { Prompt } from 'features/textGenerator/store/types';
import { httpGetPromptsByLanguage } from 'services/backofficeIntegration/http/endpoints/textGeneration/httpGetPromptsByLanguage';
import { create } from 'zustand';

// TODO: Centralize the store of prompts somewhere else
type PromptsByLanguageStoreState = {
  prompts: Record<string, Prompt[]>;
};

type PromptsByLanguageStoreActions = {
  setPrompts: (key: string, prompts: Prompt[]) => void;
};

const usePromptsByLanguageStore = create<
  PromptsByLanguageStoreState & PromptsByLanguageStoreActions
>((set, get) => ({
  prompts: {},
  setPrompts: (key, prompts) => {
    set({ prompts: { ...get().prompts, [key]: prompts } });
  }
}));

export const useGetPromptsByLanguage = (language: string) => {
  const currentStoredPrompts = usePromptsByLanguageStore(state => state.prompts);
  const updateStorePrompts = usePromptsByLanguageStore(state => state.setPrompts);

  const isMissingCacheForKey =
    !currentStoredPrompts[language] || currentStoredPrompts[language].length === 0;

  const { data } = useQuery({
    enabled: isMissingCacheForKey,
    queryKey: httpGetPromptsByLanguage.makeQueryKey(language),
    queryFn: () =>
      httpGetPromptsByLanguage.callEndpoint(language).then(res => res.map(promptFromApi)),
    onSuccess: data => {
      updateStorePrompts(language, data);
    },
    staleTime: Infinity
  });

  const fetchedPrompts = data ?? [];
  if (isMissingCacheForKey) {
    return fetchedPrompts;
  }

  return currentStoredPrompts[language] ?? [];
};

export const fetchAndCachePromptsByLanguage = async (language: string) => {
  const currentStoredPrompts = usePromptsByLanguageStore.getState().prompts;
  const updateStorePrompts = usePromptsByLanguageStore.getState().setPrompts;

  if (currentStoredPrompts[language]) {
    return currentStoredPrompts[language];
  }

  const fetchedPrompts = await httpGetPromptsByLanguage
    .callEndpoint(language)
    .then(res => res.map(promptFromApi));

  updateStorePrompts(language, fetchedPrompts);

  return fetchedPrompts;
};
