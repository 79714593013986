import { makeSeoAnalysisFormValidation } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/makeSeoAnalysisFormValidation';
import { makeSeoInitialValues } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/makeSeoInitialValues';
import { SeoAnalysisMainSubStep } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/SeoAnalysisMainSubStep';
import {
  keywordField,
  searchEngineField,
  SeoAnalysisFormValues
} from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/SeoAnalysisMainSubStep/MainSubStepFormSection';
import { SeoAnalysisSearchResultsSubStep } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/SeoAnalysisSearchResultsSubStep';
import { SeoAnalysisStatisticsSubStep } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/SeoAnalysisStatisticsSubStep';
import useCountWordOccurrence from 'features/aiWriter/AiWriterTextEditor/hooks/useCountWordOccurrence';
import useEditorText from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorText';
import { useEditorWordsCount } from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorWordsCount';
import { analyseTextThunk } from 'features/aiWriter/store/actions/seoAnalysis/thunks/analyseTextThunk';
import {
  getCurrentModelLanguageAndCountry,
  getInspirationsCurrentExtendedStep,
  getSeoKeyword
} from 'features/aiWriter/store/selectors';
import { Formik } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useRunInTask from 'utils/hooks/useRunInTask';
import useTr from 'utils/hooks/useTr';

export const SeoAnalysisStep = () => {
  const tr = useTr();
  const dispatch = useAppDispatch();

  const editorText = useEditorText();
  const countWordOccurrence = useCountWordOccurrence();

  const [isLoading, runInTask] = useRunInTask();
  const editorWordsCount = useEditorWordsCount();

  const extendedStep = useAppSelector(getInspirationsCurrentExtendedStep);
  const { currentModelCountry } = useAppSelector(getCurrentModelLanguageAndCountry);

  const seoAnalysisFormSchema = makeSeoAnalysisFormValidation({
    searchEngineField,
    keywordField,
    tr
  });

  const seoKeyword = useAppSelector(getSeoKeyword);

  const initialValues = makeSeoInitialValues(currentModelCountry, seoKeyword);

  const onSubmit = async ({ keyword, searchEngine }: SeoAnalysisFormValues) => {
    await runInTask(() =>
      dispatch(
        analyseTextThunk({
          text: editorText,
          keyword,
          searchEngine,
          wordsAmount: editorWordsCount,
          countWordOccurrence
        })
      )
    );
  };

  if (extendedStep?.step === 'performance') {
    // Formik was moved to the top of the SEO structure, to be available from all SEO substeps
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={seoAnalysisFormSchema}
        validateOnBlur
        validateOnChange
      >
        {(() => {
          switch (extendedStep.subStep) {
            case 'seoMain':
              return <SeoAnalysisMainSubStep isLoading={isLoading} />;
            case 'seoStatistics':
              return <SeoAnalysisSearchResultsSubStep />;
            case 'seoSearchResults':
              return <SeoAnalysisStatisticsSubStep />;
          }
        })()}
      </Formik>
    );
  }

  return null;
};
