import { Button, Tooltip } from '@mui/material';
import magicFeatherIcon from 'assets/icons/icon-magic-feather-sparkle.svg';
import useContinueWritingFromSelectionOrContextAction from 'features/aiWriter/AiWriterTextEditor/hooks/hotkey-actions/useContinueWritingFromSelectionOrContextAction';
import useEditorText from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorText';
import { getGeneratingTextInEditor } from 'features/aiWriter/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactElement, useCallback } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { isMacOsUser } from 'utils/isMacOsUser';

export const EditorContinueWritingButton = (): ReactElement => {
  const textGenerationActive = useAppSelector(getGeneratingTextInEditor);

  const continueWritingAction = useContinueWritingFromSelectionOrContextAction();

  const isMacOs = isMacOsUser();

  const handleClick = useCallback(
    () => continueWritingAction({ isHotKeyUsage: true }),
    [continueWritingAction]
  );

  const editorText = useEditorText();
  const isEditorTextEmpty = editorText.length === 0;

  const disabled = isEditorTextEmpty || textGenerationActive;

  return (
    <Tooltip
      title={
        <FormattedMessage
          id={
            isEditorTextEmpty
              ? 'aiWriter.toolbar.continue.tooltip.disabled'
              : 'aiWriter.toolbar.continue.tooltip'
          }
        />
      }
    >
      {/* span is needed for the tooltip in the case of the disabled button
        see: https://mui.com/material-ui/react-tooltip/#disabled-elements */}
      <span>
        <StyledRewriteButton
          {...withGtmInteraction(gtmIds.aiWriter.editor.continueWriting)}
          variant="contained"
          size="large"
          onClick={handleClick}
          disabled={disabled}
          startIcon={<WhiteIcon src={magicFeatherIcon} />}
        >
          <FormattedMessage
            id="aiWriter.toolbar.continue"
            values={{
              shortcut: isMacOs ? (
                <FormattedMessage id="aiWriter.toolbar.continue.mac" />
              ) : (
                <FormattedMessage id="aiWriter.toolbar.continue.other" />
              )
            }}
          />
        </StyledRewriteButton>
      </span>
    </Tooltip>
  );
};
const WhiteIcon = styled.img`
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);

  width: 18px;
  height: 18px;
`;

const StyledRewriteButton = styled(Button)`
  && {
    text-transform: none;
    border-radius: ${({ theme }) => theme.borderRadius.one};
    padding: ${({ theme }) => `${theme.spacings.two} ${theme.spacings.four}`};
  }
  height: 30px;
`;
