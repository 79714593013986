import { useEffect, useRef } from 'react';

type Options = {
  shouldTrigger: boolean;
  delay: number;
  action: () => void;
};

export function useAutoTriggerAfterTimeout({ shouldTrigger, delay, action }: Options) {
  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (shouldTrigger && !timeoutRef.current) {
      timeoutRef.current = window.setTimeout(() => {
        action();
      }, delay);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
    };
  }, [shouldTrigger, action, delay]);
}
