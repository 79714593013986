import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { ColoredTypography } from 'components/ColoredTypography';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import { CustomerAvatar } from 'features/customer/CustomerAvatar';
import { getIsApiCustomer, getUserImage, getUserName } from 'features/customer/store/selectors';
import { DarkModeToggleItem } from 'features/pageSidebar/sidebarItems/actions/account/DarkModeToggleItem';
import ProfileItem from 'features/pageSidebar/sidebarItems/actions/account/ProfileItem';
import SignOutItem from 'features/pageSidebar/sidebarItems/actions/account/SignOutItem';
import { UsersItem } from 'features/pageSidebar/sidebarItems/actions/account/UsersItem';
import { LanguageButton } from 'features/pageSidebar/sidebarItems/actions/LanguageButton';
import { SidebarItem } from 'features/pageSidebar/sidebarItems/SidebarItem';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { MouseEvent } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

export const SidebarItemAccount = (): React.ReactElement => {
  const { anchorElement, isOpen, open, close } = useDropdown();
  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const name = useAppSelector(getUserName);
  const image = useAppSelector(getUserImage);

  const showDarkModeToggle = useFeatureFlagEnabled('dark-mode-toggle');

  const handleClose = () => {
    close();
  };

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    open(event);
  };

  return (
    <StyledMenuDropdown
      trigger={
        <SidebarItem
          gtmId={gtmIds.sidebar.accountMenu}
          icon={<CustomerAvatar name={name} image={image ?? undefined} size="24px" />}
          onClick={handleOpen}
          tooltip="layout.sidebar.actions.profile.menu_trigger"
        >
          <LabelContainer>
            <UserName variant="body1" color="blackMediumEmphasis">
              {name}
            </UserName>
            {isOpen ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          </LabelContainer>
        </SidebarItem>
      }
      isOpen={isOpen}
      anchorElement={anchorElement}
      close={close}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      {!isApiCustomer && <ProfileItem onClose={handleClose} />}
      <UsersItem onClose={handleClose} />
      <LanguageButton onClose={handleClose} />
      <DarkModeToggleItem enabled={!!showDarkModeToggle} />
      <SignOutItem onClose={handleClose} />
    </StyledMenuDropdown>
  );
};

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserName = styled(ColoredTypography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledMenuDropdown = styled(MenuDropdown)`
  .MuiList-root {
    width: 325px;
  }
`;
