import { useEffect } from 'react';
import { create } from 'zustand';

type SidebarState = {
  isEnabled: boolean;
  isExpanded: boolean;

  enableSidebar: () => void;
  disableSidebar: () => void;
  toggleSidebar: () => void;
  expandSidebar: () => void;
  collapseSidebar: () => void;
};

export const useSidebarStore = create<SidebarState>()(set => ({
  isEnabled: false,
  isExpanded: true,

  enableSidebar: () => set({ isEnabled: true }),
  disableSidebar: () => set({ isEnabled: false }),
  toggleSidebar: () => set(state => ({ isExpanded: !state.isExpanded })),
  collapseSidebar: () => set({ isExpanded: false }),
  expandSidebar: () => set({ isExpanded: true })
}));

export const useEnableSidebarOnMount = (shouldCollapseSidebar?: boolean) => {
  const enableSidebar = useSidebarStore(state => state.enableSidebar);
  const collapseSidebar = useSidebarStore(state => state.collapseSidebar);

  useEffect(() => {
    enableSidebar();

    if (shouldCollapseSidebar) {
      collapseSidebar();
    }
  }, [collapseSidebar, enableSidebar, shouldCollapseSidebar]);
};

export const useDisableSidebarOnMount = () => {
  const disableSidebar = useSidebarStore(state => state.disableSidebar);

  useEffect(() => {
    disableSidebar();
  }, [disableSidebar]);
};

export const collapseSidebar = () => useSidebarStore.getState().collapseSidebar();
