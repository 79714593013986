import { GetGroupResponse } from 'services/api/customer/types';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { call, put, takeLatest } from 'typed-redux-saga';
import { getType } from 'typesafe-actions';
import { reportErrors } from 'utils/reportErrors';
import { arrayToDictionary } from 'utils/utils';

import Toast from '../../../components/toasts/Toast';
import CustomerAPI from '../../../services/api/customer';
import * as actions from './actions';
import { Group } from './types';
import { groupMemberFromApi, weMapperFromApi } from './utils';

function* getGroupSaga() {
  try {
    const response: GetGroupResponse = yield* call(CustomerAPI.getGroup);

    if (response.status) {
      const { data } = response;

      const group: Group = {
        id: data.details.id,
        name: data.details.name,
        createdAtDate: data.details.created_at,
        deletedAtDate: data.details.deleted_at ?? undefined,
        updatedAtDate: data.details.updated_at,
        members: arrayToDictionary(data.members.map(groupMemberFromApi), 'id'),
        weMappers: data.details.we_mapper.map(weMapperFromApi)
      };

      yield* put(actions.getGroup.success(group));
    } else {
      yield* put(actions.getGroup.failure());
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(actions.getGroup.failure());
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

export const teamSagas = [takeLatest(getType(actions.getGroup.request), getGroupSaga)];
