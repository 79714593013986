import Toast from 'components/toasts/Toast';
import { TestingContent } from 'features/aiTester/store/types';
import filterWordAttributes from 'features/aiTester/store/utils/filters/filterWordAttributes';
import { getExtendWithScoreByOpenRate } from 'features/aiTester/store/utils/getters/getExtendWithScoreByOpenRate';
import mkMapScoreTextData from 'features/aiTester/store/utils/mappers/mapScoreTextsData';
import prepareLegend from 'features/aiTester/store/utils/preparators/prepareLegend';
import prepareOrdering from 'features/aiTester/store/utils/preparators/prepareOrdering';
import prepareScoreTextConfig from 'features/aiTester/store/utils/preparators/prepareScoreTextConfig';
import { getWordsStats } from 'features/aiTester/utils/getTokensFromText';
import getAudienceModelIdById from 'features/audiences/utils/getAudienceModelIdById';
import WordEmbeddingAPI from 'services/api/wordEmbedding';
import { handleScoreWordsErrors } from 'services/api/wordEmbedding/errors';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

import { sortByScore } from '../../general/actions';
import { scoreWordsThunk } from '../../words/thunks/scoreWordsThunk';
import { scoreTexts } from '../actions';
import { countPrevalence } from '../utils/countPrevalence';
import { isTextAvailable } from '../utils/isTextAvailable';

// TODO extract chart building to separeted file

export const scoreTextsThunk = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const currentTab = getCurrentTab(state.tester);
  if (!currentTab) {
    return;
  }

  const {
    id: tabId,
    embeddingModelId,
    mapperId,
    legend: prevLegend,
    texts,
    wordAttributes,
    dimensions,
    manualDimensions,
    sortByScore: sortByScoreValue,
    generateTextConfig
  } = currentTab;

  const availableTexts = texts.filter(text => {
    const { available } = getWordsStats(text.words ?? []);

    return available > 0;
  });

  if (availableTexts.length > 0) {
    dispatch(scoreTexts.request());

    const audienceModelId = getAudienceModelIdById(state.audiences, generateTextConfig?.audienceId);

    const rawTexts = texts.filter(item => item.text).map(item => item.text);
    const filteredWordAttributes = filterWordAttributes(wordAttributes, ['model_rank_score']);
    const wordAttributesValues = filteredWordAttributes.map(attr => attr.value);

    const extendWithScoreByOpenRate = getExtendWithScoreByOpenRate({ state });

    const { goalConfig, apiDimensions, mappers } = prepareScoreTextConfig(
      {
        audienceModelId,
        mapperId,
        wordAttributes: filteredWordAttributes,
        dimensions,
        manualDimensions
      },
      { extendWithScoreByOpenRate }
    );

    const { legendOrder, dimensionsOrder, wordAttributesOrder } = prepareOrdering({
      dimensions,
      manualDimensions,
      wordAttributes: wordAttributesValues,
      extendWithScoreByOpenRate
    });

    try {
      const response = await WordEmbeddingAPI.scoreTexts({
        embeddingModelId: embeddingModelId,
        texts: rawTexts,
        dimensions: apiDimensions,
        mappers,
        goalConfig
      });

      if (response.status) {
        const mapScoreData = mkMapScoreTextData(
          audienceModelId,
          wordAttributesOrder,
          dimensionsOrder
        );

        const scoreDetails = response.data.map(mapScoreData);

        const scoredTexts: TestingContent[] = texts.map((t, index) => {
          const { model_rank_score: modelRankScore, goal_score: goalScore } = response.data[index];

          const prevalence = countPrevalence(modelRankScore ?? undefined, wordAttributesValues);
          const isAvailable = isTextAvailable(t.words);

          return {
            ...t,
            scoreDetails: scoreDetails[index],
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            goalScore: goalScore!,
            prevalence,
            isAvailable
          };
        });

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const firstCorrectText = scoredTexts.find(t => t.isAvailable)!;

        dispatch(
          scoreTexts.success({
            tabId,
            texts: scoredTexts,
            legend: prepareLegend(prevLegend, firstCorrectText.scoreDetails),
            legendOrder
          })
        );

        dispatch(scoreWordsThunk({ tabId, textIds: texts.map(t => t.id) }));

        if (sortByScoreValue) {
          dispatch(sortByScore());
        }

        return Promise.resolve();
      } else {
        dispatch(scoreTexts.failure());

        Toast.backendError(handleScoreWordsErrors(response.data.message));

        return Promise.reject(response.message);
      }
    } catch (error) {
      dispatch(scoreTexts.failure());
      Toast.apiError();
      return Promise.reject((error as Error).toString());
    }
  }

  return Promise.resolve();
};
