import { AnyAction, configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import LocalStorageKey from 'config/localStorageKey';
import { globalErrorLogContextStore } from 'features/logging/GlobalErrorLogContextStore';
import fileSaver from 'file-saver';
import { throttle } from 'lodash';
import createSagaMiddleware from 'redux-saga';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';
import rootReducer from 'store/rootReducer';
import rootSaga from 'store/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunkMiddleware, sagaMiddleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

sagaMiddleware.run(rootSaga);

store.subscribe(
  throttle(() => {
    const state = store.getState();
    localStorage.setItem(LocalStorageKey.AppLanguage, state.language);
    localStorage.setItem(
      LocalStorageKey.ExplorerVerticalLayout,
      `${state.explorer.pageLayoutVertical}`
    );
    localStorage.setItem(LocalStorageKey.ToolbarLocked, `${state.toolbar.toolbarLocked}`);
  }, 1000)
);

store.subscribe(() => {
  const state = store.getState();

  globalErrorLogContextStore.setState({
    currentAiWriterDocumentId: state.aiWriter.currentTab,
    currentAiTesterDocumentId: state.tester.currentTab,
    currentExplorerDocumentId: state.explorer.currentTab,

    customerHasTeam: state.customer.hasTeam,
    customerIsApiCustomer: state.customer.isApiCustomer,
    customerPricingId: state.customer.pricing.id,
    customerPricingType: state.customer.pricing.type
  });
});

if (process.env.REACT_APP_DUMP_STATE_ENABLED === 'true') {
  window.dumpState = () => {
    const state = store.getState();
    const json = JSON.stringify(state);
    fileSaver.saveAs(
      new Blob([json], { type: 'application/json' }),
      `state_${new Date().toJSON()}.json`
    );
  };
}

export default store;
