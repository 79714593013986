import { Button } from '@mui/material';
import { collapseSelection } from '@udecode/plate-common';
import {
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { PrimaryAwesomeIcon } from 'features/aiWriter/slashCommands/FlashActionsInput';
import {
  setFlashActionsSelectedText,
  setFlashActionsSelection
} from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ELEMENT_FLASH_ACTIONS_INPUT } from 'features/plate/customPlugins/createflashActionsPlugin';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  selectedText: string;
  hideLabel?: boolean;
  gtmId: string;

  styleVariant?: EditorFormatButtonProps;
};

export function FlashActionsTopToolbarButton(props: Props) {
  const editor = useEditor();

  const handleClick = () => {
    if (editor.selection) {
      setFlashActionsSelectedText(props.selectedText);
      const previousSelection = editor.selection;
      setFlashActionsSelection(previousSelection);

      collapseSelection(editor, { edge: 'end' });

      editor?.insertNode({
        type: ELEMENT_FLASH_ACTIONS_INPUT,
        children: [{ text: '' }]
      });
    }
  };

  if (props.styleVariant === 'icon') {
    return (
      <EditorIconFormatButton {...withGtmInteraction(props.gtmId)} onClick={handleClick}>
        <PrimaryAwesomeIcon fontSize="small" />
      </EditorIconFormatButton>
    );
  }

  return (
    <CustomButton
      {...withGtmInteraction(props.gtmId)}
      variant="text"
      startIcon={<PrimaryAwesomeIcon />}
      onClick={handleClick}
    >
      <FormattedMessage id="common.ask_ai" />
    </CustomButton>
  );
}

const CustomButton = styled(Button)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    width: 28px;
    height: 28px;
    border-radius: ${({ theme }) => theme.borderRadius.four};
  }
`;
