import FlexContainer from 'components/FlexContainer';
import { BasicInfoButton } from 'components/iconButtons/BasicInfoButton';
import { useModal } from 'components/modals';
import { PricingRestrictionHint } from 'features/aiWriter/AiWriterSidebar/steps/PricingRestrictionHint';
import MainSubStepFormSection from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/SeoAnalysisMainSubStep/MainSubStepFormSection';
import MainSubStepSearchResultSection from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/SeoAnalysisMainSubStep/MainSubStepSearchResultSection';
import MainSubStepStatisticsSection from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/SeoAnalysisMainSubStep/MainSubStepStatisticsSection';
import MainSubStepWordAmountSection from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/SeoAnalysisMainSubStep/MainSubStepWordAmountSection';
import { getSeoAnalysis } from 'features/aiWriter/store/selectors';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

type Props = {
  isLoading?: boolean;
};

export const SeoAnalysisMainSubStep = ({ isLoading }: Props) => {
  const seoAnalysis = useAppSelector(getSeoAnalysis);
  const isSeoAnalysisAvailable = useAppSelector(getGetIsFeatureAvailable)('seoAnalysis');

  return (
    <FlexContainer gap="xxlarge">
      <MainSubStepFormSection isLoading={isLoading} />
      {isSeoAnalysisAvailable && !!seoAnalysis && (
        <>
          <MainSubStepWordAmountSection />
          <MainSubStepStatisticsSection />
          <MainSubStepSearchResultSection />
        </>
      )}
      {!isSeoAnalysisAvailable && <PricingRestrictionHint />}
    </FlexContainer>
  );
};

export function SeoInfoButton() {
  const { showModal } = useModal();
  const translate = useTr();

  function handleClick() {
    showModal('YOUTUBE_VIDEO', {
      size: 1100,
      videoId: translate('aiWriter.inspirations.seoAnalysis.info_button.video_id')
    });
  }

  return (
    <BasicInfoButton
      onClick={handleClick}
      tooltip={translate('aiWriter.inspirations.info_button.tooltip.video')}
    />
  );
}
