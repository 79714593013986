import { Chip, Typography } from '@mui/material';
import neuroflashLogo from 'assets/neuroflashLogo.svg';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { rgba } from 'polished';
import { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';
import useTr from 'utils/hooks/useTr';

const getEnvironmentBadgeLabel = () => {
  if (location.hostname === 'localhost') {
    return 'LOCAL';
  }
  if (location.host === 'app-dev.neuroflash.com') {
    return 'DEV';
  }
  if (location.host === 'app-qa.neuroflash.com') {
    return 'QA';
  }

  return null;
};

type Props = {
  sloganKey?: IntlMessageKeys;
  align?: CSSProperties['alignItems'];
  className?: string;
};

export function LogoWithSlogan({ sloganKey, align, className }: Props) {
  const translate = useTr();
  const environmentBadgeLabel = getEnvironmentBadgeLabel();
  const showEnvironmentBadge = !!environmentBadgeLabel;

  return (
    <Root className={className} align={align}>
      <LogoContainer $isSlogan={!!sloganKey}>
        <LogoImage src={neuroflashLogo} alt={translate('common.neuroflash')} />

        {showEnvironmentBadge && (
          <EnvironmentBadgeContainer>
            <RotatedChip label={environmentBadgeLabel} color="secondary" />
          </EnvironmentBadgeContainer>
        )}
      </LogoContainer>

      {sloganKey && (
        <Slogan variant="h5">
          <FormattedMessage id={sloganKey} />
        </Slogan>
      )}
    </Root>
  );
}

const Root = styled.div<{ align?: Props['align'] }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};
  align-items: ${({ align }) => align};

  @media (max-width: ${mobileWindowWidthCSS}) {
    align-items: center;
  }
`;

const LogoContainer = styled.div<{ $isSlogan?: boolean }>`
  position: relative;
  margin-bottom: ${({ theme, $isSlogan }) => ($isSlogan ? '0' : theme.spacings.three)};
`;

const LogoImage = styled.img<{ $isSlogan?: boolean }>`
  width: 460px;
`;

const EnvironmentBadgeContainer = styled.div`
  position: absolute;
  right: -40px;
  top: -15px;
`;

const RotatedChip = styled(Chip)`
  @keyframes pulsing-chip {
    0% {
      box-shadow: 0 0 0 0 ${({ theme }) => rgba(theme.colors.secondaryColorMain, 0.4)};
    }
    70% {
      box-shadow: 0 0 10px 20px ${({ theme }) => rgba(theme.colors.secondaryColorMain, 0)};
    }
    100% {
      box-shadow: 0 0 0 0 ${({ theme }) => rgba(theme.colors.secondaryColorMain, 0)};
    }
  }

  transform: rotate(40deg);

  box-shadow: 0 0 0 ${({ theme }) => theme.colors.secondaryColorMain};
  animation: pulsing-chip 2s infinite;
`;

const Slogan = styled(Typography)`
  color: ${({ theme }) => theme.colors.primaryColorMain};

  @media (max-width: ${mobileWindowWidthCSS}) {
    display: none;
  }
`;
