import { FormControlLabel, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { FieldCheckbox } from 'features/workflowBlogPostBuilder/steps/FieldCheckbox';
import {
  CheckboxGroup,
  ProChip,
  ProLabelContainer
} from 'features/workflowBlogPostBuilder/steps/Step3';

type Props = {
  isFeatureDisabled?: boolean;
  isFreePlan: boolean;
};

export const Step3ReferencesSection = ({ isFeatureDisabled, isFreePlan }: Props) => (
  <section>
    <FlexContainer gap="small" direction="row">
      <Typography variant="subtitle1">
        <FormattedMessage id="blog_post_builder.steps.elements.references" />
      </Typography>
    </FlexContainer>
    <CheckboxGroup>
      <FormControlLabel
        control={
          <FieldCheckbox fieldName="elements" value="external_links" disabled={isFeatureDisabled} />
        }
        label={
          <ProLabelContainer>
            <Typography variant="body1">
              <FormattedMessage id="blog_post_builder.steps.elements.references.ref_check" />
            </Typography>
            {isFreePlan && <ProChip />}
          </ProLabelContainer>
        }
      />
    </CheckboxGroup>
  </section>
);
