import FlexContainer from 'components/FlexContainer';
import { getRoutePath, RestrictedRoute } from 'config/routes';
import { getRestrictions } from 'features/customer/store/selectors';
import { PremiumToolButton } from 'features/homePage/PremiumToolButton';
import FormattedMessage from 'features/i18n/FormattedMessage';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';

const useIsRestricted = (slug: RestrictedRoute) => {
  const restrictions = useAppSelector(getRestrictions);

  return restrictions[slug] ?? false;
};

const useFeatureClickHandler = (restricted: boolean, navigate: ReturnType<typeof useNavigate>, route: RestrictedRoute) => {
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  return () => restricted ? showUpgradeSubscriptionModal() : navigate(getRoutePath(route));
}

export const HomeFeatureCards = (): ReactElement => {
  return (
    <FlexContainer direction="row" gap="three">
      <TestingCard />
      <ExploringCard />
    </FlexContainer>
  );
};

function TestingCard() {
  const restricted = useIsRestricted('aiTester');
  const navigate = useNavigate();
  const clickHandler = useFeatureClickHandler(restricted, navigate, 'aiTester');

  return (
    <PremiumToolButton
      title={<FormattedMessage id="product.create_and_compare" />}
      subtitle={<FormattedMessage id="product_desc.create_and_compare" />}
      gtmId={gtmIds.home.toolCard.testing}
      onClick={clickHandler}
      icon={false}
    />
  );
}

function ExploringCard() {
  const restricted = useIsRestricted('exploring');
  const navigate = useNavigate();
  const clickHandler = useFeatureClickHandler(restricted, navigate, 'exploring');

  return (
    <PremiumToolButton
      title={<FormattedMessage id="product.explorer" />}
      subtitle={<FormattedMessage id="product_desc.explorer" />}
      gtmId={gtmIds.home.toolCard.exploring}
      onClick={clickHandler}
      icon={false}
    />
  );
}
