import { AutoAwesomeRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { useAiWriterExpandedSidebarStore } from 'features/aiWriter/AiWriterSidebar/sidebar/aiWriterExpandedSidebarStore';
import { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

export const IMPROVE_PROMPT_OUTPUT_TYPE = 'improve_prompt';

type Props = {
  prompt: string;
  loading: boolean;
  undoVisible: boolean;
  isInChat?: boolean;

  onOptimizeClick: () => void;
  onUndoClick: () => void;
  onMouseDown?: (e: MouseEvent) => void;
};

export function ChatOptimizePromptButton(props: Props) {
  const { prompt, loading, undoVisible, isInChat, onOptimizeClick, onUndoClick, onMouseDown } =
    props;
  const isSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.isSidebarExpanded);

  const isTooLong = prompt.length > 5000;
  const isTooShort = prompt.length === 0;

  const tooltipText = isTooLong
    ? 'aiWriter.inspirations.chat.message.optimize.tooltip.disabled'
    : 'aiWriter.inspirations.chat.message.optimize.tooltip';

  const isDisabled = isTooLong || isTooShort;

  // for the use outside of the chat we always display the text
  const shouldDisplayText = !isInChat || isSidebarExpanded;

  if (undoVisible) {
    return (
      <Button
        {...withGtmInteraction(gtmIds.aiWriter.chat.undoOptimizePrompt)}
        onClick={onUndoClick}
      >
        <FormattedMessage id="aiWriter.inspirations.chat.message.undo" />
      </Button>
    );
  }

  if (shouldDisplayText) {
    return (
      <Tooltip title={<FormattedMessage id={tooltipText} />}>
        <FlexContainer direction="row" alignItems="center">
          <LoadingButtonGrey
            {...withGtmInteraction(gtmIds.aiWriter.chat.optimizePrompt)}
            startIcon={<AutoAwesomeRounded />}
            onClick={onOptimizeClick}
            loading={loading}
            disabled={isDisabled}
            onMouseDown={onMouseDown}
          >
            <FormattedMessage id="aiWriter.inspirations.chat.message.optimize" />
          </LoadingButtonGrey>
        </FlexContainer>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={<FormattedMessage id={tooltipText} />}>
      <span>
        <IconButton
          onClick={onOptimizeClick}
          disabled={isDisabled}
          {...withGtmInteraction(gtmIds.aiWriter.chat.optimizePrompt)}
          onMouseDown={onMouseDown}
        >
          {loading ? <CircularProgress size={20} /> : <OptimizeIcon />}
        </IconButton>
      </span>
    </Tooltip>
  );
}
const OptimizeIcon = styled(AutoAwesomeRounded)``;

const LoadingButtonGrey = styled(LoadingButton)`
  color: ${({ theme }) => theme.colors.componentsIconActive};
`;
