import { ChatStep } from 'features/aiWriter/AiWriterSidebar/steps/chat/ChatStep';
import { ImagesStep } from 'features/aiWriter/AiWriterSidebar/steps/ImagesStep';
import { PerformanceStep } from 'features/aiWriter/AiWriterSidebar/steps/PerformanceStep';
import { TemplatesStep } from 'features/aiWriter/AiWriterSidebar/steps/TemplatesStep';
import { TextInspirationsStep } from 'features/aiWriter/AiWriterSidebar/steps/TextInspirationsStep';
import { getInspirationsCurrentStep } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const AiWriterSidebarViews = () => {
  const inspirationsCurrentStep = useAppSelector(getInspirationsCurrentStep);

  return (
    <Root>
      {(() => {
        switch (inspirationsCurrentStep) {
          case AiWriterSidebarStep.aiWriter:
            return <TextInspirationsStep />;
          case AiWriterSidebarStep.images:
            return <ImagesStep />;

          case AiWriterSidebarStep.templates:
            return <TemplatesStep />;

          case AiWriterSidebarStep.chat:
            return <ChatStep />;
          case AiWriterSidebarStep.performance:
            return <PerformanceStep />;
        }
      })()}
    </Root>
  );
};

const Root = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;
