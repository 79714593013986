import { TextInputField } from 'features/workflowBlogPostBuilder/steps/components/TextInputField';
import { KeyboardEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import useTr from 'utils/hooks/useTr';

import * as CommonStyled from './common.styles';

type Props = {
  onKeyDown?: (event: KeyboardEvent) => void;
};
export const SelectTitleStep = ({ onKeyDown }: Props) => {
  const translate = useTr();

  return (
    <>
      <CommonStyled.Title>
        <FormattedMessage id="blog_post_builder.steps.title.title" />
      </CommonStyled.Title>

      <TextInputField
        name="title"
        onKeyDown={onKeyDown}
        placeholder={translate('blog_post_builder.steps.title.placeholder')}
      />
    </>
  );
};
