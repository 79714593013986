import { ProgressCategory } from 'components/ProgressBar/RangeProgressBar';
import cuid from 'cuid';
import { AdwordsAttributeType } from 'services/api/adwords/types';
import { Dimension, ManualDimension } from 'services/api/dimension/types';
import { ScoreWordsData, ScoreWordsGoalConfig } from 'services/api/wordEmbedding/types';
import { adjustDimensionIds } from 'store/utils/dataUtils';
import { progressBarColors } from 'styles/styleUtils';
import {
  WordAttribute,
  WordAttributeWithOption,
  WordAttributeWithOptions
} from 'types/ReduxStateTypes';

import LocalStorageKey from '../../../config/localStorageKey';
import { ConceptFlashTab, ConceptFlashTabData } from './types';

export const defaultTab: ConceptFlashTab = {
  id: '',
  name: '',
  isInitialized: false,
  settingsModalShown: false,
  filters: [],
  embeddingModelId: '',
  fromList: false,
  seoParams: [],
  seedWords: [],
  goalConfig: {
    seed_score: 1,
    model_rank_score: 1,
    dimensions: [],
    mappers: []
  },
  modelMapperId: '',
  manualDimensions: [],
  dimensions: [],
  wordAttributes: [
    { value: 'seed_score' },
    {
      value: 'model_rank_score',
      label: 'complexity',
      option: { label: 'complexity.easy', value: 'easy', icon: 0x1f642 }
    }
  ],
  i16s: {},
  data: [],
  graphColoring: 'seed_score',
  palette: 'redBlue',
  translationEnabled: false,
  graphTranslationEnabled: false,
  graphI16sEnabled: false,
  graphZoomEnabled: false,
  lookupTable: {},
  tableState: {},
  selectedRows: []
};

export const columnCategoriesDict: { [key: string]: ProgressCategory[] } = {
  model_rank_score: [
    {
      label: 'complexity.easy',
      startValue: 0.0,
      endValue: 0.2,
      color: progressBarColors.green
    },
    {
      label: 'complexity.medium',
      startValue: 0.2,
      endValue: 0.6,
      color: progressBarColors.yellow
    },
    {
      label: 'complexity.complex',
      startValue: 0.6,
      endValue: 1.0,
      color: progressBarColors.red
    }
  ]
};

export const wordAttributeWithOptions: WordAttributeWithOptions[] = [
  {
    value: 'model_rank_score',
    label: 'complexity',
    options: [
      {
        value: 'easy',
        label: 'complexity.easy',
        icon: 0x1f642
      },
      {
        value: 'complex',
        label: 'complexity.complex',
        icon: 0x1f914
      }
    ]
  },
  {
    value: 'valence',
    label: 'sentiment',
    options: [
      {
        label: 'goal_score.valence.happy',
        value: 'happy',
        icon: 0x1f60a
      },
      {
        label: 'goal_score.valence.sad',
        value: 'sad',
        icon: 0x1f625,
        oppositeValue: true
      }
    ]
  },
  {
    value: 'arousal',
    options: [
      {
        label: 'goal_score.arousal.exciting',
        value: 'exciting',
        icon: 0x1f62e
      },
      {
        label: 'goal_score.arousal.calm',
        value: 'calm',
        icon: 0x1f60c,
        oppositeValue: true
      }
    ]
  },
  {
    value: 'dominance',
    options: [
      {
        label: 'goal_score.dominance.powerful',
        value: 'powerful',
        icon: 0x1f60e
      },
      {
        label: 'goal_score.dominance.gentle',
        value: 'gentle',
        icon: 0x1f92b,
        oppositeValue: true
      }
    ]
  }
];

export const seoParamsOptions: AdwordsAttributeType[] = [
  'averagecpc',
  'competition',
  'searchvolume'
];

function transformCommonnessIntoComplexity(commonness: number) {
  return 1 - commonness;
}

export const createEmptyTab = (withValence?: boolean): ConceptFlashTab => ({
  ...defaultTab,
  id: cuid(),
  embeddingModelId: localStorage.getItem(LocalStorageKey.ExplorerModel) || '',
  modelMapperId: localStorage.getItem(LocalStorageKey.ExplorerModelMapper) || '',
  wordAttributes: withValence
    ? defaultTab.wordAttributes.concat([
        {
          value: 'valence',
          option: { label: 'goal_score.valence.happy', value: 'happy', icon: 0x1f60a }
        }
      ])
    : defaultTab.wordAttributes,
  graphColoring: withValence ? 'valence' : 'seed_score',
  palette: withValence ? 'redGreen' : 'redBlue'
});

export function prepareExplorerData(responseData: ScoreWordsData): ConceptFlashTabData[] {
  return responseData.scored_words.map(row => ({
    ...row,
    word: row.word.replace(/_/g, ' '),
    translation_en: row.translation_en ? row.translation_en.replace(/_/g, ' ') : null,
    dimension_scores: adjustDimensionIds(row.dimension_scores),
    model_rank_score: transformCommonnessIntoComplexity(row.model_rank_score),
    ...row.mapper_scores.reduce<Record<string, number>>((scores, { variable, score }) => {
      scores[variable] = score;
      return scores;
    }, {})
  }));
}

export function getAvailableGraphColoring(wordAttributes: WordAttribute[]) {
  const choiceOrder: WordAttribute[] = ['valence', 'seed_score', 'model_rank_score'];
  for (const choice of choiceOrder) {
    if (wordAttributes.includes(choice)) return choice;
  }
  return 'goal_score';
}

export const prepareGoalConfig = (
  wordAttributes: WordAttributeWithOption[],
  dimensions: Dimension[],
  manualDimensions: ManualDimension[]
): ScoreWordsGoalConfig => {
  const wordAttributesModelValues = wordAttributes.map(attr => attr.value);
  return {
    model_rank_score: wordAttributesModelValues.includes('model_rank_score') ? 1 : 0,
    seed_score: wordAttributesModelValues.includes('seed_score') ? 1 : 0,
    mappers: wordAttributes.map(({ value, option }) => ({
      id: value,
      weight: option?.oppositeValue ? -1 : 1
    })),
    dimensions: [...dimensions, ...manualDimensions].map(({ id }) => ({ id, weight: 1 }))
  };
};
