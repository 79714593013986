import axios, { AxiosInstance } from 'axios';
import { showChurnKeyPauseWall } from 'features/churnKey/churnkeySlice';
import store from 'store/store';
import { isObject } from 'utils/isObject';

const errorCode = 'ERROR_SUBSCRIPTION_IS_PAUSED';

export const isSubscriptionPausedError = (error: unknown) =>
  axios.isAxiosError(error) && getErrorMessage(error.response?.data) === errorCode;

export const isSubscriptionPausedResponse = (response: unknown) =>
  response &&
  isObject(response) &&
  isObject(response?.data) &&
  response?.data?.message === errorCode;

const getErrorMessage = (data: unknown) => {
  if (isObject(data)) {
    return data.message;
  } else {
    return undefined;
  }
};

export const withChurnKeyPauseWall = (client: AxiosInstance) => {
  client.interceptors.response.use(undefined, (error: unknown) => {
    if (isSubscriptionPausedError(error)) {
      store.dispatch(showChurnKeyPauseWall());
    }

    throw error;
  });
};
