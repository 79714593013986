import { Dispatch, SetStateAction, useEffect } from 'react';
import { isValidHttpUrl } from 'utils/IsValidHttpUrl';

export const useValidateUrlAndUpdateState = (
  text: string,
  setIsUrlValid: Dispatch<SetStateAction<boolean>>
) => {
  useEffect(() => {
    // Empty field is also valid
    if (text.length === 0) {
      setIsUrlValid(true);
      return;
    }

    setIsUrlValid(isValidHttpUrl(text));
  }, [setIsUrlValid, text]);
};
