import { Typography } from '@mui/material';
import { BuilderModularWorkflowStepOutput } from 'features/modular-workflow/builder/types-builder';
import { TextInputField } from 'features/modular-workflow/runner/fields/TextInputField';
import { UrlInputField } from 'features/modular-workflow/runner/fields/UrlInputField';
import { RunnerError } from 'features/modular-workflow/runner/getRunnerErrorsFromResponse';
import {
  RunnerModularWorkflowStep,
  RunnerModularWorkflowStepInput
} from 'features/modular-workflow/runner/types-runner';
import { useModulareWorkflowRunnerStore } from 'features/modular-workflow/runner/useModulareWorkflowRunnerStore';
import { createContext, KeyboardEvent } from 'react';

type FieldRendererContextType = {
  step: RunnerModularWorkflowStep;
  stepIndex: number;
  input: RunnerModularWorkflowStepInput;
  inputOutputs: BuilderModularWorkflowStepOutput[];
  inputErrorList: RunnerError[];
  onChange: (
    input: RunnerModularWorkflowStepInput,
    value: RunnerModularWorkflowStep['inputs'][number]['value']
  ) => void;
  onValidationChange: (inputRef: string, isValid: boolean) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
};

export const FieldRendererContext = createContext<FieldRendererContextType>(
  {} as unknown as FieldRendererContextType
);

type Props = {
  step: RunnerModularWorkflowStep;
  input: RunnerModularWorkflowStepInput;
  errorList: RunnerError[];
  onChange: FieldRendererContextType['onChange'];
  onValidationChange: FieldRendererContextType['onValidationChange'];
  onKeyDown?: (event: KeyboardEvent) => void;
};

export const FieldRenderer = ({
  input,
  step,
  errorList,
  onChange,
  onValidationChange,
  onKeyDown
}: Props) => {
  const inputOutputs = step.outputs.filter(
    output => output.related_input_reference === input.reference
  );
  const stepIndex = useModulareWorkflowRunnerStore(state => state.selectedStepIndex);

  return (
    <FieldRendererContext.Provider
      value={{
        step,
        stepIndex,
        input,
        inputOutputs,
        inputErrorList: errorList,
        onChange,
        onValidationChange,
        onKeyDown
      }}
    >
      {(() => {
        switch (input.reference) {
          case 'text_input_field':
            return <TextInputField />;
          case 'url_scrape_field':
            return <UrlInputField />;
        }

        return (
          <Typography color="red" fontWeight="bold">
            {`Unsupported field: ${input.reference}`}
          </Typography>
        );
      })()}
    </FieldRendererContext.Provider>
  );
};
