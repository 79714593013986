import { Button, Link } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { useModal } from 'components/modals';
import { CloseModal } from 'components/modals/types';
import { getRoutePath } from 'config/routes';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { ACLPricing } from 'services/api/customer/types';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type CustomModalProps = {
  selectedPricingPlan: ACLPricing;
};

type ModalProps = CloseModal & CustomModalProps;

export const PricingDowngradePreventionModal = (props: ModalProps) => {
  const { closeModal, selectedPricingPlan } = props;

  const navigate = useNavigate();
  const translate = useTr();

  const handleGoToUsers = () => {
    closeModal();
    navigate(getRoutePath('workspace'));
  };

  return (
    <Root>
      <FlexContainer direction="row" justifyContent="space-between" alignItems="center">
        <ColoredTypography variant="h5">
          <FormattedMessage id="pricing.downgrade_prevention_modal.title" />
        </ColoredTypography>
        <DeleteIconButton onClick={closeModal} tooltip={translate('common.close')} />
      </FlexContainer>

      <ColoredTypography variant="body1">
        <FormattedMessage
          id="pricing.downgrade_prevention_modal.body"
          values={{
            br: <br />,
            pricingPlan: selectedPricingPlan.name,
            details: (text: string) => (
              <Link
                href={translate('pricing.downgrade_prevention_modal.helpcenter_link_id')}
                target="_blank"
              >
                {text}
              </Link>
            ),
            contact: (text: string) => (
              <Link href="mailto:magicpen@neuroflash.com" target="_blank" rel="noreferrer">
                {text}
              </Link>
            )
          }}
        />
      </ColoredTypography>

      <FlexContainer direction="row" gap="medium" justifyContent="flex-end">
        <Button onClick={handleGoToUsers}>
          <FormattedMessage id="pricing.downgrade_prevention_modal.go_to_users" />
        </Button>
        <Button onClick={closeModal} variant="contained">
          <FormattedMessage id="common.ok" />
        </Button>
      </FlexContainer>
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({ gap: 'medium' })`
  padding: ${({ theme }) => theme.spacings.xmedium};
`;

export const usePricingDowngradePreventionModal = () => {
  const { showModal } = useModal();
  const userCurrentPricing = useAppSelector(state => state.customer.pricing);

  return () => {
    showModal('PRICING_DOWNGRADE', {
      size: 600,
      selectedPricingPlan: userCurrentPricing
    });
  };
};
