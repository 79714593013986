import axios from 'axios';
import { ErrorScope, logError } from 'features/logging/logError';

export const reportErrors = <E extends Error>(scope: ErrorScope, error: E) => {
  // This is handled by the global error boundary
  if (axios.isAxiosError(error)) {
    return;
  }

  logError(error, scope);
};
