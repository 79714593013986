import EditIcon from '@mui/icons-material/Edit';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import NotesIcon from '@mui/icons-material/Notes';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Typography } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import { CloseModal } from 'components/modals/types';
import { TermOfServiceModalFooter } from 'features/information/TermOfServiceModalFooter';
import {
  CREATION_METHODS,
  CreationMethod,
  personalityCreationModalHeight,
  ViewMode
} from 'features/personality/creation/PersonalityCreationModal';
import {
  ModalBodyWithColumnDirection,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { actionableCard } from 'styles/styledComponents/mixins';

export function PersonalityChoiceView(
  props: {
    setView: (view: ViewMode) => void;
    setCreationMethod: (value: CreationMethod) => void;
  } & CloseModal
) {
  const { setView, setCreationMethod, closeModal } = props;

  const handleManualCreationClick = () => {
    setCreationMethod(CREATION_METHODS.MANUAL);
    setView(ViewMode.create_or_edit);
  };

  const handleAutomaticCreationClick = () => {
    setCreationMethod(CREATION_METHODS.COPY_PASTE);
    setView(ViewMode.create_automatic);
  };

  const handlePersonalityFormInformationClick = () => {
    setCreationMethod(CREATION_METHODS.FROM_INFORMATION);
    setView(ViewMode.create_from_information);
  };

  const handlePersonalityShareUrlClick = () => {
    setCreationMethod(CREATION_METHODS.SHARE_URL);
    setView(ViewMode.share_url);
  };

  return (
    <ModalWithDividedHeaderLayout
      title={<FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.title" />}
      helpLink="aiWriter.inspirations.aiwriter.main.personality.help_link"
      closeModal={closeModal}
      height={personalityCreationModalHeight}
      slots={{
        bodyContainer: ModalBodyWithColumnDirection
      }}
    >
      <Content>
        <TilesBox>
          <Tile
            {...withGtmInteraction(gtmIds.personalitiesLibrary.manualCreationChoice)}
            onClick={handleManualCreationClick}
          >
            <Typography variant="h6">
              <FormattedMessage
                id="aiWriter.inspirations.chat.personalities.library_modal.choice.manual.title"
                values={{
                  br: <br />
                }}
              />
            </Typography>
            <EditIcon color="primary" />
            <ColoredTypography variant="body2" color="blackMediumEmphasis">
              <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.choice.manual.body" />
            </ColoredTypography>
          </Tile>
          <Tile
            {...withGtmInteraction(gtmIds.personalitiesLibrary.automatedCreationChoice)}
            onClick={handleAutomaticCreationClick}
          >
            <Typography variant="h6">
              <FormattedMessage
                id="aiWriter.inspirations.chat.personalities.library_modal.choice.copy_paste.title"
                values={{
                  br: <br />
                }}
              />
            </Typography>
            <NotesIcon color="primary" />
            <ColoredTypography variant="body2" color="blackMediumEmphasis">
              <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.choice.automatic.body" />
            </ColoredTypography>
          </Tile>

          <Tile
            {...withGtmInteraction(gtmIds.personalitiesLibrary.fromInformationCreationChoice)}
            onClick={handlePersonalityFormInformationClick}
          >
            <Typography variant="h6">
              <FormattedMessage
                id="aiWriter.inspirations.chat.personalities.library_modal.choice.fromInfo.title"
                values={{
                  br: <br />
                }}
              />
            </Typography>
            <PlaylistAddCheckIcon color="primary" />
            <ColoredTypography variant="body2" color="blackMediumEmphasis">
              <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.choice.fromInfo.body" />
            </ColoredTypography>
          </Tile>

          <Tile
            {...withGtmInteraction(gtmIds.personalitiesLibrary.shareURLCreationChoice)}
            onClick={handlePersonalityShareUrlClick}
          >
            <Typography variant="h6">
              <FormattedMessage
                id="aiWriter.inspirations.chat.personalities.library_modal.choice.shareUrl.title"
                values={{
                  br: <br />
                }}
              />
            </Typography>
            <InsertLinkIcon color="primary" />
            <ColoredTypography variant="body2" color="blackMediumEmphasis">
              <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.shareUrl.fromInfo.body" />
            </ColoredTypography>
          </Tile>
        </TilesBox>
      </Content>

      <TermOfServiceModalFooter />
    </ModalWithDividedHeaderLayout>
  );
}

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const TilesBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.four};
`;

const Tile = styled.div`
  ${actionableCard};
  position: relative;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacings.four};

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.two};

  text-align: center;
`;
