import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

type Props = {
  children: React.ReactNode;
};

export const RecaptchaProvider = ({ children }: Props) => {
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey} useEnterprise={true}>
      {children}
    </GoogleReCaptchaProvider>
  );
};
