import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { URLString } from 'utils/IsValidHttpUrl';

export type CreateInformationFromUrlParams = {
  url: URLString;
};

export type CreateInformationFromUrlDto = {
  content: string;
};

type SuccessDto = BaseSuccessDto<CreateInformationFromUrlDto>;

export const httpCreateInformationFromUrl = mutationEndpoint({
  callEndpoint: (params: CreateInformationFromUrlParams) =>
    backofficeClient
      .post<SuccessDto>('/informations/from-url', params)
      .then(response => response.data)
      .catch(error => error.response.data)
});
