import { BriefConfigValues } from 'features/aiTester/modals/briefConfig/BriefConfigModal';
import {
  getCurrentModelLanguageAndCountry,
  getTesterActiveTab
} from 'features/aiTester/store/selectors';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import {
  useGetDefaultOutputTypeByLanguageMemoized,
  useGetPrefillValueByLanguageCountryAndOutputType
} from 'features/textGenerator/utils/useGetPromptsOthers';
import { useSelector } from 'react-redux';

export default function useInitialValues(): BriefConfigValues {
  const { generateTextConfig } = useSelector(getTesterActiveTab);
  const { currentModelLanguage, currentModelCountry } = useSelector(
    getCurrentModelLanguageAndCountry
  );

  const getPrefillValue = useGetPrefillValueByLanguageCountryAndOutputType(
    currentModelLanguage,
    currentModelCountry
  );
  const outputType = useGetDefaultOutputTypeByLanguageMemoized(
    currentModelLanguage,
    currentModelCountry
  );

  return {
    text: generateTextConfig?.text || getPrefillValue(outputType, 'text') || '',
    keywords: generateTextConfig?.keywords || getPrefillValue(outputType, 'keywords') || '',
    keywords2: generateTextConfig?.brand || getPrefillValue(outputType, 'keywords2') || '',
    outputType: generateTextConfig?.outputType || outputType,
    userTonality: generateTextConfig?.userTonality || [],
    systemTonality: (generateTextConfig?.systemTonality || []) as SystemTonality[],
    nTextItems: generateTextConfig?.nTextItems || 2,
    personalityId: generateTextConfig?.personalityId || undefined,
    informationList: generateTextConfig?.informationList || undefined
  };
}
