import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColoredTypography } from 'components/ColoredTypography';
import { PersonalityChatSelector } from 'components/personality/PersonalityChatSelector';
import { savePersonalityId } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { CommandTemplatesButton } from 'features/aiWriter/AiWriterSidebar/steps/chat/CommandTemplatesButton';
import { useSetDefaultChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useSetDefaultPersonality';
import {
  ChatOptimizePromptButton,
  IMPROVE_PROMPT_OUTPUT_TYPE
} from 'features/aiWriter/chat/ChatOptimizePromptButton';
import { GptSelect } from 'features/aiWriter/chat/GptSelect';
import { InformationButton } from 'features/aiWriter/chat/InformationButton';
import { useShowTemplatesModal } from 'features/aiWriter/commandTemplates/useShowTemplatesModal';
import { useUpdateCommandTemplateUsage } from 'features/aiWriter/commandTemplates/useUpdateCommandTemplateUsage';
import { useGetProjectOrPreselectedLanguageAndCountry } from 'features/aiWriter/hooks/useGetProjectOrPreselectedLanguageAndCountry';
import { MessageConfig } from 'features/homePage/chatForm/CreateChatForm';
import { usePromptOptimizerControlled } from 'features/textGenerator/promptOptimizer/usePromptOptimizerContorlled';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { usePostHog } from 'posthog-js/react';
import { ChangeEvent, KeyboardEvent } from 'react';
import { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { GptModel } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { ChatMessageSource, GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  modelId: string;
  isSending: boolean;
  value: MessageConfig;
  sendButtonConfig: { gtmId: string; disabled?: boolean };
  onChange: (message: MessageConfig) => void;
  onSend: (text: string) => void;
};

export function OverviewMessageInput({
  modelId,
  isSending,
  value,
  sendButtonConfig,
  onChange,
  onSend
}: Props) {
  const translate = useTr();
  const { mutate: setDefaultPersonality } = useSetDefaultChatPersonality();

  const postHog = usePostHog();

  const { text, gptModel, personality, informationList } = value;

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      sendMessage({ source: 'enter' });
    }
  }

  function handleClick() {
    sendMessage({ source: 'button' });
  }

  function sendMessage({ source }: { source: ChatMessageSource }) {
    GAEvents.sentChatMessage({ source });
    postHog?.capture('CF - chat message sent via overview');
    onSend(text);
  }

  function selectAdornment() {
    if (isSending) {
      return <CircularProgress size={24} />;
    }

    return (
      <IconButton
        {...withGtmInteraction(sendButtonConfig.gtmId)}
        type="button"
        onClick={handleClick}
        aria-label={translate('chat.message_input.placeholder')}
        disabled={!text.length || sendButtonConfig.disabled}
      >
        <SendIcon />
      </IconButton>
    );
  }

  const handleGptModelChange = (event: SelectChangeEvent<unknown>) => {
    const newModel = event.target.value as GptModel;
    // if upgrade option is selected it has no value therefore we do nothing here
    if (!newModel) {
      return;
    }
    onChange({ ...value, gptModel: newModel });
  };

  const handleTemplateSelect = (template: string) => {
    onChange({ ...value, text: template });
    onSend(template);

    if (isUndoVisible) {
      setIsUndoVisible(false);
    }
  };

  const handlePersonalitySelect = (personality: PersonalityDto | undefined | null) => {
    onChange({ ...value, personality });
    // important to save personality for chat
    savePersonalityId(personality?.id ?? null);

    if (personality) {
      setDefaultPersonality({
        personalityId: personality.id,
        country: personality.country,
        language: personality.language
      });
    }
  };

  const handleInformationSelect = (informationList: InformationDto[]) => {
    onChange({ ...value, informationList });
  };

  const { language, country } = useGetProjectOrPreselectedLanguageAndCountry(modelId);

  const params = { language, country };
  useQuery({
    queryKey: httpGetDefaultPersonality.makeQueryKey(params),
    queryFn: () => httpGetDefaultPersonality.callEndpoint(params),
    onSuccess: data => {
      if (data) {
        onChange({ ...value, personality: data });

        return;
      }

      onChange({ ...value, personality: undefined });
    }
  });

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({ ...value, text: e.currentTarget.value });

    if (isUndoVisible) {
      setIsUndoVisible(false);
    }
  };

  const {
    isUndoVisible,
    setInitialPrompt,
    optimizePromptMutation,
    undoPromptOptimization,
    setIsUndoVisible
  } = usePromptOptimizerControlled({
    prompt: text,
    setPrompt: (prompt: string) => onChange({ ...value, text: prompt }),
    locale: { language, country },
    initialPrompt: '',
    outputType: IMPROVE_PROMPT_OUTPUT_TYPE
  });
  const { mutate: optimizePrompt, isLoading: isOptimizationLoading } = optimizePromptMutation;

  const handleOptimizeClick = () => {
    setInitialPrompt(text);
    optimizePrompt();
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <TextField
        variant="standard"
        value={text}
        onChange={handleTextFieldChange}
        onKeyDown={handleKeyDown}
        aria-label={translate('chat.message_input.aria_label')}
        placeholder={translate('chat.message_input.placeholder')}
        disabled={isSending}
        multiline
        maxRows={4}
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">{selectAdornment()}</InputAdornment>,
          disableUnderline: true
        }}
        sx={{
          width: '100%',
          '& > div': {
            paddingBottom: '55px'
          },
          padding: '16.5px 14px 0 14px'
        }}
        inputProps={{
          'data-lt-active': 'false'
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          position: 'absolute',
          bottom: '0px',
          left: '0px',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <ActionBox>
          <PersonalityChatSelector
            value={personality}
            onChange={handlePersonalitySelect}
            onClick={() => GAEvents.overviewPersonalitySelectOpen()}
            languageModelId={modelId}
            menuOpeningDirection="bottom"
          />
          <Separator />
          <InformationButton value={informationList} onChange={handleInformationSelect} />
          <Separator />
          <GptSelect
            onClick={() => GAEvents.overviewGptSelectOpen()}
            value={gptModel}
            onChange={handleGptModelChange}
            menuOpeningDirection="bottom"
          />
          <Separator />
          <TemplateSelect modelId={modelId} onSelection={handleTemplateSelect} />
        </ActionBox>

        <ChatOptimizePromptButton
          prompt={text}
          undoVisible={isUndoVisible}
          loading={isOptimizationLoading}
          onOptimizeClick={handleOptimizeClick}
          onUndoClick={undoPromptOptimization}
        />
      </Box>
    </Box>
  );
}

export const ActionBox = styled.div`
  display: flex;
  align-items: center;
`;

export function ShortcutHint() {
  return (
    <ColoredTypography variant="caption" color="blackMediumEmphasis">
      <FormattedMessage id="chat.message_input.new_line_shortcut" />
    </ColoredTypography>
  );
}

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.inputBackground};
  height: 19px;
  width: 1px;
  margin: 0 5px;
`;

type TemplateSelectProps = {
  onMouseDown?: (e: MouseEvent) => void;
  modelId: string;
  onSelection: (template: string) => void;
};

export function TemplateSelect(props: TemplateSelectProps) {
  const { modelId, onSelection, onMouseDown } = props;

  const showTemplatesModal = useShowTemplatesModal();
  const { mutate: updateCommandTemplateUsage } = useUpdateCommandTemplateUsage();

  function handleSelectTemplate() {
    trackingWrapper.track('templatesOpenedViaChat');
    showTemplatesModal({
      preselectedModelId: modelId,
      onTemplateSelect: dto => {
        trackingWrapper.track('templateSelected', {
          templateId: dto.id,
          templateLabel: dto.title,
          templateCountry: dto.country,
          templateLanguage: dto.language
        });

        updateCommandTemplateUsage({ templateId: dto.id });
        onSelection(dto.template);
        GAEvents.promptInChatSent();
      }
    });
  }

  return (
    <CommandTemplatesButton
      gtmId={gtmIds.aiWriter.projectOverview.chat.openPromptLibrary}
      onSelection={handleSelectTemplate}
      onMouseDown={onMouseDown}
    />
  );
}
