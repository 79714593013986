import {
  AiWriterSupportedLanguageAndCountryCode,
  aiWriterSupportedLanguageAndCountryCodes
} from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';

type ExampleData = {
  title: string;
  text: string;
};

export const welcomeMessage: Record<AiWriterSupportedLanguageAndCountryCode, ExampleData> = {
  [aiWriterSupportedLanguageAndCountryCodes.de_de]: {
    title: 'Hallo',
    text: '\n\nich bin ChatFlash! Wie kann ich dir helfen?\nTipp! Wähle eine Markenstimme aus, um personalisierte Antworten zu erhalten.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.de_at]: {
    title: 'Hallo',
    text: '\n\nich bin ChatFlash! Wie kann ich dir helfen?\nTipp! Wähle eine Markenstimme aus, um personalisierte Antworten zu erhalten.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.de_ch]: {
    title: 'Hallo',
    text: '\n\nich bin ChatFlash! Wie kann ich dir helfen?\nTipp! Wähle eine Markenstimme aus, um personalisierte Antworten zu erhalten.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.en_us]: {
    title: 'Hello',
    text: '\n\nI am ChatFlash! How can I help?\nTip! Select a brand voice to get personalized responses.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.en_uk]: {
    title: 'Hello',
    text: '\n\nI am ChatFlash! How can I help?\nTip! Select a brand voice to get personalized responses.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.fr_fr]: {
    title: 'Bonjour',
    text: '\n\nJe suis ChatFlash ! Comment puis-je vous aider ?\nAstuce ! Sélectionnez une voix de marque pour obtenir des réponses personnalisées'
  },
  [aiWriterSupportedLanguageAndCountryCodes.fr_ch]: {
    title: 'Bonjour',
    text: '\n\nJe suis ChatFlash ! Comment puis-je vous aider ?\nAstuce ! Sélectionnez une voix de marque pour obtenir des réponses personnalisées'
  },
  [aiWriterSupportedLanguageAndCountryCodes.es_es]: {
    title: 'Hola',
    text: '\n\n¡Soy ChatFlash! ¿Cómo puedo ayudarte?\nConsejo: Selecciona una voz de marca para obtener respuestas personalizadas.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.ca_es]: {
    title: 'Hola',
    text: '\n\n¡Soy ChatFlash! ¿Cómo puedo ayudarte?\nConsejo: Selecciona una voz de marca para obtener respuestas personalizadas.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.nl_nl]: {
    title: 'Hallo',
    text: '\n\nik ben ChatFlash! Hoe kan ik je helpen?\nTip! Selecteer een merkstem voor gepersonaliseerde antwoorden.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.it_it]: {
    title: 'Ciao',
    text: '\n\nsono ChatFlash! Come posso aiutarti?\nSuggerimento! Seleziona una voce di marca per ricevere risposte personalizzate'
  },
  [aiWriterSupportedLanguageAndCountryCodes.pl_pl]: {
    title: 'Cześć',
    text: '\n\njestem ChatFlash! Jak mogę pomóc?\nWskazówka! Wybierz głos marki, aby uzyskać spersonalizowane odpowiedzi.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.pt_pt]: {
    title: '👋 Olá',
    text: '\n\nsou o ChatFlash! Como posso ajudar?\nDica! Selecione uma voz de marca para obter respostas personalizadas.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.pt_br]: {
    title: 'Olá',
    text: '\n\neu sou o ChatFlash! Como posso ajudar?\nDica! Selecione uma voz de marca para obter respostas personalizadas.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.es_mx]: {
    title: 'Hola',
    text: '\n\n¡Soy ChatFlash! ¿Cómo puedo ayudarte?\nConsejo: Selecciona una voz de marca para obtener respuestas personalizadas.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.sv_se]: {
    title: 'Hej',
    text: '\n\nJag är ChatFlash! Hur kan jag hjälpa till?\nTips! Välj en varumärkesröst för att få personligt anpassade svar.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.cs_cz]: {
    title: 'Ahoj',
    text: '\n\njsem ChatFlash! Jak mohu pomoci?\nTip! Vyberte si hlas značky pro získání personalizovaných odpovědí.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.hu_hu]: {
    title: 'Helló',
    text: '\n\nén vagyok a ChatFlash! Hogyan segíthetek?\nTipp! Válassz egy márkhangot a személyre szabott válaszokhoz.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.hr_hr]: {
    title: 'Bok',
    text: '\n\nja sam ChatFlash! Kako ti mogu pomoći?\nSavjet! Odaberi glas marke za personalizirane odgovore.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.zh_cn]: {
    title: '你好',
    text: '\n\n我是ChatFlash！我可以帮你什么？\n提示！选择一个品牌声音以获得个性化的响应。'
  },
  [aiWriterSupportedLanguageAndCountryCodes.tr_tr]: {
    title: 'Merhaba',
    text: '\n\nben ChatFlash! Size nasıl yardımcı olabilirim?\nİpucu! Kişiselleştirilmiş yanıtlar almak için bir marka sesi seçin.'
  }
};
