import DownloadIcon from '@mui/icons-material/Download';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import ShareIcon from '@mui/icons-material/Share';
import { Box, IconButton, Link, Paper, Tooltip, Typography } from '@mui/material';
import { ELEMENT_IMAGE } from '@udecode/plate-media';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import Toast from 'components/toasts/Toast';
import LocalStorageKey from 'config/localStorageKey';
import { getRoutePath } from 'config/routes';
import dayjs from 'dayjs';
import { AddImageToDocumentMenu } from 'features/aiImages/AiImagesPage/AddImageToDocumentMenu';
import { AddToExistingDocumentDialog } from 'features/aiImages/AiImagesPage/AddToExistingDocumentDialog';
import { AddToNewDocumentDialog } from 'features/aiImages/AiImagesPage/AddToNewDocumentDialog';
import { useAiImagesGeneratorContext } from 'features/aiImages/AiImagesPage/AiImagesGeneratorContext';
import { ErrorMessage } from 'features/aiImages/AiImagesPage/ErrorMessage';
import { useShowUpgradeImagesModal } from 'features/aiImages/AiImagesPage/UpgradeImagesModal';
import { ImageGeneratorResult } from 'features/aiImages/AiImagesPage/useImageGenerator';
import { useInsertImageToEditor } from 'features/aiImages/AiImagesPage/useInsertImageToEditor';
import { configureChatTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/configureChatTabThunk';
import { initializeTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/initializeTabThunk';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import getModelInitValue from 'features/embeddingModels/store/utils/getModelInitValue';
import { LoadingAnimationWithAlternatingMessages } from 'features/loading-spinner/LoadingAnimationWithAlternatingMessages';
import { useCreateEmptyPage } from 'features/plate/components/plate-ui/useCreateEmptyPage';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingPlan } from 'features/pricing/types';
import { useGetDefaultOutputTypeByLanguageMemoized } from 'features/textGenerator/utils/useGetPromptsOthers';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { usePostHog } from 'posthog-js/react';
import { ReactElement, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { resetLink } from 'styles/styledComponents/resets';
import useDialog from 'utils/hooks/useDialog';
import useTr from 'utils/hooks/useTr';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type ImagesBoxProps = {
  isInsideContentFlash?: boolean;
};

export const ImagesBox = ({ isInsideContentFlash }: ImagesBoxProps): ReactElement => {
  const { generatedImages } = useAiImagesGeneratorContext();
  const showBorder = generatedImages.length === 0 && !isInsideContentFlash;

  return (
    <Paper
      variant="elevation"
      elevation={0}
      sx={{
        minHeight: isInsideContentFlash ? '0' : '512px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: isInsideContentFlash ? 'flex-start' : 'center',
        flexWrap: 'wrap',
        flex: 1,
        columnGap: 2,
        rowGap: 1,
        borderWidth: showBorder ? '1px' : 0
      }}
    >
      <GeneratorResultContent isInsideContentFlash={isInsideContentFlash} />
    </Paper>
  );
};

function GeneratorResultContent({ isInsideContentFlash }: ImagesBoxProps): ReactElement | null {
  const translate = useTr();
  const { showModal } = useModal();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { insertImageToEditor } = useInsertImageToEditor();
  const { createEmptyPage } = useCreateEmptyPage();
  const [currentImgUrl, setCurrentImgUrl] = useState<string>('');

  const dispatch = useAppDispatch();

  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const defaultLanguage: EmbeddingModel = getModelInitValue(
    models,
    LocalStorageKey.AiWriterModel
  ) ??
    models[0] ?? {
      // On first mount, after login, this is rendered before models are loaded
      language: 'en',
      country: 'us',
      label: 'hardcoded_default_en_us',
      id: ''
    };
  const audiences = useAppSelector(getUserAudiences);
  const defaultOutputType = useGetDefaultOutputTypeByLanguageMemoized(
    defaultLanguage.language,
    defaultLanguage.country
  );

  const audience = getPreferredAudience({
    audiences,
    locale: { language: defaultLanguage.language, country: defaultLanguage.country }
  });
  const model = models.find(
    model =>
      model.language === defaultLanguage.language && model.country === defaultLanguage.country
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOptionsOpen = Boolean(anchorEl);

  const handleClickOption = (
    event: React.MouseEvent<HTMLButtonElement>,
    imgData: ImageGeneratorResult
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentImgUrl(imgData.imgSrc);
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
  };

  const { prompt, generatedImages, isImagesFetching, imageFetchingError } =
    useAiImagesGeneratorContext();

  const showUpgradeImagesModal = useShowUpgradeImagesModal();

  const hasImagesUpgradeModalSeen = localStorage.getItem(LocalStorageKey.ImagesUpgradeModalSeen);
  const { currentPricingPlan } = useSubscriptionState();
  const isFreeAccountUser = currentPricingPlan.type === PricingPlan.free;
  const shouldShowUpgradeModal = isFreeAccountUser && !hasImagesUpgradeModalSeen;

  const handleDownloadClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, imageURL: string, generationId: string) => {
      event.stopPropagation();
      trackingWrapper.track('aiImageGeneratorDownloadImage', {
        prompt,
        imageGenerationId: generationId
      });

      posthog?.capture('aiImageGeneratorDownloadImage');

      GAEvents.downloadImage();

      if (shouldShowUpgradeModal) {
        showUpgradeImagesModal(imageURL);
      }
    },
    [prompt, posthog, shouldShowUpgradeModal, showUpgradeImagesModal]
  );

  const handleShareClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    image: ImageGeneratorResult
  ) => {
    event.stopPropagation();
    const daysUntilExpire = dayjs(image.expireDate)
      .startOf('day')
      .diff(dayjs().startOf('day'), 'day');

    showModal('SHARE_AI_IMAGE', {
      size: 340,
      imageId: image.generationId,
      daysUntilExpire
    });
  };

  const addImage = async (image: ImageGeneratorResult) => {
    createEmptyPage();
    insertImageToEditor(image.imgSrc);
  };

  const handleExistingDocument = () => {
    openExistingDocumentDialog();
  };

  const [addToNewDialogOpen, openDialog, closeAddToNewDialog] = useDialog();

  const [addToExistingDialogOpen, openExistingDocumentDialog, closeAddToExistingDialog] =
    useDialog();

  const handleNewDocument = () => {
    openDialog();
  };

  const handleAddToNewDialogConfirm = async () => {
    assertNonNullable(model, 'Model not found');

    // Here we need to create a new document with the image using initializeTabThunk
    // but first we need to navigate to the project page to obtain the context for the project
    await navigate(getRoutePath('aiWriter'));

    const defaultPersonalityResult = await httpGetDefaultPersonality.callEndpoint({
      language: model.language,
      country: model.country
    });

    // #tech-debt: https://app.clickup.com/t/86bw25y7m
    await dispatch(
      initializeTabThunk(
        {
          embeddingModelId: model?.id ?? '',
          audienceId: audience?.id ?? 0,
          personalityId: defaultPersonalityResult?.id,
          name: unnamed,
          isNewDocument: true,
          outputType: defaultOutputType,
          brief: '',
          keywords: '',
          keywords2: '',
          tonality: [],
          text: [
            {
              type: ELEMENT_IMAGE,
              url: currentImgUrl,
              children: [{ text: '' }]
            },
            {
              type: ELEMENT_PARAGRAPH,
              children: [{ text: '' }]
            }
          ]
        },
        configureChatTabThunk
      )
    );
    GAEvents.imageAdded();
    Toast.success('ai_images.generator_page.images.added.success');
    closeAddToNewDialog();
  };

  const handleAddToExistingDialogConfirm = () => {
    closeAddToExistingDialog();
  };

  if (generatedImages.length === 0 && !isImagesFetching && !imageFetchingError) {
    return isInsideContentFlash ? (
      <ContentFlashText>
        <FormattedMessage
          id="ai_images.generator_page.terms_of_use"
          values={{
            a: (content: string) => (
              <Link
                href={translate('ai_images.generator_page.terms_of_use.link')}
                target="_blank"
                rel="noreferrer"
              >
                {content}
              </Link>
            )
          }}
        />
      </ContentFlashText>
    ) : null;
  }

  if (isImagesFetching) {
    return <LoadingAnimationWithAlternatingMessages />;
  }

  if (imageFetchingError || generatedImages.length === 0) {
    return <ErrorMessage error={imageFetchingError} />;
  }

  return (
    <>
      <AddToNewDocumentDialog
        isDialogOpen={addToNewDialogOpen}
        onCancel={closeAddToNewDialog}
        onConfirm={handleAddToNewDialogConfirm}
      />
      <AddToExistingDocumentDialog
        isDialogOpen={addToExistingDialogOpen}
        onCancel={closeAddToExistingDialog}
        onConfirm={handleAddToExistingDialogConfirm}
        imageUrl={currentImgUrl}
      />
      {generatedImages?.map(imgData => (
        <Box
          sx={{
            position: 'relative',
            width: generatedImages.length > 1 ? 'calc(50% - 8px)' : '100%',
            cursor: isInsideContentFlash ? 'pointer' : 'initial',
            marginTop: isInsideContentFlash ? '10px' : '0'
          }}
          key={imgData.imgSrc}
          onClick={() => addImage(imgData)}
        >
          <img style={{ width: '100%', height: '100%', borderRadius: 4 }} src={imgData.imgSrc} />
          <Box sx={{ position: 'absolute', right: 5, top: 10 }}>
            <FlexContainer direction="row" gap="small">
              <AddImageToDocumentMenu
                anchorEl={anchorEl}
                isOptionsOpen={isOptionsOpen}
                handleCloseOption={handleCloseOption}
                handleNewDocument={handleNewDocument}
                handleExistingDocument={handleExistingDocument}
              />
              {!isInsideContentFlash && (
                <Tooltip title={translate('ai_images.generator_page.result.add_to_writer.tooltip')}>
                  <ImageButton onClick={event => handleClickOption(event, imgData)}>
                    <ReadMoreIcon />
                  </ImageButton>
                </Tooltip>
              )}

              <Tooltip title={translate('ai_images.generator_page.result.download.tooltip')}>
                <ImageButton
                  id={gtmIds.aiImages.generatorPage.downloadButton}
                  onClick={event =>
                    handleDownloadClick(event, imgData.imgSrc, imgData.generationId)
                  }
                >
                  <ImageLink
                    target="_blank"
                    rel="noreferrer"
                    href={imgData.imgSrc.replace('/view', '/download')}
                  >
                    <DownloadIcon />
                  </ImageLink>
                </ImageButton>
              </Tooltip>

              <Tooltip title={translate('ai_images.generator_page.result.share.tooltip')}>
                <ImageButton onClick={event => handleShareClick(event, imgData)}>
                  <ShareIcon />
                </ImageButton>
              </Tooltip>
            </FlexContainer>
          </Box>
        </Box>
      ))}
    </>
  );
}

const ContentFlashText = styled(Typography)`
  color: ${({ theme }) => theme.colors.textDisabled};

  margin-top: ${({ theme }) => theme.spacings.two};
`;

const ImageButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.primaryColorMain};
  color: ${({ theme }) => theme.colors.commonWhiteMain};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryColorDark};
  }
`;

const ImageLink = styled.a`
  ${resetLink};
  line-height: 0;

  cursor: pointer;
  color: inherit;
  &:hover {
    color: inherit;
  }
`;
