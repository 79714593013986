import { getGetMappersForCountry } from 'features/group/store/selectors';
import { modelMapperFromWeMapper } from 'features/group/store/utils';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { APIModel, ModelMapper } from 'services/api/embeddingModels/types';
import { useAppSelector } from 'store/hooks';
import { css } from 'styled-components';
import { toShortened } from 'utils/numberUtils';

import ComboBox from '../../components/Autocomplete/ComboBox';
import CountryFlag from '../../components/base/CountryFlag';
import FormGroup from '../../components/base/FormGroup';
import HelpText from '../../components/base/HelpText';
import Icon from '../../components/base/Icon';
import Tooltip from '../../components/tooltips/Tooltip';
import MapperSelect from './MapperSelect';
import {
  getEmbeddingModelDataSelector,
  getEmbeddingModelsByCountry,
  getGetEmbeddingModelsForCountry
} from './store/selectors';
import { EmbeddingModel } from './store/types';

type Props = {
  initialId?: string;
  initialCountry?: string;
  initialLanguage?: string;
  initialMapperId?: string;
  withMapper?: boolean;
  hideDateSelect?: boolean;
  hideFlag?: boolean;
  horizontalForm?: boolean;
  modelInfo?: boolean;
  onMapperChange?: (mapperName: string) => void;
  onChange: (model: APIModel) => void;
  countriesFilterFn?: (modelGroup: EmbeddingModel) => boolean;
};

export const SelectItemWithFlag = (item: EmbeddingModel) => (
  <>
    <CountryFlag
      language={item.language}
      country={item.country}
      css={css`
        height: 14px;
        margin-right: 0.25rem;
        vertical-align: -2px;
      `}
    />{' '}
    {item.label}
  </>
);

export const SelectItem = (item: EmbeddingModel) => item.label;

const ModelSelect = ({
  initialCountry,
  initialId,
  initialLanguage,
  initialMapperId,
  withMapper,
  hideDateSelect,
  hideFlag,
  horizontalForm,
  modelInfo,
  countriesFilterFn,
  onChange,
  onMapperChange
}: Props) => {
  const modelsByCountry = useAppSelector(getEmbeddingModelsByCountry);
  const getModelsForCountry = useAppSelector(getGetEmbeddingModelsForCountry);
  const getModelData = useAppSelector(getEmbeddingModelDataSelector);
  const getMappersForCountry = useAppSelector(getGetMappersForCountry);

  const [model, setModel] = useState<APIModel | null>(initialId ? getModelData(initialId) : null);
  const [countryModelGroup, setCountryModelGroup] = useState<EmbeddingModel | undefined>(
    model
      ? modelsByCountry.find(
          modelGroup =>
            modelGroup.country === model.country && modelGroup.language === model.language
        )
      : modelsByCountry.find(
          modelGroup =>
            modelGroup.country === initialCountry && modelGroup.language === initialLanguage
        )
  );
  const [modelsForCountry, setModelsForCountry] = useState<APIModel[]>(
    initialCountry && initialLanguage
      ? getModelsForCountry(initialCountry, initialLanguage)
      : model
      ? getModelsForCountry(model.country, model.language)
      : []
  );

  const countryMappers = useMemo(
    () =>
      getMappersForCountry({
        country: countryModelGroup?.country,
        language: countryModelGroup?.language
      }).map(modelMapperFromWeMapper),
    [countryModelGroup?.country, countryModelGroup?.language, getMappersForCountry]
  );

  const [mapper, setMapper] = useState<ModelMapper | null>(() => {
    if (model) {
      const initialMapper = countryMappers.find(i => i.name === initialMapperId);

      if (initialMapper) {
        return initialMapper;
      } else {
        const firstMapper = countryMappers[0];

        if (firstMapper) {
          onMapperChange?.(firstMapper.name);

          return firstMapper;
        }
      }
    }
    return null;
  });

  const onModelChange = (item: EmbeddingModel | null) => {
    if (item) {
      const filteredModels = getModelsForCountry(item.country, item.language);
      const mappers = getMappersForCountry({ country: item.country, language: item.language }).map(
        modelMapperFromWeMapper
      );
      const newModel = filteredModels[0];

      setCountryModelGroup(item);
      setModelsForCountry(filteredModels);
      setModel(newModel);
      onChange(newModel);
      if (mappers?.length) {
        setMapper(mappers[0]);
        onMapperChange?.(mappers[0].name);
      } else {
        setMapper(null);
        onMapperChange?.('');
      }
    }
  };

  const hasMoreThanOneMapper = countryMappers.length > 1;

  return (
    <>
      <FormGroup label="common.address.country" horizontal={horizontalForm}>
        <ComboBox
          data={countriesFilterFn ? modelsByCountry.filter(countriesFilterFn) : modelsByCountry}
          initialSelectedItem={countryModelGroup}
          itemId="id"
          itemToString={item => (item ? item.label : '')}
          flag={!hideFlag ? countryModelGroup?.country : undefined}
          onChange={onModelChange}
        >
          {hideFlag ? SelectItem : SelectItemWithFlag}
        </ComboBox>

        {modelInfo && model ? (
          <HelpText>
            <FormattedMessage
              id="model.select.info"
              values={{ words: toShortened(model.token_count) }}
            />
          </HelpText>
        ) : null}
      </FormGroup>

      {!hideDateSelect && (
        <FormGroup label="common.date" horizontal={horizontalForm}>
          <ComboBox
            data={modelsForCountry}
            selectedItem={model}
            itemId="id"
            disabled={!countryModelGroup || !model}
            itemToString={modelItemToString}
            onChange={item => {
              if (item) {
                setModel(item);
                onChange(item);
              }
            }}
          >
            {item => (
              <div className="d-flex justify-content-between">
                <div>{modelItemToString(item)}</div>
                <div>
                  {item.loading_status === 'not loaded' && (
                    <Tooltip content={<FormattedMessage id="models.model_status.not_loaded" />}>
                      <Icon name="refresh-cw" />
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </ComboBox>
        </FormGroup>
      )}

      {withMapper && onMapperChange && hasMoreThanOneMapper && (
        <FormGroup label="model_selection.mapper" horizontal={horizontalForm}>
          <MapperSelect
            value={mapper}
            onChange={item => {
              setMapper(item);
              onMapperChange(item.name);
            }}
            options={countryMappers}
          />
        </FormGroup>
      )}
    </>
  );
};

const modelItemToString = (item: APIModel | null) =>
  item ? `${item.friendly_month} ${item.year}${item.label ? ` (${item.label})` : ''}` : '';

export default ModelSelect;
