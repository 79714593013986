import Confetti from 'react-confetti';
import useWindowSize from 'utils/hooks/useWindowSize';

export const InitialConfetti = () => {
  const { width, height } = useWindowSize();

  return (
    <Confetti
      width={width}
      height={height}
      numberOfPieces={1000}
      gravity={0.1}
      initialVelocityX={4}
      initialVelocityY={10}
      wind={0}
      recycle={false}
    />
  );
};
