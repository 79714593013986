import axios, { AxiosResponse } from 'axios';
import { tryGetTransactionIdFromAxiosError } from 'features/logging/logAxiosError';
import { ApiResponseCancelled, ApiResponseError } from 'services/api/types';
import { createBackofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { isNonNullable } from 'utils/typescript/nonNullable';

const AxiosClient = createBackofficeClient();

const expectedProps = ['data', 'status', 'statusText', 'headers', 'config', 'request'];
function isNotYetUnpacked(response: unknown): response is AxiosResponse {
  return (
    typeof response === 'object' &&
    isNonNullable(response) &&
    expectedProps.every(prop => prop in response)
  );
}

/**
 * #tech-debt https://app.clickup.com/t/37yz688
 */
AxiosClient.interceptors.response.use(
  response => {
    // #tech-debt https://app.clickup.com/t/862jw9ptf
    if (isNotYetUnpacked(response)) {
      return response?.data;
    } else {
      return response;
    }
  },
  (error: unknown): ApiResponseError<string> => {
    // there are places that expect a Cancel error to be returned from this function
    // e.g. https://bitbucket.org/neuroflash/neuroflash_app/src/bccd6eefb0c9207e4727c40af877d12a3349d9a7/src/features/explorer/store/thunks.ts#thunks.ts-201:201
    if (axios.isCancel(error)) {
      return error as ApiResponseCancelled;
    }

    const unifiedError: ApiResponseError<string> = {
      status: false,
      data: {}
    };

    if (axios.isAxiosError(error)) {
      const { response } = error;
      unifiedError.dangerous_transactionId = tryGetTransactionIdFromAxiosError(error);

      if (response) {
        /**
         * This is the most hacky part, we assume here that the shape returned
         * from BO matches our types. In the future it would be nice to have some
         * kind of data validation here.
         */
        Object.assign(unifiedError, response.data);
      }
    }

    return unifiedError;
  }
);

export default AxiosClient;
