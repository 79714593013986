import { Typography } from '@mui/material';
import { StyledTextareaAutosize } from 'components/StyledTextareaAutosize';
import { BaseField } from 'features/modular-workflow/runner/fields/BaseField';
import { FieldRendererContext } from 'features/modular-workflow/runner/fields/FieldRenderer';
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { isValidHttpUrl } from 'utils/IsValidHttpUrl';

const maxInputLength = 1000;

export const UrlInputField = () => {
  const translate = useTr();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { input, onChange, onValidationChange, inputErrorList } = useContext(FieldRendererContext);
  const [localValue, setLocalValue] = useState<string>((input.value as string) || '');

  const hasValidValue = isValidHttpUrl(localValue);
  const hasValidOrEmptyValue = hasValidValue || localValue.length === 0;
  const hasError = inputErrorList.length > 0 || !hasValidOrEmptyValue;
  const errorMessages = inputErrorList.map(({ errorMessage }) => errorMessage);
  if (!hasValidOrEmptyValue) {
    errorMessages.push(translate('modular_workflow.runner.error_invalid_url'));
  }

  useEffect(() => {
    onValidationChange(input.reference, hasValidValue);
    // Disable ESlint check because we only wanna react on the hasValidValue change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasValidValue]);

  return (
    <BaseField>
      {elementId => (
        <>
          <StyledTextareaAutosize
            ref={textAreaRef}
            id={elementId}
            defaultValue={input.value as string}
            placeholder={input.description}
            // Matches roughly 400px
            maxRows={15}
            onChange={event => {
              const newValue = event.target.value;
              if (newValue.length > maxInputLength) {
                return;
              }

              setLocalValue(newValue);
              onChange(input, newValue);
            }}
            $hasError={hasError}
          />
          <CounterBox>
            {errorMessages.length > 0 ? (
              <div>
                {errorMessages.map(errorMessage => (
                  <Typography key={Math.random()} variant="caption" color="error">
                    {errorMessage}
                  </Typography>
                ))}
              </div>
            ) : (
              <div />
            )}
            <Typography variant="caption" color={hasError ? 'error' : undefined}>
              {localValue.length}/{maxInputLength}
            </Typography>
          </CounterBox>
        </>
      )}
    </BaseField>
  );
};

const CounterBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
