import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { getActiveTabFactory, getTabsHeadersFactory } from 'store/utils/selectorFactories';
import { TrProps } from 'utils/hocs/withTr';
import { intersectAndJoin } from 'utils/utils';

import { getEmbeddingModelDataSelector } from '../../embeddingModels/store/selectors';
import { ColoringParameter, ConceptFlashTab } from './types';
import { defaultTab } from './utils';

export const getExplorerTabs = (state: RootState) => state.explorer.tabs;
export const getCurrentTabId = (state: RootState) => state.explorer.currentTab;
export const getExplorerPageLayout = (state: RootState) => state.explorer.pageLayoutVertical;
export const getExplorerIsLoading = (state: RootState) => state.explorer.isLoading;

export const getActiveTab = getActiveTabFactory<ConceptFlashTab>(
  getExplorerTabs,
  getCurrentTabId,
  defaultTab
);

export const getActiveTabData = createSelector(getActiveTab, tab => tab.data);

export const getTabHasData = createSelector(getActiveTabData, data => data.length > 0);

export const getIsTabInitialized = createSelector(getActiveTab, tab => tab.isInitialized);

export const getSettingsModalShown = createSelector(getActiveTab, tab => tab.settingsModalShown);

export const getGraphColoringLabel = createSelector(
  getActiveTab,
  ({ dimensions, manualDimensions, graphColoring, wordAttributes }) => {
    const potentialDimension = graphColoring.split('.');
    if (potentialDimension.length === 2) {
      let dimension;
      if (potentialDimension[1].includes('_')) {
        const [id] = potentialDimension[1].split('_');
        dimension = manualDimensions.find(dim => dim.id === parseInt(id, 10));
      } else {
        dimension = dimensions.find(dim => dim.id === parseInt(potentialDimension[1], 10));
      }

      if (dimension) {
        return {
          label: dimension.label,
          needsTranslation: false
        };
      }
    }
    const matchingAttribute = wordAttributes.find(attr => attr.value === graphColoring);
    return {
      label: matchingAttribute?.option
        ? `goal_score.${graphColoring}.${matchingAttribute.option.value}`
        : `goal_score.${graphColoring}`,
      needsTranslation: true
    };
  }
);

export const getParametersList = createSelector(
  getActiveTab,
  ({ manualDimensions, dimensions, wordAttributes }): ColoringParameter[] => {
    const wordAttributesParams = wordAttributes.map(param => ({
      label: param?.option
        ? `goal_score.${param.value}.${param.option.value}`
        : `goal_score.${param.value}`,
      value: param.value,
      needsTranslation: true
    }));

    const manualDimensionsParams = manualDimensions.map(({ id, label }) => ({
      label,
      value: `dimension_scores.${id}_manual`,
      needsTranslation: false
    }));

    const dimensionsParams = dimensions.map(({ id, label }) => ({
      label,
      value: `dimension_scores.${id}`,
      needsTranslation: false
    }));

    return [
      { label: 'goal_score.goal_score', value: 'goal_score', needsTranslation: true },
      ...wordAttributesParams,
      ...manualDimensionsParams,
      ...dimensionsParams
    ];
  }
);

export const getGoalConfigStatus = createSelector(
  getActiveTab,
  ({ goalConfig, manualDimensions, dimensions, wordAttributes }) => {
    if (goalConfig) {
      const goalDimensions = intersectAndJoin(dimensions, goalConfig.dimensions, 'id');
      const goalManualDimensions = intersectAndJoin(manualDimensions, goalConfig.dimensions, 'id');
      const goalAttributes = goalConfig.mappers.map(({ id, weight }) => {
        const matchingAttribute = wordAttributes.find(el => el.value === id);
        const key = matchingAttribute?.option
          ? `goal_score.${id}.${matchingAttribute.option.value}`
          : `goal_score.${id}`;
        return { key, value: weight };
      });

      return {
        goalConfig: goalAttributes,
        manualDimensions:
          goalManualDimensions.length > 0
            ? {
                value: goalManualDimensions
                  .map(({ label, weight }) => {
                    const sign = weight > 0 ? '+' : '-';
                    return `${sign}${label}`;
                  })
                  .join(', '),
                key: 'word_param.association_score'
              }
            : {},
        dimensions:
          goalDimensions.length > 0
            ? {
                value: goalDimensions
                  .map(({ label, weight }) => {
                    const sign = weight > 0 ? '+' : '-';
                    return `${sign}${label}`;
                  })
                  .join(', '),
                key: 'goal_score.dimensions'
              }
            : {}
      };
    }
    return { goalConfig: [], manualDimensions: {}, dimensions: {} };
  }
);

export const getExportNameSelector = createSelector(
  getActiveTab,
  getGoalConfigStatus,
  getEmbeddingModelDataSelector,
  ({ name, embeddingModelId }, { goalConfig, manualDimensions, dimensions }, getModelData) =>
    (tr: TrProps['tr']) => {
      const modelData = getModelData(embeddingModelId);

      if (modelData) {
        const { country, friendly_month: month, year } = modelData;
        return [
          `${country}-${month}-${year}`,
          name,
          goalConfig.reduce((acc, param) => {
            if (param.value) {
              const sign = param.value > 0 ? '+' : '-';
              return `${acc}${sign + tr(param.key)}`;
            }
            return acc;
          }, ''),
          ...(dimensions.key ? [dimensions.value] : []),
          ...(manualDimensions.key ? [manualDimensions.value] : [])
        ].join('_');
      }

      return 'invalid';
    }
);

export const getNumberOfSelectedRows = createSelector(getActiveTab, tab =>
  tab.selectedRows ? tab.selectedRows.length : 0
);

export const getSelectedWords = createSelector(getActiveTab, tab =>
  tab.selectedRows.map(index => tab.data[parseInt(index, 10)])
);

export const getCurrentTabModelData = createSelector(
  getActiveTab,
  getEmbeddingModelDataSelector,
  (tab, getData) => getData(tab.embeddingModelId)
);

export const getExplorerTabMapperId = createSelector(getActiveTab, tab => tab.modelMapperId);

export const getTabsHeaders = getTabsHeadersFactory(getExplorerTabs);
