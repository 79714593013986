import { Switch } from '@mui/material';
import { MenuDropdownItem } from 'components/MenuDropdown/MenuDropdownItem';
import { SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { useAppThemeStore } from 'utils/hooks/useAppThemeStore';

type Props = {
  enabled: boolean;
};
export const DarkModeToggleItem = (props: Props) => {
  const themeMode = useAppThemeStore(state => state.themeMode);
  const setThemeMode = useAppThemeStore(state => state.setThemeMode);

  const handleToggle = (event: SyntheticEvent) => {
    event.preventDefault();

    setThemeMode(themeMode === 'dark' ? 'light' : 'dark');
  };

  if (!props.enabled) {
    return null;
  }

  return (
    <MenuDropdownItem
      gtmId={gtmIds.sidebar.accountMenuItem}
      icon={<Switch size="small" checked={themeMode === 'dark'} onChange={handleToggle} />}
      onClick={handleToggle}
    >
      <FormattedMessage id="layout.sidebar.actions.profile.dark_mode" />
    </MenuDropdownItem>
  );
};
