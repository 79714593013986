import { Chip } from '@mui/material';
import usersIcon from 'assets/icon-users.svg';
import FlexContainer from 'components/FlexContainer';
import { MenuDropdownItem } from 'components/MenuDropdown/MenuDropdownItem';
import { getRoutePath } from 'config/routes';
import { NavLink } from 'features/pageSidebar/sidebarItems/actions/account/UnstyledLink';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingPlan } from 'features/pricing/types';
import { useInviteUserModal } from 'features/profilePage/useInviteUserModal';
import { useIsTeamOwnerOrAdmin } from 'features/team/useIsTeamOwnerOrAdmin';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  onClose: () => void;
};

export const UsersItem = forwardRef<HTMLAnchorElement, Props>(({ onClose }, ref) => {
  const { currentPricingPlan } = useSubscriptionState();
  const isFreeOrStandardPlan =
    currentPricingPlan.type === PricingPlan.free ||
    currentPricingPlan.type === PricingPlan.standard;

  const isTeamOwnerOrAdmin = useIsTeamOwnerOrAdmin();
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();
  const showInviteUserModal = useInviteUserModal();

  const handleBadgeClick = () => {
    if (isFreeOrStandardPlan) {
      showUpgradeSubscriptionModal();
      return;
    }

    showInviteUserModal();
  };

  return (
    <NavLink to={getRoutePath('workspace')} ref={ref}>
      <MenuDropdownItem
        gtmId={gtmIds.sidebar.accountMenuItem}
        icon={<img src={usersIcon} alt="users" />}
        onClose={onClose}
      >
        <FlexContainer direction="row" justifyContent="space-between">
          <FormattedMessage id="layout.sidebar.actions.profile.users" />
          <StyledBadge
            {...withGtmInteraction(gtmIds.sidebar.inviteTeammates)}
            label={<FormattedMessage id="layout.sidebar.actions.profile.users.invite" />}
            size="small"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              handleBadgeClick();
              onClose();
            }}
            disabled={!isTeamOwnerOrAdmin}
          />
        </FlexContainer>
      </MenuDropdownItem>
    </NavLink>
  );
});

const StyledBadge = styled(Chip)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  margin-right: ${({ theme }) => theme.spacings.medium};
  font-size: 10px;
`;
