import { IStylesOptions } from 'docx';

/**
 * The following styles configuration uses the following units:
 * - Font size is specified in half-points (1pt = 2 units).
 * - All spacings is specified in DXA (1 DXA = 1/20th of a point).
 */
export const docxStyles: IStylesOptions = {
  default: {
    document: {
      run: {
        font: 'Montserrat',
        size: 24
      },
      paragraph: {
        spacing: {
          line: 276
        }
      }
    },
    heading1: {
      run: {
        size: 32,
        color: '#000000',
        bold: true
      },
      paragraph: {
        spacing: {
          before: 240,
          after: 120
        }
      }
    },
    heading2: {
      run: {
        size: 26,
        color: '#000000',
        bold: true
      },
      paragraph: {
        spacing: {
          before: 200,
          after: 100
        }
      }
    },
    heading3: {
      run: {
        size: 24,
        color: '#000000',
        bold: true
      },
      paragraph: {
        spacing: {
          before: 160,
          after: 80
        }
      }
    }
  }
};
