import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { useState } from 'react';
import useTr from 'utils/hooks/useTr';
import { withTestId } from 'utils/utils';

import TextInputField from '../TextInputField';

type Props = {
  name: string;
  label?: string;
};

const PasswordField = ({ name, label }: Props) => {
  const tr = useTr();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  return (
    <TextInputField
      type={showPassword ? 'text' : 'password'}
      name={name}
      {...withTestId('password-field')}
      variant="outlined"
      fullWidth
      placeholder={tr(label ?? 'common.form.field.password.placeholder')}
      label={tr(label ?? 'common.form.field.password.placeholder')}
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={handleClickShowPassword} edge="end">
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default PasswordField;
