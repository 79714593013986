import Bowser from 'bowser';
import { getErrorStackWithOriginalSource } from 'features/logging/getErrorStackWithOriginalSource';
import { globalErrorLogContextStore } from 'features/logging/GlobalErrorLogContextStore';
import { sendLogToApi } from 'features/logging/sendLogToApi';
import { retrieveRequestIdHeader } from 'services/backofficeIntegration/http/client/useRequestIdHeaderStore';

const getAdditionalContext = (): LogOptions['context'] => {
  const lastRequestId = retrieveRequestIdHeader() ?? '';
  const browser = Bowser.getParser(window.navigator.userAgent);

  const environmentInfo = `${browser.getBrowserName()} ${browser.getBrowserVersion()} - ${browser.getOSName()} ${browser.getOSVersion()}`;

  const currentPage = window.location.pathname + window.location.search + window.location.hash;

  const currentLoggingContextState = globalErrorLogContextStore.getState();

  return {
    'meta.lastRequestId': lastRequestId,
    'meta.browser': environmentInfo,
    'meta.userAgent': window.navigator.userAgent,
    'meta.page': currentPage,
    // We don't want to introduce too much logic here,
    // therefore not checking if the user is actually on that page.
    'meta.aiWriter.currentDocumentId': currentLoggingContextState.currentAiWriterDocumentId,
    'meta.aiTester.currentDocumentId': currentLoggingContextState.currentAiTesterDocumentId,
    'meta.explorer.currentDocumentId': currentLoggingContextState.currentExplorerDocumentId,
    'meta.customer.pricingId': currentLoggingContextState.customerPricingId,
    'meta.customer.pricingType': currentLoggingContextState.customerPricingType,
    'meta.customer.hasTeam': currentLoggingContextState.customerHasTeam,
    'meta.customer.isApiCustomer': currentLoggingContextState.customerIsApiCustomer
  };
};

export type ErrorScope = 'unknown' | 'http' | 'react' | 'component' | 'saga' | 'modular_workflow';

type LogOptions = Parameters<typeof sendLogToApi>[0];

export const logError = async (
  errorOrMessage: Error | LogOptions['message'],
  errorScope: ErrorScope | undefined = 'unknown',
  context: LogOptions['context'] = {}
) => {
  const error = typeof errorOrMessage === 'string' ? new Error(errorOrMessage) : errorOrMessage;
  let errorStack = error.stack;

  // Try advancing stack trace with source map locations
  if (errorStack) {
    const advancedStack = await getErrorStackWithOriginalSource(error, errorStack);
    if (advancedStack) {
      context['meta.error.originalStack'] = errorStack;
      errorStack = advancedStack;
    }
  }

  return sendLogToApi({
    message: error.message,
    severity: 'ERROR',
    context: {
      'meta.timestamp': new Date().toISOString(),
      'meta.scope': errorScope,
      ...getAdditionalContext(),
      ...context
    },
    stackTrace: errorStack
  });
};

// @ts-expect-error - Expose logError to the window object for debugging purposes
window.logError = logError;
