import { TLocation, Value } from '@udecode/plate-common';
import { PerformanceTab } from 'features/aiWriter/AiWriterSidebar/steps/PerformanceStep';
import { GeneratedText, GenerateTextConfig } from 'features/textGenerator/store/types';
import { AiWriterProjectTagType } from 'services/api/aiWriter/types';
import { ApiTextAnalysisResult, ApiTextAnalysisStat } from 'services/api/seoAnalysis/types';
import { BaseTab, Loading, Tabs } from 'types/ReduxStateTypes';
import { safeEnum } from 'utils/safeEnum';

export type AiWriterState = Tabs<AiWriterTab> &
  Loading & {
    projects: AiWriterProjectWithShortText[];

    inspirationsCurrentStep?: InspirationsExtendedStep;
    favorites: Favorite[];
    history: History[];

    areFavoritesLoading?: boolean;
    isHistoryLoading?: boolean;

    isAiWriterTourFinished: boolean;
    // #tech-debt https://app.clickup.com/t/862jjp0bb
    isUserOnboarded: boolean;

    isBlogPostBuilderStarted: boolean;
    isInspirationsPanelDisabled: boolean;
    // If you will set this value the the editor selection will be updated.
    // Undefined does not mean “no selection” it means “do nothing with selection.”
    selection?: TLocation;

    builder: {
      blogPost: {
        title: string;
        outline: string;
        language?: { language: string; country: string };
      };
      socialPost: {
        language?: { language: string; country: string };
      };
    };

    initTextLibraryCategory?: string;
  };

export type AiWriterTab = BaseTab &
  Omit<AiWriterProject, 'isLoading'> & {
    seoAnalysis?: SeoAnalysis;
    seoConfig: SeoConfig;
    seoHighlightedKeyword?: string | string[];

    isLoading: AiWriterTabLoaders;
    isNewDocument?: boolean;
    generatingTextInEditor?: boolean;

    isSaving: boolean;
  };

export type AiWriterProject = {
  id: string;
  hash: string;
  name: string;
  embeddingModelId: string;
  text: Value;
  short_text: string | null;
  generateTextConfig: GenerateTextConfig;
  textInspirations: TextInspiration[];
  images: AiWriterImage[];
  imagesNotFound: boolean;
  updatedAt: string;
  isPublic: boolean;
  isLoading: boolean;
  authorId?: number;
  authorName: string;
  sharingPermission: SharingPermission;
  tags?: AiWriterProjectTagType[];
};

export type AiWriterProjectWithShortText = Omit<AiWriterProject, 'text'>;

export const SharingPermission = safeEnum.make({
  private: 'private',
  team: 'team'
});

export type SharingPermission = safeEnum.infer<typeof SharingPermission>;

export type AiWriterProjectSettings = {
  embeddingModelId: string;
  audienceId: number;
  sharingPermission: SharingPermission;
};

export type AiWriterProjectPreview = {
  name: string;
  text: Value;
  authorName: string;
  authorImage?: string;
  updatedAt: string;
};

export type AiWriterTabLoader = keyof AiWriterTabLoaders;

export type AiWriterTabLoaders = {
  generateTextInspirations?: boolean;
  fetchImages?: boolean;
};

export enum AiWriterSidebarStep {
  aiWriter = 'aiWriter',
  images = 'images',
  seoAnalysis = 'seoAnalysis',
  // Note: The missing plural 's' is not a typo. It's a legacy name
  //       If you wanna change that, you have to change translation tokens as well
  templates = 'template',
  plagiarism = 'plagiarism',
  chat = 'chat',
  performance = 'performance'
}

export type AiImagesSubStep = 'aiImages' | 'unsplashImages';

export type InspirationsCommonSubStep = 'main';

export type AiWriterSubStep =
  | InspirationsCommonSubStep
  | 'editBrief'
  | 'blogPost'
  | 'history'
  | 'favorites';

export type SeoAnalysisSubStep = InspirationsCommonSubStep | 'statistics' | 'searchResults';

export type InspirationsExtendedStep =
  | {
      step: AiWriterSidebarStep.aiWriter;
      subStep: AiWriterSubStep;
    }
  | {
      step: AiWriterSidebarStep.images;
      subStep: AiImagesSubStep;
    }
  | {
      step: AiWriterSidebarStep.templates;
      subStep: InspirationsCommonSubStep;
    }
  | {
      step: AiWriterSidebarStep.chat;
      subStep: InspirationsCommonSubStep;
    }
  | {
      step: AiWriterSidebarStep.performance;
      subStep: PerformanceTab;
    };

export type TextInspiration = GeneratedText & {
  isLoading?: boolean;
};

export type SavedTextInspiration = {
  id?: string;
  text: string;
  generationId?: number;
  textItemId?: string;
};

export type Favorite = GeneratedText & {
  projectId: string;
  isLoading?: boolean;
};

export type History = {
  id: string;
  text: string;
  createdAt: string;
  updatedAt: string;
};

export type AiWriterImage = {
  id: string;
  author: string;
  authorPage: string;
  thumb: string;
  image: string;
  downloadLink: string;
  trackDownloadLink: string;
};

export type TermSuggestion = {
  word: string;
  occurrence: number;
  minUse?: number;
  maxUse?: number;
};

export type SeoAnalysis = {
  termSuggestions: SeoTermSuggestions;
  stats: ApiTextAnalysisStat[];
  results: Record<string, SeoTextAnalysisResult>;
  wordsAmount: number;
};

export type SeoTermSuggestions = {
  more: TermSuggestion[];
  less: TermSuggestion[];
  ok: TermSuggestion[];
};

export type SeoConfig = {
  searchEngine: string;
  keyword: string;
};

export type SeoTextAnalysisSubResult = {
  title: string;
  description: string;
};

export type SeoTextAnalysisResult = ApiTextAnalysisResult & {
  subResult?: SeoTextAnalysisSubResult;
};
