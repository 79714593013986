import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { invalidateGetTeamMembersQuery } from 'features/profilePage/useGetTeamMembers';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import {
  httpUpdateUserRole,
  UpdateUserRoleParams
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpUpdateUserRole';

export const useUpdateUserRole = () =>
  useMutation({
    mutationFn: (params: UpdateUserRoleParams) => httpUpdateUserRole.callEndpoint(params),
    onSuccess: () => {
      invalidateGetTeamMembersQuery();
    },
    onError: err => {
      const errorCode = getErrorCodeFromAxiosError(err);
      if (errorCode) {
        Toast.backendError(handleCommonApiError(errorCode));
      } else {
        Toast.apiError();
      }
    }
  });
