import { getRoutePath, RouteName, routeNames } from 'config/routes';
import { Link } from 'react-router-dom';

import { createApiErrorMessageHandler } from './createApiErrorMessageHandler';

function mkHandleApiErrorMessageWithHyperlink<T extends string>(
  errorCodes: Record<T, string | undefined>,
  hyperlinks: Partial<Record<T, string>>
) {
  return (errorCode: T | undefined) => {
    const id = createApiErrorMessageHandler(errorCodes)(errorCode);
    const hyperlink = errorCode ? hyperlinks[errorCode] : undefined;

    const isInternalRoute = routeNames.some(val => val === hyperlink);

    if (isInternalRoute) {
      const values = {
        a: (value: string) => <Link to={getRoutePath(hyperlink as RouteName)}>{value}</Link>
      };

      return [id, values] as const;
    }

    const values = {
      a: (value: string) => (
        <a href={hyperlink} rel="noreferrer">
          {value}
        </a>
      )
    };

    return [id, values] as const;
  };
}

export default mkHandleApiErrorMessageWithHyperlink;
