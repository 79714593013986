import { CountryOption } from 'components/forms/customFields/CountrySelect';
import { BasicDataStep } from 'components/register/registerPanel/BasicDataStep';
import { PricingPlanStep } from 'components/register/registerPanel/PricingPlanStep';
import { RegistrationSuccess } from 'components/register/RegistrationSuccess';
import Toast from 'components/toasts/Toast';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { CouponCode } from 'features/pricing/CouponCode';
import defaultTaxType from 'features/pricing/utils/defaultTaxType';
import { Formik, FormikHelpers } from 'formik';
import posthog from 'posthog-js';
import React, { useCallback, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router';
import adcellClient from 'services/adcell';
import CustomerAPI from 'services/api/customer';
import { handleCustomerError } from 'services/api/customer/errors';
import { ACLPricing, isCreateCheckoutResponse } from 'services/api/customer/types';
import firstPromoterClient from 'services/firstPromoterClient';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import getFirstPromoterReferralId from 'utils/getFirstPromoterReferralId';
import getTimezone from 'utils/getTimezone';
import { getValidationForField } from 'utils/getValidationForField';
import useTr from 'utils/hooks/useTr';
import redirectToStripe from 'utils/redirectToStripe';
import { delayExecution } from 'utils/utils';
import { passwordRules } from 'utils/validationRules';
import { boolean, object, string } from 'yup';

export const companyNameField = 'companyName';
export const nameField = 'name';
export const emailField = 'email';
export const passwordField = 'password';
export const tosField = 'tos';
export const newsletterSubscribedField = 'newsletterSubscribed';
export const useCompanyAddressField = 'useCompanyAddress';

export const pricingField = 'pricing';

export const couponField = 'coupon';
export const cardOwnerField = 'cardOwner';
export const countryField = 'country';
export const cityField = 'city';
export const postalCodeField = 'postalCode';
export const streetField = 'street';
export const vatIdField = 'vatId';

const currentStepConfig = 'currentStep';
const repeatPaymentDataConfig = 'repeatPaymentData';
export const isCouponSectionExtendedConfig = 'isCouponSectionExtended';
export const isAddressSectionExtendedConfig = 'isAddressSectionExtended';
export const isVatSectionExtendedConfig = 'isVatSectionExtended';

export type RegisterFormState = {
  [nameField]: string;
  [companyNameField]: string;
  [emailField]: string;
  [passwordField]: string;
  [newsletterSubscribedField]: boolean;
  [useCompanyAddressField]: boolean;

  [pricingField]: ACLPricing | null;

  [couponField]: CouponCode;
  [cardOwnerField]: string;
  [countryField]: CountryOption | null;
  [cityField]: string;
  [postalCodeField]: string;
  [streetField]: string;
  [vatIdField]: string;

  [currentStepConfig]: CurrentStep;
  [repeatPaymentDataConfig]?: RepeatPaymentData;
  [isCouponSectionExtendedConfig]: boolean;
  [isAddressSectionExtendedConfig]: boolean;
  [isVatSectionExtendedConfig]: boolean;
};

const initialValues: RegisterFormState = {
  [nameField]: '',
  [companyNameField]: '',
  [emailField]: '',
  [passwordField]: '',
  [newsletterSubscribedField]: false,
  [useCompanyAddressField]: false,
  [pricingField]: null,
  [cardOwnerField]: '',
  [countryField]: null,
  [cityField]: '',
  [postalCodeField]: '',
  [streetField]: '',
  [couponField]: {
    code: '',
    name: '',
    amountOff: undefined,
    percentOff: undefined
  },
  [vatIdField]: '',
  [currentStepConfig]: 'basicData',
  [isCouponSectionExtendedConfig]: false,
  [isAddressSectionExtendedConfig]: false,
  [isVatSectionExtendedConfig]: false
};

type CurrentStep = 'basicData' | 'pricingPlan' | 'payment';

type RepeatPaymentData = {
  clientSecret: string;
  customerId: number;
};

const recaptchaAction = 'register';

export const CreateAccountStep = () => {
  const tr = useTr();
  const navigate = useNavigate();
  const language = useAppSelector(getCurrentLanguage);

  const [registrationSuccessScreen, setRegistrationSuccessScreen] =
    useState<React.ReactElement | null>(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const validationSchema = useMemo(
    () =>
      object().shape({
        [useCompanyAddressField]: boolean(),
        [nameField]: string().when(useCompanyAddressField, {
          is: false,
          then: string().required(tr(getValidationForField('name'))),
          otherwise: string().notRequired()
        }),
        [companyNameField]: string().when(useCompanyAddressField, {
          is: true,
          then: string().required(tr('validation.field_required')),
          otherwise: string().notRequired()
        }),
        [emailField]: string()
          .required(tr(getValidationForField('email')))
          .email(),
        [passwordField]: passwordRules(tr),
        [pricingField]: object().when(currentStepConfig, {
          is: (step: CurrentStep) => step === 'pricingPlan',
          then: object().required(),
          otherwise: object().notRequired().nullable()
        }),
        [cardOwnerField]: string().when(currentStepConfig, {
          is: (step: CurrentStep) => step === 'payment',
          then: string().required(tr(getValidationForField('card_owner'))),
          otherwise: string().notRequired()
        }),
        [countryField]: object().when(currentStepConfig, {
          is: (step: CurrentStep) => step === 'payment',
          then: object()
            .nullable(true)
            .required(tr(getValidationForField('country'))),
          otherwise: object().nullable(true).notRequired()
        })
      }),
    [tr]
  );

  const submitForm = useCallback(
    async (
      {
        name,
        email,
        password,
        pricing,
        newsletterSubscribed,
        country,
        city,
        postalCode,
        street,
        vatId,
        companyName,
        useCompanyAddress
      }: RegisterFormState,
      { setStatus }: FormikHelpers<RegisterFormState>
    ) => {
      if (!pricing) {
        return;
      }

      const recaptchaResult = await executeRecaptcha?.(recaptchaAction);
      if (!recaptchaResult) {
        Toast.backendError(handleCustomerError('ERROR_INITIALIZATION_ACCOUNT_ACTIVITY'));
        return;
      }

      const response = await CustomerAPI.createCheckout({
        name,
        email,
        password,
        pricing: pricing.id,
        newsletterSubscribed,
        timezone: getTimezone(),
        address: street,
        city,
        country: country?.label || '',
        postalCode,
        taxId: vatId,
        taxType: defaultTaxType,
        use_company_name: useCompanyAddress,
        company_name: companyName,
        referralId: getFirstPromoterReferralId(),
        bid: adcellClient.getSavedBid(),
        locale: language,
        token: recaptchaResult,
        expected_action: recaptchaAction
      });

      if (!response.status) {
        setErrorStatus(response, setStatus);
        Toast.backendError(handleCustomerError(response.message));
        return;
      }

      // Required for some tracking AFAIK
      navigate({ search: `?ref=${pricing.id}` });

      // Track registration
      GAEvents.userRegistration({
        userId: response.data.id,
        userEmail: email,
        pricingId: pricing.id,
        type: 'email'
      });

      if (pricing.isTrial) {
        GAEvents.registerAndStartTrial();
      }

      posthog?.capture('Sign-up', {
        userId: response.data.id,
        userEmail: email,
        pricingId: pricing.id,
        type: 'email'
      });

      // Adcell & first promoter tracking
      firstPromoterClient.sendReferralEmail(email);

      // Wait for tracking to be sent
      await delayExecution(10);

      if (isCreateCheckoutResponse(response.data)) {
        redirectToStripe(response.data.checkout_url);
        return;
      }

      setRegistrationSuccessScreen(<RegistrationSuccess email={email} password={password} />);
    },
    [language, navigate, executeRecaptcha]
  );

  if (registrationSuccessScreen) {
    return registrationSuccessScreen;
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      initialErrors={{ tos: 'tos must be one of the following values: true' }}
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({ values, setFieldValue, ...rest }) => {
        switch (values.currentStep) {
          case 'basicData': {
            return (
              <BasicDataStep
                {...rest}
                goToNextStep={() => setFieldValue(currentStepConfig, 'pricingPlan')}
              />
            );
          }
          case 'pricingPlan': {
            return (
              <PricingPlanStep
                {...rest}
                goToPrevStep={() => setFieldValue(currentStepConfig, 'basicData')}
              />
            );
          }
          default:
            return null;
        }
      }}
    </Formik>
  );
};

/**
 * This code was already a mess and I'm not sure what is the expected behavior.
 * Just added types to remove warnings about `any` and made it a bit more
 * defensive. But it's still stinky as hell. Please don't blame me ;P
 */
const setErrorStatus = (
  response: { message?: string; data?: Record<string, unknown> },
  setStatus: (status: string) => void
) => {
  if (response.message === 'validation errors' && response.data) {
    setStatus(
      `Validation errors: ${(Object.values(response.data) as string[][])
        ?.map(value => value)
        .join('\n')}`
    );
  } else if (response.message) {
    setStatus(response.message);
  }
};
