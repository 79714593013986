import { LoadingAnimationWithAlternatingMessages } from 'features/loading-spinner/LoadingAnimationWithAlternatingMessages';
import styled from 'styled-components';

export const StepInProgressState = () => (
  <Root>
    <LoadingAnimationWithAlternatingMessages />
  </Root>
);

const Root = styled.div`
  width: 100%;
  height: 100%;
  // Sorry.. we wanna have a vertical centering, but have a margin-top: auto for the bottom content,
  // therefore we need to set a min-height to make sure the loader eats more space than required
  min-height: 380px;
  display: grid;
  justify-items: center;
`;
