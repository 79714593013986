import { SocialEndpoint, SocialLoginErrorCode } from 'services/api/social';
import { ApiErrors } from 'services/api/types';

import { createApiErrorMessageHandler } from '../utils/createApiErrorMessageHandler';
import mkApiErrorMessage from '../utils/mkApiErrorMessage';

const mkSocialApiErrors = (endpoint: SocialEndpoint, errorCode: SocialLoginErrorCode) =>
  mkApiErrorMessage('social', endpoint, errorCode);

const socialErrors: ApiErrors<SocialLoginErrorCode> = {
  ERROR_CUSTOMER_EMAIL_EXISTS: mkSocialApiErrors(
    'loginWithSocialProvider',
    'ERROR_CUSTOMER_EMAIL_EXISTS'
  ),
  ERROR_SOCIAL_AUTH_FAILED: mkSocialApiErrors('loginWithSocialProvider', 'ERROR_SOCIAL_AUTH_FAILED')
};

export const handleSocialErrors = createApiErrorMessageHandler(socialErrors);
