import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import styled, { DefaultTheme } from 'styled-components';

export const BANNER_BREAKING_POINT = 1250;
export const BANNER_HIDE_BREAKING_POINT = 550;

type BannerProps = {
  title: ReactElement;
  content: ReactElement;
  image: string;
  color: keyof DefaultTheme['colors']['palette'];
  children?: ReactElement;
};

export const HomeBannerLayout = (props: BannerProps) => {
  return (
    <Root $color={props.color}>
      <Body>
        <HeaderText>{props.title}</HeaderText>
        <BodyText>{props.content}</BodyText>
        <LeftContainer>{props.children}</LeftContainer>
      </Body>
      <ImageContainer>
        <Image src={props.image} />
      </ImageContainer>
    </Root>
  );
};

const Root = styled.div<{ $color: keyof DefaultTheme['colors']['palette'] }>`
  max-height: 200px;
  border-radius: ${({ theme }) => theme.borderRadius.one};
  overflow: hidden;
  background-color: ${({ theme, $color }) => theme.colors.palette[$color]};

  display: flex;
  align-items: center;

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }

  @media (max-width: ${BANNER_HIDE_BREAKING_POINT}px) {
    display: none;
  }
`;

const Body = styled.div`
  padding: ${({ theme }) => theme.spacings.six};

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.one};

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }
`;

const HeaderText = styled(Typography).attrs({
  variant: 'h5',
  color: 'white'
})`
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.h5
    })}
  }
`;

const BodyText = styled(Typography).attrs({
  variant: 'body1',
  color: 'white'
})`
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.body2
    })}
  }
`;

const LeftContainer = styled.div``;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 660px) {
    display: none;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(85px) translateY(-62px);
  max-width: unset;
`;
