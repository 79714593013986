import { AutoAwesome, InsertDriveFileRounded, KeyboardReturnRounded } from '@mui/icons-material';
import {
  Box,
  InputBase,
  ListItemIcon,
  MenuItem,
  MenuList,
  Popover,
  Typography
} from '@mui/material';
import Menu from '@mui/material/Menu';
import { isEditorReadOnly, PlateElement, PlateElementProps } from '@udecode/plate-common';
import { ReactComponent as ChatIcon } from 'assets/icons/icon-empty-document-action-chat.svg';
import { ReactComponent as AiImagesIcon } from 'assets/icons/icon-empty-document-action-images.svg';
import { ReactComponent as TextTypesIcon } from 'assets/icons/icon-empty-document-action-text-types.svg';
import { ReactComponent as MagicFeatherWithSparklesIcon } from 'assets/icons/icon-improve.svg';
import { editorWidth } from 'features/aiWriter/AiWriterTextEditor/AiWriterTextEditor';
import { useGenerateTextInDocument } from 'features/aiWriter/AiWriterTextEditor/hooks/useGenerateTextInDocument';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { changeOutputTypeThunk } from 'features/aiWriter/store/actions/config/thunks/changeOutputTypeThunk';
import { renameCurrentTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/renameCurrentTabThunk';
import { getActiveTab, getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import { useCreateEmptyPage } from 'features/plate/components/plate-ui/useCreateEmptyPage';
import {
  MenuItemType,
  useEmptyDocumentStore
} from 'features/plate/components/plate-ui/useEmptyDocumentStore';
import { useShowOutputTypeModal } from 'features/textGenerator/outputTypeModal/useShowOutputTypeModal';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import {
  ChangeEvent,
  ElementRef,
  forwardRef,
  HTMLAttributes,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { FormattedMessage } from 'react-intl';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { maxProjectNameLength } from 'store/utils/tabUtils';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const menuItemAmount = Object.keys(MenuItemType).filter(item => isNaN(Number(item))).length;

type SelectableMenuItemProps = HTMLAttributes<HTMLLIElement> & {
  type: MenuItemType;
  children: ReactNode;
  icon: ReactNode;
  onClick: () => void;
  useColoredIcon?: boolean;
};

const SelectableMenuItem = forwardRef<HTMLLIElement, SelectableMenuItemProps>(
  ({ type, children, icon, onClick, useColoredIcon = false, ...rest }, ref) => {
    const selectedMenuItem = useEmptyDocumentStore(state => state.selectedMenuItem);
    const isSelected = selectedMenuItem === type;

    return (
      <StyledMenuItem ref={ref} selected={isSelected} onClick={onClick} {...rest}>
        {useColoredIcon ? (
          <ListItemIcon>{icon}</ListItemIcon>
        ) : (
          <SlimGrayListItemIcon>{icon}</SlimGrayListItemIcon>
        )}

        {children}

        {isSelected && <RightAlignedKeyboardReturnRoundedIcon />}
      </StyledMenuItem>
    );
  }
);

const useUpdateCurrentTabName = () => {
  const dispatch = useAppDispatch();

  const updateCurrentTabName = (headline: string) => {
    const cleanHeadline = headline.trim();
    if (cleanHeadline.length > 0) {
      dispatch(renameCurrentTabThunk(cleanHeadline));
    }
    return;
  };
  return { updateCurrentTabName };
};

export const EmptyDocumentElement = forwardRef<ElementRef<typeof PlateElement>, PlateElementProps>(
  ({ nodeProps, children, ...props }, ref) => {
    const translate = useTr();
    const dispatch = useAppDispatch();
    const showOutputTypeModal = useShowOutputTypeModal();
    const runCommandMutation = useGenerateTextInDocument({
      outputType: 'command',
      toasts: {
        loading: <FormattedMessage id="aiWriter.command.toast_start" />,
        success: <FormattedMessage id="aiWriter.command.toast_end" />,
        error: <FormattedMessage id="aiWriter.command.toast_end.failed" />
      }
    });

    const inputRef = useRef<HTMLInputElement>(null);
    const aiActionItemRef = useRef<HTMLLIElement>(null);

    const mainMenuRef = useRef<HTMLUListElement>(null);
    const selectedMenuItem = useEmptyDocumentStore(state => state.selectedMenuItem);
    const setSelectedMenuItem = useEmptyDocumentStore(state => state.setSelectedMenuItem);
    const enteredHeadline = useEmptyDocumentStore(state => state.enteredHeadline);
    const setEnteredHeadline = useEmptyDocumentStore(state => state.setEnteredHeadline);
    const { createEmptyPage } = useCreateEmptyPage();
    const { updateCurrentTabName } = useUpdateCurrentTabName();

    const currentWriterModelLanguageAndCountry = useAppSelector(getCurrentModelLanguageAndCountry);
    const { name: currentDocumentTitle } = useAppSelector(getActiveTab);

    const [customPrompt, setCustomPrompt] = useState('');
    const [showPredefinedAiActionsMenu, setShowPredefinedAiActionsMenu] = useState(false);
    const [showFloatingAiPromptInput, setShowFloatingAiPromptInput] = useState(false);

    const mainMenuMinWidth = mainMenuRef.current?.getBoundingClientRect()?.width || 0;

    const handleCreateEmptyPageAction = () => {
      GAEvents.editorEmptyDocumentCreateEmptyPage();
      trackingWrapper.track('aiWriterEditorEmptyDocumentCreateEmptyPage');

      createEmptyPage();
    };

    const handleRunAiAction = () => {
      // No input -> show predefined actions
      if (enteredHeadline.trim().length === 0) {
        setShowPredefinedAiActionsMenu(true);
        return;
      }

      // Show prompt input to allow further customization
      setCustomPrompt(
        translate('aiWriter.editor.empty_document.custom_prompt', { input: enteredHeadline.trim() })
      );
      setShowFloatingAiPromptInput(true);
    };

    const handleShowTextTypesAction = () => {
      GAEvents.editorEmptyDocumentShowTextTypes();
      trackingWrapper.track('aiWriterEditorEmptyDocumentShowTextTypes');

      createEmptyPage();

      dispatch(
        setInspirationsCurrentStep({ step: AiWriterSidebarStep.aiWriter, subStep: 'editBrief' })
      );

      // Show output types
      const { currentModelCountry, currentModelLanguage } = currentWriterModelLanguageAndCountry;
      showOutputTypeModal({
        modelCountry: currentModelCountry,
        modelLanguage: currentModelLanguage,
        onOutputTypeChange: value => dispatch(changeOutputTypeThunk(value))
      });
    };

    const handleShowChatAction = () => {
      GAEvents.editorEmptyDocumentShowChat();
      trackingWrapper.track('aiWriterEditorEmptyDocumentShowChat');

      createEmptyPage();

      dispatch(setInspirationsCurrentStep({ step: AiWriterSidebarStep.chat, subStep: 'main' }));
    };

    const handleShowAiImageAction = () => {
      GAEvents.editorEmptyDocumentShowAiImages();
      trackingWrapper.track('aiWriterEditorEmptyDocumentShowAiImages');

      createEmptyPage();

      dispatch(
        setInspirationsCurrentStep({ step: AiWriterSidebarStep.images, subStep: 'aiImages' })
      );
    };

    const handleCustomPromptKeyDown = (event: KeyboardEvent) => {
      if (event.key !== 'Enter') {
        return;
      }

      GAEvents.editorEmptyDocumentRunCustomPrompt({
        prompt: customPrompt
      });
      trackingWrapper.track('aiWriterEditorEmptyDocumentRunCustomPrompt', {
        prompt: customPrompt
      });

      createEmptyPage();
      setShowFloatingAiPromptInput(false);

      runCommandMutation.mutate({ text: customPrompt });
    };

    const handlePredefinedAiActionClick = (prompt: string) => {
      GAEvents.editorEmptyDocumentRunPredefinedPrompt({
        prompt
      });
      trackingWrapper.track('aiWriterEditorEmptyDocumentRunPredefinedPrompt', {
        prompt
      });

      createEmptyPage();
      setShowPredefinedAiActionsMenu(false);

      runCommandMutation.mutate({ text: prompt });
    };

    const handleHeadlineChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newHeadline = event.target.value;
      setEnteredHeadline(newHeadline);
      updateCurrentTabName(newHeadline);
    };

    useEffect(() => {
      // After mount, the editor has the focus. Move it to our input field.
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }, []);

    // After mount, use the current document name as headline
    useEffect(() => {
      if (currentDocumentTitle && currentDocumentTitle !== unnamed) {
        setEnteredHeadline(currentDocumentTitle);
      }
    }, [currentDocumentTitle, setEnteredHeadline]);

    if (isEditorReadOnly(props.editor)) {
      return null;
    }

    return (
      <PlateElement ref={ref} {...props}>
        <div contentEditable={false} {...nodeProps}>
          <HeadlineTextInput
            placeholder={translate('aiWriter.editor.empty_document.title_placeholder')}
            value={enteredHeadline}
            inputRef={inputRef}
            onChange={handleHeadlineChange}
            inputProps={{ maxLength: maxProjectNameLength }}
            onKeyDown={event => {
              // Execute selected item with enter
              // TODO: Refactor this to reduce duplicate code
              if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();

                GAEvents.editorEmptyDocumentRunActionWithEnter();
                trackingWrapper.track('aiWriterEditorEmptyDocumentRunActionWithEnter');

                switch (selectedMenuItem) {
                  case MenuItemType.EmptyPage:
                    handleCreateEmptyPageAction();
                    break;
                  case MenuItemType.AiAction:
                    handleRunAiAction();
                    break;
                  case MenuItemType.TextTypes:
                    handleShowTextTypesAction();
                    break;
                  case MenuItemType.Chat:
                    handleShowChatAction();
                    break;
                  case MenuItemType.AiImage:
                    handleShowAiImageAction();
                    break;
                }
              }

              // Change selected item with arrow keys
              if (event.key === 'ArrowDown') {
                event.preventDefault();
                event.stopPropagation();

                setSelectedMenuItem((selectedMenuItem + 1) % menuItemAmount);
              }

              if (event.key === 'ArrowUp') {
                event.preventDefault();
                event.stopPropagation();

                setSelectedMenuItem(
                  // Allow jumping to the end of the list
                  selectedMenuItem - 1 < 0 ? menuItemAmount - 1 : selectedMenuItem - 1
                );
              }
            }}
          />

          <Box>
            <BlurableMenuList ref={mainMenuRef} $isBlurred={showFloatingAiPromptInput}>
              <SelectableMenuItem
                type={MenuItemType.EmptyPage}
                icon={<PrimaryInsertDriveFileRoundedIcon />}
                onClick={createEmptyPage}
                useColoredIcon={true}
              >
                <PrimaryActionText>
                  {translate('aiWriter.editor.empty_document.menu_item_empty_page')}
                </PrimaryActionText>
              </SelectableMenuItem>
              <SelectableMenuItem
                ref={aiActionItemRef}
                type={MenuItemType.AiAction}
                icon={<AutoAwesome />}
                onClick={handleRunAiAction}
              >
                <DisabledText>
                  {translate(
                    enteredHeadline.trim().length > 0
                      ? 'aiWriter.editor.empty_document.menu_item_command_action_with_headline'
                      : 'aiWriter.editor.empty_document.menu_item_command_action_from_scratch',
                    { headline: enteredHeadline.trim() }
                  )}
                </DisabledText>
              </SelectableMenuItem>

              <SectionTitle>{translate('aiWriter.editor.empty_document.suggestions')}</SectionTitle>

              <SelectableMenuItem
                type={MenuItemType.TextTypes}
                icon={<TextTypesIcon />}
                onClick={handleShowTextTypesAction}
              >
                <DisabledText>
                  {translate('aiWriter.editor.empty_document.menu_item_text_types')}
                </DisabledText>
              </SelectableMenuItem>
              <SelectableMenuItem
                type={MenuItemType.Chat}
                icon={<ChatIcon />}
                onClick={handleShowChatAction}
              >
                <DisabledText>
                  {translate('aiWriter.editor.empty_document.menu_item_chat')}
                </DisabledText>
              </SelectableMenuItem>
              <SelectableMenuItem
                type={MenuItemType.AiImage}
                icon={<AiImagesIcon />}
                onClick={handleShowAiImageAction}
              >
                <DisabledText>
                  {translate('aiWriter.editor.empty_document.menu_item_ai_image')}
                </DisabledText>
              </SelectableMenuItem>
            </BlurableMenuList>
          </Box>

          {children}

          <Menu
            open={showPredefinedAiActionsMenu}
            anchorEl={aiActionItemRef.current}
            onClose={() => setShowPredefinedAiActionsMenu(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <MenuItem
              onClick={() =>
                handlePredefinedAiActionClick(
                  translate('aiWriter.editor.empty_document.predefined_prompt_1_prompt')
                )
              }
            >
              {translate('aiWriter.editor.empty_document.predefined_prompt_1_title')}
            </MenuItem>
            <MenuItem
              onClick={() =>
                handlePredefinedAiActionClick(
                  translate('aiWriter.editor.empty_document.predefined_prompt_2_prompt')
                )
              }
            >
              {translate('aiWriter.editor.empty_document.predefined_prompt_2_title')}
            </MenuItem>
            <MenuItem
              onClick={() =>
                handlePredefinedAiActionClick(
                  translate('aiWriter.editor.empty_document.predefined_prompt_3_prompt')
                )
              }
            >
              {translate('aiWriter.editor.empty_document.predefined_prompt_3_title')}
            </MenuItem>
          </Menu>

          <FloatingCustomPromptInputPopover
            open={showFloatingAiPromptInput}
            onClose={() => setShowFloatingAiPromptInput(false)}
            anchorEl={aiActionItemRef.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            style={{ minWidth: mainMenuMinWidth }}
          >
            <CustomPromptInput
              autoFocus={true}
              value={customPrompt}
              onChange={event => setCustomPrompt(event.target.value)}
              startAdornment={<MagicFeatherWithSparklesIcon />}
              endAdornment={<KeyboardReturnRounded />}
              onKeyDown={handleCustomPromptKeyDown}
            />
          </FloatingCustomPromptInputPopover>
        </div>
      </PlateElement>
    );
  }
);
EmptyDocumentElement.displayName = 'EmptyPageElement';

const HeadlineTextInput = styled(InputBase)`
  font-size: ${({ theme }) => theme.typography.h5.fontSize};
  font-weight: ${({ theme }) => theme.typography.h5.fontWeight};
`;

const BlurableMenuList = styled(MenuList)<{ $isBlurred: boolean }>`
  filter: ${({ $isBlurred }) => ($isBlurred ? 'blur(2px)' : 'none')};
  opacity: ${({ $isBlurred }) => ($isBlurred ? 0.3 : 1)};

  transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out;
`;

const SlimGrayListItemIcon = styled(ListItemIcon)`
  color: rgba(0, 0, 0, 0.12);
  fill: rgba(0, 0, 0, 0.12);
  font-size: 24px;
`;

const PrimaryInsertDriveFileRoundedIcon = styled(InsertDriveFileRounded)`
  color: ${({ theme }) => theme.colors.primaryColorDark};
`;

const RightAlignedKeyboardReturnRoundedIcon = styled(KeyboardReturnRounded)`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.primaryColorDark};
`;

const FloatingCustomPromptInputPopover = styled(Popover)`
  margin-left: ${({ theme }) => theme.spacings.small};
  display: flex;
`;

const CustomPromptInput = styled(InputBase)`
  padding: ${({ theme }) => theme.spacings.xxsmall} ${({ theme }) => theme.spacings.small};
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
  font-size: 14px;
  min-width: ${({ theme }) => `calc(${editorWidth} - (${theme.spacings.small} * 2))`};
`;

const StyledMenuItem = styled(MenuItem)`
  padding: ${({ theme }) => theme.spacings.xsmall};
  border-radius: ${({ theme }) => theme.borderRadius.one};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryColorHover};
    border-radius: ${({ theme }) => theme.borderRadius.one};
    svg,
    div {
      color: ${({ theme }) => theme.colors.componentsIconActive};
      fill: ${({ theme }) => theme.colors.componentsIconActive};
    }
  }
`;

const SectionTitle = styled(Typography).attrs({ variant: 'overline', color: 'text.secondary' })`
  margin-top: ${({ theme }) => theme.spacings.four};
  display: block;
`;

const PrimaryActionText = styled(Typography).attrs({ variant: 'body1' })`
  color: ${({ theme }) => theme.colors.primaryColorDark};
`;

const DisabledText = styled(Typography).attrs({ variant: 'body1', color: 'text.disabled' })``;
