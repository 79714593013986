import { createPluginFactory } from '@udecode/plate';
import { DecorateEntry, isText } from '@udecode/plate-common';
import { PlatePlugin } from '@udecode/plate-core';
import { useFlashScoreSidebarState } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useFlashScoreSidebarState';
import { boundedTextRegExp } from 'features/aiWriter/AiWriterTextEditor/utils/boundedTextRegExp';
import { getInspirationsCurrentExtendedStep } from 'features/aiWriter/store/selectors';
import { FlashScoreTextRating } from 'features/flashScore/useScoreTexts';
import { Range } from 'slate';
import { useAppSelector } from 'store/hooks';
import { forceNonNullable } from 'utils/typescript/nonNullable';

export const MARK_FLASHSCORE_HIGHLIGHT = 'neuroflash-flashscore-highlight';
export const MARK_FLASHSCORE_EMOTIONALITY_1 = 'neuroflash-flashscore-emotionality-1';
export const MARK_FLASHSCORE_EMOTIONALITY_2 = 'neuroflash-flashscore-emotionality-2';
export const MARK_FLASHSCORE_EMOTIONALITY_3 = 'neuroflash-flashscore-emotionality-3';

export type EmotionalityLeafProps = {
  search: string;
  emotionality: FlashScoreTextRating;
  /**
   * Range of the current node, required to replace it with a synonym
   */
  range: Range;
  [MARK_FLASHSCORE_EMOTIONALITY_1]?: true;
  [MARK_FLASHSCORE_EMOTIONALITY_2]?: true;
  [MARK_FLASHSCORE_EMOTIONALITY_3]?: true;
};

type SearchRange = Range & EmotionalityLeafProps;

type PluginOptions = {
  isInsideFlashScoreTab?: boolean;
};

const decorate: PlatePlugin<PluginOptions>['decorate'] =
  (editor, { key }): DecorateEntry =>
  ([node, path]) => {
    // The plugin passed to this function is not updated when the plugin options change
    // So we need to access the plugin options from current state
    const options = editor.pluginsByKey[key].options as PluginOptions;
    const { showEmotionalityWordHighlight, wordRating } = useFlashScoreSidebarState.getState();

    const ranges: SearchRange[] = [];

    if (!options.isInsideFlashScoreTab || !showEmotionalityWordHighlight || !isText(node)) {
      return ranges;
    }

    const nodeText = node.text;

    const highlightList = wordRating
      .map(({ word, emotionality }) => ({
        word,
        type: `neuroflash-flashscore-emotionality-${emotionality.grade}`,
        emotionality
      }))
      // TODO: Highlighting more than 50 creates performance issues
      .slice(0, 50);

    if (highlightList.length === 0) {
      return ranges;
    }

    for (const highlight of highlightList) {
      // Copy from search-and-replace code
      // Doesn't honor word boundaries but is faster than the regex
      /*
      const parts = lowerCaseNodeText.split(highlight.word.toLowerCase());
      let offset = 0;
      parts.forEach((part, i) => {
        // First index always contains the text before the first occurrence
        if (i !== 0) {
          ranges.push({
            anchor: { path, offset: offset - highlight.word.length },
            focus: { path, offset },
            search: highlight.word,
            [highlight.type]: true
          });
        }

        offset = offset + part.length + highlight.word.length;
      });
      */

      const query = highlight.word.trim();
      const searchRegExp = boundedTextRegExp(query);

      for (const result of nodeText.matchAll(searchRegExp)) {
        const [match] = result;

        const start = forceNonNullable(result.index);
        const end = start + match.length;

        const range: Range = {
          anchor: { path, offset: start },
          focus: { path, offset: end }
        };

        ranges.push({
          ...range,
          [highlight.type]: true,
          // All non-range props will be magically passed to the rendered component
          // (magically done by Plate.js)
          search: highlight.word,
          emotionality: highlight.emotionality,
          range
        });
      }
    }

    return ranges;
  };

export const createFlashScoreHighlightPlugin = createPluginFactory<PluginOptions>({
  key: MARK_FLASHSCORE_HIGHLIGHT,
  isLeaf: true,
  decorate,
  useHooks: (editor, plugin) => {
    const inspirationsCurrentExtendedStep = useAppSelector(getInspirationsCurrentExtendedStep);

    const currentOptionValue = plugin.options.isInsideFlashScoreTab;
    const isInsideFlashScoreTab =
      inspirationsCurrentExtendedStep?.step === 'performance' &&
      inspirationsCurrentExtendedStep?.subStep === 'flashScore';
    plugin.options.isInsideFlashScoreTab = isInsideFlashScoreTab;

    // If it switched, redecorate to remove the old highlights or apply new ones
    if (currentOptionValue !== plugin.options.isInsideFlashScoreTab) {
      editor.redecorate();
    }
  },
  plugins: [
    {
      key: MARK_FLASHSCORE_EMOTIONALITY_1,
      isLeaf: true
    },
    {
      key: MARK_FLASHSCORE_EMOTIONALITY_2,
      isLeaf: true
    },
    {
      key: MARK_FLASHSCORE_EMOTIONALITY_3,
      isLeaf: true
    }
  ]
});
