import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { ReactElement } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';

type Props = {
  children: ReactElement[] | undefined;
};

export const CarouselComponent = ({ children }: Props) => (
  <StyledCarousel
    showThumbs={false}
    showIndicators={false}
    useKeyboardArrows={true}
    showStatus={false}
    infiniteLoop={true}
    renderArrowPrev={(clickHandler, hasPrev) =>
      hasPrev && (
        <StyledLeftArrow
          onClick={() => {
            GAEvents.onboardingSelectionSlidePrevious();
            clickHandler();
          }}
        />
      )
    }
    renderArrowNext={(clickHandler, hasNext) =>
      hasNext && (
        <StyledRightArrow
          onClick={() => {
            GAEvents.onboardingSelectionSlideNext();
            clickHandler();
          }}
        />
      )
    }
  >
    {children}
  </StyledCarousel>
);

const StyledLeftArrow = styled(KeyboardArrowLeftRounded)`
  position: absolute;
  top: 44%;
  left: -24px;
  z-index: 1;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.commonWhiteMain};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.divider};
`;

const StyledRightArrow = styled(KeyboardArrowRightRounded)`
  position: absolute;
  top: 44%;
  right: -24px;
  z-index: 1;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.commonWhiteMain};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.divider};
`;

const StyledCarousel = styled(Carousel)`
  position: relative;
  stroke: transparent;

  .carousel .slider-wrapper {
    padding-bottom: ${({ theme }) => theme.spacings.two};
  }
  .carousel.carousel-slider {
    overflow: visible;
  }
`;
