import Toast from 'components/toasts/Toast';
import { updateIsProjectPublic } from 'features/aiWriter/store/actions/preview/actions';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useCallback } from 'react';
import AiWriterAPI from 'services/api/aiWriter';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useRunInTask from 'utils/hooks/useRunInTask';
import useToggle from 'utils/hooks/useToggle';

export const useTogglePublicSharing = ({ callback }: { callback: () => void }) => {
  const dispatch = useAppDispatch();

  const activeTab = useAppSelector(getActiveTab);

  const [isShared, togglePublicSharing] = useToggle(activeTab.isPublic);
  const [isLoading, runTask] = useRunInTask();

  const shareProject = useCallback(
    () =>
      runTask(async () => {
        try {
          const isPublic = !activeTab.isPublic;

          const response = await AiWriterAPI.update(activeTab.id, { is_public: isPublic });

          if (response.status) {
            togglePublicSharing();
            callback();
            dispatch(updateIsProjectPublic({ tabId: activeTab.id, isPublic }));
          } else {
            Toast.backendError(handleCommonApiError(response.data.message));
          }
        } catch (e) {
          Toast.commonError();
        }
      }),
    [runTask, activeTab.isPublic, activeTab.id, togglePublicSharing, callback, dispatch]
  );

  return {
    isShared,
    isLoading: isLoading,
    toggle: shareProject
  };
};
