import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FlexContainer from 'components/FlexContainer';
import { Card, Header } from 'components/profile/components/common';
import dayjs from 'dayjs';
import { getPricing } from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { getPricingPlan } from 'features/pricing/store/selectors';
import { PricingPlan } from 'features/pricing/types';
import { CurrentTeamUsageSection } from 'features/profilePage/wordsUsage/currentUsage/CurrentTeamUsageSection';
import { CurrentUsageSection } from 'features/profilePage/wordsUsage/currentUsage/CurrentUsageSection';
import { DailyTeamUsageSection } from 'features/profilePage/wordsUsage/dailyUsage/DailyTeamUsageSection';
import { DailyUsageSection } from 'features/profilePage/wordsUsage/dailyUsage/DailyUsageSection';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store/hooks';

const permittedPricingTypes: Array<PricingPlan> = [
  PricingPlan.pro,
  PricingPlan.business,
  PricingPlan.enterprise
];

const useHasTeamDashboardAvailable = () => {
  const pricingPlan = useAppSelector(getPricingPlan);
  const isPermittedPlan = permittedPricingTypes.includes(pricingPlan);

  const teamRole = useAppSelector(state => state.customer.teamRole);
  const isOwnerOrAdmin = teamRole === CustomerRole.OWNER || teamRole === CustomerRole.ADMIN;

  const customerHasTeam = useAppSelector(state => state.customer.hasTeam);

  return isPermittedPlan && customerHasTeam && isOwnerOrAdmin;
};

export const UsageCard = () => {
  useEnableSidebarOnMount();
  const { pricingExpiryDate } = useAppSelector(state => state.customer, shallowEqual);
  const pricingPlan = useAppSelector(getPricing);

  const periodEnd = dayjs(pricingExpiryDate);
  const periodStart =
    pricingPlan.periodName === 'month'
      ? periodEnd.subtract(1, 'month')
      : periodEnd.subtract(1, 'year');

  const hasTeamDashboardAvailable = useHasTeamDashboardAvailable();

  function renderUsageSection() {
    if (hasTeamDashboardAvailable) {
      return (
        <>
          <CurrentTeamUsageSection />
          <DailyTeamUsageSection />
        </>
      );
    }

    return (
      <>
        <CurrentUsageSection />
        <DailyUsageSection />
      </>
    );
  }

  return (
    <Card variant="outlined">
      <Header style={{ flexDirection: 'column' }}>
        <Typography variant="h5">
          <FormattedMessage
            id={
              hasTeamDashboardAvailable
                ? 'profile_page.usage_card.team.heading'
                : 'profile_page.usage_card.heading'
            }
          />
        </Typography>
        <Box>
          <Typography variant="caption" color="text.disabled">
            <FormattedMessage
              id="profile_page.usage_card.subline"
              values={{
                periodStart: periodStart.format('DD.MM.YYYY'),
                periodEnd: periodEnd.format('DD.MM.YYYY')
              }}
            />
          </Typography>
        </Box>
      </Header>

      <FlexContainer gap="medium">{renderUsageSection()}</FlexContainer>
    </Card>
  );
};
