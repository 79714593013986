import { ModularWorkflowCreationPageTopBar } from 'features/modular-workflow/builder/ModularWorkflowCreationPageTopBar';
import { ModularWorkflowCreationViewEdit } from 'features/modular-workflow/builder/ModularWorkflowCreationViewEdit';
import { ModularWorkflowCreationViewSetup } from 'features/modular-workflow/builder/ModularWorkflowCreationViewSetup';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { ModularWorkflowStructureProvider } from 'features/modular-workflow/ModularWorkflowStructureProvider';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import styled from 'styled-components';

export const ModulareWorkflowCreatePage = () => {
  const currentView = useModulareWorkflowCreationStore(state => state.currentView);

  useEnableSidebarOnMount();

  return (
    <Root>
      <ModularWorkflowCreationPageTopBar />

      <ModularWorkflowStructureProvider>
        {(() => {
          switch (currentView) {
            case 'setup':
              return <ModularWorkflowCreationViewSetup />;
            case 'edit':
              return <ModularWorkflowCreationViewEdit />;
          }
        })()}
      </ModularWorkflowStructureProvider>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;
