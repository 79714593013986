import { useQuery } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { setSubscription } from 'features/customer/store/actions';
import { getIsAppReady, getIsAuthenticated } from 'features/customer/store/selectors';
import { CustomerSubscriptionData } from 'features/customer/store/types';
import CustomerAPI from 'services/api/customer';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export default function useFetchSubscriptionInBackground() {
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const isAppReady = useAppSelector(getIsAppReady);

  useQuery({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'subscription'],
    queryFn: async () => await CustomerAPI.subscription(),
    onSuccess(data) {
      if (data.status) {
        const subscriptionData: CustomerSubscriptionData = {
          subscription: {
            status: data.data.subscription_status,
            // Note: The backend naming here is inconsistent
            //       It's not related to the subscription itself, but only to the payment of the subscription
            paymentStatus: data.data.subscription_is_incomplete ? 'pending' : 'fulfilled'
          }
        };

        dispatch(setSubscription(subscriptionData));
      } else {
        const errorId = handleCommonApiError(data.data.message);

        Toast.backendError(errorId);
      }
    },
    onError() {
      Toast.apiError();
    },
    enabled: isAuthenticated && isAppReady
  });
}
