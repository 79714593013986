import Toast from 'components/toasts/Toast';
import { aiWriterFavoriteFromApi } from 'features/aiWriter/store/utils/mappers/aiWriterFavoriteMappers';
import FavoritesAPI from 'services/api/favorites';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { AppThunk } from 'store/store';

import { fetchFavorites } from '../actions';

export const fetchFavoritesThunk = (): AppThunk<void> => async dispatch => {
  dispatch(fetchFavorites.request());

  try {
    const response = await FavoritesAPI.get();

    if (response.status) {
      dispatch(fetchFavorites.success(response.data.map(aiWriterFavoriteFromApi)));
    } else {
      const errorId = handleCommonApiError(response.data.message);

      Toast.backendError(errorId);
    }
  } catch (e) {
    dispatch(fetchFavorites.failure());
    Toast.commonError();
  }
};
