import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Grid, IconButton, Typography } from '@mui/material';
import {
  Card,
  Header,
  StyledCardContent,
  StyledDivider
} from 'components/profile/components/common';
import ActiveSubscriptionCard from 'components/profile/components/subscription/ActiveSubscriptionCard';
import ApplyPromotionCode from 'components/profile/components/subscription/ApplyPromotionCode';
import EndSubscriptionHint from 'components/profile/components/subscription/EndSubscriptionHint';
import dayjs from 'dayjs';
import { getIsApiCustomer } from 'features/customer/store/selectors';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useIsOnTrial } from 'features/pricing/hook/useIsOnTrial';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { isSubscriptionInGrace } from 'features/pricing/utils/isSubscriptionInGrace';
import { isSubscriptionPaymentPending } from 'features/pricing/utils/isSubscriptionPaymentPending';
import { isSubscriptionWithAutoRenewal } from 'features/pricing/utils/isSubscriptionWithAutoRenewal';
import BillingInfoStatus from 'features/profilePage/BillingInfoStatus';
import InformationBlock from 'features/profilePage/InformationBlock';
import PaymentInfoStatus from 'features/profilePage/PaymentInfoStatus';
import { useStripePortalUrl } from 'features/stripe-portal/useStripePortalUrl';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/store';
import styled from 'styled-components';

export const SubscriptionCard = (): ReactElement | null => {
  const { pricingExpiryDate, cardBrand, cardLastFour } = useAppSelector(
    (state: RootState) => state.customer,
    shallowEqual
  );

  const isApiCustomer = useAppSelector(getIsApiCustomer);

  const { currentPricingPlan, currentSubscriptionState } = useSubscriptionState();
  const isOnTrial = useIsOnTrial({ currentSubscriptionState });

  const {
    stripePortalUrl,
    stripePortalPaymentMethodChangeUrl,
    stripePortalBillingAddressChangeUrl
  } = useStripePortalUrl();

  useEnableSidebarOnMount();

  if (!currentPricingPlan || !currentSubscriptionState) {
    return null;
  }

  const hasAutoRenewal = isSubscriptionWithAutoRenewal(currentSubscriptionState);
  const inGrace =
    isSubscriptionInGrace(currentSubscriptionState) ||
    currentSubscriptionState.status === 'on trial grace';
  const hasPendingPayment = isSubscriptionPaymentPending(currentSubscriptionState);
  const isActive = inGrace || hasAutoRenewal || isOnTrial;

  return (
    <Card variant="outlined">
      <Header>
        <Typography variant="h5">
          <FormattedMessage id="profile.subscription.title" />
        </Typography>
      </Header>
      <StyledCardContent>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <ActiveSubscriptionCard pricingPlan={currentPricingPlan} />
            {inGrace && (
              <GracePeriodMessageTypography variant="body2">
                <FormattedMessage id="profile.unsubscribed" />
              </GracePeriodMessageTypography>
            )}

            <InformationBlock headline="profile.subscription.state">
              {hasPendingPayment && (
                <Typography variant="body2">
                  <FormattedMessage id="profile.subscription.pending" />
                </Typography>
              )}

              <FormattedMessage
                id={
                  isActive
                    ? 'profile.subscription.state_active'
                    : 'profile.subscription.state_inactive'
                }
                values={{ date: dayjs(pricingExpiryDate).format('LL') }}
              />
            </InformationBlock>
            <InformationBlock headline="profile.subscription.subscription_auto_renewal">
              <AutoRenewal variant="body2" $active={hasAutoRenewal || isOnTrial}>
                <FormattedMessage
                  id={
                    hasAutoRenewal || isOnTrial
                      ? 'profile.subscription.subscription_auto_renewal_on'
                      : 'profile.subscription.subscription_auto_renewal_off'
                  }
                />
              </AutoRenewal>
            </InformationBlock>
            {!isApiCustomer && (
              <InformationBlock headline="profile.subscription.subscription_coupon">
                <ApplyPromotionCode />
              </InformationBlock>
            )}
          </Grid>

          <Grid item xs={6}>
            <PaymentInfoStatus
              cardBrand={cardBrand}
              cardLastFour={cardLastFour}
              stripePortalUrl={stripePortalPaymentMethodChangeUrl}
            />
            <StyledDivider />

            <BillingInfoStatus stripePortalUrl={stripePortalBillingAddressChangeUrl} />
            {!isApiCustomer && (
              <>
                <StyledDivider />

                <InformationBlock
                  headline="profile.subscription.invoice_history"
                  button={
                    <IconButton component="a" href={stripePortalUrl} target="_blank">
                      <OpenInNewIcon />
                    </IconButton>
                  }
                />
              </>
            )}
          </Grid>
        </Grid>
        {!isApiCustomer && (
          <EndSubscriptionHint
            currentPricingPlan={currentPricingPlan}
            hasAutoRenewal={hasAutoRenewal}
          />
        )}
      </StyledCardContent>
    </Card>
  );
};

const AutoRenewal = styled(Typography)<{ $active?: boolean }>`
  color: ${({ theme, $active }) => ($active ? theme.colors.success : theme.colors.danger)};
`;

const GracePeriodMessageTypography = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacings.small};
`;
