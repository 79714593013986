import { AiWriterSidebar } from 'features/aiWriter/AiWriterSidebar/AiWriterSidebar';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { CommonWorkflowContainer } from 'features/workflow/CommonWorkflowContainer';
import { SelectOutlineStep, SelectTitleStep, Step2 } from 'features/workflowBlogPostBuilder/steps';
import { GenerateContentStep } from 'features/workflowBlogPostBuilder/steps/GenerateContentStep';
import { Step1 } from 'features/workflowBlogPostBuilder/steps/Step1';
import { Step3 } from 'features/workflowBlogPostBuilder/steps/Step3';
import { BlogBuilderSteps } from 'features/workflowBlogPostBuilder/types';
import { useAutoTriggerAfterTimeout } from 'features/workflowBlogPostBuilder/useAutoTriggerAfterTimeout';
import { useSteps } from 'features/workflowBlogPostBuilder/useSteps';
import { useSyncWithInspirationPanel } from 'features/workflowBlogPostBuilder/useSyncWithInspirationPanel';
import { KeyboardEvent, useCallback, useMemo } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import { isInputSubmitAttempt } from 'utils/isInputSubmitAttempt';

const redirectAfterTextGenerationDelay = 4000;

export const BlogPostBuilder = () => {
  const { currentStep, isNextStepBtnDisabled, onPreviousStep, onPrimaryAction, goToStep } =
    useSteps();
  const isSidebarDisabled = useAppSelector(state => state.aiWriter.isInspirationsPanelDisabled);

  const textGenerationFinished =
    currentStep === BlogBuilderSteps.GenerateText && !isNextStepBtnDisabled;

  useSyncWithInspirationPanel();

  useAutoTriggerAfterTimeout({
    shouldTrigger: textGenerationFinished,
    delay: redirectAfterTextGenerationDelay,
    action: onPrimaryAction
  });

  const handleGoToKeywords = useCallback(() => {
    goToStep(BlogBuilderSteps.Step1);
  }, [goToStep]);

  const handleEnterKeyPress = useCallback(
    (event: KeyboardEvent, step: BlogBuilderSteps) => {
      if (isInputSubmitAttempt(event) && !isNextStepBtnDisabled) {
        event.preventDefault();
        goToStep(step);
      }
    },
    [goToStep, isNextStepBtnDisabled]
  );

  const renderedStep = useMemo(() => {
    switch (currentStep) {
      case BlogBuilderSteps.Step1:
        return <Step1 />;
      case BlogBuilderSteps.Step2:
        return <Step2 />;
      case BlogBuilderSteps.Step3:
        return <Step3 onGoToKeywordsClick={handleGoToKeywords} />;

      case BlogBuilderSteps.Step4:
        return <SelectTitleStep onKeyDown={e => handleEnterKeyPress(e, BlogBuilderSteps.Step5)} />;
      case BlogBuilderSteps.Step5:
        return (
          <SelectOutlineStep
            onKeyDown={e => handleEnterKeyPress(e, BlogBuilderSteps.GenerateText)}
          />
        );
      case BlogBuilderSteps.GenerateText:
        return <GenerateContentStep />;
    }
  }, [currentStep, handleEnterKeyPress, handleGoToKeywords]);

  return (
    <>
      <CommonWorkflowContainer
        stepCount={6}
        activeStep={currentStep}
        nextButtonProps={{
          ...withGtmInteraction(gtmIds.blogPostBuilder.primaryAction, {
            withDeprecatedStyle: true
          }),
          disabled: isNextStepBtnDisabled,
          onClick: onPrimaryAction
        }}
        hasBackButton={currentStep !== BlogBuilderSteps.Step1}
        backButtonProps={{
          onClick: onPreviousStep
        }}
      >
        {renderedStep}
      </CommonWorkflowContainer>

      {!isSidebarDisabled && (
        <AiWriterSidebar isDisabled={true} availableSteps={[AiWriterSidebarStep.aiWriter]} />
      )}
    </>
  );
};

export default BlogPostBuilder;
