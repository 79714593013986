import { ELEMENT_H1 } from '@udecode/plate';
import { getRoutePath } from 'config/routes';
import { blogPostWorkflowOutputTypes } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/stepOutputTypes';
import { isAiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import {
  disableInspirationsPanel,
  enableInspirationsPanel,
  setBlogPostOutline,
  setBlogPostTitle
} from 'features/aiWriter/store/actions/builder/actions';
import {
  changeConfigText,
  changeGenerateTextConfig,
  changeKeywords,
  changeOutputType,
  changeSeoConfig,
  setInspirationsCurrentStep
} from 'features/aiWriter/store/actions/config/actions';
import { setSelection, updateText } from 'features/aiWriter/store/actions/editor/actions';
import { generateTextInspirationsThunk } from 'features/aiWriter/store/actions/inspirations/thunks/generateTextInspirationsThunk';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { setCurrentTab } from 'features/aiWriter/store/actions/tabs/actions';
import { renameCurrentTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/renameCurrentTabThunk';
import {
  setIsAiWriterTourFinished,
  setIsUserOnboarded
} from 'features/aiWriter/store/actions/tour/actions';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { fallbackModelCountry } from 'features/aiWriter/store/utils/defaults/fallbackModelCountry';
import { fallbackModelLanguage } from 'features/aiWriter/store/utils/defaults/fallbackModelLanguage';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { removeNumberingFromHeadline } from 'features/workflowBlogPostBuilder/removeNumberingFromHeadline';
import { BlogBuilderSteps, FormValues } from 'features/workflowBlogPostBuilder/types';
import { useCreateDocument } from 'features/workflowBlogPostBuilder/useCreateDocument';
import { useGenerateBlogPostContent } from 'features/workflowBlogPostBuilder/useGenerateBlogPostContent';
import { useGenerateFullBlogPost } from 'features/workflowBlogPostBuilder/useGenerateFullBlogPost';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useSteps = () => {
  const [currentStep, setCurrentStep] = useState<BlogBuilderSteps>(BlogBuilderSteps.Step1);
  const { id: tabId } = useAppSelector(getActiveTab);

  const navigate = useNavigate();

  const { values, setValues, errors, setFieldValue } = useFormikContext<FormValues>();

  const [isNextStepBtnDisabled, setIsNextStepBtnDisabled] = useState(values.disableNextStep);

  const dispatch = useAppDispatch();
  const { createDocument } = useCreateDocument();

  const getModelById = useAppSelector(getEmbeddingModelDataSelector);
  const model = getModelById(values.language);
  const languageAndCountryCode = model
    ? `${model.language}_${model.country}`
    : `${fallbackModelLanguage}_${fallbackModelCountry}`;

  const tonalitySupportedInLanguage =
    isAiWriterSupportedLanguageAndCountryCode(languageAndCountryCode);

  const generatePost = useGenerateFullBlogPost();
  const generateContent = useGenerateBlogPostContent();

  const runPostWorkFlowActions = useCallback(async () => {
    // #tech-debt https://app.clickup.com/t/862jjp0bb
    dispatch(setIsUserOnboarded(true));
    await navigate(getRoutePath('aiWriter'));
    dispatch(setCurrentTab(tabId));
    dispatch(updateCurrentProjectInBackgroundThunk());
    trackingWrapper.track('aiWriterBlogPostCreated');
    GAEvents.workflowFinished({
      name: 'blog post builder'
    });
  }, [dispatch, navigate, tabId]);

  const onPreviousStep = useCallback(() => {
    switch (currentStep) {
      case BlogBuilderSteps.Step1: {
        navigate(getRoutePath('home'));
        return;
      }

      case BlogBuilderSteps.Step2: {
        setCurrentStep(prev => --prev);
        return;
      }
      case BlogBuilderSteps.Step3: {
        if (tonalitySupportedInLanguage) {
          setCurrentStep(prev => --prev);
        } else {
          setCurrentStep(prev => prev - 2);
        }

        return;
      }
      case BlogBuilderSteps.Step4: {
        dispatch(disableInspirationsPanel());
        dispatch(setInspirationsCurrentStep(undefined));

        setCurrentStep(prev => --prev);

        return;
      }
      case BlogBuilderSteps.Step5: {
        dispatch(changeConfigText(values.goal));
        setCurrentStep(prev => --prev);
        return;
      }
      case BlogBuilderSteps.GenerateText: {
        dispatch(disableInspirationsPanel());
        dispatch(setInspirationsCurrentStep(undefined));
        setCurrentStep(prev => --prev);
        return;
      }
      default:
        setCurrentStep(prev => --prev);
    }
  }, [currentStep, navigate, tonalitySupportedInLanguage, dispatch, values.goal]);

  const onPrimaryAction = useCallback(async () => {
    switch (currentStep) {
      case BlogBuilderSteps.Step1: {
        const chosenModel = getModelById(values.language);
        const defaultPersonalityResult = await httpGetDefaultPersonality.callEndpoint({
          language: chosenModel.language,
          country: chosenModel.country
        });
        setFieldValue('personality', defaultPersonalityResult);

        await createDocument(values.language);

        GAEvents.blogPostWorkflowKeywordSubmitted({ keywords: values.keywords });

        setCurrentStep(prev => ++prev);
        break;
      }

      case BlogBuilderSteps.Step2: {
        dispatch(renameCurrentTabThunk(values.goal));
        dispatch(changeConfigText(values.goal));
        dispatch(renameCurrentTabThunk(values.goal));

        dispatch(
          changeGenerateTextConfig({
            systemTonality: values.systemTonality,
            userTonality: values.userTonality,
            outputType: blogPostWorkflowOutputTypes.title,
            text: values.goal,
            personalityId: values.personality?.id,
            informationList: values.information
          })
        );

        dispatch(updateCurrentProjectInBackgroundThunk());

        setCurrentStep(prev => prev + 1);

        break;
      }
      case BlogBuilderSteps.Step3: {
        setCurrentStep(prev => ++prev);
        GAEvents.blogPostWorkflowElementSelected({ elements: values.elements });
        break;
      }
      case BlogBuilderSteps.Step4: {
        dispatch(changeConfigText(values.title));
        dispatch(updateCurrentProjectInBackgroundThunk());
        setCurrentStep(prev => ++prev);
        break;
      }
      case BlogBuilderSteps.Step5: {
        dispatch(changeConfigText(values.outline));
        dispatch(updateCurrentProjectInBackgroundThunk());
        dispatch(disableInspirationsPanel());
        dispatch(setInspirationsCurrentStep(undefined));

        // Clear the content in case the user jumps back and forth
        setValues({
          ...values,
          generatedContent: []
        });

        setCurrentStep(prev => ++prev);

        const sectionHeadlines = values.outline
          .split('\n')
          .filter(str => str !== '' && str !== null);

        const firstHeadlineText = removeNumberingFromHeadline(sectionHeadlines[0]);

        dispatch(changeKeywords(firstHeadlineText));

        const imageConfig = values.elements.includes('images') ? { query: values.goal } : null;

        const isGenerateTextFlow = values.elements.length === (imageConfig ? 1 : 0);

        // this should be only a temporary solution
        if (isGenerateTextFlow) {
          const result = await generateContent({
            title: values.title,
            systemTonality: values.systemTonality,
            userTonality: values.userTonality,
            sectionHeadlines,
            imageConfig,
            modelId: values.language,
            personalityId: values.personality?.id ?? null,
            informationList: values.information
          });

          setValues({
            ...values,
            generatedContent: result
          });

          break;
        }

        //for now we only want to use the first keyword
        const keywords = values.keywords[0];

        // Remove images from elements if it's included because we call for images separately
        const elements = values.elements.includes('images')
          ? values.elements.filter(el => el !== 'images')
          : values.elements;

        const result = await generatePost({
          blogConfig: {
            title: values.title,
            elements: elements,
            tonality: [...values.systemTonality, ...values.userTonality],
            language: values.language,
            outline: sectionHeadlines,
            keywords,
            personality_id: values.personality?.id ?? null,
            information_ids: values.information?.map(info => info.id)
          },
          imageConfig
        });

        setValues({
          ...values,
          generatedContent: result
        });

        break;
      }
      case BlogBuilderSteps.GenerateText: {
        dispatch(
          updateText({
            tabId,
            value: [
              {
                type: ELEMENT_H1,
                children: [{ text: values.title }]
              },
              ...values.generatedContent
            ]
          })
        );

        dispatch(
          changeGenerateTextConfig({
            text: '',
            brand: '',
            keywords: '',
            keywords2: '',
            systemTonality: [],
            userTonality: values.userTonality,
            outputType: 'rewrite',
            length: null,
            personalityId: values.personality?.id,
            informationList: values.information
          })
        );
        // #tech-debt #862j76zhz
        // This solution is trash... but it works for now
        // Set editor focus to the empty paragraph added above
        dispatch(
          setSelection({
            path: [2, 0],
            offset: 0
          })
        );
        // Show SEO analysis panel
        dispatch(
          setInspirationsCurrentStep({ step: AiWriterSidebarStep.performance, subStep: 'seoMain' })
        );

        runPostWorkFlowActions();
        break;
      }

      default:
        setCurrentStep(prev => ++prev);
    }
  }, [
    currentStep,
    getModelById,
    values,
    setFieldValue,
    createDocument,
    dispatch,
    setValues,
    generatePost,
    generateContent,
    tabId,
    runPostWorkFlowActions
  ]);

  useEffect(() => {
    switch (currentStep) {
      case BlogBuilderSteps.Step1:
        return setIsNextStepBtnDisabled(
          !values.language || values.goal.length === 0 || values.disableNextStep
        );
      case BlogBuilderSteps.Step2:
        return setIsNextStepBtnDisabled(false);
      case BlogBuilderSteps.Step3:
        return setIsNextStepBtnDisabled(false);
      case BlogBuilderSteps.Step4:
        return setIsNextStepBtnDisabled(values.title.length === 0);
      case BlogBuilderSteps.Step5:
        return setIsNextStepBtnDisabled(values.outline.length === 0 || !!errors.outline);
      case BlogBuilderSteps.GenerateText:
        return setIsNextStepBtnDisabled(values.generatedContent.length === 0);
    }
  }, [values, currentStep, errors.outline]);

  useEffect(() => {
    switch (currentStep) {
      case BlogBuilderSteps.Step1: {
        dispatch(setBlogPostTitle(''));
        dispatch(setBlogPostOutline(''));
        dispatch(disableInspirationsPanel());
        break;
      }
      case BlogBuilderSteps.Step4: {
        dispatch(enableInspirationsPanel());
        dispatch(
          setInspirationsCurrentStep({ step: AiWriterSidebarStep.aiWriter, subStep: 'blogPost' })
        );
        dispatch(changeKeywords(values.keywords[0]));
        dispatch(changeSeoConfig({ keyword: values.keywords[0], searchEngine: '' }));
        dispatch(changeOutputType(blogPostWorkflowOutputTypes.title));
        dispatch(generateTextInspirationsThunk());
        break;
      }
      case BlogBuilderSteps.Step5: {
        dispatch(enableInspirationsPanel());
        dispatch(
          setInspirationsCurrentStep({ step: AiWriterSidebarStep.aiWriter, subStep: 'blogPost' })
        );
        dispatch(changeOutputType(blogPostWorkflowOutputTypes.outline));
        dispatch(generateTextInspirationsThunk());
        break;
      }
      case BlogBuilderSteps.GenerateText: {
        dispatch(disableInspirationsPanel());
        break;
      }
    }
    // NOTE: DO NOT add the values object here, as it causes this effect to be re-executed on every
    //       re-render, which triggers the text generation again and again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDocument, currentStep, dispatch, values.keywords]);

  useEffect(() => {
    dispatch(setInspirationsCurrentStep(undefined));
    dispatch(setIsAiWriterTourFinished(true));
  }, [dispatch]);

  const goToStep = useCallback((step: BlogBuilderSteps) => {
    setCurrentStep(step);
  }, []);

  return {
    currentStep,
    isNextStepBtnDisabled,
    onPreviousStep,
    onPrimaryAction,
    goToStep
  };
};
