import { Checkbox } from '@mui/material';
import { Elements } from 'features/workflowBlogPostBuilder/types';
import { useField } from 'formik';

export function FieldCheckbox(props: { fieldName: string; value: Elements; disabled?: boolean }) {
  const { fieldName, value, disabled } = props;
  const [field] = useField(fieldName);

  return (
    <Checkbox {...field} checked={field.value.includes(value)} value={value} disabled={disabled} />
  );
}
