import { Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import mobileWarningImage from 'assets/mobile-warning.svg';
import neuroflashLogoImage from 'assets/neuroflashLogo.svg';
import FlexContainer from 'components/FlexContainer';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { useModal } from 'components/modals';
import { CloseModal } from 'components/modals/types';
import LocalStorageKey from 'config/localStorageKey';
import { RouteConfig } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useDebouncedEffect } from 'pages/mobileWarning/useDebouncedEffect';
import { ChangeEvent, useEffect } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { useCurrentRoutePath } from 'utils/hooks/useCurrentRoutePath';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { mobileWindowWidth, useMobileVersion } from 'utils/hooks/useMobileVersion';
import useTr from 'utils/hooks/useTr';
import useWindowSize from 'utils/hooks/useWindowSize';

const disabledAppPaths: RouteConfig['path'][] = [
  '/login',
  '/register',
  '/register/success',
  '/free',
  '/activation-mail',
  '/forgot-password',
  '/reset-password',
  '/resend-mail',
  '/invalid-reset-password',
  '/social/:provider/callback',
  '/verify-account',
  '/onboarding/welcome',
  '/onboarding/survey',
  '/onboarding/usecase',
  '/onboarding/brand-voice',
  '/onboarding/brand-voice-finished',
  '/onboarding/selection',
  '/socialRegister'
];

export function useShowMobileWarningModal() {
  const [canShowWarning, setDisableMobileWarning, isMobileWidth] = useMobileVersion();
  const { showModal } = useModal();
  const currentRoutePath = useCurrentRoutePath();

  const [isShown, setIsShown] = useLocalStorage(LocalStorageKey.MobileWarningModalShow, true);

  useEffect(() => {
    if (!canShowWarning || disabledAppPaths.includes(currentRoutePath) || !isShown) {
      return;
    }

    GAEvents.modalSeen({
      modal: 'Mobile warning modal'
    });

    showModal('MOBILE_WARNING', {
      size: 'halfscreen',
      isMobileWidth,
      closeWarning: setDisableMobileWarning,
      onDoNotShowAgain: setIsShown
    });
  }, [
    setDisableMobileWarning,
    showModal,
    canShowWarning,
    currentRoutePath,
    isShown,
    setIsShown,
    isMobileWidth
  ]);
}

type Props = {
  closeWarning: () => void;
  onDoNotShowAgain: (value: boolean) => void;
} & CloseModal;

export function MobileWarningModal({ closeModal, closeWarning, onDoNotShowAgain }: Props) {
  const tr = useTr();

  const { width } = useWindowSize();
  const debounceDelay = 200;

  useDebouncedEffect(
    () => {
      if (width !== undefined && width > mobileWindowWidth) {
        closeModal();
      }
    },
    [width],
    debounceDelay
  );

  const handleContinueClick = () => {
    closeModal();
    closeWarning();
  };

  return (
    <Root gap="xlarge">
      <FlexContainer gap="xmedium">
        <FlexContainer direction="row" justifyContent="space-between" gap="three">
          <LogoImage src={neuroflashLogoImage} alt={tr('common.neuroflash')} />

          <DeleteIconButton
            {...withGtmInteraction(gtmIds.warningModal.close)}
            onClick={closeModal}
          />
        </FlexContainer>
        <ImageWrapper>
          <img src={mobileWarningImage} alt="" />
        </ImageWrapper>
        <Typography variant="h4">
          <FormattedMessage id="mobile_warning.header" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id="mobile_warning.body_1" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id="mobile_warning.body_2" />
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onDoNotShowAgain(!event.target.checked)
                }
              />
            }
            label={<FormattedMessage id="mobile_warning.dont_show_checkbox" />}
          />
        </FormGroup>
      </FlexContainer>
      <FlexContainer direction="row" justifyContent="space-between">
        <Button
          {...withGtmInteraction(gtmIds.warningModal.goToNF)}
          variant="contained"
          onClick={handleContinueClick}
          color="primary"
        >
          <FormattedMessage id="mobile_warning.button.continue" />
        </Button>
        <Button
          {...withGtmInteraction(gtmIds.warningModal.courses)}
          component="a"
          href={tr('external_links.app_academy')}
          target="_blank"
          rel="noreferrer"
          color="primary"
        >
          <FormattedMessage id="mobile_warning.button.academy" />
        </Button>
      </FlexContainer>
    </Root>
  );
}

const Root = styled(FlexContainer)`
  position: relative;
  margin: ${({ theme }) => `${theme.spacings.large} ${theme.spacings.large}`};
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const LogoImage = styled.img`
  width: auto;
  max-width: fit-content;
`;
