import { AxiosInstance } from 'axios';
import LocalStorageKey from 'config/localStorageKey';
import { apiKeyHeader } from 'services/backofficeIntegration/http/backofficeHeaders';

export const withApiKey = (client: AxiosInstance) => {
  client.interceptors.request.use(request => {
    const isApiCustomer = localStorage.getItem(LocalStorageKey.IsApiCustomer) === '1';

    Object.assign(request.headers, apiKeyHeader({ isApiCustomer }));

    return request;
  });
};
