import {
  keywords2Field,
  keywordsField,
  textBodyField
} from 'features/aiTester/modals/setup/SetupModal';
import { useGetPrefillValueByLanguageCountryAndOutputType } from 'features/textGenerator/utils/useGetPromptsOthers';
import { SetFieldValue } from 'types/Formik';

export default function useHandleOutputTypeChange(language: string, country: string) {
  const getPrefillValue = useGetPrefillValueByLanguageCountryAndOutputType(language, country);

  return (outputType: string, setFieldValue: SetFieldValue) => {
    setFieldValue(textBodyField, getPrefillValue(outputType, 'text'));
    setFieldValue(keywordsField, getPrefillValue(outputType, 'keywords'));
    setFieldValue(keywords2Field, getPrefillValue(outputType, 'keywords2'));
  };
}
