import { Typography } from '@mui/material';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { LazyLoadedLoadingAnimation } from 'features/loading-spinner/LazyLoadedLoadingAnimation';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const defaultMessages: Array<IntlMessageKeys> = [
  'tip_loader.alternating_messages.average_time',
  'tip_loader.alternating_messages.creativity_progress'
];

type Props = {
  alternatingMessages?: IntlMessageKeys[];
};

export const LoadingAnimationWithAlternatingMessages = ({
  alternatingMessages = defaultMessages
}: Props) => {
  const [currentTranslationIndex, setCurrentTranslationIndex] = useState(0);
  const currentTranslation = alternatingMessages[currentTranslationIndex];

  useEffect(() => {
    const labelTimer = setInterval(() => {
      setCurrentTranslationIndex(prevCount => (prevCount + 1) % alternatingMessages.length);
    }, 3000);

    return () => {
      clearInterval(labelTimer);
    };
  }, [currentTranslationIndex, currentTranslation, alternatingMessages]);

  return (
    <Root>
      <LazyLoadedLoadingAnimation />

      <Typography component="div" variant="body1">
        <FormattedMessage id={currentTranslation} />
      </Typography>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.four};
`;
