import { useCalculateInspirationsStep } from 'features/aiWriter/AiWriterSidebar/hooks/useCalculateInspirationsStep';
import { AiWriterSidebarButtons } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarButtons';
import { AiWriterSidebarDrawer } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarDrawer';
import { AiWriterSidebarViews } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarViews';
import { useFlashScoreSidebarState } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useFlashScoreSidebarState';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { getInspirationsCurrentStep } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { ComponentProps, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const useAiWriterSidebarStepChange = () => {
  const dispatch = useAppDispatch();
  const inspirationsCurrentStep = useAppSelector(getInspirationsCurrentStep);

  const calculateInspirationsStep = useCalculateInspirationsStep();

  return (step: AiWriterSidebarStep) => {
    if (step === inspirationsCurrentStep) {
      dispatch(setInspirationsCurrentStep(undefined));
      return;
    }

    const calculatedStep = calculateInspirationsStep(step);
    dispatch(setInspirationsCurrentStep(calculatedStep));
  };
};

type Props = {
  isDisabled?: boolean;
  availableSteps?: ComponentProps<typeof AiWriterSidebarButtons>['availableSteps'];
};

export const AiWriterSidebar = ({ isDisabled = false, availableSteps }: Props) => {
  const handleStepChange = useAiWriterSidebarStepChange();
  const resetFlashScoreSidebarState = useFlashScoreSidebarState(state => state.reset);

  // Reset FlashScore on mount to ensure it's showing the initial view on document change
  useEffect(() => {
    resetFlashScoreSidebarState();
  }, [resetFlashScoreSidebarState]);

  return (
    <AiWriterSidebarDrawer withButtons={true}>
      {() => (
        <Root>
          <AiWriterSidebarButtons
            isDisabled={isDisabled}
            onStepChange={handleStepChange}
            availableSteps={availableSteps}
          />
          <AiWriterSidebarViews />
        </Root>
      )}
    </AiWriterSidebarDrawer>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: row;

  flex: 1;
  width: 100%;
  height: 100%;
`;
