import { Button, CircularProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColoredTypography } from 'components/ColoredTypography';
import { Card, Header } from 'components/profile/components/common';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { PlanCard } from 'features/homePage/apiPricing/PlanCard';
import FormattedMessage from 'features/i18n/FormattedMessage';
import PricingAPI from 'services/api/pricing';
import { ApiPricingType } from 'services/api/pricing/types';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { isNonNullable } from 'utils/typescript/nonNullable';

export function useApiPricingQuery() {
  return useQuery({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'api-pricing'],
    queryFn: async () => {
      const response = await PricingAPI.getPricings({});
      if (!response.status) {
        throw new Error(handleCommonApiError(response.data.message));
      }

      return Array.from(ApiPricingType)
        .map(type => response.data.find(p => p.type === type))
        .filter(isNonNullable);
    }
  });
}

const CenteredItemsBox = styled.div`
  display: grid;
  place-items: center;
`;

const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.medium};
`;

function PricingPlanList() {
  const result = useApiPricingQuery();

  if (result.isSuccess) {
    return (
      <CardsGrid>
        {result.data.map(p => (
          <PlanCard key={p.id} pricingPlan={p} />
        ))}
      </CardsGrid>
    );
  }

  if (result.isError) {
    return (
      <CenteredItemsBox>
        <FormattedMessage id="api_dashboard.pricing.data_error" />
        <Button onClick={() => result.refetch()}>
          <FormattedMessage id="common.refresh" />
        </Button>
      </CenteredItemsBox>
    );
  }

  return (
    <CenteredItemsBox>
      <CircularProgress />
    </CenteredItemsBox>
  );
}

function Footer() {
  const translate = useTr();
  return (
    <ColoredTypography variant="caption" color="blackMediumEmphasis">
      <FormattedMessage
        id="change_pricing_modal.footer.pricing_plans_hint"
        values={{
          a: (text: string) => (
            <StyledLink
              href={translate('change_pricing_modal.footer.pricing_plans_hint_video_link')}
              target="_blank"
              rel="noreferrer"
            >
              {text}
            </StyledLink>
          )
        }}
      />
    </ColoredTypography>
  );
}

export function ApiPlansCard() {
  return (
    <Card variant="outlined">
      <Header>
        <Typography variant="h5">
          <FormattedMessage id="api_dashboard.pricing.heading" />
        </Typography>
      </Header>
      <PricingPlanList />
      <Footer />
    </Card>
  );
}

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  text-decoration: underline;
`;
