import { backofficeClientStore } from 'services/backofficeIntegration/http/client/backofficeClientStore';
import { isNotProd } from 'utils/isNotProd';

export const logsEndpointUrl = '/logs';

const logDevelopment = (logData: { severity: Severity } & Record<string, unknown>) => {
  type ConsoleLogMethod = 'info' | 'warn' | 'error';
  const defaultMethod: ConsoleLogMethod = 'info';

  const { message, severity, ...rest } = logData;
  const lowerSeverity = severity?.toLowerCase() || defaultMethod;

  let logMethod: ConsoleLogMethod = defaultMethod;
  switch (lowerSeverity) {
    case 'warn':
    case 'error':
    case 'info':
      logMethod = lowerSeverity;
      break;
    default:
      break;
  }
  // eslint-disable-next-line no-console
  console[logMethod](`[LOG][${severity}] ${message}`, rest);
};

type Severity = 'INFO' | 'WARN' | 'ERROR';
type Context = Record<string, unknown>;

type Options = {
  message: string;
  severity: Severity;
  context?: Context;
  stackTrace?: string;
};
export const sendLogToApi = ({ message, severity, context, stackTrace }: Options) => {
  const logData = {
    message,
    severity: severity,
    stack_trace: stackTrace || JSON.stringify([]),
    context: {
      timestamp: new Date().toISOString(),
      ...context
    }
  };

  // Allow faster debugging by logging to console in non-production environments
  if (isNotProd()) {
    logDevelopment(logData);
  }

  // Don't push to log API during local development
  if (location.hostname === 'localhost') {
    return;
  }

  return backofficeClientStore
    .getState()
    .client?.post(logsEndpointUrl, btoa(JSON.stringify(logData)));
};
