import { useMutation } from '@tanstack/react-query';
import { createProgressToast, ProgressToastFunctions } from 'components/toasts/createProgressToast';
import Toast from 'components/toasts/Toast';
import { ToastErrorWithBugReportLink } from 'components/toasts/ToastErrorWithBugReportLink';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { SuccessfulUsersInvitationHint } from 'features/profilePage/SuccessfulUsersInvitationHint';
import { invalidateGetTeamMembersQuery } from 'features/profilePage/useGetTeamMembers';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { Dispatch, ReactElement, SetStateAction, useRef } from 'react';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import {
  CustomErrorResponseAlreadyInTeam,
  httpInviteUsers,
  InviteUsersParams,
  ResponseDto
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpInviteUsers';
import { isObject } from 'utils/isObject';

export const isCustomerAlreadyInTeamError = (
  response: ResponseDto
): response is CustomErrorResponseAlreadyInTeam =>
  response.message === 'BE.team.customer_already_in_team' &&
  isObject(response.data) &&
  'emails' in response.data &&
  Array.isArray(response.data.emails) &&
  response.data.emails.length > 0;

type Options = {
  setError: Dispatch<SetStateAction<ReactElement | null>>;
};

export const useInvitedUserMutation = ({ setError }: Options) => {
  const progressToast = useRef<ProgressToastFunctions | null>();

  return useMutation({
    mutationFn: (params: InviteUsersParams) => {
      progressToast.current = createProgressToast(
        <FormattedMessage id="profile.users.users_list.invitation_started" />,
        {
          type: 'info'
        }
      );

      return httpInviteUsers.callEndpoint(params);
    },
    onSuccess: data => {
      if (isCustomerAlreadyInTeamError(data)) {
        const renderedError = (
          <FormattedMessage
            id="profile.users.invite_user.error.customer_already_in_team"
            values={{ emails: data.data.emails.join(', ') }}
          />
        );

        progressToast.current?.error({
          render: renderedError,
          autoClose: 5000
        });
        setError(renderedError);
      } else if (Array.isArray(data.data) && data.data.length > 0) {
        progressToast.current?.success({
          render: <SuccessfulUsersInvitationHint />,
          autoClose: 8000
        });

        // Ensure that the team members list is updated
        invalidateGetTeamMembersQuery();
      } else {
        progressToast.current?.error({
          render: <ToastErrorWithBugReportLink />,
          icon: false,
          autoClose: 10000
        });
      }
    },
    onError: err => {
      const errorCode = getErrorCodeFromAxiosError(err);
      if (errorCode) {
        progressToast.current?.error({
          render: Toast.backendError(handleCommonApiError(errorCode)),
          icon: false,
          autoClose: 10000
        });
      } else {
        progressToast.current?.error({
          render: <ToastErrorWithBugReportLink />,
          icon: false,
          autoClose: 10000
        });
      }
    }
  });
};
