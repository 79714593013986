import { useChannel, useEvent } from '@harelpls/use-pusher';
import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { getUserId } from 'features/customer/store/selectors';
import { logError } from 'features/logging/logError';
import {
  getRunnerErrorsFromResponse,
  RunnerError
} from 'features/modular-workflow/runner/getRunnerErrorsFromResponse';
import { StepContainer } from 'features/modular-workflow/runner/StepContainer';
import {
  RunnerModularWorkflowStep,
  RunnerModularWorkflowStepStatus
} from 'features/modular-workflow/runner/types-runner';
import { useModulareWorkflowRunnerStore } from 'features/modular-workflow/runner/useModulareWorkflowRunnerStore';
import { WorkflowDoneState } from 'features/modular-workflow/runner/WorkflowDoneState';
import { useState } from 'react';
import { httpGetRunningModularWorkflow } from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpGetRunningModularWorkflow';
import { httpRunModularWorkflow } from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpRunModularWorkflow';
import {
  httpUpdateRunningModularWorkflowSteps,
  UpdateRunningModularWorkflowStepsParams
} from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpUpdateRunningModularWorkflowSteps';
import { useAppSelector } from 'store/hooks';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export type ChannelResponse = {
  status: RunnerModularWorkflowStepStatus;
  workflow_run_id: number;
  workflow_step_id: number;
};

const notificationEventName = 'workflow-step-changed-event';

export const ModularWorkflowRunView = () => {
  const customerId = useAppSelector(getUserId);
  const workflow = useModulareWorkflowRunnerStore(state => state.workflow);
  const runningWorkflow = useModulareWorkflowRunnerStore(state => state.runningWorkflow);
  const updateRunningWorkflow = useModulareWorkflowRunnerStore(
    state => state.updateRunningWorkflow
  );
  const updateRunningWorkflowStepStatus = useModulareWorkflowRunnerStore(
    state => state.updateRunningWorkflowStepStatus
  );
  const setRunningWorkflow = useModulareWorkflowRunnerStore(state => state.setRunningWorkflow);
  const selectedStepIndex = useModulareWorkflowRunnerStore(state => state.selectedStepIndex);
  const setSelectedStepIndex = useModulareWorkflowRunnerStore(state => state.setSelectedStepIndex);

  const [lastStepUpdateErrorList, setLastStepUpdateErrorList] = useState<RunnerError[]>([]);

  const { mutateAsync: fetchRunningModularWorkflow } = useMutation({
    mutationFn: (workflowId: number) => httpGetRunningModularWorkflow.callEndpoint(workflowId)
  });

  const hasFinishedWorkflow =
    runningWorkflow &&
    runningWorkflow.steps[selectedStepIndex]?.status === 'completed' &&
    selectedStepIndex >= runningWorkflow.steps.length - 1;

  const channelName = `workflow-notification-channel-${customerId}`;
  const channel = useChannel(channelName);
  useEvent<ChannelResponse>(channel, notificationEventName, async data => {
    if (!data) {
      return;
    }

    // Not sure if this is possible, but we should handle it
    if (data.workflow_run_id !== runningWorkflow?.id) {
      return;
    }

    // Find the step index to update
    const updatedStepIndex = runningWorkflow.steps.findIndex(
      step => step.id === data.workflow_step_id
    );
    if (updatedStepIndex === -1) {
      Toast.error('modular_workflow.runner.error_step_not_found');

      logError(new Error('Step index not found'), 'modular_workflow', {
        eventData: JSON.stringify(data)
      });
      return;
    }

    updateRunningWorkflowStepStatus(updatedStepIndex, data.status);

    // If status is error, update the whole workflow
    if (data.status === 'failed') {
      const result = await fetchRunningModularWorkflow(runningWorkflow.id);
      if (!result) {
        logError(new Error('Failed to fetch running workflow'), 'modular_workflow', {
          workflowName: runningWorkflow.name,
          workflowId: runningWorkflow.related_workflow_id,
          runningWorkflowId: runningWorkflow.id
        });
        return;
      }

      updateRunningWorkflow(result);
    }

    // Auto-forward to next step if the current step is completed
    if (
      selectedStepIndex === updatedStepIndex &&
      data.status === 'completed' &&
      selectedStepIndex < runningWorkflow.steps.length - 1
    ) {
      setSelectedStepIndex(selectedStepIndex + 1);
    }
  });

  assertNonNullable(workflow, 'workflow must be loaded first');

  const currentStep = workflow.steps[selectedStepIndex];

  const { mutateAsync: mutateRunModularWorkflow } = useMutation({
    mutationFn: (workflowId: number) => httpRunModularWorkflow.callEndpoint(workflowId)
  });

  const { mutateAsync: mutateRunningModularWorkflowStep } = useMutation({
    mutationFn: (params: UpdateRunningModularWorkflowStepsParams) =>
      httpUpdateRunningModularWorkflowSteps.callEndpoint(params)
  });

  const handleNextStep = async (updatedStep: RunnerModularWorkflowStep) => {
    // The current state might be null, but we need the id's of the running workflow
    // in order to update the steps.
    // Context: Running a workflow creates a new instance from the workflow including all steps.
    //          The workflow and each step has a new id, related to this run-process.
    //          The existing workflow acts as a template for the run.
    let currentRunningWorkflow = runningWorkflow;

    // Run the workflow once the first step has been confirmed
    if (selectedStepIndex === 0 || !currentRunningWorkflow?.id) {
      const result = await mutateRunModularWorkflow(workflow?.id);
      if (!result || !result.data || !result.data.status) {
        Toast.error('modular_workflow.runner.error_start_run');

        logError(new Error('Failed to start workflow'), 'modular_workflow', {
          result: JSON.stringify(result),
          workflowName: runningWorkflow?.name,
          workflowId: runningWorkflow?.related_workflow_id,
          runningWorkflowId: runningWorkflow?.id
        });
        return;
      }

      currentRunningWorkflow = result.data.data;
      setRunningWorkflow(currentRunningWorkflow);
    }

    // Only update if input is allowed
    const currentRunningWorkflowStep = currentRunningWorkflow.steps[selectedStepIndex];
    if (currentRunningWorkflowStep.status !== 'requires_customer_input') {
      return;
    }

    const updateStepResult = await mutateRunningModularWorkflowStep({
      workflowRunId: currentRunningWorkflow.id,
      steps: [
        {
          ...updatedStep,
          // Use id from running workflow to update the content
          id: currentRunningWorkflowStep.id
        }
      ]
    });

    if (!updateStepResult || !updateStepResult.status) {
      Toast.error('modular_workflow.runner.error_update_steps');

      if (updateStepResult && updateStepResult.data) {
        setLastStepUpdateErrorList([
          ...lastStepUpdateErrorList,
          ...getRunnerErrorsFromResponse(updateStepResult)
        ]);

        return;
      }

      // If the result is empty, we have a bigger problem
      logError(new Error('Failed to update step'), 'modular_workflow', {
        result: JSON.stringify(updateStepResult),
        workflowName: runningWorkflow?.name,
        workflowId: runningWorkflow?.related_workflow_id,
        runningWorkflowId: runningWorkflow?.id
      });
      return;
    }

    setLastStepUpdateErrorList([]);
    updateRunningWorkflow(updateStepResult.data);
  };

  if (hasFinishedWorkflow && runningWorkflow) {
    return (
      <WorkflowDoneState
        runningWorkflowId={runningWorkflow.id}
        runningWorkflowName={runningWorkflow.name}
      />
    );
  }

  return (
    <StepContainer
      step={currentStep}
      stepIndex={selectedStepIndex}
      stepErrorList={lastStepUpdateErrorList.filter(
        error => error.stepId === runningWorkflow?.steps[selectedStepIndex]?.id
      )}
      onNextStep={handleNextStep}
    />
  );
};
