import { PushPin } from '@mui/icons-material';
import { Autocomplete, Popper, SxProps, TextField, Theme } from '@mui/material';
import { ReactComponent as SetPinIcon } from 'assets/set-pin-icon.svg';
import CountryFlag from 'components/base/CountryFlag';
import FlexContainer from 'components/FlexContainer';
import LocalStorageKey from 'config/localStorageKey';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { sortModelsByLanguageAlphabetically } from 'features/embeddingModels/store/utils/sortModelsByLanguage';
import { useField } from 'formik';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const mapModelToOption = (model: EmbeddingModel): ModelOption => ({
  value: model.id,
  label: model.label,
  country: model.country,
  language: model.language
});

export type ModelOption = {
  value: string;
  label: string;
  country: string;
  language: string;
};

type Props = {
  name: string;
  models: EmbeddingModel[];
  placeholder?: string;
  disabled?: boolean;
  onSelect?: (value: string) => void;
  size?: 'small' | 'medium';
  isSaveAsDefaultShown?: boolean;
  customTextFieldStyles?: SxProps<Theme>;
  customAutocompleteStyles?: SxProps<Theme>;
};

export const HomeChatModelSelector = ({
  name,
  models,
  placeholder,
  disabled,
  onSelect,
  size,
  isSaveAsDefaultShown = false,
  customTextFieldStyles,
  customAutocompleteStyles
}: Props) => {
  const tr = useTr();
  const [field, meta, helper] = useField<string>({
    name
  });

  const { mutateAsync: updatePreferences } = useUpdateCustomerPreferences();
  const { data: customerPreferences } = useCustomerPreferences();
  const defaultLanguageId = models.find(
    model =>
      model.language === customerPreferences?.preferredLanguage &&
      model.country === customerPreferences.preferredLanguageCountry
  )?.id;

  const options = useMemo(
    () => sortModelsByLanguageAlphabetically(models).map(mapModelToOption),
    [models]
  );
  const value = options.find(v => v.value === field.value);

  const handleSetPreferredLanguage = (value: ModelOption | undefined) => {
    updatePreferences({
      preferredLanguage: value?.language,
      preferredLanguageCountry: value?.country
    });
    localStorage.setItem(LocalStorageKey.AiWriterModel, String(value?.value));
  };

  const handleChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: ModelOption | null) => {
      if (value) {
        helper.setValue(value.value);
        helper.setTouched(true);
        onSelect?.(value.value);
      }
    },
    [helper, onSelect]
  );

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: ModelOption) => {
    const params = { ...props, selected: value === option };
    return (
      <Option {...params}>
        <FlexContainer alignItems="center" direction="row" gap="small">
          <CountryFlag country={option.country} language={option.language} />
          {option.label}
        </FlexContainer>
        {isSaveAsDefaultShown &&
          (defaultLanguageId && defaultLanguageId === option.value ? (
            <StyledPin fontSize="small" />
          ) : (
            (value !== option || option.value !== defaultLanguageId) && (
              <StyledSetPin
                fontSize="small"
                className="pinIcon"
                onClick={() => handleSetPreferredLanguage(option)}
              />
            )
          ))}
      </Option>
    );
  };

  const [width, setWidth] = useState('auto');
  const autocompleteRef = useRef(null);

  useLayoutEffect(() => {
    /**
     * This effect is used to calculate the width of the autocomplete based on the selected value
     */
    if (autocompleteRef.current && value) {
      const selectedOption = options.find(option => option.value === value.value);
      if (selectedOption) {
        const tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.whiteSpace = 'nowrap';
        tempDiv.innerText = selectedOption.label;
        document.body.appendChild(tempDiv);
        setWidth(`${tempDiv.offsetWidth + 40}px`);
        document.body.removeChild(tempDiv);
      }
    }
  }, [value, options]);

  const popperStyles = {
    popper: {
      minWidth: '250px'
    }
  };

  return (
    <StyledAutocomplete
      ref={autocompleteRef}
      id="model"
      disableClearable
      disabled={disabled}
      options={options}
      autoHighlight
      getOptionLabel={option => option.label}
      renderOption={renderOption}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      value={value}
      onChange={handleChange}
      PopperComponent={props => <Popper {...props} style={popperStyles.popper} />}
      ListboxProps={{
        style: {
          /**
           * Key object here was to increase the dropdown "height" so that 1440x838res
           * has all options without scroll. I choose that value experimentally
           */
          maxHeight: '60vh'
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          name={name}
          placeholder={placeholder ?? tr('common.language')}
          variant="outlined"
          fullWidth
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
          InputProps={{
            ...params.InputProps,
            startAdornment: <CountryFlag country={value?.country} language={value?.language} />
          }}
          sx={customTextFieldStyles}
        />
      )}
      size={size}
      sx={{ ...customAutocompleteStyles, width }}
    />
  );
};

export const Option = styled.span<{ selected: boolean }>`
  display: flex;
  flex-direction: row;

  .pinIcon {
    display: none;
  }

  &:hover {
    .pinIcon {
      display: block;
    }
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-input {
    cursor: pointer;
  }

  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 0;
  }
` as typeof Autocomplete;

const StyledPin = styled(PushPin)`
  &,
  > svg {
    color: ${({ theme }) => theme.colors.blackInactive};
  }
  position: absolute;
  right: 1rem;
`;

const StyledSetPin = styled(SetPinIcon)`
  position: absolute;
  right: 1rem;
`;
