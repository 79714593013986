import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { LoadingAnimationWithAlternatingMessages } from 'features/loading-spinner/LoadingAnimationWithAlternatingMessages';
import * as CommonStyled from 'features/workflowBlogPostBuilder/steps/common.styles';
import { useState } from 'react';
import styled from 'styled-components';
import { useTimeout } from 'utils/hooks/useTimeout';

// Show a hint after 45 seconds
const longWaitingTime = 45_000;

export const GenerateContentStep = () => {
  const [showLongWaitingTimeHint, setShowLongWaitingTimeHint] = useState(false);

  useTimeout(() => setShowLongWaitingTimeHint(true), longWaitingTime);

  return (
    <>
      <CommonStyled.Title>
        <FormattedMessage id="blog_post_builder.steps.generate_content.title" />
      </CommonStyled.Title>
      <LimitedSpaceContainer>
        <LoadingAnimationWithAlternatingMessages
          alternatingMessages={[
            'blog_post_builder.steps.outline.loader.average_time',
            'blog_post_builder.steps.outline.loader_1',
            'blog_post_builder.steps.outline.loader.creativity_progress',
            'blog_post_builder.steps.outline.loader_2'
          ]}
        />

        {showLongWaitingTimeHint && (
          <LongWaitingTimeHintRoot>
            <Typography>
              <FormattedMessage id="blog_post_builder.steps.generate_content.long_waiting_time_hint" />
            </Typography>
          </LongWaitingTimeHintRoot>
        )}
      </LimitedSpaceContainer>
    </>
  );
};

const LimitedSpaceContainer = styled(FlexContainer).attrs({
  alignItems: 'center',
  justifyContent: 'center'
})`
  // This improves the positioning of the loader because it’s centered in the whole area,
  // but the headline is left-aligned.
  // This doesn't makes it as beautiful as f*ck but aligns it a lil bit more
  width: 350px;
  // Sorry.. we wanna have a vertical centering, but have a margin-top: auto for the bottom content,
  // therefore we need to set a min-height to make sure the loader eats more space than required
  min-height: 440px;
  margin: 0 auto;
`;

const LongWaitingTimeHintRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 20rem;
  padding: ${({ theme }) => `${theme.spacings.xsmall} ${theme.spacings.small}`};
  margin-top: ${({ theme }) => theme.spacings.small};

  background-color: ${({ theme }) => theme.colors.primary50};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;
