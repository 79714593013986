import { Box, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Value } from '@udecode/plate-common';
import { ELEMENT_H1 } from '@udecode/plate-heading';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import FlexContainer from 'components/FlexContainer';
import CheckboxField from 'components/forms/CheckboxField';
import StepperModal, { ModalStep } from 'components/modals/StepperModal';
import { CloseModal } from 'components/modals/types';
import LocalStorageKey from 'config/localStorageKey';
import { getRoutePath } from 'config/routes';
import useHandleModelChange from 'features/aiWriter/modals/setup/useHandleModelChange';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { changeOutputTypeThunk } from 'features/aiWriter/store/actions/config/thunks/changeOutputTypeThunk';
import { initializeTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/initializeTabThunk';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getPreferredEmbeddingModel } from 'features/aiWriter/utils/getPreferredEmbeddingModel';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import { AudienceSelect } from 'features/audiences/AudienceSelect';
import { useInitialModelAndAudience } from 'features/audiences/hooks/useInitialModelAndAudience';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { usePreferredLocaleQuery } from 'features/customerPreferences/usePreferredLocaleQuery';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import {
  getEmbeddingModelDataSelector,
  getEmbeddingModels,
  getEmbeddingModelsByLanguageAndAudience
} from 'features/embeddingModels/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { LazyLoadedLoadingAnimation } from 'features/loading-spinner/LazyLoadedLoadingAnimation';
import {
  useShowPersonalityCreationModal,
  ViewMode
} from 'features/personality/creation/PersonalityCreationModal';
import { useEmptyDocumentStore } from 'features/plate/components/plate-ui/useEmptyDocumentStore';
import { ELEMENT_EMPTY_DOCUMENT } from 'features/plate/customPlugins/createEmptyDocumentPlugin';
import { useShowOutputTypeModal } from 'features/textGenerator/outputTypeModal/useShowOutputTypeModal';
import { fetchDefaultOutputTypeByLanguageCountry } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getValidationForField } from 'utils/getValidationForField';
import useRunInTask from 'utils/hooks/useRunInTask';
import useTr from 'utils/hooks/useTr';
import { useStableCallback } from 'utils/react/useStableCallback';
import { isNonNullable } from 'utils/typescript/nonNullable';
import { boolean, object, string } from 'yup';

export const embeddingModelField = 'embeddingModelId';
export const audienceField = 'audienceId';
export const languageToolField = 'languageTool';

export type AiWriterSetupValues = {
  [embeddingModelField]: string;
  [audienceField]: number;
  [languageToolField]: boolean;
};

const modalStep: ModalStep[] = [
  {
    title: 'aiWriter.setup.models.title',
    okButton: {
      text: 'common.create'
    },
    cancelButton: {
      hide: true
    }
  }
];

export type PreferredParameters = {
  embeddingModelId: string;
  audienceId: number;
  language: string;
  country: string;
};

export function usePreferredParameters() {
  const models = useAppSelector(getEmbeddingModels);
  const audiences = useAppSelector(getUserAudiences);

  return usePreferredLocaleQuery({
    select: locale => {
      if (!locale) return undefined;

      const { language, country } = locale;

      const embeddingModelId = getPreferredEmbeddingModel({ models, locale })?.id;
      const audienceId = getPreferredAudience({ audiences, locale })?.id;

      if (isNonNullable(audienceId) && isNonNullable(embeddingModelId)) {
        return {
          embeddingModelId,
          audienceId,
          language,
          country
        };
      }

      return undefined;
    }
  });
}

const customEmptyDocumentText: Value = [
  {
    type: ELEMENT_EMPTY_DOCUMENT,
    children: [{ text: '' }]
  }
];

const plainEmptyDocumentText: Value = [
  {
    type: ELEMENT_H1,
    children: [{ text: '' }]
  },
  {
    type: ELEMENT_PARAGRAPH,
    children: [{ text: '' }]
  }
];

type InitTabCallbackOptions = {
  embeddingModelId: string;
  audienceId: number;
  language: string;
  country: string;
  createCustomEmptyDocument?: boolean;
};

export function useInitTab() {
  const dispatch = useAppDispatch();
  const setEnteredHeadline = useEmptyDocumentStore(state => state.setEnteredHeadline);

  const getModelById = useSelector(getEmbeddingModelDataSelector);

  return useStableCallback(async function ({
    embeddingModelId,
    audienceId,
    language,
    country,
    createCustomEmptyDocument
  }: InitTabCallbackOptions) {
    // Clear the headline in the editor store
    setEnteredHeadline('');

    const model = getModelById(embeddingModelId);
    const outputType = await fetchDefaultOutputTypeByLanguageCountry(language, country);
    const defaultPersonalityResult = await httpGetDefaultPersonality.callEndpoint({
      language: model.language,
      country: model.country
    });

    await dispatch(
      initializeTabThunk(
        {
          embeddingModelId,
          audienceId,
          personalityId: defaultPersonalityResult?.id,
          name: unnamed,
          isNewDocument: true,
          outputType,
          brief: '',
          keywords: '',
          keywords2: '',
          tonality: [],
          text: createCustomEmptyDocument ? customEmptyDocumentText : plainEmptyDocumentText
        },
        // This hides the editor sidebar
        null
      )
    );
  });
}

type ProjectWithPreferredLanguageProps = {
  closeModal: Props['closeModal'];
  handleFormSubmit: (language?: string, country?: string) => void;
  createCustomEmptyDocument?: boolean;
};

function useProjectWithPreferredLanguage({
  closeModal,
  handleFormSubmit,
  createCustomEmptyDocument
}: ProjectWithPreferredLanguageProps) {
  const preferredParametersResult = usePreferredParameters();
  const preferredParameters = preferredParametersResult.data;
  const initTab = useInitTab();

  const openProject = useMutation({
    mutationFn: (props: PreferredParameters) => {
      const { audienceId, embeddingModelId, language, country } = props;
      return initTab({
        audienceId,
        embeddingModelId,
        language,
        country,
        createCustomEmptyDocument
      });
    },
    onSuccess: (_, props) => {
      const { language, country } = props;
      closeModal();
      handleFormSubmit(language, country);
    },
    onError: () => {
      closeModal();
    }
  });

  /**
   * This solution may lead to edge cases where we use stale `preferredParameters`.
   * However I think that it's a tradeoff worth taking, since preferred language
   * is not something that changes often.
   */
  const shouldOpen = openProject.isIdle && !!preferredParameters;
  const shouldFallbackToForm = preferredParametersResult.isFetched && !preferredParameters;

  useEffect(() => {
    if (shouldOpen) {
      openProject.mutate(preferredParameters);
    }
  }, [shouldOpen, preferredParameters, openProject]);

  return { shouldFallbackToForm };
}

type Props = CloseModal & {
  createCustomEmptyDocument?: boolean;
  showPromptLibrary?: boolean;
};

export const SetupModal = ({
  closeModal,
  createCustomEmptyDocument = true,
  showPromptLibrary
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const showPersonalityCreationModal = useShowPersonalityCreationModal();
  const showOutputTypeModal = useShowOutputTypeModal();
  const { data: customerPreferences } = useCustomerPreferences();

  const currentWriterModelLanguageAndCountry = useAppSelector(getCurrentModelLanguageAndCountry);

  const hashParams = new URLSearchParams(location.hash.length > 0 ? location.hash.slice(1) : '');
  const disableCustomEmptyDocument = hashParams.has('noEmptyDocumentMenu');

  const handleFormSubmit = async () => {
    closeModal();

    if (location.pathname !== getRoutePath('aiWriter')) {
      await navigate(getRoutePath('aiWriter'));
    }

    if (hashParams.has('createBrandVoice')) {
      showPersonalityCreationModal({
        initialViewMode: ViewMode.choice
      });
      return;
    }

    if (hashParams.has('chatFlash')) {
      dispatch(setInspirationsCurrentStep({ step: AiWriterSidebarStep.chat, subStep: 'main' }));
      return;
    }

    if (hashParams.has('imageFlash')) {
      dispatch(
        setInspirationsCurrentStep({ step: AiWriterSidebarStep.images, subStep: 'aiImages' })
      );
      return;
    }

    if (showPromptLibrary || hashParams.has('promptLibrary')) {
      dispatch(
        setInspirationsCurrentStep({ step: AiWriterSidebarStep.aiWriter, subStep: 'editBrief' })
      );
      showOutputTypeModal({
        modelCountry:
          customerPreferences?.preferredLanguageCountry ??
          currentWriterModelLanguageAndCountry.currentModelCountry,
        modelLanguage:
          customerPreferences?.preferredLanguage ??
          currentWriterModelLanguageAndCountry.currentModelLanguage,
        onOutputTypeChange: value => dispatch(changeOutputTypeThunk(value))
      });
      return;
    }
  };

  const shouldCreateCustomEmptyDocument = createCustomEmptyDocument && !disableCustomEmptyDocument;
  const { shouldFallbackToForm } = useProjectWithPreferredLanguage({
    closeModal,
    handleFormSubmit,
    createCustomEmptyDocument: shouldCreateCustomEmptyDocument
  });

  return shouldFallbackToForm ? (
    <SetupModalContent
      onFormSubmitted={handleFormSubmit}
      onCancel={closeModal}
      createCustomEmptyDocument={shouldCreateCustomEmptyDocument}
    />
  ) : (
    <Box
      sx={{
        display: 'grid',
        placeContent: 'center',
        height: '100%'
      }}
      padding={3}
    >
      <LazyLoadedLoadingAnimation />
    </Box>
  );
};

type SetupModalContentProps = {
  createCustomEmptyDocument: boolean;
  onFormSubmitted: (language?: string, country?: string) => void;
  onCancel: Props['closeModal'];
};

export function SetupModalContent({
  onFormSubmitted,
  onCancel,
  createCustomEmptyDocument
}: SetupModalContentProps) {
  const tr = useTr();

  const initTab = useInitTab();

  const getModelById = useSelector(getEmbeddingModelDataSelector);
  const preferencesQuery = useCustomerPreferences();
  const { data: preferences } = preferencesQuery;
  const { mutateAsync: updatePreferences } = useUpdateCustomerPreferences();

  const [isLoading, runInTask] = useRunInTask();

  const { embeddingModelId: initialModelId, audienceId: initialAudienceId } =
    useInitialModelAndAudience();

  const validationSchema = useMemo(
    () =>
      object().shape(
        {
          [embeddingModelField]: string().required(tr(getValidationForField('model'))),
          [audienceField]: string().required(tr(getValidationForField('audience'))),
          [languageToolField]: boolean()
        },
        []
      ),
    [tr]
  );

  const onSubmit = useCallback(
    async (values: AiWriterSetupValues) => {
      const {
        [audienceField]: audienceId,
        [embeddingModelField]: embeddingModelId,
        [languageToolField]: languageToolEnabled
      } = values;
      const model = getModelById(embeddingModelId);
      const { language, country } = model;
      await runInTask(async () => {
        await initTab({
          audienceId,
          embeddingModelId,
          language,
          country,
          createCustomEmptyDocument
        });

        await updatePreferences({
          languageToolEnabled
        });
      });
      onFormSubmitted?.(language, country);
    },
    [
      getModelById,
      runInTask,
      onFormSubmitted,
      initTab,
      createCustomEmptyDocument,
      updatePreferences
    ]
  );

  const initialValues: AiWriterSetupValues = {
    embeddingModelId: initialModelId,
    audienceId: initialAudienceId,
    languageTool: preferences?.languageToolEnabled === true
  };

  return (
    <StepperModal
      validateOnChange={false}
      isLoading={isLoading || preferencesQuery.isLoading}
      steps={modalStep}
      stepComponent={() => <MainStep />}
      initialValues={initialValues}
      onCancel={onCancel}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      tracking={{ saveButtonId: gtmIds.aiWriter.initializeProject }}
    />
  );
}

const MainStep = (): React.ReactElement => {
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const {
    values: { embeddingModelId, languageTool }
  } = useFormikContext<AiWriterSetupValues>();

  const onModelChange = useHandleModelChange();

  const onProjectSelect = useCallback((value: unknown) => {
    localStorage.setItem(LocalStorageKey.AiWriterProject, String(value));
  }, []);

  return (
    <FlexContainer gap="medium">
      <ModelAutocomplete
        name={embeddingModelField}
        models={models}
        onSelect={onModelChange}
        isSaveAsDefaultShown={true}
      />
      <AudienceSelect
        name={audienceField}
        embeddingModelId={embeddingModelId}
        onSelect={onProjectSelect}
      />
      <CheckboxField
        rawLabel={
          <FormattedMessage
            id="aiWriter.setup.languageTool_checkbox_label"
            values={{
              br: <br />,
              small: children => <Typography variant="caption">{children}</Typography>
            }}
          />
        }
        name={languageToolField}
        value="languageTool"
        checked={languageTool}
      />
    </FlexContainer>
  );
};
