import { Button, Link, Paper, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

type RegisterSuccessCardProps = {
  email?: string;
  resendButton: ReactElement;
};

export function RegisterSuccessCard(props: RegisterSuccessCardProps) {
  const { resendButton } = props;
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate('/login');
  }

  return (
    <Root>
      <FlexContainer gap="four" alignItems="center">
        <FlexContainer gap="small" alignItems="center">
          <Typography variant="h6">
            <FormattedMessage id="register.success.verify_email.header" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage
              id="register.success.verify_email.description"
              values={{ br: <br /> }}
            />
          </Typography>
        </FlexContainer>
        <Footer>
          <Button variant="contained" color="primary" onClick={handleGoToLogin}>
            <FormattedMessage id="register_success.go_to_login_cta" />
          </Button>
          <Typography component="div" variant="caption">
            <FormattedMessage id="register.success.verificationEmail.explanation" /> {resendButton}
          </Typography>
        </Footer>
      </FlexContainer>
    </Root>
  );
}

RegisterSuccessCard.ResendButton = ResendButton;

function ResendButton(props: { onClick: () => void }) {
  return (
    <Link component="button" onClick={props.onClick}>
      <FormattedMessage id="register.success.verificationEmail.resend" />
    </Link>
  );
}

const Root = styled(Paper).attrs({
  elevation: 2
})`
  position: relative;

  height: fit-content;
  width: 496px;
  padding: ${({ theme }) => theme.spacings.five};
  border-radius: ${({ theme }) => theme.borderRadius.one};

  @media (max-width: ${mobileWindowWidthCSS}) {
    width: 100%;
    padding: ${({ theme }) => theme.spacings.medium};
  } ;
`;

const Footer = styled(FlexContainer).attrs({
  gap: 'three',
  justifyContent: 'center',
  alignItems: 'center'
})`
  width: 100%;
`;
