import { EditRounded, RemoveRedEye } from '@mui/icons-material';
import { Button, ButtonGroup } from '@mui/material';
import { isAxiosError } from 'axios';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { AppLanguage } from 'features/language/store/types';
import { logError } from 'features/logging/logError';
import {
  BuilderError,
  getBuilderErrorsFromResponse
} from 'features/modular-workflow/builder/getBuilderErrorsFromResponse';
import { ModularWorkflowUpdateError } from 'features/modular-workflow/builder/ModularWorkflowUpdateError';
import { ModularWorkflowStepList } from 'features/modular-workflow/builder/step-list/ModularWorkflowStepList';
import { GenericDeletableStep } from 'features/modular-workflow/builder/steps/GenericDeletableStep';
import { GenericStep } from 'features/modular-workflow/builder/steps/GenericStep';
import {
  isMutableStepReference,
  isNotDeletableStepReference
} from 'features/modular-workflow/builder/types-api';
import { BuilderModularWorkflowStep } from 'features/modular-workflow/builder/types-builder';
import { useCreateWorkflowMutation } from 'features/modular-workflow/builder/useCreateWorkflowMutation';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { useUpdateWorkflowMutation } from 'features/modular-workflow/builder/useUpdateWorkflowMutation';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

export const ModularWorkflowCreationViewEdit = () => {
  const navigate = useNavigate();
  const workflow = useModulareWorkflowCreationStore(state => state.workflow);
  const selectedStepIndex = useModulareWorkflowCreationStore(state => state.selectedStepIndex);
  const setSelectedStepIndex = useModulareWorkflowCreationStore(
    state => state.setSelectedStepIndex
  );
  const removeWorkflowStep = useModulareWorkflowCreationStore(state => state.removeWorkflowStep);
  const updateWorkflowStep = useModulareWorkflowCreationStore(state => state.updateWorkflowStep);

  const [hasDirtyInputs, setHasDirtyInputs] = useState<boolean>(false);
  const [lastStepUpdateErrorList, setLastStepUpdateErrorList] = useState<BuilderError[]>([]);

  const handleSaveError = (error: unknown) => {
    logError(new Error('Builder: failed to save workflow'), 'modular_workflow', {
      error: JSON.stringify(error)
    });

    if (
      isAxiosError(error) &&
      typeof error.response?.data === 'object' &&
      'data' in error.response.data
    ) {
      const newErrorList = getBuilderErrorsFromResponse(error.response.data);
      setLastStepUpdateErrorList(newErrorList);

      if (newErrorList.length > 0) {
        Toast.customError(
          <ModularWorkflowUpdateError errorList={newErrorList} workflow={workflow} />,
          {
            autoClose: false
          }
        );

        return;
      }

      // No error extracted, fall down to common error
    }

    // Clear errors if we can't extract it from response to prevent confusion
    setLastStepUpdateErrorList([]);

    Toast.commonError();
  };

  const { mutate: createWorkflowMutation } = useCreateWorkflowMutation({
    onMutate: () => setLastStepUpdateErrorList([]),
    onError: handleSaveError,
    onSuccess: data => {
      Toast.success('modular_workflow.builder.update_successful');

      if (data.id) {
        navigate(getRoutePath('modularWorkflowEdit', { workflowId: data.id }));
      }
    }
  });

  const { mutate: updateWorkflowMutation } = useUpdateWorkflowMutation({
    onMutate: () => setLastStepUpdateErrorList([]),
    onError: handleSaveError
  });

  // TODO: Check using useEffect() to save the workflow on every change

  const currentStep = workflow.steps[selectedStepIndex];

  const handleStepClick = (stepIndex: number) => {
    if (stepIndex < 0 || stepIndex > workflow.steps.length - 1 || stepIndex === selectedStepIndex) {
      return;
    }

    if (hasDirtyInputs) {
      // eslint-disable-next-line no-alert
      if (
        !window.confirm(
          "You have unsaved changes which will be lost if you don't save. Are you sure you want to continue?"
        )
      ) {
        return;
      }
    }

    setSelectedStepIndex(stepIndex);
  };

  const handleCurrentStepSave = (changedStep: BuilderModularWorkflowStep) => {
    // Before saving the step, make sure all localized fields are filled
    // by coping the English value into other languages if they are empty
    const updatedStep: BuilderModularWorkflowStep = {
      ...changedStep,
      inputs: changedStep.inputs.map(input => {
        if (!input.value || !input.is_localized) {
          return input;
        }

        return {
          ...input,
          value: {
            ...input.value,
            [AppLanguage.German]:
              input.value[AppLanguage.German] || input.value[AppLanguage.English],
            [AppLanguage.Spanish]:
              input.value[AppLanguage.Spanish] || input.value[AppLanguage.English]
          }
        };
      })
    };

    // Update step in state
    const updatedWorkflow = updateWorkflowStep(updatedStep, selectedStepIndex);

    // If we ever created the workflow, we'll have an id for each step and the workflow itself
    if (updatedWorkflow.id) {
      updateWorkflowMutation(updatedWorkflow);
      return;
    }

    createWorkflowMutation(updatedWorkflow);
  };

  const handleCurrentStepDelete = () => {
    removeWorkflowStep(currentStep.order);
  };

  const handleInputsDirty = (dirtyInputs: boolean) => {
    setHasDirtyInputs(dirtyInputs);
  };

  return (
    <Content>
      <ViewSwitchBox>
        <ButtonGroup variant="contained">
          <Button startIcon={<EditRounded />}>
            <FormattedMessage id="modular_workflow.builder.mode_toggle.edit" />
          </Button>
          <Button variant="outlined" endIcon={<RemoveRedEye />} disabled={true}>
            <FormattedMessage id="modular_workflow.builder.mode_toggle.test" />
          </Button>
        </ButtonGroup>
      </ViewSwitchBox>

      <ModularWorkflowStepList
        activeStepIndex={selectedStepIndex}
        onStepClick={handleStepClick}
        stepErrorList={lastStepUpdateErrorList}
      />

      {(() => {
        if (isNotDeletableStepReference(currentStep.reference)) {
          return (
            <GenericStep
              step={currentStep}
              onSave={handleCurrentStepSave}
              onDirtyChange={handleInputsDirty}
              stepErrorList={lastStepUpdateErrorList.filter(
                error => error.stepIndex === selectedStepIndex
              )}
            />
          );
        }

        if (isMutableStepReference(currentStep.reference)) {
          return (
            <GenericDeletableStep
              step={currentStep}
              onSave={handleCurrentStepSave}
              onDelete={handleCurrentStepDelete}
              onDirtyChange={handleInputsDirty}
              stepErrorList={lastStepUpdateErrorList.filter(
                error => error.stepIndex === selectedStepIndex
              )}
            />
          );
        }

        return (
          <div>
            <h1>Not implemented yet: {currentStep.reference}</h1>
          </div>
        );
      })()}
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.medium};
  width: 100%;
  margin: 0 auto;
`;

const ViewSwitchBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.medium};
`;
