import { Divider, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ModalHeader } from 'features/theme-2024/ModalHeader';
import { ComponentProps, ComponentType, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
  title: ReactNode;
  description?: ReactNode;
  height?: number;
  footer?: ReactNode;
  slots?: {
    bodyContainer?: ComponentType<{ children: ReactNode }>;
    footerContainer?: ComponentType<{ children: ReactNode }>;
  };
  additionalHeaderActions?: ReactNode;
} & Omit<ComponentProps<typeof ModalHeader>, 'children'>;

export const ModalWithDividedHeaderLayout = ({
  title,
  description,
  height,
  helpLink,
  helpLinkTooltip,
  closeModal,
  children,
  slots,
  additionalHeaderActions,
  footer
}: Props) => {
  const BodyContainer = slots?.bodyContainer || ModalBody;
  const FooterContainer = slots?.footerContainer || SpacedBetweenFooter;

  return (
    // Note: The data attribute is used to identify if a modal uses the new layout
    //       In src/components/modals/RootModal.scss we're applying a temporary CSS to the old layout
    <Root $height={height} data-new-modal-leyout={true}>
      <ModalHeader
        helpLink={helpLink}
        helpLinkTooltip={helpLinkTooltip}
        closeModal={closeModal}
        additionalActions={additionalHeaderActions}
      >
        <HeaderContent>
          {title}
          <Typography variant="subtitle1">{description}</Typography>
        </HeaderContent>
      </ModalHeader>

      <Divider />

      <BodyContainer>{children}</BodyContainer>

      <FooterContainer>{footer}</FooterContainer>
    </Root>
  );
};

const Footer = styled.div`
  display: flex;
`;

export const SpacedBetweenFooter = styled(Footer)`
  justify-content: space-between;
`;

export const RightAlignedFooter = styled(Footer)`
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.two};
`;

const Root = styled(FlexContainer)<{ $height?: number }>`
  height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
`;

const ModalBody = styled(FlexContainer).attrs({ gap: 'four', direction: 'row' })`
  padding-top: ${({ theme }) => theme.spacings.four};

  overflow-y: auto;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding-bottom: ${({ theme }) => theme.spacings.two};
`;

export const ModalBodyWithColumnDirection = styled(ModalBody)`
  flex-direction: column;
`;

export const ModalBodyWithColumnDirectionAndSpaceBetween = styled(ModalBodyWithColumnDirection)`
  justify-content: space-between;
`;

export const TwoColumnModalBody = styled(ModalBody)`
  display: grid;
  grid-template-columns: 240px 1fr;
  gap: ${({ theme }) => theme.spacings.four};
  overflow: hidden;
`;

export const MaxHeightOverflowContainer = styled.div`
  overflow-y: auto;
  max-height: 100%;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
`;
