import { Typography } from '@mui/material';
import { Container } from 'components/Container';
import FlexContainer from 'components/FlexContainer';
import { NewProjectsSection } from 'features/aiWriter/AiWriterProjectOverview/NewProjectsSection';
import { TagsBanner } from 'features/aiWriter/AiWriterProjectOverview/TagsBanner';
import { HomeRecentFiles } from 'features/homePage/HomeRecentFiles';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import styled from 'styled-components';

export function MyContentPage() {
  useEnableSidebarOnMount();
  useNavigateToOnboarding();

  return (
    <Root>
      <TagsBanner />
      <RecentFilesSection>
        <Typography variant="subtitle1">
          <FormattedMessage id="home.sections.recentFiles.title" />
        </Typography>
        <HomeRecentFiles />
      </RecentFilesSection>
      <NewProjectsSection enableProjectCreation />
    </Root>
  );
}

const RecentFilesSection = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacings.two};
`;

const Root = styled(Container).attrs({ size: 'lg' })`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.four};
`;
