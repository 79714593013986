import { Button, TextField } from '@mui/material';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ColoredTypography } from 'components/ColoredTypography';
import { CloseModal } from 'components/modals/types';
import Toast from 'components/toasts/Toast';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { FileField, FileTile } from 'features/information/FileField';
import { informationModalHeight } from 'features/information/InformationModal';
import { PdfFileWarning } from 'features/information/PdfWarning';
import { TagField } from 'features/information/TagField';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import {
  ModalBodyWithColumnDirectionAndSpaceBetween,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { Form, Formik, FormikHelpers, useField } from 'formik';
import { useRef } from 'react';
import {
  CreateInformationParams,
  httpCreateInformation
} from 'services/backofficeIntegration/http/endpoints/infomration/httpCreateInformation';
import {
  httpGetInformationCategories,
  InformationCategoryDto
} from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';
import { invalidateInformationLimitationsQuery } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationLimitations';
import {
  InformationDto,
  invalidateInformationListQuery
} from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import {
  httpUpdateInformation,
  UpdateInformationParams
} from 'services/backofficeIntegration/http/endpoints/infomration/httpUpdateInformation';
import {
  getNextPageParam,
  PaginatedListParams
} from 'services/backofficeIntegration/http/paginatedListEndpoint';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { boolean, mixed, object, string } from 'yup';

type Information = {
  name: string;
  description: string;
  tag: InformationCategoryDto | null;
  files: File[] | null;
};

type FormType = Information & { isEditing: boolean };

const NAME_MAX_CHAR = 50;
const DESCRIPTION_MAX_CHAR = 500;

const validationSchema = object().shape({
  name: string().required().max(NAME_MAX_CHAR),
  description: string().max(DESCRIPTION_MAX_CHAR),
  tag: object().required(),
  isEditing: boolean(),
  files: mixed().when('isEditing', {
    is: false,
    then: mixed().required(),
    otherwise: mixed()
  })
});

type Props = {
  preselectedInformation?: InformationDto;
  preselectedTag?: InformationCategoryDto;
  onUploadStarted?: () => void;
  onBackClick: () => void;
} & CloseModal;

export function UploadView({
  preselectedInformation,
  preselectedTag,
  onUploadStarted,
  onBackClick,
  closeModal
}: Props) {
  const isEditing = !!preselectedInformation;

  const initialValues: FormType = {
    name: preselectedInformation?.name ?? '',
    description: preselectedInformation?.description ?? '',
    files: null,
    tag: preselectedTag ?? null,

    isEditing: isEditing
  };

  const { mutate: createInformation } = useMutation({
    mutationFn: (params: CreateInformationParams) => httpCreateInformation.callEndpoint(params),
    onSuccess: () => {
      invalidateInformationListQuery();
      invalidateInformationLimitationsQuery();
    },
    onError: (error: unknown) => {
      const errorCode = getErrorCodeFromAxiosError(error);

      if (errorCode) {
        Toast.error(`information.uploadView.error.${errorCode}`);
        return;
      }

      Toast.apiError();
    }
  });

  const { mutate: updateInformation } = useMutation({
    mutationFn: (params: UpdateInformationParams) => httpUpdateInformation.callEndpoint(params),
    onSuccess: () => {
      invalidateInformationListQuery();
    }
  });

  const handleSubmitClick = (values: FormType, formikHelpers: FormikHelpers<FormType>) => {
    // Safety check, should be handled by validationSchema already
    if (!values.tag) {
      Toast.error('information.modal.copy_paste.error.missing_tag');
      formikHelpers.setFieldError('tag', 'no category provided');
      return;
    }

    if (values.files?.length === 0) {
      formikHelpers.setFieldError('files', 'no file provided');
      return;
    }

    if (!isEditing) {
      const formData = new FormData();
      formData.append('type', 'file');
      formData.append('name', values.name);
      formData.append('description', values.description);
      formData.append('category_id', String(values.tag.id));

      values.files?.forEach((file: File) => {
        formData.append('files[]', file, file.name);
      });

      onUploadStarted?.();
      createInformation(formData);
    } else {
      updateInformation({
        type: 'file',
        informationId: preselectedInformation?.id,
        name: values.name,
        description: values.description,
        category_id: values.tag.id
      });
    }

    closeModal();
  };

  const formikSubmitRef = useRef<() => void>();

  return (
    <ModalWithDividedHeaderLayout
      title={<FormattedMessage id="information.modal.title" />}
      description={<FormattedMessage id="information.modal.copy_paste.body" />}
      helpLink="aiWriter.inspirations.aiwriter.main.information.help_link"
      closeModal={closeModal}
      height={informationModalHeight}
      slots={{
        bodyContainer: ModalBodyWithColumnDirectionAndSpaceBetween
      }}
      footer={
        <>
          <Button variant="text" onClick={onBackClick}>
            <FormattedMessage id="common.back" />
          </Button>
          <Button
            type="button"
            variant="contained"
            {...withGtmInteraction(
              isEditing
                ? gtmIds.brandHub.information.updated
                : gtmIds.brandHub.information.createdInformationFromUpload
            )}
            onClick={() => formikSubmitRef.current?.()}
          >
            <FormattedMessage id="common.save" />
          </Button>
        </>
      }
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitClick}
        innerRef={formikRef => (formikSubmitRef.current = formikRef?.submitForm)}
      >
        {({ values }) => {
          const hasSelectedPdf =
            Array.isArray(values.files) &&
            values.files.some(file => file.type === 'application/pdf');

          return (
            <FormBox>
              <FieldsBox>
                {hasSelectedPdf && <PdfFileWarning />}
                <NameField name="name" />
                <DescriptionField name="description" />
                <TagField name="tag" />
                {/* File cannot be edited*/}
                {isEditing ? (
                  preselectedInformation.file_name && (
                    <FileTile name={preselectedInformation.file_name} />
                  )
                ) : (
                  <FileField name="files" />
                )}
              </FieldsBox>
            </FormBox>
          );
        }}
      </Formik>
    </ModalWithDividedHeaderLayout>
  );
}

const FormBox = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.four};
`;

const FieldsBox = styled.div`
  max-height: 490px;
  overflow-y: auto;

  display: grid;
  gap: ${({ theme }) => theme.spacings.three};
`;

const NameField = (props: { name: string }) => {
  const { name } = props;
  const [field, meta] = useField(name);

  const tr = useTr();

  return (
    <FieldBox>
      <ColoredTypography variant="body2" color="blackMediumEmphasis">
        <FormattedMessage id="information.modal.copy_paste.name" />
      </ColoredTypography>
      <TextField
        {...field}
        {...props}
        error={!!meta.error && meta.touched}
        fullWidth
        placeholder={tr('information.modal.copy_paste.name.placeholder')}
        helperText={
          !!meta.error &&
          tr('information.modal.copy_paste.error.text_limit', { limit: NAME_MAX_CHAR })
        }
      />
    </FieldBox>
  );
};

const DescriptionField = (props: { name: string }) => {
  const { name } = props;
  const [field, meta] = useField(name);

  const tr = useTr();

  return (
    <FieldBox>
      <ColoredTypography variant="body2" color="blackMediumEmphasis">
        <FormattedMessage id="information.modal.copy_paste.description" />
      </ColoredTypography>
      <TextField
        {...field}
        {...props}
        error={!!meta.error && meta.touched}
        fullWidth
        placeholder={tr('information.modal.copy_paste.description.placeholder')}
        helperText={
          !!meta.error &&
          tr('information.modal.copy_paste.error.text_limit', { limit: DESCRIPTION_MAX_CHAR })
        }
      />
    </FieldBox>
  );
};

const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xsmall};
`;

export function useGetInformationCategoriesQuery(params: { name?: string; is_paginated?: 0 | 1 }) {
  return useInfiniteQuery({
    queryKey: httpGetInformationCategories.makeQueryKey(params),
    queryFn: (context: { pageParam?: PaginatedListParams }) => {
      const { pageParam = {} } = context;
      return httpGetInformationCategories.callEndpoint({
        ...params,
        ...pageParam,
        // this should be a temporary solution
        is_paginated: params?.is_paginated
      });
    },
    getNextPageParam: getNextPageParam
  });
}
