import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ColoredTypography } from 'components/ColoredTypography';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useGetInformationCategoriesQuery } from 'features/information/UploadView';
import { useField } from 'formik';
import { useState } from 'react';
import { httpCreateInformationCategory } from 'services/backofficeIntegration/http/endpoints/infomration/httpCreateInformationCategory';
import {
  InformationCategoryDto,
  invalidateInformationCategoriesQuery
} from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';
import useTr from 'utils/hooks/useTr';

const filter = createFilterOptions<InformationCategoryDto>();

export const TagField = (props: { name: string }) => {
  const { name } = props;
  const [field, meta, helper] = useField(name);
  const [value, setValue] = useState<InformationCategoryDto | null>(field.value);

  const [searchQuery, setSearchQuery] = useState<string>();
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const translate = useTr();

  const queryResult = useGetInformationCategoriesQuery({ name: debouncedSearchQuery });

  const tags = queryResult.data?.pages.map(page => page.data).flat(1);

  const { mutate: createCategory, isLoading: isCreating } = useMutation(
    httpCreateInformationCategory.callEndpoint,
    {
      onSuccess: data => {
        invalidateInformationCategoriesQuery();
        queryResult.refetch();
        helper.setValue(data);
      }
    }
  );

  const handleCreateCategory = (name?: string) => {
    if (!name) {
      return;
    }

    createCategory({ name });
  };

  return (
    <FieldBox>
      <ColoredTypography variant="body2" color="blackMediumEmphasis">
        <FormattedMessage id="information.modal.copy_paste.tag" />
      </ColoredTypography>
      <Autocomplete
        value={value}
        onChange={(_, value, reason) => {
          if (reason === 'clear') {
            setSearchQuery(undefined);
            setValue(null);
            helper.setValue(null);
            return;
          }

          if (value && value.isNew) {
            setValue({ ...value, name: value.alias });
            handleCreateCategory(value.alias);
            return;
          }

          helper.setValue(value);
          setValue(value);
        }}
        filterOptions={(options, state) => {
          const filtered = filter(options, state);
          const isNew =
            state.inputValue !== '' && !filtered.some(option => option.name === state.inputValue);

          if (isNew) {
            filtered.push({
              id: 0,
              customer_id: '',
              alias: state.inputValue,
              name: translate('common.add') + ` "${state.inputValue}"`,
              isNew
            });
          }

          return filtered;
        }}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        options={tags ?? []}
        loading={queryResult.isLoading || isCreating}
        renderInput={params => (
          <TextField
            {...params}
            error={!!meta.error && meta.touched}
            onChange={e => setSearchQuery(e.currentTarget.value)}
            placeholder={translate('information.modal.copy_paste.tag.placeholder')}
          />
        )}
      />
    </FieldBox>
  );
};

const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xsmall};
`;
