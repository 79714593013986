import { DownloadRounded, ShareRounded } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { ReactComponent as DiamondIcon } from 'assets/icons/diamond.svg';
import { DownloadMenu } from 'features/aiWriter/AiWriterWorkspace/header/DownloadMenu';
import { ShareMenu } from 'features/aiWriter/AiWriterWorkspace/header/ShareMenu';
import { useAiWriterTab } from 'features/aiWriter/hooks/useAiWriterTab';
import { setCurrentTab } from 'features/aiWriter/store/actions/tabs/actions';
import { renameCurrentTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/renameCurrentTabThunk';
import {
  getActiveTab,
  getAiWriterTabsHeaders,
  getIsSaving
} from 'features/aiWriter/store/selectors';
import {
  DocumentPageTopBar,
  popoverProps,
  StyledIconButton
} from 'features/document-top-bar/DocumentPageTopBar';
import { useEmptyDocumentStore } from 'features/plate/components/plate-ui/useEmptyDocumentStore';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingPlan } from 'features/pricing/types';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useMenu } from 'utils/hooks/useMenu';

type Props = {
  readonly: boolean;
};

export const AiWriterDocumentPageTopBar = ({ readonly }: Props) => {
  const dispatch = useAppDispatch();
  const activeDocument = useAppSelector(getActiveTab);
  const documentHeaders = useAppSelector(getAiWriterTabsHeaders, shallowEqual);
  const isSaving = useAppSelector(getIsSaving);

  const setEnteredHeadline = useEmptyDocumentStore(state => state.setEnteredHeadline);

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const { currentPricingPlan } = useSubscriptionState();

  const showUpgradeButton =
    currentPricingPlan.type === PricingPlan.free ||
    currentPricingPlan.type === PricingPlan.standard;

  const { onCreate, onSelect, onClose } = useAiWriterTab();

  const canShare = !readonly;

  const {
    anchorEl: downloadAnchorEl,
    isOpen: isDownloadMenuOpen,
    onTriggerClick: onDownloadTriggerClick,
    onMenuClose: onDownloadMenuClose
  } = useMenu();

  const {
    anchorEl: shareAnchorEl,
    isOpen: isShareMenuOpen,
    onTriggerClick: onShareTriggerClick,
    onMenuClose: onShareMenuClose
  } = useMenu();

  const handleUpgradeClick = () => {
    showUpgradeSubscriptionModal();
  };

  const handleDocumentRename = (value: string) => {
    if (value === '') {
      return;
    }
    dispatch(renameCurrentTabThunk(value));
  }

  return (
    <DocumentPageTopBar
      activeDocumentId={activeDocument.id}
      documents={documentHeaders}
      isSaving={isSaving}
      onSelect={tabId => onSelect(tabId)}
      onClose={onClose}
      onCreate={onCreate}
      onBack={() => {
        // Make sure to clear a potential entered document title
        setEnteredHeadline('');
        dispatch(setCurrentTab(''));
      }}
      onRename={handleDocumentRename}
      onLastClose={() => dispatch(setCurrentTab(''))}
      enablePersonalityButton={true}
      enableTagsButton={false}
      hideBackButton={true}
    >
      <StyledIconButton
        {...withGtmInteraction(gtmIds.aiWriter.exportDocument)}
        onClick={onDownloadTriggerClick}
      >
        <DownloadRounded color="action" />
      </StyledIconButton>

      {showUpgradeButton && (
        <>
          {canShare && (
            <StyledIconButton
              {...withGtmInteraction(gtmIds.aiWriter.exportDocument)}
              onClick={onShareTriggerClick}
            >
              <ShareRounded color="action" />
            </StyledIconButton>
          )}

          <Button
            {...withGtmInteraction(gtmIds.pricing.upgradePopUp)}
            variant="contained"
            size="small"
            startIcon={<DiamondIcon />}
            onClick={handleUpgradeClick}
          >
            <Typography variant="subtitle2">
              <FormattedMessage id="common.upgrade" />
            </Typography>
          </Button>
        </>
      )}

      <DownloadMenu
        anchorEl={downloadAnchorEl}
        open={isDownloadMenuOpen}
        onClose={onDownloadMenuClose}
        {...popoverProps}
      />
      <ShareMenu
        anchorEl={shareAnchorEl}
        open={isShareMenuOpen}
        onClose={onShareMenuClose}
        {...popoverProps}
      />
    </DocumentPageTopBar>
  );
};
