import { getRoutePath } from 'config/routes';
import { useSurveyAnswers } from 'features/onboardingJourney/newUserSurvey/useSurveyAnswers';
import { useSurveyQuestions } from 'features/onboardingJourney/newUserSurvey/useSurveyQuestions';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useNavigate } from 'react-router';
import { SurveyAnswersDto } from 'services/backofficeIntegration/http/dtos/SurveyAnswersDto';
import {
  SurveyQuestionDto,
  SurveyQuestionOptionDto,
  SurveyQuestionsDto
} from 'services/backofficeIntegration/http/dtos/SurveyQuestionsDto';

type NewAnswers = Record<number, number>;

export type LocaleConfig = {
  embeddingModelId: string;
  audienceId: number;
  audienceModelId: string;
  language: string;
  country: string;
};

type OnboardingContextType = {
  isLoading: boolean;
  questions: SurveyQuestionsDto;
  existingAnswers: SurveyAnswersDto;
  newAnswers: NewAnswers;

  setNewAnswers: Dispatch<SetStateAction<NewAnswers>>;

  isAnswerOptionChecked: (
    question: SurveyQuestionDto | undefined,
    answer: SurveyQuestionOptionDto
  ) => boolean;

  refetchAnswers: () => void;

  localeConfig?: LocaleConfig;
  chatMessagePrompt: string;
  setChatMessagePrompt: Dispatch<SetStateAction<string>>;
  setLocalConfig: Dispatch<SetStateAction<LocaleConfig | undefined>>;
};

const OnboardingContext = createContext<OnboardingContextType>(
  // We manually export the provider including a value so this is safe here.
  undefined as unknown as OnboardingContextType
);

type Props = {
  children: ReactNode;
};

export const OnboardingContextProvider = ({ children }: Props) => {
  const { data: questions, isLoading: isLoadingQuestions } = useSurveyQuestions();
  const {
    data: existingAnswers,
    isLoading: isLoadingAnswers,
    refetch: refetchAnswers
  } = useSurveyAnswers();
  const [newAnswers, setNewAnswers] = useState<NewAnswers>({});
  const [chatMessagePrompt, setChatMessagePrompt] = useState('');
  const [localeConfig, setLocalConfig] = useState<LocaleConfig>();
  const navigate = useNavigate();

  const isAnswerOptionChecked = (
    question: SurveyQuestionDto | undefined,
    answer: SurveyQuestionOptionDto
  ) => {
    if (!question) {
      return false;
    }

    // We store and send answers as a record of <question-id, answer-id>,
    // but the answers are given as a record of <question-slug, answer-text>.
    // Therefore, we have to compare the local/new answers by id,
    // but the existing answers by slug and text.
    if (newAnswers[question.id]) {
      return newAnswers[question.id] === answer.id;
    }

    return existingAnswers?.[question.slug] === answer.option_text;
  };

  useEffect(() => {
    // If we don't get any questions, redirect
    if (!isLoadingQuestions && !isLoadingAnswers && (!questions || questions.length === 0)) {
      navigate(getRoutePath('home'));
    }
  }, [isLoadingAnswers, isLoadingQuestions, navigate, questions]);

  return (
    <OnboardingContext.Provider
      value={{
        isLoading: isLoadingQuestions || isLoadingAnswers,
        questions: questions ?? [],
        existingAnswers: existingAnswers ?? {},
        newAnswers,
        setNewAnswers,
        isAnswerOptionChecked,
        refetchAnswers,
        localeConfig,
        setLocalConfig,
        chatMessagePrompt,
        setChatMessagePrompt
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => useContext(OnboardingContext);
