import { createContext, ReactNode, useState } from 'react';

export enum Steps {
  Step1,
  Step2,
  Step3
}

type SocialPostBuilderStepContext = {
  currentStep: Steps;
  setCurrentStep: (step: Steps) => void;
};

export const SocialPostBuilderStepContext = createContext<SocialPostBuilderStepContext>(
  {} as SocialPostBuilderStepContext
);

type Props = {
  children: ReactNode;
};

export const SocialPostBuilderStepProvider = ({ children }: Props) => {
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.Step1);

  return (
    <SocialPostBuilderStepContext.Provider value={{ currentStep, setCurrentStep }}>
      {children}
    </SocialPostBuilderStepContext.Provider>
  );
};
