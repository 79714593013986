import { InputAdornment, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { HelpLinkingButton } from 'components/HelpLinkingButton';
import { PersonalityCommonSelector } from 'components/personality/PersonalityCommonSelector';
import { PersonalityIcon } from 'components/personality/PersonalityIcon';
import { useUpdateAiWriterAndDefaultPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useUpdateAiWriterAndDefaultPersonality';
import { isAiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { TonalitySelect } from 'features/aiWriter/tonality/TonalitySelect';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useShowApplyInformationModal } from 'features/information/apply-information/ApplyInformationModal';
import { InformationField } from 'features/information/apply-information/InformationField';
import { FormValues } from 'features/workflowBlogPostBuilder/types';
import { useFormikContext } from 'formik';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

import * as CommonStyled from './common.styles';

export const Step2 = () => {
  const getModelById = useAppSelector(getEmbeddingModelDataSelector);
  const translate = useTr();

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const model = getModelById(values.language);
  const languageAndCountryCode = `${model.language}_${model.country}`;

  const tonalitySupportedInLanguage =
    isAiWriterSupportedLanguageAndCountryCode(languageAndCountryCode);

  const updatePersonality = useUpdateAiWriterAndDefaultPersonality();

  const handlePersonalityChange = (personality: PersonalityDto | undefined | null) => {
    updatePersonality({ personality });

    setFieldValue('personality', personality);
  };

  const showApplyInformationModal = useShowApplyInformationModal();

  const handleInformationApply = () => {
    showApplyInformationModal({
      preselectedInformation: values.information,
      onApply: informationList => {
        setFieldValue('information', informationList);
      }
    });
  };

  const handleInformationRemove = (id: number) => {
    setFieldValue(
      'information',
      values.information?.filter(information => information.id !== id)
    );
  };

  if (!tonalitySupportedInLanguage) {
    return null;
  }

  return (
    <>
      <CommonStyled.Title>
        <FormattedMessage id="blog_post_builder.steps.2.title" />
      </CommonStyled.Title>

      <TonalitySelect
        label={translate('blog_post_builder.tonality_step.label')}
        systemTonality={values.systemTonality}
        userTonality={values.userTonality}
        maxLength={2}
        languageAndCountryCode={languageAndCountryCode}
        onChange={(newSystemTonalities, newUserTonalities) => {
          setFieldValue('systemTonality', newSystemTonalities);
          setFieldValue('userTonality', newUserTonalities);
        }}
        onInputChange={inputValue => {
          // Disable nex step if user entered a tonality but didn't confirmed it
          setFieldValue('disableNextStep', inputValue.length > 0);
        }}
        gtmAttributes={withGtmInteraction(gtmIds.blogPostBuilder.tonality)}
      />

      <PersonalityContainer>
        <FlexContainer direction="row" gap="small">
          <Typography variant="subtitle1">
            <FormattedMessage id="common.brandVoice" />
          </Typography>

          <HelpLinkingButton
            link="aiWriter.inspirations.aiwriter.main.personality.help_link"
            tooltip={<FormattedMessage id="blog_post_builder.tonality_step.personality.tooltip" />}
          />
        </FlexContainer>
        <PersonalitySelectorContainer>
          <PersonalityCommonSelector
            value={values.personality}
            onChange={handlePersonalityChange}
            preselectedModelId={model.id}
            isPropsLoading={false}
            size="small"
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <PersonalityIcon />
              </InputAdornment>
            }
          />
        </PersonalitySelectorContainer>
      </PersonalityContainer>

      <InformationField
        informationList={values.information}
        onApply={handleInformationApply}
        onRemove={handleInformationRemove}
        fontVariant="subtitle1"
      />
    </>
  );
};

const PersonalityContainer = styled(FlexContainer).attrs({
  direction: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 'two'
})``;

const PersonalitySelectorContainer = styled.div`
  width: 10rem;
`;
