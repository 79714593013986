import { PrimaryChip } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/NumberOfSuggestionsChips';
import {
  FormValues,
  SocialPlatform
} from 'features/workflowSocialPostBuilder/SocialPostBuilderFormikProvider';
import { useField, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';

type Props = {
  platform: SocialPlatform;
};

export function SocialPlatformChip({ platform }: Props) {
  const [field] = useField('socialPlatforms');
  const { setFieldValue } = useFormikContext<FormValues>();

  return (
    <PrimaryChip
      {...withGtmInteraction(gtmIds.socialPostBuilder.socialChannel, { withDeprecatedStyle: true })}
      label={<FormattedMessage id={`social_post_builder.${platform}.headline`} />}
      $selected={field.value.includes(platform)}
      onClick={e => {
        e.preventDefault();

        const newValue = field.value.includes(platform)
          ? field.value.filter((item: SocialPlatform) => item !== platform)
          : [...field.value, platform];

        setFieldValue('socialPlatforms', newValue);
      }}
      data-social-channel={platform.toLowerCase()}
    />
  );
}
