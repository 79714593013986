import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAiImagesGeneratorContext } from 'features/aiImages/AiImagesPage/AiImagesGeneratorContext';
import { ComponentProps } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import useTr from 'utils/hooks/useTr';

type Props = {
  SelectProps?: ComponentProps<typeof Select<number>>;
  onNumberOfImagesChange?: (value: number) => void;
}

export const NumberOfImagesSelect = ({ SelectProps, onNumberOfImagesChange }: Props) => {
  const translate = useTr();

  const { numberOfImages, amountImagesLeft, setNumberOfImages } = useAiImagesGeneratorContext();

  const handleNumberOfImagesChange =     (e: SelectChangeEvent<number>) => {
    const newNumberOfImages = e.target.value as number;

    setNumberOfImages(newNumberOfImages);

    onNumberOfImagesChange?.(newNumberOfImages);
  };

  return (
      <Select
        fullWidth
        // Fallback to 1 to not show an empty (because of value "0") select
        defaultValue={numberOfImages || 1}
        onChange={handleNumberOfImagesChange}
        label={translate('ai_images.generator_page.images.label')}
        {...withGtmInteraction(gtmIds.aiImages.generatorPage.numberOfImagesSelect)}
        disabled={amountImagesLeft < 1}
        {...SelectProps}
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2} disabled={amountImagesLeft < 2}>
          2
        </MenuItem>
        <MenuItem value={3} disabled={amountImagesLeft < 3}>
          3
        </MenuItem>
        <MenuItem value={4} disabled={amountImagesLeft < 4}>
          4
        </MenuItem>
      </Select>
  );
};

