import { improveGoal } from 'features/aiTester/store/actions/suggestions/actions';
import { ImproveGoalRequestPayload } from 'features/aiTester/store/actions/suggestions/types';
import { updateTextThunk } from 'features/aiTester/store/actions/texts/thunks/updateTextThunk';
import { getGetAudienceById } from 'features/audiences/store/selectors';
import { generateTexts, GenerateTextsInput } from 'features/textGenerator/actions/generateTexts';
import { fetchPromptConfigNTimes } from 'features/textGenerator/utils/useGetPromptsByCountryAndLanguage';
import { isValidAudienceModel } from 'services/api/audience/types';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';
import { WordAttributeOptionValue } from 'types/ReduxStateTypes';

const outputType = 'performance_flash_optimizer';

export const improveGoalThunk =
  (payload: ImproveGoalRequestPayload): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const { tester: testerState } = getState();
    const currentTab = getCurrentTab(testerState);

    if (!currentTab) {
      return;
    }

    const { generateTextConfig, id: tabId, wordAttributes, manualDimensions, texts } = currentTab;

    if (!generateTextConfig) {
      return;
    }

    const { audienceId } = generateTextConfig;
    const audienceModel = getGetAudienceById(state)(audienceId);

    const generatedText = texts.find(t => t.id === payload.textId);

    if (!isValidAudienceModel(audienceModel) || !generatedText) {
      return;
    }

    // wordAttributes contains both the readability and the sentiment attributes and for the request we have to split them
    const keywords = wordAttributes.find(w => w.value === 'readability')?.option?.value || '';
    const tonalities = wordAttributes
      .filter(w => w.value !== 'readability')
      .map(w => w.option?.value)
      .filter((value): value is WordAttributeOptionValue => !!value);
    const keywords2 = manualDimensions.map(d => d.words[0].word).join(',');

    const params: GenerateTextsInput = {
      keywords,
      userTonality: tonalities,
      keywords2,
      text: generatedText.text
    };

    dispatch(improveGoal.request({ textId: payload.textId }));

    const nTimes = await fetchPromptConfigNTimes(
      audienceModel.language,
      audienceModel.country,
      outputType
    );

    try {
      const { outputs } = await generateTexts(
        { audienceModelId: audienceModel.model_id, outputType, nTimes },
        { ...params }
      );
      const firstOutput = outputs[0];

      dispatch(
        improveGoal.success({
          textId: payload.textId,
          tabId,
          improvedText: firstOutput.text
        })
      );

      dispatch(
        updateTextThunk({
          id: payload.textId,
          textItemId: firstOutput.textItemId,
          generationId: firstOutput.generationId,
          text: firstOutput.text
        })
      );
    } catch (e) {
      dispatch(improveGoal.failure({ textId: payload.textId }));
    }
  };
