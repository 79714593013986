import axios, { AxiosInstance } from 'axios';
import { startMaintenance } from 'features/maintenance/maintenanceSlice';
import store from 'store/store';
import { isObject } from 'utils/isObject';

const getErrorMessage = (data: unknown) => {
  if (isObject(data)) {
    return data.message;
  } else {
    return undefined;
  }
};

export const withMaintenanceMode = (client: AxiosInstance) => {
  client.interceptors.response.use(undefined, (error: unknown) => {
    if (axios.isAxiosError(error) && getErrorMessage(error.response?.data) === 'MAINTENANCE_MODE') {
      store.dispatch(startMaintenance());
    }

    /**
     * #tech-debt https://app.clickup.com/t/37yzkxb
     *
     * Currently there is no way to signal that the error was handled.
     * Because of that in most cases an error toast will be displayed
     * after the redirect to maintenance page. It would require refactoring
     * of all places where we call API to disable that.
     */
    throw error;
  });
};
