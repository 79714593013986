import { Typography } from '@mui/material';
import { ApiModularWorkflowStepSettings } from 'features/modular-workflow/builder/types-api';
import { BuilderModularWorkflowStepOutput } from 'features/modular-workflow/builder/types-builder';
import { RunnerError } from 'features/modular-workflow/runner/getRunnerErrorsFromResponse';
import { DataTypeBoolean } from 'features/modular-workflow/runner/settings/DataTypeBoolean';
import { DataTypeBrandVoice } from 'features/modular-workflow/runner/settings/DataTypeBrandVoice';
import { DataTypeCheckbox } from 'features/modular-workflow/runner/settings/DataTypeCheckbox';
import { DataTypeDestinationFormat } from 'features/modular-workflow/runner/settings/DataTypeDestinationFormat';
import { DataTypeDocument } from 'features/modular-workflow/runner/settings/DataTypeDocument';
import { DataTypeImageCount } from 'features/modular-workflow/runner/settings/DataTypeImageCount';
import { DataTypeImageQuality } from 'features/modular-workflow/runner/settings/DataTypeImageQuality';
import { DataTypeImageRatio } from 'features/modular-workflow/runner/settings/DataTypeImageRatio';
import { DataTypeInformationCollection } from 'features/modular-workflow/runner/settings/DataTypeInformationCollection';
import { DataTypeLanguage } from 'features/modular-workflow/runner/settings/DataTypeLanguage';
import { DataTypeLargeText } from 'features/modular-workflow/runner/settings/DataTypeLargeText';
import { DataTypeLengthOfOutput } from 'features/modular-workflow/runner/settings/DataTypeLengthOfOutput';
import { DataTypeText } from 'features/modular-workflow/runner/settings/DataTypeText';
import { DataTypeTextGenerationModel } from 'features/modular-workflow/runner/settings/DataTypeTextGenerationModel';
import { DataTypeTonality } from 'features/modular-workflow/runner/settings/DataTypeTonality';
import {
  RunnerModularWorkflowStep,
  RunnerModularWorkflowStepInput
} from 'features/modular-workflow/runner/types-runner';
import { useModulareWorkflowRunnerStore } from 'features/modular-workflow/runner/useModulareWorkflowRunnerStore';
import { createContext } from 'react';

type InputRendererContextType = {
  step: RunnerModularWorkflowStep;
  stepIndex: number;
  input: RunnerModularWorkflowStepInput;
  inputOutputs: BuilderModularWorkflowStepOutput[];
  inputSettings: ApiModularWorkflowStepSettings;
  inputErrorList: RunnerError[];
  onChange: (
    input: RunnerModularWorkflowStepInput,
    value: RunnerModularWorkflowStep['inputs'][number]['value']
  ) => void;
  onValidationChange: (inputRef: string, isValid: boolean) => void;
};

export const InputRendererContext = createContext<InputRendererContextType>(
  {} as unknown as InputRendererContextType
);

type Props = {
  step: RunnerModularWorkflowStep;
  input: RunnerModularWorkflowStepInput;
  stepSettings: ApiModularWorkflowStepSettings[];
  errorList: RunnerError[];
  onChange: InputRendererContextType['onChange'];
  onValidationChange: InputRendererContextType['onValidationChange'];
};

export const InputRenderer = ({
  input,
  stepSettings,
  step,
  errorList,
  onChange,
  onValidationChange
}: Props) => {
  const inputSettings = stepSettings.find(setting => setting.reference === input.reference);
  const inputOutputs = step.outputs.filter(
    output => output.related_input_reference === input.reference
  );
  const stepIndex = useModulareWorkflowRunnerStore(state => state.selectedStepIndex);

  if (!inputSettings) {
    return (
      <Typography color="red" fontWeight="bold">
        {`Failed to find settings for input ${input.reference}`}
      </Typography>
    );
  }

  return (
    <InputRendererContext.Provider
      value={{
        step,
        stepIndex,
        input,
        inputOutputs,
        inputSettings,
        inputErrorList: errorList,
        onChange,
        onValidationChange
      }}
    >
      {(() => {
        switch (inputSettings.data_type.type) {
          case 'text':
            return <DataTypeText />;
          case 'large_text':
            return <DataTypeLargeText />;
          case 'boolean':
            return <DataTypeBoolean />;
          case 'checkbox':
            return <DataTypeCheckbox />;
          case 'length_of_output':
            return <DataTypeLengthOfOutput />;
          case 'image_count':
            return <DataTypeImageCount />;
          case 'image_ratio':
            return <DataTypeImageRatio />;
          case 'image_quality':
            return <DataTypeImageQuality />;
          case 'brand_voice':
            return <DataTypeBrandVoice />;
          case 'text_generation_model':
            return <DataTypeTextGenerationModel />;
          case 'destination_format':
            return <DataTypeDestinationFormat />;
          case 'tonality':
            return <DataTypeTonality />;
          case 'language':
            return <DataTypeLanguage />;
          case 'document':
            return <DataTypeDocument />;
          case 'information_collection':
            return <DataTypeInformationCollection />;
        }

        return (
          <Typography color="red" fontWeight="bold">
            {`Unsupported data type: ${input.reference}`}
          </Typography>
        );
      })()}
    </InputRendererContext.Provider>
  );
};
