import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { IconButton, Typography } from '@mui/material';
import { insertNodes } from '@udecode/plate-common';
import FlexContainer from 'components/FlexContainer';
import SeoAddAllButton from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/SeoAddAllButton';
import SeoStatisticsSection from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/SeoStatisticsSection';
import useReturnToMainSeoStep from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/useReturnToMainSeoStep';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { getSeoAnalysisSuggestions } from 'features/aiWriter/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const SeoAnalysisStatisticsSubStep = () => {
  const editor = useEditor();
  const dispatch = useAppDispatch();

  const seoAnalysisSuggestions = useAppSelector(getSeoAnalysisSuggestions);

  const returnToMainSeoStep = useReturnToMainSeoStep();

  const addAllToEditor = useCallback(() => {
    if (!editor) {
      return;
    }

    if (!seoAnalysisSuggestions) {
      return;
    }

    const content = seoAnalysisSuggestions.more.map(suggestion => suggestion.word).join(' ');

    insertNodes(editor, [{ type: 'paragraph', children: [{ text: content }] }], {
      at: [editor.children.length]
    });

    dispatch(updateCurrentProjectInBackgroundThunk());
  }, [editor, seoAnalysisSuggestions, dispatch]);

  if (!seoAnalysisSuggestions) {
    return null;
  }

  return (
    <FlexContainer gap="medium">
      <FlexContainer direction="row" gap="three" alignItems="center">
        <IconButton onClick={returnToMainSeoStep}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h6">
          <FormattedMessage id="aiWriter.inspirations.seoAnalysis.statistics.title" />
        </Typography>
      </FlexContainer>
      <SeoAddAllButton onClick={addAllToEditor} />
      <SeoStatisticsSection
        more={seoAnalysisSuggestions.more}
        less={seoAnalysisSuggestions.less}
        perfect={seoAnalysisSuggestions.ok}
      />
    </FlexContainer>
  );
};
