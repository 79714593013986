import Toast from 'components/toasts/Toast';
import { deleteFavorite } from 'features/aiWriter/store/actions/favorites/actions';
import { Favorite } from 'features/aiWriter/store/types';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import FavoritesAPI from 'services/api/favorites';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

export const deleteFavoriteThunk =
  (favorite: Favorite): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const currentTab = getCurrentTab(state.aiWriter);
    const { id, text, textItemId, generationId } = favorite;

    if (currentTab) {
      const { id: tabId, generateTextConfig } = currentTab;

      trackingWrapper.track('aiWriterSidebarFavoritesRemoveFavorite', {
        documentId: tabId,
        text,
        textItemId,
        generationId,
        audienceId: generateTextConfig.audienceId
      });
    }

    dispatch(deleteFavorite.request({ id }));

    try {
      const response = await FavoritesAPI.delete(id);

      if (response.status) {
        dispatch(deleteFavorite.success({ id }));
      } else {
        const errorId = handleCommonApiError(response.data.message);

        Toast.backendError(errorId);
      }
    } catch (e) {
      dispatch(deleteFavorite.failure({ id }));
      Toast.commonError();
    }
  };
