import { LeaderboardRounded } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { collapseSelection } from '@udecode/plate-common';
import FlexContainer from 'components/FlexContainer';
import { FlashScorePopper } from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar/FlashScorePopper';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { getSelectionTextWithSeparatedBlocks } from 'features/aiWriter/AiWriterTextEditor/utils/getSelectionTextWithSeparatedBlocks';
import { PrimaryAwesomeIcon } from 'features/aiWriter/slashCommands/FlashActionsInput';
import {
  setFlashActionsSelectedText,
  setFlashActionsSelection
} from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import { useIsFlashScoreEnabled } from 'features/flashScore/useIsFlashScoreEnabled';
import { FloatingToolbar } from 'features/plate/components/plate-ui/floating-toolbar';
import { ELEMENT_FLASH_ACTIONS_INPUT } from 'features/plate/customPlugins/createflashActionsPlugin';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

export function EditorFloatingToolbar() {
  const editor = useEditor();
  const isFlashScoreEnabled = useIsFlashScoreEnabled();

  const toolbarRef = useRef<HTMLDivElement>(null);
  const [showFlashScorePopper, setShowFlashScorePopper] = useState(false);

  const selectedText = editor ? getSelectionTextWithSeparatedBlocks(editor, { noTrim: true }) : '';

  const handleFlashScoreButtonClick = () => {
    setShowFlashScorePopper(prevState => !prevState);
  };

  const handleClick = () => {
    if (editor.selection) {
      setFlashActionsSelectedText(selectedText);
      const previousSelection = editor.selection;
      setFlashActionsSelection(previousSelection);

      collapseSelection(editor, { edge: 'end' });

      editor?.insertNode({
        type: ELEMENT_FLASH_ACTIONS_INPUT,
        children: [{ text: '' }]
      });
    }
  };

  return (
    <FloatingToolbar ref={toolbarRef}>
      <FlexContainer direction="row" alignItems="center">
        <Tooltip arrow={true} title={<FormattedMessage id="aiWriter.toolbar.ai" />}>
          <Button
            {...withGtmInteraction(gtmIds.aiWriter.editor.floatingToolbar.flashActions)}
            variant="text"
            startIcon={<PrimaryAwesomeIcon fontSize="small" />}
            onClick={handleClick}
          >
            <FormattedMessage id="common.ask_ai" />
          </Button>
        </Tooltip>

        {isFlashScoreEnabled && (
          <>
            <Separator />
            <Tooltip
              arrow={true}
              title={<FormattedMessage id="aiWriter.floating_toolbar.flashScore" />}
            >
              <Button
                {...withGtmInteraction(gtmIds.aiWriter.flashScore.floatingToolbar)}
                onClick={handleFlashScoreButtonClick}
                startIcon={<LeaderboardRounded fontSize="small" />}
              >
                <FormattedMessage id="aiWriter.floating_toolbar.analyze_text" />
              </Button>
            </Tooltip>
          </>
        )}
      </FlexContainer>

      <FlashScorePopper
        anchorElement={toolbarRef.current}
        isOpen={showFlashScorePopper}
        onClose={handleFlashScoreButtonClick}
        selectedText={selectedText}
      />
    </FloatingToolbar>
  );
}

const Separator = styled.div`
  display: inline-block;
  border-left: 1px solid ${({ theme }) => theme.colors.divider};
  height: 24px;
`;
