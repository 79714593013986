import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import {
  FavoriteOutputTypeRequestParams,
  httpFavoriteOutputType
} from 'services/backofficeIntegration/http/endpoints/textGeneration/httpFavoriteOutputType';

export const useFavoriteOutputType = () =>
  useMutation({
    mutationFn: (params: FavoriteOutputTypeRequestParams) =>
      httpFavoriteOutputType.callEndpoint(params),

    onError: err => {
      const errorCode = getErrorCodeFromAxiosError(err);
      if (errorCode) {
        Toast.backendError(handleCommonApiError(errorCode));
      } else {
        Toast.apiError();
      }
    }
  });
