import { KeyboardArrowRight, LabelRounded } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { ClickAwayListener, ListItemIcon, Menu, MenuItem } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { AiWriterProjectTagsDropdownMenu } from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTagsDropdownMenu';
import { AiWriterProjectWithShortText } from 'features/aiWriter/store/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import { useMenu } from 'utils/hooks/useMenu';

export const ProjectsMenu = (props: {
  project: AiWriterProjectWithShortText;
  anchorEl: HTMLElement | null;
  isOptionsOpen: boolean;
  handleCloseOption: () => void;
  renameClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  copyDocument: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  deleteProject: (id: string[]) => void;
}) => {
  const {
    project,
    renameClick,
    copyDocument,
    deleteProject,
    anchorEl,
    isOptionsOpen,
    handleCloseOption
  } = props;

  const customerId = useAppSelector(state => state.customer.id);

  const handleRenameClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    renameClick(e);
    handleCloseOption();
  };

  const handleCopyDocument = async (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    copyDocument(e);
    handleCloseOption();
  };

  const {
    anchorEl: tagsAnchorEl,
    isOpen: isTagsMenuOpen,
    onTriggerClick: onTagsTriggerClick,
    onMenuClose: onTagsMenuClose
  } = useMenu();

  const handleTagsClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    onTagsTriggerClick(event);
    GAEvents.tagsInProjectMoreMenuClicked();
  };

  return (
    <ClickAwayListener onClickAway={handleCloseOption}>
      <Menu
        anchorEl={anchorEl}
        open={isOptionsOpen}
        onClose={handleCloseOption}
        sx={{ minWidth: '200px' }}
      >
        {customerId === project.authorId && (
          <MenuItem onClick={handleRenameClick}>
            <ListItemIcon>
              <ModeEditIcon fontSize="small" />
            </ListItemIcon>
            <FormattedMessage id="common.rename" />
          </MenuItem>
        )}
        {customerId !== project.authorId && (
          <MenuItem onClick={handleCopyDocument}>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <FormattedMessage id="common.make_a_copy" />
          </MenuItem>
        )}
        {customerId === project.authorId && (
          <MenuItem onClick={handleTagsClick}>
            <FlexContainer direction="row" gap="medium" alignItems="center">
              <FlexContainer direction="row" alignItems="center">
                <ListItemIcon>
                  <LabelRounded fontSize="small" />
                </ListItemIcon>
                <FormattedMessage id="common.tags" />
              </FlexContainer>
              <FlexContainer alignItems="center">
                <KeyboardArrowRight fontSize="small" />
              </FlexContainer>
            </FlexContainer>
          </MenuItem>
        )}
        {customerId === project.authorId && (
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              deleteProject([project.id]);
              handleCloseOption();
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <FormattedMessage id="common.delete" />
          </MenuItem>
        )}
        <AiWriterProjectTagsDropdownMenu
          anchorEl={tagsAnchorEl}
          isOpen={isTagsMenuOpen}
          handleCloseMenu={onTagsMenuClose}
          project={project}
          onManageButtonClick={handleCloseOption}
        />
      </Menu>
    </ClickAwayListener>
  );
};
