import { Badge } from '@mui/material';
import { ReactComponent as NotificationsIcon } from 'assets/sidebar/icon-notifications.svg';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useNotificationCenterEntries } from 'features/notifications/useNotificationCenterEntries';
import { SidebarItem, SidebarItemText } from 'features/pageSidebar/sidebarItems/SidebarItem';
import React from 'react';
import gtmIds from 'services/tracking/GTMIds';

export const SidebarItemNotificationCenter = (): React.ReactElement => {
  const { showModal } = useModal();

  const notifications = useNotificationCenterEntries();
  const numberOfNotifications = notifications.length;

  const notificationIcon =
    numberOfNotifications > 0 ? (
      <Badge badgeContent={numberOfNotifications} color="error">
        <NotificationsIcon />
      </Badge>
    ) : (
      <NotificationsIcon />
    );

  return (
    <SidebarItem
      gtmId={gtmIds.sidebar.notifications}
      onClick={() => showModal('NOTIFICATIONS')}
      icon={notificationIcon}
      tooltip="layout.sidebar.actions.notifications"
    >
      <FlexContainer direction="row" alignItems="center" justifyContent="space-between">
        <SidebarItemText variant="body1">
          <FormattedMessage id="layout.sidebar.actions.notifications" />
        </SidebarItemText>
      </FlexContainer>
    </SidebarItem>
  );
};
