import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { ToastErrorWithBugReportLink } from 'components/toasts/ToastErrorWithBugReportLink';
import { ToastErrorWithRequestId } from 'components/toasts/ToastErrorWithRequestId';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { ComponentProps, ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';

type FormatMessageValueArg = ComponentProps<typeof FormattedMessage>['values'];

const autoClose = 10000;

const Toast = {
  defaultAutoClose: autoClose,

  dispatch: (
    type: 'success' | 'warning' | 'error',
    message: string,
    values?: Record<string, string>,
    config?: ToastOptions
  ) => Toast[type](message, values, config),
  custom: (content: ReactNode, config?: ToastOptions) => toast.info(content, config),
  close: (id: string | number) => toast.dismiss(id),
  success: (message: IntlMessageKeys, values?: FormatMessageValueArg, config?: ToastOptions) =>
    toast.success(<FormattedMessage id={message} values={values} />, {
      ...config,
      autoClose: autoClose,
      icon: () => <CheckCircleIcon />
    }),
  info: (message: IntlMessageKeys, values?: FormatMessageValueArg, config?: ToastOptions) =>
    toast.info(<FormattedMessage id={message} values={values} />, config),
  warning: (message: IntlMessageKeys, values?: FormatMessageValueArg, config?: ToastOptions) =>
    toast.warn(<FormattedMessage id={message} values={values} />, config),
  error: (message: IntlMessageKeys, values?: FormatMessageValueArg, config?: ToastOptions) =>
    toast.error(
      <ToastErrorWithRequestId>
        <FormattedMessage id={message} values={values} />
      </ToastErrorWithRequestId>,
      {
        ...config,
        autoClose: autoClose,
        icon: () => <ErrorIcon />
      }
    ),
  commonError: () =>
    toast.error(
      <ToastErrorWithRequestId>
        <FormattedMessage id="common.error" />
      </ToastErrorWithRequestId>,
      {
        toastId: 'commonError',
        type: 'error',
        autoClose: autoClose,
        icon: () => <ErrorIcon />
      }
    ),
  backendError: (messageId: IntlMessageKeys, values?: FormatMessageValueArg) =>
    messageId === 'app.api_error'
      ? Toast.apiError()
      : toast.error(
          <ToastErrorWithRequestId>
            <FormattedMessage id={messageId} values={{ br: <br />, ...values }} />
          </ToastErrorWithRequestId>,
          {
            toastId: messageId,
            autoClose: autoClose,
            icon: () => <ErrorIcon />
          }
        ),
  apiError: () =>
    toast.error(
      <ToastErrorWithRequestId>
        <ToastErrorWithBugReportLink />
      </ToastErrorWithRequestId>,
      {
        toastId: 'apiError',
        autoClose: autoClose,
        icon: () => <ErrorIcon />
      }
    ),
  backendErrorHTMLMessage: (htmlMessage: string) =>
    toast.error(
      <ToastErrorWithRequestId>
        <ToastErrorWithBugReportLink
          message="app.server_error"
          // eslint-disable-next-line react/no-danger
          values={{ error: <span dangerouslySetInnerHTML={{ __html: htmlMessage }} /> }}
        />
      </ToastErrorWithRequestId>,
      { toastId: 'serverError', autoClose: autoClose, icon: () => <ErrorIcon /> }
    ),
  customError: (component: ReactNode, config?: ToastOptions) =>
    toast.error(<ToastErrorWithRequestId>{component}</ToastErrorWithRequestId>, {
      ...config,
      autoClose: autoClose,
      icon: () => <ErrorIcon />
    })
};

export default Toast;
