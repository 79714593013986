import { FilterList } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup, IconButton, Menu, MenuItem } from '@mui/material';
import { SpinnerDimmer } from 'components/base/SpinnerDimmer';
import { ConfigFormValues } from 'features/explorer/ConfigForm';
import { useFormikContext } from 'formik';
import { MouseEvent, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ApiCommonErrorCode } from 'services/api/types';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import WhitelistAPI from 'services/api/whitelist';
import { Whitelist } from 'services/api/whitelist/types';
import styled from 'styled-components';
import useEndpoint from 'utils/hooks/useEndpoint';

type Props = {
  modelLanguage: string;
  name: string;
  selectedFilters: number;
  clearFilters: () => void;
};

export const FilterMenuButton = ({ modelLanguage, name, selectedFilters, clearFilters }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchFilters = useCallback(
    () => WhitelistAPI.getAll({ language: modelLanguage || undefined }),
    [modelLanguage]
  );

  const [filters, isLoading] = useEndpoint<Whitelist[], ApiCommonErrorCode>({
    initialState: [],
    endpoint: fetchFilters,
    mapResponseFn: d => d,
    errorHandler: handleCommonApiError
  });

  const {
    values: { filters: formValueFilters },
    setFieldValue
  } = useFormikContext<ConfigFormValues>();

  return (
    <>
      <IconButton
        size="medium"
        onClick={handleClick}
        color={selectedFilters === 0 ? undefined : 'primary'}
      >
        <FilterList fontSize="medium" />
      </IconButton>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'filter-button'
        }}
      >
        <SpinnerDimmer loader active={isLoading}>
          <DropdownContent>
            {filters.length ? (
              <FormGroup>
                {filters.map(filter => (
                  <MenuItem key={filter.id}>
                    <FormControlLabel
                      value={filter.id}
                      name={name}
                      control={<Checkbox checked={formValueFilters.includes(filter.id)} />}
                      label={filter.label}
                      labelPlacement="end"
                      onChange={(_event, checked) => {
                        setFieldValue(
                          name,
                          checked
                            ? [...formValueFilters, filter.id]
                            : formValueFilters.filter(filterId => filterId !== filter.id)
                        );
                      }}
                    />
                  </MenuItem>
                ))}
              </FormGroup>
            ) : (
              <MenuItem disabled={true}>
                <FormattedMessage id="exploring.form.no_filters" />
              </MenuItem>
            )}
          </DropdownContent>

          <MenuItem onClick={clearFilters}>
            <FormattedMessage id="exploring.form.clear_filters" />
          </MenuItem>
        </SpinnerDimmer>
      </Menu>
    </>
  );
};

const DropdownContent = styled.div`
  max-height: 20rem;
  overflow: auto;
`;
