import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type Options = {
  url: string;
};

type ExtractBrandVoiceTextFromUrlResponseDto = {
  error_message: null | string;
  is_successful: boolean;
  personality_text: string;
  status_code: number;
};

type SuccessDto = BaseSuccessDto<ExtractBrandVoiceTextFromUrlResponseDto>;

export const httpExtractBrandVoiceTextFromUrl = mutationEndpoint({
  callEndpoint: ({ url }: Options): Promise<SuccessDto> =>
    backofficeClient
      .post<SuccessDto>('/chat-personalities/from-url', {
        url
      })
      .then(response => response.data)
      .catch(error => error.response.data)
});
