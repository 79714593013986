import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  Chip,
  TextField,
  TextFieldProps
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';
import React, { forwardRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import WordEmbeddingAPI from 'services/api/wordEmbedding';
import { secondaryText } from 'styles/styleUtils';

const useChipStyles = makeStyles({
  deleteIcon: {
    color: secondaryText
  }
});

export type AutocompleteTextFieldProps = Omit<TextFieldProps, 'error' | 'onKeyPress'>;

type Props = Omit<
  AutocompleteProps<string, true, true, true>,
  | 'ref'
  | 'freeSolo'
  | 'multiple'
  | 'autoHighlight'
  | 'value'
  | 'inputValue'
  | 'options'
  | 'disabled'
  | 'onInputChange'
  | 'onChange'
  | 'renderInput'
  | 'renderTags'
> & {
  model: string;
  initialValue?: string[];
  textFieldProps?: AutocompleteTextFieldProps;
  disabled?: boolean;

  onChange: (value: string[]) => void;
};

const AutocompleteChipsInput = forwardRef<HTMLDivElement, Props>(
  (
    { model, disabled, initialValue = [], textFieldProps, onChange, ...restAutocompleteProps },
    ref
  ) => {
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [value, setValue] = useState<string[]>(initialValue);
    const [inputValue, setInputValue] = useState<string>('');
    const [error, setError] = useState<string>('');

    const classes = useChipStyles();

    // When the initial value changes, update the value state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const resetSuggestions = () => {
      setSuggestions([]);
    };

    const getSuggestions = (word: string) => {
      WordEmbeddingAPI.wordAutocomplete({ word, model })
        .then(response => {
          if (response.status) {
            setSuggestions(response.data.suggestions);
          }
        })
        .catch(() => resetSuggestions());
    };

    const getSuggestionsDebounced = debounce(getSuggestions, 500);

    const handleInputChange = (
      _: React.SyntheticEvent<Element, Event>,
      inputValue: string,
      reason: AutocompleteInputChangeReason
    ) => {
      if (reason !== 'reset') {
        setInputValue(inputValue);
      }
      if (reason === 'input' && inputValue) {
        getSuggestionsDebounced(inputValue);
      } else {
        resetSuggestions();
      }
    };

    const wordsAvailable = async (words: string[]) => {
      try {
        const response = await WordEmbeddingAPI.wordsAvailable({ words, model });
        if (response.status) {
          return response.data.every(word => word.is_available);
        } else {
          return response.status;
        }
      } catch {
        return false;
      }
    };

    const addChip = (value: string[]) => {
      setValue(value);
      setInputValue('');
      setError('');
      onChange(value);
    };

    const checkWordsAndAddChip = (valueToCheck: string[], valueToAdd: string[]) => {
      wordsAvailable(valueToCheck).then(isAvailable => {
        if (isAvailable) {
          addChip(valueToAdd);
        } else {
          setError('common.autocomplete.chips_input.error');
        }
      });
    };

    const handleChange = (
      _: React.SyntheticEvent<Element, Event>,
      value: string[],
      reason: AutocompleteChangeReason
    ) => {
      if (reason === 'createOption') {
        checkWordsAndAddChip(value.slice(-1), value);
      } else {
        addChip(value);
      }
    };

    const editChip = (chipIndex: number) => {
      const editedChip = value[chipIndex];
      setValue(prevValue => prevValue.filter((_, index) => index !== chipIndex));
      setInputValue(editedChip);
    };

    const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === ',') {
        checkWordsAndAddChip([inputValue], [...value, inputValue]);
      }
    };

    return (
      <Autocomplete
        {...restAutocompleteProps}
        ref={ref}
        freeSolo={true}
        multiple={true}
        autoHighlight={true}
        value={value}
        inputValue={inputValue}
        options={suggestions}
        disabled={disabled}
        onInputChange={handleInputChange}
        onChange={handleChange}
        renderInput={params => (
          <TextField
            {...params}
            {...textFieldProps}
            error={!!error}
            helperText={
              error ? (
                <FormattedMessage id={error} values={{ word: inputValue }} />
              ) : (
                textFieldProps?.helperText
              )
            }
            onKeyPress={handleInputKeyPress}
          />
        )}
        renderTags={(value: string[], getTagProps) =>
          value.map((word, index) => (
            <Chip
              {...getTagProps({ index })}
              key={word}
              classes={classes}
              label={word}
              onClick={() => editChip(index)}
              clickable
            />
          ))
        }
      />
    );
  }
);

export default AutocompleteChipsInput;
