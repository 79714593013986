import { LottieComponent } from 'features/lottie/LottieComponent';
import { sample } from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const loadingAnimationPromises = [
  import('assets/loadingAnimation/loading-animation-1.lottie'),
  import('assets/loadingAnimation/loading-animation-2.lottie'),
  import('assets/loadingAnimation/loading-animation-3.lottie'),
  import('assets/loadingAnimation/loading-animation-4.lottie')
];

export const LazyLoadedLoadingAnimation = () => {
  const [animationFile, setAnimationFile] = useState<string | null>(null);

  useEffect(() => {
    if (animationFile) {
      return;
    }

    const loadingAnimationPromise = sample(loadingAnimationPromises);
    if (!loadingAnimationPromise) {
      return;
    }

    loadingAnimationPromise.then(module => {
      setAnimationFile(module.default);
    });
  }, [animationFile]);

  return (
    <AnimationContainer>
      {animationFile ? <LottieComponent src={animationFile} /> : null}
    </AnimationContainer>
  );
};

const AnimationContainer = styled.div`
  // This is the default size of the loading animation
  // Note: This seems to needed to prevent a always growing canvas element, e.g. while running a modular workflow
  height: 150px;
`;
