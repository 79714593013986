/**
 * Heavily inspired by https://github.com/fgnass/retrace.
 * Utilizes source-map library (https://github.com/mozilla/source-map) to map minified stack traces
 * to original source code.
 */

import { dataUriToBuffer } from 'data-uri-to-buffer';
import parser, { StackFrame } from 'error-stack-parser';
import {
  BasicSourceMapConsumer,
  NullableMappedPosition,
  RawSourceMap,
  SourceMapConsumer
} from 'source-map';

const sourceMapConsumers: Record<string, BasicSourceMapConsumer> = {};

// Requires the native wasm module to parse the source maps
SourceMapConsumer.initialize({
  'lib/mappings.wasm': 'https://unpkg.com/source-map@0.7.3/lib/mappings.wasm'
});

const fetchSourceMap = async (sourceUrl: string): Promise<RawSourceMap | null> => {
  const sourceResult = await fetch(sourceUrl);
  const sourceText = await sourceResult.text();
  const sourceLines = sourceText.split('\n');

  // Expect that the last line of the source file contains the source map
  for (let i = sourceLines.length - 1; i; i--) {
    const m = /\/[/*][@#]\s*sourceMappingURL=(.+?)(?:\s|\*|$)/.exec(sourceLines[i]);
    if (!m) {
      continue;
    }

    // This can either be a data: URI or a relative path to the source map
    const uri = m[1];
    if (uri.indexOf('data:') === 0) {
      const src = dataUriToBuffer(uri).toString();
      return JSON.parse(src) as RawSourceMap;
    }

    // Create source map uri based on the relative path and the origin url of the source file
    const sourceMapUri = new URL(uri, sourceResult.url).href;
    return fetch(sourceMapUri).then(res => res.json());
  }

  return null;
};

const getSourceMapConsumer = async (sourceUrl: string) => {
  if (sourceMapConsumers[sourceUrl]) {
    return sourceMapConsumers[sourceUrl];
  }

  const sourceMap = await fetchSourceMap(sourceUrl);
  if (!sourceMap) {
    return null;
  }

  sourceMapConsumers[sourceUrl] = await new SourceMapConsumer(sourceMap);

  return sourceMapConsumers[sourceUrl];
};

const mapFrameToSourceMap = async (frame: StackFrame): Promise<NullableMappedPosition> => {
  const createFallbackFrame = (frame: StackFrame) => ({
    name: frame.functionName || null,
    source: frame.fileName || null,
    line: frame.lineNumber || null,
    column: frame.columnNumber || null
  });

  if (!frame.fileName || frame.lineNumber === undefined || frame.columnNumber === undefined) {
    return createFallbackFrame(frame);
  }

  const sourceMapConsumer = await getSourceMapConsumer(frame.fileName);
  if (!sourceMapConsumer) {
    return createFallbackFrame(frame);
  }

  return sourceMapConsumer.originalPositionFor({
    line: frame.lineNumber,
    column: frame.columnNumber
  });
};

const getRelativeSourcePath = (source: string | null) => {
  if (!source) {
    return '<unknown>';
  }

  // Very naive implementation, but should work for our purposes
  return source.replace(/.*\/(src|node_modules)\//, '$1/');
};

const getFormatedAdvancedFrame = (advancedFrame: Awaited<NullableMappedPosition>) => {
  let errorPosition = getRelativeSourcePath(advancedFrame.source);
  if (advancedFrame.line) {
    errorPosition += ':' + advancedFrame.line;
  }

  if (advancedFrame.column !== null && advancedFrame.column >= 0) {
    errorPosition += ':' + advancedFrame.column;
  }

  const errorLocation = advancedFrame.name
    ? advancedFrame.name + '(' + errorPosition + ')'
    : errorPosition;

  return '    at ' + errorLocation;
};

const getFormattedStack = (
  errorMessage: string,
  advancedFrames: Awaited<NullableMappedPosition>[]
) => {
  const formattedStack = advancedFrames
    .map(advancedFrame => getFormatedAdvancedFrame(advancedFrame))
    .join('\n');

  return errorMessage + '\n' + formattedStack;
};

export const getErrorStackWithOriginalSource = async (error: Error, originStack: string) => {
  const stackFrames = parser.parse(error);

  // TODO: Every frame may reference an error in the same file, therefore require the same source map.
  //       As we process all of them in one step, the same source map may be requested multiple times.
  //       This could be optimized but I currently wanted to make it faster to load.
  //       And the browser will load the file anyway only once and
  //       return it from cache for every subsequent request.
  //       If we instead wait for each frame to be processed and the stack is across multiple files,
  //       we would wait a longer time for the stack to be processed in general.
  return Promise.all(stackFrames.map(mapFrameToSourceMap)).then(advancedFrames => {
    // First line of stack trace contains a concrete error message, if present
    const errorMessage = /^.*$/m.exec(originStack)?.[0] || error.message;

    return getFormattedStack(errorMessage, advancedFrames);
  });
};
