import { CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-delete.svg';
import CountryFlag from 'components/base/CountryFlag';
import FlexContainer from 'components/FlexContainer';
import {
  AiWriterProjectGroupOfTags,
  AiWriterProjectTag
} from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTag';
import { AiWriterProjectTagsDropdownMenu } from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTagsDropdownMenu';
import { AiWriterProjectWithShortText } from 'features/aiWriter/store/types';
import { getGetEmbeddingModelById } from 'features/embeddingModels/store/selectors';
import ProjectCard from 'features/projects/ProjectCard';
import React from 'react';
import { AiWriterProjectTagType } from 'services/api/aiWriter/types';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useMenu } from 'utils/hooks/useMenu';
import useTr from 'utils/hooks/useTr';

export const projectPreviewLength = 60;

type Props = {
  title: string;
  date: string;
  contentPreview?: string;
  embeddingModelId: string;
  isLoading?: boolean;
  menu?: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  onDelete?: () => void;
  tags?: AiWriterProjectTagType[];
  onShouldClickBePrevented?: (isPrevented: boolean) => void;
  project?: AiWriterProjectWithShortText;
  gtmId?: string;
};

export function ProjectTile({
  title,
  date,
  contentPreview,
  embeddingModelId,
  isLoading,
  onClick,
  onDelete,
  menu,
  tags,
  onShouldClickBePrevented,
  project,
  gtmId
}: Props) {
  const translate = useTr();
  const modelLang = useAppSelector(getGetEmbeddingModelById)(embeddingModelId);

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onDelete?.();
  };

  const {
    anchorEl: tagsAnchorEl,
    isOpen: isTagsMenuOpen,
    onTriggerClick: onTagsTriggerClick,
    onMenuClose: onTagsMenuClose
  } = useMenu();

  const handleTagClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onTagsTriggerClick(e);
    // we need this here to "hack" prevent the event from being fired and propagated to the parent which opens the tile
    onShouldClickBePrevented?.(true);
    GAEvents.tagClickedInProjectOverview();
  };

  const handleCloseMenu = () => {
    onTagsMenuClose();
    onShouldClickBePrevented?.(false);
  };

  return (
    <ProjectCard
      menu={menu}
      title={title}
      onClick={onClick}
      id={gtmId}
      footer={
        tags && (
          <TagsFooter>
            {tags.slice(0, 2).map(tag => (
              <AiWriterProjectTag key={tag.id} tag={tag} onClick={handleTagClick} />
            ))}
            {tags.length > 2 && (
              <AiWriterProjectGroupOfTags
                numberOfTags={tags.length - 2}
                tagsList={tags.slice(2)}
                onClick={handleTagClick}
              />
            )}
          </TagsFooter>
        )
      }
    >
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <FlexContainer gap="two">
            <FlexContainer direction="row" alignItems="center">
              <StyledFlag language={modelLang?.language} country={modelLang?.country} />
              <Typography variant="caption">{date}</Typography>
            </FlexContainer>

            {contentPreview && (
              <Typography variant="body2" component="div">
                <Ellipsis>{contentPreview}</Ellipsis>
              </Typography>
            )}
          </FlexContainer>

          {onDelete && (
            <Footer>
              <Tooltip title={translate('common.delete_doc')}>
                <IconButton size="small" onClick={handleDeleteClick}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Footer>
          )}
        </>
      )}
      <AiWriterProjectTagsDropdownMenu
        anchorEl={tagsAnchorEl}
        isOpen={isTagsMenuOpen}
        handleCloseMenu={handleCloseMenu}
        project={project}
      />
    </ProjectCard>
  );
}

const Footer = styled.div`
  margin-top: auto;
  margin-left: auto;
  display: flex;
`;

const StyledFlag = styled(CountryFlag)`
  width: 16px;
  height: 16px;
  margin-right: ${({ theme }) => theme.spacings.xsmall};
`;

const TagsFooter = styled(FlexContainer).attrs({ direction: 'row' })`
  margin-top: ${({ theme }) => theme.spacings.two};
  gap: ${({ theme }) => theme.spacings.xsmall};
`;

const Ellipsis = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  max-height: 60px;
`;
