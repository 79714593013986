import { Typography } from '@mui/material';
import RefreshButton from 'components/buttons/RefreshButton';
import FlexContainer from 'components/FlexContainer';
import { AiWriterSidebarPanel } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarPanel';
import { blogPostWorkflowOutputTypes } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/stepOutputTypes';
import { TextInspirationsStepHeader } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/TextInspirationsStepHeader';
import {
  ActionsSpinner,
  BasicFavoriteAction,
  BasicRewriteAction
} from 'features/aiWriter/markdown/actions';
import {
  BasicMarkdownPreview,
  MarkdownTile,
  NeutralButtonSurface
} from 'features/aiWriter/markdown/MarkdownTile';
import {
  setBlogPostOutline,
  setBlogPostTitle
} from 'features/aiWriter/store/actions/builder/actions';
import { createFavoriteThunk } from 'features/aiWriter/store/actions/favorites/thunks/createFavoriteThunk';
import { generateTextInspirationsThunk } from 'features/aiWriter/store/actions/inspirations/thunks/generateTextInspirationsThunk';
import { rewriteTextInspirationsThunk } from 'features/aiWriter/store/actions/inspirations/thunks/rewriteTextInspirationsThunk';
import {
  getActiveTab,
  getFavorites,
  getGetIsAiWriterLoading,
  getTextInspirations
} from 'features/aiWriter/store/selectors';
import { TextInspiration } from 'features/aiWriter/store/types';
import { LoadingAnimationWithAlternatingMessages } from 'features/loading-spinner/LoadingAnimationWithAlternatingMessages';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { UsageIndicator } from 'features/usageIndicator/UsageIndicator';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';
import FlipMove from 'react-flip-move';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const BlogPostSubStep = () => {
  const dispatch = useAppDispatch();
  const tr = useTr();
  const postHog = usePostHog();

  const { generateTextConfig, id: tabId } = useAppSelector(getActiveTab);
  const textInspirations = useAppSelector(getTextInspirations);
  const areTextInspirationsLoading = useAppSelector(getGetIsAiWriterLoading)(
    'generateTextInspirations'
  );

  const favorites = useAppSelector(getFavorites);

  const addSuggestion = useCallback(
    (inspiration: TextInspiration) => {
      const { text, generationId, textItemId } = inspiration;

      const reportEvent = () => {
        trackingWrapper.track('aiWriterSidebarTextGeneratorSelectSuggestion', {
          documentId: tabId,
          text,
          generationId,
          textItemId,
          audienceModelId: generateTextConfig.audienceId
        });

        postHog?.capture('Writer - Selects copy suggestion', {
          documentId: tabId,
          text,
          generationId,
          textItemId,
          audienceModelId: generateTextConfig.audienceId
        });
      };

      switch (generateTextConfig.outputType) {
        case blogPostWorkflowOutputTypes.title:
          dispatch(setBlogPostTitle(text));
          reportEvent();
          break;
        case blogPostWorkflowOutputTypes.outline:
          dispatch(setBlogPostOutline(text));
          reportEvent();
          break;
      }
    },
    [dispatch, generateTextConfig, postHog, tabId]
  );

  const getIsSavedAsFavorite = useCallback(
    (inspiration: TextInspiration) => favorites.some(f => f.text === inspiration.text),
    [favorites]
  );

  const renderTileActions = useCallback(
    (i: TextInspiration) =>
      i.isLoading ? (
        <ActionsSpinner key="spinner" />
      ) : (
        <>
          <BasicRewriteAction
            key="rewrite"
            withDeprecatedGtm
            gtmId={gtmIds.aiWriter.refreshSelectedTextInspiration}
            onClick={() => dispatch(rewriteTextInspirationsThunk(i))}
          />
          <BasicFavoriteAction
            key="favorite"
            withDeprecatedGtm
            gtmId={gtmIds.aiWriter.saveAsFavorite}
            favorite={getIsSavedAsFavorite(i)}
            onFavorite={() => dispatch(createFavoriteThunk(i))}
          />
        </>
      ),
    [getIsSavedAsFavorite, dispatch]
  );

  return (
    <AiWriterSidebarPanel
      title="aiWriter.inspirations.aiwriter.title"
      infoButton={<TextInspirationsStepHeader />}
    >
      <Root gap="small">
        <FormBox gap="small">
          <UsageIndicator type={UsageIndicatorType.textGeneration} hasAccess={true} />
          <Actions>
            <RefreshButton
              id={gtmIds.aiWriter.refreshAllTextInspirations}
              onClick={() => dispatch(generateTextInspirationsThunk())}
              isLoading={areTextInspirationsLoading}
            />
          </Actions>
          <Caption variant="caption">
            <FormattedMessage id="aiWriter.inspirations.textInspirations.main.caption" />
          </Caption>
        </FormBox>
        {areTextInspirationsLoading ? (
          <LoadingAnimationWithAlternatingMessages />
        ) : (
          <Inspirations>
            {/* @ts-expect-error Package isn't updated for React 18 and misses the children prop */}
            <StyledFlipMove>
              {textInspirations.map(item => (
                <MarkdownTile
                  key={item.id}
                  surface={
                    <NeutralButtonSurface
                      disabled={item.isLoading}
                      onClick={() => addSuggestion(item)}
                      aria-label={tr('aiWriter.copy_to_editor')}
                      {...withGtmInteraction(gtmIds.aiWriter.selectTextInspiration, {
                        withDeprecatedStyle: true
                      })}
                    />
                  }
                  markdown={<BasicMarkdownPreview>{item.text}</BasicMarkdownPreview>}
                  rightActions={renderTileActions(item)}
                />
              ))}
            </StyledFlipMove>
          </Inspirations>
        )}
      </Root>
    </AiWriterSidebarPanel>
  );
};

const Root = styled(FlexContainer)`
  height: 100%;
`;

const FormBox = styled(FlexContainer)`
  flex: 0 0 auto;
`;

const Actions = styled.div`
  display: flex;
  flex: 1 0 0;
  justify-content: space-between;
`;

const Caption = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;

const Inspirations = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: ${({ theme }) => theme.spacings.xxxxlarge};
  padding: 0 var(--inspiration-panel-horizontal-padding);
  margin: 0 calc(-1 * var(--inspiration-panel-horizontal-padding));
`;

const StyledFlipMove = styled(FlipMove)`
  display: grid;
  gap: ${({ theme }) => theme.spacings.four};
`;

export default BlogPostSubStep;
