import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  title: ReactNode;
  subtitle: ReactNode;
  gtmId?: string;
  onClick: () => void;
  icon?: ReactNode;
};

export const CreateWorkflowButton = ({ title, subtitle, gtmId, onClick, icon }: Props) => {
  return (
    <Root id={gtmId} role="button" onClick={onClick}>
      {icon}
      <TextContainer>
        <TitleText>{title}</TitleText>
        <SubtitleText>{subtitle}</SubtitleText>
      </TextContainer>
    </Root>
  );
};

const Root = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.one};
  cursor: pointer;
  position: relative;
  max-width: 310px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.three};
  padding: ${({ theme }) => theme.spacings.four};
  border: 1px solid ${({ theme }) => theme.colors.componentsRatingEnabledborder};
  transition: ${({ theme }) => theme.transition.create(theme.transition.default, 'box-shadow')};

  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.card};
  }
`;

const TextContainer = styled(FlexContainer).attrs({ gap: 'one' })`
  text-align: left;
`;

const TitleText = styled(Typography).attrs({ variant: 'subtitle1' })`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 170px;
`;

const SubtitleText = styled(Typography).attrs({ variant: 'body2' })`
  color: ${({ theme }) => theme.colors.textSecondary};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
