import { logError } from 'features/logging/logError';
import type { ErrorInfo } from 'react';

export const logReactError = (error: unknown, errorInfo?: ErrorInfo) => {
  const errorObjectOrMessage = error instanceof Error ? error : new Error(String(error));

  // Only available during ErrorBoundary catches
  const componentStack = errorInfo?.componentStack;

  return logError(errorObjectOrMessage, 'react', {
    componentStack
  });
};
