import { Button } from '@mui/material';
import AutocompleteChipsInput from 'components/AutocompleteInput/AutocompleteChipsInput';
import FlexContainer from 'components/FlexContainer';
import AutoSaveFormik from 'components/forms/AutoSaveFormik';
import { useModal } from 'components/modals';
import LocalStorageKey from 'config/localStorageKey';
import { getEmbeddingModelMapperVariablesSelector } from 'features/embeddingModels/store/selectors';
import { getInitialValuesFromTab } from 'features/explorer/ConfigSection';
import { FilterMenuButton } from 'features/explorer/FilterMenuButton';
import {
  getActiveTab,
  getCurrentTabId,
  getCurrentTabModelData,
  getIsTabInitialized
} from 'features/explorer/store/selectors';
import { setTabModel } from 'features/explorer/store/slice';
import { ConceptFlashGoal, GoalValues, ModelValues } from 'features/explorer/store/types';
import { prepareGoalConfig } from 'features/explorer/store/utils';
import { TabInitialization } from 'features/explorer/TabInitialization';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { Form, FormikProps, useFormikContext } from 'formik';
import { useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export type ConfigFormValues = ConceptFlashGoal & {
  seedWords: string[];
  listId: number | undefined;
};

const useResetFormValuesOnTabChange = () => {
  const currentTab = useAppSelector(getActiveTab);
  const currentTabId = useAppSelector(getCurrentTabId);
  const { resetForm } = useFormikContext<ConfigFormValues>();

  const currentTabIdRef = useRef(currentTabId);

  const initialValues = getInitialValuesFromTab(currentTab);

  // We can't use a side effect here because
  // we don't want it to be triggered on values change, only on tabId change
  if (currentTabIdRef.current !== currentTabId) {
    resetForm({
      values: initialValues
    });
    currentTabIdRef.current = currentTabId;
  }
};
export const ConfigForm = ({
  values,
  submitForm,
  setFieldValue,
  setValues
}: FormikProps<ConfigFormValues>) => {
  const { showModal } = useModal();
  const translate = useTr();

  const dispatch = useAppDispatch();
  const isInitialized = useAppSelector(getIsTabInitialized);
  const model = useAppSelector(getCurrentTabModelData);
  const getMapperVariables = useAppSelector(getEmbeddingModelMapperVariablesSelector);

  const { language: modelLanguage } = model || {};

  useResetFormValuesOnTabChange();

  const handleSettingsClick = () => {
    showModal({
      modalType: 'EXPLORING_SETTINGS_MODAL',
      modalProps: {
        size: 900,
        onSubmit: onSetModel
      }
    });
  };

  const handleGoalClick = () => {
    const { wordAttributes, seoParams, dimensions, manualDimensions } = values;
    showModal({
      modalType: 'EXPLORING_GOAL_MODAL',
      modalProps: {
        size: 1100,
        onSubmit: (modalValues: GoalValues) => {
          const { dimensions, manualDimensions, wordAttributes } = modalValues;
          setValues({
            ...values,
            ...modalValues,
            wordAttributes,
            goalConfig: prepareGoalConfig(wordAttributes, dimensions, manualDimensions)
          });
        },
        initialValues: {
          wordAttributes,
          seoParams,
          dimensions,
          manualDimensions
        }
      }
    });
  };

  const onSetGoal = useCallback(
    ({ wordAttributes, dimensions, manualDimensions, seoParams }: GoalValues) => {
      setFieldValue('wordAttributes', wordAttributes);
      setFieldValue('dimensions', dimensions);
      setFieldValue('manualDimensions', manualDimensions);
      setFieldValue('seoParams', seoParams);
      setFieldValue('goalConfig', prepareGoalConfig(wordAttributes, dimensions, manualDimensions));
    },
    [setFieldValue]
  );

  const onSetModel = useCallback(
    ({ model: { modelId, modelMapper } }: ModelValues) => {
      const mapperVariables = getMapperVariables(modelId, modelMapper);
      dispatch(
        setTabModel({
          embeddingModelId: modelId,
          modelMapperId: modelMapper,
          hasValence: mapperVariables.includes('valence')
        })
      );
      localStorage.setItem(LocalStorageKey.ExplorerModel, modelId);
      localStorage.setItem(LocalStorageKey.ExplorerModelMapper, modelMapper);

      submitForm();
    },
    [getMapperVariables, dispatch, submitForm]
  );

  if (!isInitialized) {
    return (
      <Form>
        <TabInitialization
          seedWords={values.seedWords}
          onChange={newSeedWords => {
            setFieldValue('seedWords', newSeedWords);
          }}
          submitForm={submitForm}
          onSetModel={onSetModel}
          onSetGoal={onSetGoal}
        />
      </Form>
    );
  }

  return (
    <Form>
      <FormContainer>
        {!values.listId && (
          <FlexContainer direction="row">
            <StyledAutocompleteChipsInput
              onChange={newSeedWords => {
                setFieldValue('seedWords', newSeedWords);
              }}
              model={model?.id}
              initialValue={values.seedWords}
              textFieldProps={{
                variant: 'outlined',
                fullWidth: true,
                label:
                  values.seedWords.length === 0 ? (
                    <FormattedMessage id="exploring.form.title" />
                  ) : undefined,
                placeholder:
                  values.seedWords.length === 0
                    ? undefined
                    : translate('common.autocomplete.tags_input.placeholder.new_word')
              }}
            />
          </FlexContainer>
        )}

        <ActionsContainer>
          <FilterMenuButton
            name="filters"
            selectedFilters={values.filters.length}
            modelLanguage={modelLanguage}
            clearFilters={() => {
              setFieldValue('filters', []);
              submitForm();
            }}
          />
          <Button color="primary" onClick={handleSettingsClick}>
            <FormattedMessage id="common.settings" />
          </Button>
          <Button color="primary" onClick={handleGoalClick}>
            <FormattedMessage id="goal_score.goals" />
          </Button>
        </ActionsContainer>
      </FormContainer>
      <AutoSaveFormik waitTime={500} />
    </Form>
  );
};

const FormContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
`;

const ActionsContainer = styled(FlexContainer).attrs({
  direction: 'row',
  justify: 'flex-end',
  gap: 'two'
})`
  width: fit-content;
  margin-left: auto;
`;

const StyledAutocompleteChipsInput = styled(AutocompleteChipsInput)`
  width: 100%;
  min-width: 500px;
  max-width: 640px;
`;
