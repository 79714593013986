import { CircularProgress, TextField, Typography } from '@mui/material';
import { Container } from 'components/Container';
import FlexContainer from 'components/FlexContainer';
import {
  getCustomerRole,
  getCustomerTeamId,
  getCustomerTeamName,
  getCustomerTeams,
  getPricingType
} from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { PricingPlan } from 'features/pricing/types';
import { useGetTeamMembers } from 'features/profilePage/useGetTeamMembers';
import { UserTable } from 'features/profilePage/users/UserTable';
import { UsersInfoCard } from 'features/profilePage/UsersInfoCard';
import { useUpdateTeamName } from 'features/profilePage/useUpdateTeamName';
import { ChangeEvent, useState } from 'react';
import { MAX_WORKSPACE_NAME_LENGTH } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpUpdateTeamName';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

const permittedPricingTypes: Array<PricingPlan> = [
  PricingPlan.pro,
  PricingPlan.business,
  PricingPlan.enterprise
];

export const UsersCard = () => {
  const customerTeamsAmount = useAppSelector(getCustomerTeams).length;
  const pricingType = useAppSelector(getPricingType);
  const isUserPermitted = permittedPricingTypes.includes(pricingType);
  const userRole = useAppSelector(getCustomerRole);
  const teamId = useAppSelector(getCustomerTeamId);
  const teamName = useAppSelector(getCustomerTeamName);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [workspaceName, setWorkspaceName] = useState(teamName);

  const updateTeamName = useUpdateTeamName();

  const {
    data: teamMembers,
    isLoading,
    isFetching,
    isSuccess
  } = useGetTeamMembers({ limit: rowsPerPage, offset: page * rowsPerPage });

  useEnableSidebarOnMount();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSetWorkspaceName = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkspaceName(event.target.value);
  };

  const handleSaveWorkspaceName = () => {
    if (!teamId) {
      return;
    }
    if (workspaceName.length >= MAX_WORKSPACE_NAME_LENGTH) {
      return;
    }
    updateTeamName.mutate({ teamId, name: workspaceName });
  };

  if (isLoading) {
    return (
      <FlexContainer direction="row" alignItems="center" justifyContent="center">
        <CircularProgress size={24} />
      </FlexContainer>
    );
  }

  if (!isSuccess) {
    return (
      <Typography variant="subtitle1">
        <FormattedMessage id="profile.users.users_list.error" />
      </Typography>
    );
  }

  if (!isUserPermitted) {
    return (
      <Root>
        <UsersInfoCard />
      </Root>
    );
  }

  const teamMemberList = teamMembers.data;
  const totalTeamMemberCount = teamMembers.total;

  return (
    <Root>
      <UsersInfoCard />
      <WorkspaceCard>
        <Typography variant="h5">
          <FormattedMessage id="profile.users.label" />
        </Typography>
        <FlexContainer direction="row" gap="four">
          <UserTotalCard>
            <Typography variant="caption">
              <FormattedMessage id="profile.users.total" />
            </Typography>
            <Typography variant="body1Bold">{totalTeamMemberCount}</Typography>
          </UserTotalCard>
          <UserTotalCard>
            <Typography variant="caption">
              <FormattedMessage id="profile.teams.total" />
            </Typography>
            <Typography variant="body1Bold">{customerTeamsAmount}</Typography>
          </UserTotalCard>
        </FlexContainer>
        <NameTextField
          variant="standard"
          label="name"
          value={workspaceName}
          onBlur={handleSaveWorkspaceName}
          onChange={handleSetWorkspaceName}
          disabled={updateTeamName.isLoading || userRole !== CustomerRole.OWNER}
        />
        {workspaceName.length > MAX_WORKSPACE_NAME_LENGTH && (
          <Typography color="error" variant="caption">
            <FormattedMessage id="profile.users.workspace.name.too_long" />
          </Typography>
        )}
      </WorkspaceCard>
      <UserTable
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        members={teamMemberList}
        totalUsers={totalTeamMemberCount}
        loadingMembers={isFetching}
      />
    </Root>
  );
};

const Root = styled(Container).attrs({ size: 'lg' })`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};
`;

const UserTotalCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  width: 11.25rem;
  background-color: ${({ theme }) => theme.colors.textHover};
  border-radius: ${({ theme }) => theme.borderRadius.four};
  gap: ${({ theme }) => theme.spacings.one};
`;

const WorkspaceCard = styled(FlexContainer)`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.four};
  border: ${({ theme }) => `1px solid ${theme.colors.divider}`};
  border-radius: ${({ theme }) => theme.borderRadius.three};
  align-items: flex-start;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.four};
`;

const NameTextField = styled(TextField)`
  width: 220px;
`;
