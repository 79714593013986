import { CustomerData, RecaptchaAction } from 'services/api/customer/types';
import AxiosClient from 'services/api/index';
import { ApiExtendedResponse } from 'services/api/types';
import { keysToSnake } from 'utils/utils';

/**
 * Before extending this, be sure the API supports the new provider!
 */
enum SupportedSocialProviders {
  google = 'google',
  linkedin = 'linkedin',
  azure = 'azure'
}

type SocialProvider = keyof typeof SupportedSocialProviders;
export type SocialDomain = 'social';

export type SocialEndpoint = keyof typeof SocialAPI;

export const getSocialProviderRedirectUri = (provider: SocialProvider) => {
  switch (provider) {
    case 'google':
      return window.location.origin + process.env.REACT_APP_GOOGLE_SOCIAL_LOGIN_REDIRECT_URI;
    case 'linkedin':
      return window.location.origin + process.env.REACT_APP_LINKEDIN_SOCIAL_LOGIN_REDIRECT_URI;
    case 'azure':
      return window.location.origin + process.env.REACT_APP_AZURE_SOCIAL_LOGIN_REDIRECT_URI;
  }
};

export const isSocialProviderrSupported = (provider: string): provider is SocialProvider =>
  Object.values<string>(SupportedSocialProviders).includes(provider);

export type SocialLoginErrorCode = 'ERROR_SOCIAL_AUTH_FAILED' | 'ERROR_CUSTOMER_EMAIL_EXISTS';

type SocialLoginRequest = {
  timezone: string;
  pricing: number;
  bid?: string;
  newsletter_subscribed?: boolean;
  campaign_url?: string;
  redirectUrl: string;
  // Recaptcha token
  token: string;
  expected_action: RecaptchaAction;
};
type SocialLoginReponse = ApiExtendedResponse<CustomerData, SocialLoginErrorCode>;

const SocialAPI = {
  loginWithSocialProvider: (provider: SocialProvider, code: string, payload?: SocialLoginRequest) =>
    AxiosClient.post<unknown, SocialLoginReponse>(
      `/social/${provider}?code=${encodeURIComponent(code)}`,
      keysToSnake(payload ?? {})
    )
};

export default SocialAPI;
