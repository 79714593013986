import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
  ResolutionOption,
  resolutionOptionMap,
  resolutionOptions,
  useAiImagesGeneratorContext
} from 'features/aiImages/AiImagesPage/AiImagesGeneratorContext';
import { imageQualityOptionsWithResolution } from 'features/aiImages/AiImagesPage/ImageQualitySelect';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { ComponentProps, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  SelectProps?: ComponentProps<typeof Select<ResolutionOption>>;
  onResolutionChange?: (value: ResolutionOption) => void;
};

export const ResolutionSelect = ({ SelectProps, onResolutionChange }: Props) => {
  const translate = useTr();
  const [open, setOpen] = useState(false);

  const { resolution, setResolution, imageQuality } = useAiImagesGeneratorContext();
  const canChangeImagesResolutions = useAppSelector(getGetIsFeatureAvailable)('imagesResolutions');

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const availableResolutions = imageQualityOptionsWithResolution.includes(imageQuality)
    ? resolutionOptions
  : [resolutionOptions[0]];

  const handleNumberOfImagesChange = (e: SelectChangeEvent<ResolutionOption>) => {
    const selectedResolution = e.target.value as ResolutionOption;

    // This blocks "Upgrade" option from being selected
    if (!availableResolutions.includes(selectedResolution) || !canChangeImagesResolutions) {
      return;
    }

    setResolution(selectedResolution);
    setOpen(false);

    onResolutionChange?.(selectedResolution);
  };

  const handleUpgradeClick = () => {
    showUpgradeSubscriptionModal();
    setOpen(false);
  };

  const handleSelectClose = () => {
    setOpen(false);
  };

  const handleSelectOpen = () => {
    setOpen(true);
  };

  return (
    <Select
      open={open}
      onClose={handleSelectClose}
      onOpen={handleSelectOpen}
      value={resolution}
      onChange={handleNumberOfImagesChange}
      label={translate('ai_images.generator_page.images.ratio_size')}
      {...SelectProps}
    >
      {!canChangeImagesResolutions && (
        <UpgradeMenuItem onClick={handleUpgradeClick}>
          <FormattedMessage id="ai_images.generator_page.images.ratio_upgrade_hint" />
        </UpgradeMenuItem>
      )}

      {availableResolutions.map(option => (
        <MenuItem
          key={option}
          value={option}
          disabled={!canChangeImagesResolutions && resolutionOptionMap[option].premium}
        >
          {`${option} (${resolutionOptionMap[option].width}px x ${resolutionOptionMap[option].height}px)`}
        </MenuItem>
      ))}
    </Select>
  );
};

const UpgradeMenuItem = styled(MenuItem)`
  color: #ed6c02;
`;
