import logoAnimation from 'assets/loadingAnimation/logo-animation.lottie';
import { HalfMask } from 'components/AppLoader';
import FlexContainer from 'components/FlexContainer';
import FullMask from 'components/FullMask';
import LocalStorageKey from 'config/localStorageKey';
import { getRoutePath } from 'config/routes';
import { setBuilderInitLanguage } from 'features/aiWriter/store/actions/builder/actions';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { getStoredUserTonality } from 'features/aiWriter/tonality/getStoredUserTonality';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import getModelInitValue from 'features/embeddingModels/store/utils/getModelInitValue';
import { LottieComponent } from 'features/lottie/LottieComponent';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import BlogPostBuilder from 'features/workflowBlogPostBuilder/BlogPostBuilder';
import { FormValues } from 'features/workflowBlogPostBuilder/types';
import { useCreateDocument } from 'features/workflowBlogPostBuilder/useCreateDocument';
import { Form, Formik } from 'formik';
import { noop } from 'lodash';
import { useEffect } from 'react';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { AiWriterWorkflowsType } from 'types/AiWriterWorkflows';

export const BlogPostFromDocumentMenuRoute = getRoutePath('blogPostWorkflow');

export const BlogPostBuilderPage = () => {
  const dispatch = useAppDispatch();
  const { createDocument } = useCreateDocument();

  useEnableSidebarOnMount(true);

  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const defaultLanguage = getModelInitValue(models, LocalStorageKey.AiWriterModel);
  const { language: onboardingLanguage } = useAppSelector(state => state.aiWriter.builder.blogPost);
  const { currentModelLanguage, currentModelCountry } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const currentLanguageModel = models.find(
    model => model.language === currentModelLanguage && model.country === currentModelCountry
  );

  const userTonality = getStoredUserTonality();

  const onboardingModel = onboardingLanguage
    ? models.find(
        model =>
          model.language === onboardingLanguage.language &&
          model.country === onboardingLanguage.country
      )
    : undefined;
  const startedFromOnboarding = onboardingModel !== undefined;

  // TODO: Pass directly to the builder component
  // Clear onboarding state after mount
  useEffect(() => {
    if (startedFromOnboarding) {
      localStorage.setItem(LocalStorageKey.AiWriterModel, onboardingModel.id);
      dispatch(
        setBuilderInitLanguage({
          builder: AiWriterWorkflowsType.BlogPost,
          language: undefined
        })
      );

      const create = async () => {
        const defaultPersonality = await httpGetDefaultPersonality.callEndpoint({
          language: onboardingModel.language,
          country: onboardingModel.country
        });

        // this is needed when we skip language step in onboarding during which we create a new document
        createDocument(onboardingModel.id, defaultPersonality ?? undefined);
      };

      create();
    }
  }, [
    createDocument,
    dispatch,
    models,
    onboardingLanguage,
    onboardingModel,
    startedFromOnboarding
  ]);

  const initialValues: FormValues = {
    title: '',
    language: currentLanguageModel?.id ?? onboardingModel?.id ?? defaultLanguage?.id ?? '',
    systemTonality: [],
    userTonality: userTonality ?? [],
    personality: undefined,
    information: undefined,
    elements: [],
    goal: '',
    keywords: [],
    outline: '',
    generatedContent: [],
    disableNextStep: false,
    startedFromOnboarding
  };

  // We need the embedding models to be loaded before we can render the builder
  if (models.length === 0) {
    return (
      <Container>
        <FullMask
          $backgroundColor="#fff"
          $zIndex={40000}
          key="mask"
          style={{ opacity: 1 }}
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <HalfMask>
            <LottieComponent src={logoAnimation} options={{ speed: 1.5 }} />
          </HalfMask>
        </FullMask>
      </Container>
    );
  }

  return (
    <Formik<FormValues>
      validateOnChange
      validateOnBlur
      onSubmit={noop}
      initialValues={initialValues}
    >
      <FullSizedForm>
        <Container direction="row">
          <BlogPostBuilder />
        </Container>
      </FullSizedForm>
    </Formik>
  );
};

const FullSizedForm = styled(Form)`
  height: 100%;
`;

const Container = styled(FlexContainer)`
  height: 100%;
`;
