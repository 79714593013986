import { ReactComponent as DiamondIcon } from 'assets/icons/diamond.svg';
import { SidebarItem } from 'features/pageSidebar/sidebarItems/SidebarItem';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';

export const SidebarItemUpgradeButton = () => {
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const handleClick = () => {
    showUpgradeSubscriptionModal();
  };

  return (
    <SidebarItem
      gtmId={gtmIds.sidebar.upgrade}
      onClick={handleClick}
      icon={<YellowDiamondIcon />}
      name="common.upgrade"
      tooltip="common.upgrade"
    />
  );
};

const YellowDiamondIcon = styled(DiamondIcon)`
  color: ${({ theme }) => theme.colors.accentYellowMain};
`;
