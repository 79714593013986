import RefreshButton from 'components/buttons/RefreshButton';
import { AddIconButton } from 'components/iconButtons/AddIconButton';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { RefreshIconButton } from 'components/iconButtons/RefreshIconButton';
import TextTile from 'components/TextTile';
import { generateSuggestionsThunk } from 'features/aiTester/store/actions/suggestions/thunks/generateSuggestionsThunk';
import { refreshSuggestionsThunk } from 'features/aiTester/store/actions/suggestions/thunks/refreshSuggestionsThunk';
import { removeSuggestionAndUpdateProjectThunk } from 'features/aiTester/store/actions/suggestions/thunks/removeSuggestionAndUpdateProjectThunk';
import { selectSuggestionThunk } from 'features/aiTester/store/actions/suggestions/thunks/selectSuggestionThunk';
import {
  getIsTextLimitAchieved,
  getIsTourRunning,
  getTesterActiveTab,
  getTesterCurrentTabSuggestions
} from 'features/aiTester/store/selectors';
import TesterSection from 'features/aiTester/TesterSection';
import TesterSubTile from 'features/aiTester/TesterSubTile';
import mkTesterTourClassName from 'features/aiTester/tour/utils/mkTesterTourClassName';
import { LoadingAnimationWithAlternatingMessages } from 'features/loading-spinner/LoadingAnimationWithAlternatingMessages';
import React, { createRef, useCallback } from 'react';
import FlipMove from 'react-flip-move';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const TesterSuggestions = (): React.ReactElement | null => {
  const dispatch = useAppDispatch();
  const tr = useTr();

  const { suggestions, isLoading } = useAppSelector(getTesterCurrentTabSuggestions);
  const { generateTextConfig } = useAppSelector(getTesterActiveTab, shallowEqual);

  const isTextLimitAchieved = useAppSelector(getIsTextLimitAchieved);
  const isTourRunning = useAppSelector(getIsTourRunning);

  const headerActions = (
    <RefreshButton
      className={mkTesterTourClassName('comparison', 2)}
      id={gtmIds.tester.rewriteAllSuggestions}
      onClick={() => dispatch(generateSuggestionsThunk())}
      isLoading={isLoading}
    />
  );

  const tileActions = useCallback(
    (id: string, text: string, textItemId?: string, generationId?: number) => (
      <>
        <DeleteIconButton
          id={gtmIds.tester.deleteSuggestion}
          tooltip={tr('common.delete')}
          circle
          onClick={() =>
            dispatch(removeSuggestionAndUpdateProjectThunk({ id, triggerSource: 'user' }))
          }
          size="small"
        />
        <RefreshIconButton
          id={gtmIds.tester.rewriteSuggestion}
          tooltip={tr('testing.actions.refresh')}
          onClick={() => {
            dispatch(refreshSuggestionsThunk({ id, source: 'suggestions' }));
            dispatchEvent(new Event('resize'));
          }}
          size="small"
        />
        {!isTextLimitAchieved && (
          <AddIconButton
            id={gtmIds.tester.selectSuggestion_plusButton}
            tooltip={tr('testing.actions.add')}
            onClick={() => dispatch(selectSuggestionThunk({ id, text, textItemId, generationId }))}
            size="small"
          />
        )}
      </>
    ),
    [isTextLimitAchieved, dispatch, tr]
  );

  if (!generateTextConfig) {
    return null;
  }

  if (isLoading) {
    return (
      <TesterSection
        title={<FormattedMessage id="testing.ai_generated_suggestions.header" />}
        actions={headerActions}
      >
        <LoadingAnimationWithAlternatingMessages />
      </TesterSection>
    );
  }

  return (
    <TesterSection
      title={<FormattedMessage id="testing.ai_generated_suggestions.header" />}
      actions={headerActions}
    >
      <Element>
        {/* @ts-expect-error Package doesn't yet define a children prop (React 18) */}
        <AnimatedList className={mkTesterTourClassName('comparison', 3)} duration={750}>
          {suggestions
            .slice()
            .sort((a, b) => b.score - a.score)
            .map(
              ({ id: suggestionId, text, textItemId, generationId, isLoading, subSuggestions }) => {
                // refreshes the tour highlight when tile actions are executed
                if (isTourRunning) {
                  dispatchEvent(new Event('resize'));
                }

                return (
                  <TextTile
                    id={gtmIds.tester.selectSuggestion_listItem}
                    ref={createRef()}
                    key={suggestionId}
                    actions={tileActions(suggestionId, text, textItemId, generationId)}
                    onClick={() =>
                      dispatch(
                        selectSuggestionThunk({
                          id: suggestionId,
                          text,
                          textItemId,
                          generationId
                        })
                      )
                    }
                    isLoading={isLoading}
                    isDisabled={isTextLimitAchieved}
                    subElements={
                      // @ts-expect-error Package doesn't yet define a children prop (React 18)
                      <AnimatedList duration={750}>
                        {subSuggestions.map(({ id, text, textItemId, generationId }) => (
                          <TesterSubTile
                            key={id}
                            id={id}
                            parentId={suggestionId}
                            text={text}
                            textItemId={textItemId}
                            generationId={generationId}
                            source="suggestions"
                          >
                            {text}
                          </TesterSubTile>
                        ))}
                      </AnimatedList>
                    }
                  >
                    {text}
                  </TextTile>
                );
              }
            )}
        </AnimatedList>
      </Element>
    </TesterSection>
  );
};

const AnimatedList = styled(FlipMove)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
`;

const Element = styled.div``;

export default TesterSuggestions;
