/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig } from 'axios';
import { keysToSnake } from 'utils/utils';
import { prepareWord, prepareWords, tagsToWords } from 'utils/wordUtils';

import AxiosClient from '..';
import {
  GenerateTextResponse,
  GenerateTextsRequest,
  GetOutputTypesSuggestionsResponse,
  ScoreTextsRequest,
  ScoreTextsResponse,
  ScoreWordsRequest,
  ScoreWordsResponse,
  TokenizerRequest,
  TokenizerResponse,
  WordAutocompleteRequest,
  WordAutocompleteResponse,
  WordsAvailableMultiModelRequest,
  WordsAvailableMultiModelResponse,
  WordsAvailableRequest,
  WordsAvailableResponse,
  WordsInterconnectednessRequest,
  WordsInterconnectednessResponse
} from './types';

const WordEmbeddingAPI = {
  scoreWords: (
    {
      seedWords,
      embeddingModelId,
      goalConfig,
      mappers,
      whitelistWords = [],
      whitelistIds = [],
      dimensions = [],
      limit = 1000
    }: ScoreWordsRequest,
    options?: AxiosRequestConfig
  ) =>
    AxiosClient.post<any, ScoreWordsResponse>(
      '/word-embedding/score_words',
      {
        seed_words: tagsToWords(seedWords),
        whitelist_words: tagsToWords(whitelistWords),
        whitelist_ids: whitelistIds,
        embedding_model_id: embeddingModelId,
        dimensions,
        mappers,
        limit,
        goal_config: goalConfig
      },
      options
    ),
  scoreTexts: (payload: ScoreTextsRequest) =>
    AxiosClient.post<any, ScoreTextsResponse>('/word-embedding/score_texts', keysToSnake(payload)),
  wordAutocomplete: ({ word, model }: WordAutocompleteRequest) =>
    AxiosClient.post<any, WordAutocompleteResponse>('/word-embedding/word_autocomplete', {
      word: prepareWord(word),
      embedding_model_id: model
    }),
  tokenizer: (payload: TokenizerRequest) =>
    AxiosClient.post<any, TokenizerResponse>('/utility-methods/tokenize', payload),
  wordsAvailable: ({ words, model }: WordsAvailableRequest) =>
    AxiosClient.post<any, WordsAvailableResponse>('/word-embedding/words_available', {
      words: prepareWords(words),
      embedding_model_id: model
    }),
  wordsAvailableMultiModel: ({
    words,
    embedding_model_ids: models
  }: WordsAvailableMultiModelRequest) =>
    AxiosClient.post<any, WordsAvailableMultiModelResponse>(
      '/word-embedding/words_available_multimodel',
      {
        words: prepareWords(words),
        embedding_models_id: models
      }
    ),
  wordsInterconnectedness: ({ words, embeddingModelId }: WordsInterconnectednessRequest) =>
    AxiosClient.post<any, WordsInterconnectednessResponse>(
      '/word-embedding/words_interconnectedness',
      {
        words: prepareWords(words),
        embedding_model_id: embeddingModelId
      }
    ),
  generateTexts: (payload: GenerateTextsRequest) =>
    AxiosClient.post<any, GenerateTextResponse>('/word-embedding/generate_text', payload),
  getOutputTypesSuggestions: () =>
    AxiosClient.get<any, GetOutputTypesSuggestionsResponse>(
      '/word-embedding/output-types/suggestions'
    )
};

export default WordEmbeddingAPI;
