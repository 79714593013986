import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { AzureLoginButton } from 'features/social/AzureLogin';
import { GoogleLogin } from 'features/social/GoogleLogin';
import { LinkedInLoginButton } from 'features/social/LinkedInLogin';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export type SocialProviderComponentProps = {
  formName: 'login' | 'register';
  newsletterSubscribed?: boolean;
  isPaid?: boolean;
  campaignUrl?: string;
};

type Props = SocialProviderComponentProps;

export const SocialRegisterOrLoginForm = ({
  formName,
  newsletterSubscribed,
  isPaid,
  campaignUrl
}: Props) => (
  <Root gap="three" alignItems="center">
    <GoogleLogin
      formName={formName}
      newsletterSubscribed={newsletterSubscribed}
      isPaid={isPaid}
      campaignUrl={campaignUrl}
    />
    <LinkedInLoginButton
      formName={formName}
      newsletterSubscribed={newsletterSubscribed}
      isPaid={isPaid}
      campaignUrl={campaignUrl}
    />
    <AzureLoginButton
      formName={formName}
      newsletterSubscribed={newsletterSubscribed}
      isPaid={isPaid}
      campaignUrl={campaignUrl}
    />

    <Divider alignItems="center" justifyContent="center">
      <DividerLabel>
        <ColoredTypography variant="caption" color="blackDisabled">
          <FormattedMessage id="common.or" />
        </ColoredTypography>
      </DividerLabel>
    </Divider>
  </Root>
);

const Root = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;

const Divider = styled(FlexContainer)`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: ${({ theme }) => theme.spacings.three} 0;
`;

const DividerLabel = styled.div`
  display: inline-block;
  width: auto;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 ${({ theme }) => theme.spacings.medium};
`;
