import { Alert, AlertTitle } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import { AuthCenteredPanel } from 'components/auth/AuthCenteredPanel';
import { AuthFooterLinks } from 'components/auth/AuthForm/AuthFooterLinks';
import AuthForm from 'components/auth/AuthForm/AuthForm';
import useRedirectWhenAuthenticated, {
  PreviousLocationStateType
} from 'components/auth/hooks/useRedirectWhenAuthenticated';
import { LoginForm } from 'components/LoginForm';
import { Ornament } from 'components/Ornament';
import { ResendVerificationEmailButton } from 'components/register/ResendVerificationEmailButton';
import { SocialRegisterOrLoginForm } from 'features/social/SocialRegisterOrLoginForm';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

export const LoginPage = (): ReactElement => {
  const { state: locationState } = useLocation() as PreviousLocationStateType;

  const tr = useTr();

  const error = useAppSelector(state => state.customer.error);
  const userEmail = useAppSelector(state => state.customer.email);

  useRedirectWhenAuthenticated();

  const campaignUrl = locationState?.from?.pathname;

  return (
    <AuthCenteredPanel slogan="register.slogan">
      <AuthForm
        header="login.header"
        extra={<AuthFooterLinks />}
        socials={<SocialRegisterOrLoginForm formName="login" campaignUrl={campaignUrl} />}
        ornaments={<Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />}
      >
        <LoginForm
          error={
            error && (
              <Alert severity="error" variant="standardFullWidth">
                <AlertTitle>
                  <FormattedMessage
                    id={error}
                    values={{
                      a: (message: string) => (
                        <ResendVerificationEmailButton email={userEmail}>
                          {message}
                        </ResendVerificationEmailButton>
                      ),
                      email: (message: string) => (
                        <a href={tr('api.error.customer.logIn.ERROR_CUSTOMER_DISABLED.mail')}>
                          {message}
                        </a>
                      )
                    }}
                  />
                </AlertTitle>
              </Alert>
            )
          }
        />
      </AuthForm>
    </AuthCenteredPanel>
  );
};
