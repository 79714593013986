import Toast from 'components/toasts/Toast';
import { resetChatStore } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import {
  forcedLogout,
  getCurrentUser,
  login,
  logout,
  unsubscribeFromPlan,
  updateCurrentUser
} from 'features/customer/store/actions';
import { getUserEmail } from 'features/customer/store/selectors';
import {
  getUserData,
  removeLocalUserData,
  saveUserDataToStorage,
  setIsFirstLoginInLocalStorage
} from 'features/customer/store/utils';
import showEmailVerificationError from 'features/customer/utils/showEmailVerificationError';
import CustomerAPI from 'services/api/customer';
import { handleCustomerError } from 'services/api/customer/errors';
import { CustomerData, UnsubscribeResponse } from 'services/api/customer/types';
import { ApiCommonErrorCode } from 'services/api/types';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { GAEvents } from 'services/tracking/GAEvents';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { getType } from 'typesafe-actions';
import { reportErrors } from 'utils/reportErrors';

// TODO combine baseCustomerData with taxCustomerData and think how to handle errors in the clever way

function* getAuthSaga(action: ReturnType<typeof login.request>) {
  try {
    const response = yield* call(CustomerAPI.logIn, action.payload);
    if (response.status) {
      const userData = getUserData({ ...response.data, tax_id: '', tax_type: '' } as CustomerData);

      // Track login
      GAEvents.userLogin({
        userId: userData.id,
        userEmail: userData.email,
        type: 'email'
      });

      yield* put(login.success(userData));

      saveUserDataToStorage(userData, response.data.is_first_login);
    } else {
      yield* put(login.failure(handleCustomerError(response.data.message)));
    }
  } catch (e) {
    yield* put(login.failure('common.error'));
  }
}

function* getCurrentUserSaga() {
  const handleFail = async (error: ApiCommonErrorCode | undefined, userEmail: string) => {
    removeLocalUserData();

    // Need to delay the toast notifications because app re-renders and toasts might not have a container to be shown in
    setTimeout(() => {
      if (error === 'ERROR_VERIFIED_INVALID_SERVICE') {
        showEmailVerificationError(userEmail);
      } else {
        Toast.backendError(handleCommonApiError(error));
      }
    }, 50);
  };

  try {
    const userEmail = yield* select(getUserEmail);

    const customerResponse = yield* call(CustomerAPI.get);
    if (!customerResponse.status) {
      handleFail(customerResponse.data.message, userEmail);
      yield* put(getCurrentUser.failure());
      return;
    }

    const userData = getUserData(customerResponse.data);

    yield* put(getCurrentUser.success(userData));
    saveUserDataToStorage(userData, customerResponse.data.is_first_login);

    // Disable first login info after we stored it once in the redux state
    // Note: The redux state will stay the same, only the localStorage will be updated
    setIsFirstLoginInLocalStorage(false);
  } catch (e) {
    removeLocalUserData();
    yield* put(getCurrentUser.failure());
  }
}

function* unsubscribeFromPlanSaga(action: ReturnType<typeof unsubscribeFromPlan.request>) {
  try {
    const response: UnsubscribeResponse = yield* call(CustomerAPI.unsubscribe);
    if (response.status) {
      const userData = getUserData(response.data);
      yield* put(unsubscribeFromPlan.success(userData, action.meta));
      Toast.success('profile.subscription_cancel_success');
    } else {
      yield* put(unsubscribeFromPlan.failure(undefined, action.meta));
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (e) {
    yield* put(unsubscribeFromPlan.failure(undefined, action.meta));
    Toast.apiError();
  }
}
// #tech-debt: https://app.clickup.com/t/30378wn
function* updateCurrentUserSaga(action: ReturnType<typeof updateCurrentUser.request>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFail = (data: { message: ApiCommonErrorCode } | any[]) => {
    // The array logic was used before, even if the backend said there's always data.message with an error
    // But to keep it backward compatible, we use the ugly | any[] hack and an array check
    Toast.error((Array.isArray(data) ? data[0] : data?.message) ?? 'common.error');
  };

  try {
    const customerResponse = yield* call(CustomerAPI.update, {
      payload: action.payload
    });
    if (!customerResponse.status) {
      handleFail(customerResponse.data);
      yield* put(updateCurrentUser.failure(undefined, action.meta));
      return;
    }

    const userData = getUserData(customerResponse.data);

    yield* put(updateCurrentUser.success(userData, action.meta));
    saveUserDataToStorage(userData, customerResponse.data.is_first_login);
  } catch (error) {
    yield* put(updateCurrentUser.failure(undefined, action.meta));
    reportErrors('saga', error as Error);
  }
}

function* logoutSaga() {
  yield* call(CustomerAPI.logOut);
  yield* put(logout.success());
  removeLocalUserData();
  resetChatStore();
}

function* forcedLogoutSaga() {
  yield removeLocalUserData();
}

export const customerSagas = [
  takeLatest(getType(login.request), getAuthSaga),
  takeLatest(getType(getCurrentUser.request), getCurrentUserSaga),
  takeLatest(getType(unsubscribeFromPlan.request), unsubscribeFromPlanSaga),
  takeLatest(getType(updateCurrentUser.request), updateCurrentUserSaga),
  takeLatest(getType(logout.request), logoutSaga),
  takeLatest(getType(forcedLogout), forcedLogoutSaga)
];
