import ShareIcon from '@mui/icons-material/Share';
import { Tooltip } from '@mui/material';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { topBarDropdownAlignmentConfig } from 'features/aiWriter/AiWriterTextEditor/utils/topBarDropdownAlignmentConfig';
import { ShareMenu } from 'features/aiWriter/AiWriterWorkspace/header/ShareMenu';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useMenu } from 'utils/hooks/useMenu';

type EditorShareButtonProps = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function EditorShareButton({ onClose, styleVariant }: EditorShareButtonProps) {
  const {
    anchorEl: shareAnchorEl,
    isOpen: isShareMenuOpen,
    onTriggerClick: onShareTriggerClick,
    onMenuClose: onShareMenuClose
  } = useMenu();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    onShareTriggerClick(e);
  };

  const handleClose = () => {
    onShareMenuClose();
    onClose && onClose();
  };

  const menu = () => (
    <ShareMenu
      anchorEl={shareAnchorEl}
      open={isShareMenuOpen}
      onClose={handleClose}
      anchorOrigin={topBarDropdownAlignmentConfig.leftAlignment.anchorOrigin}
      transformOrigin={topBarDropdownAlignmentConfig.leftAlignment.transformOrigin}
    />
  );

  if (styleVariant === 'icon') {
    return (
      <>
        <EditorIconFormatButton onClick={handleClick}>
          <Tooltip
            arrow
            title={<FormattedMessage id="aiWriter.topbar.share_label" />}
            placement="bottom"
          >
            <ShareIcon fontSize="small" />
          </Tooltip>
        </EditorIconFormatButton>
        {menu()}
      </>
    );
  }

  return (
    <>
      <EditorFormatButton onClick={handleClick}>
        <ShareIcon fontSize="small" />
        <FormattedMessage id="aiWriter.topbar.share_label" />
      </EditorFormatButton>
      {menu()}
    </>
  );
}
