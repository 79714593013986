import Toast from 'components/toasts/Toast';
import { getTesterActiveTab } from 'features/aiTester/store/selectors';
import {
  testerProjectFromApi,
  testerProjectToApi
} from 'features/aiTester/store/utils/mappers/testerProjectMappers';
import { testerTabToProject } from 'features/aiTester/store/utils/mappers/testerTabMappers';
import AiTesterAPI from 'services/api/aiTester';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { AppThunk } from 'store/store';

import { updateCurrentProjectInBackground } from '../actions';

export const updateTesterProjectInBackgroundThunk =
  (): AppThunk<void> => async (dispatch, getState) => {
    const project = testerTabToProject(getTesterActiveTab(getState()));

    dispatch(updateCurrentProjectInBackground.request({ tabId: project.id }));

    try {
      const response = await AiTesterAPI.update(project.id, {
        ...testerProjectToApi(project),
        dimensions: project.dimensions.map(d => d.id)
      });

      if (response.status) {
        dispatch(
          updateCurrentProjectInBackground.success({ project: testerProjectFromApi(response.data) })
        );
      } else {
        const errorId = handleCommonApiError(response.data.message);

        Toast.backendError(errorId);
        dispatch(updateCurrentProjectInBackground.failure({ tabId: project.id }));
      }
    } catch (e) {
      Toast.apiError();
    }
  };
