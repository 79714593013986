import { Action, combineReducers } from '@reduxjs/toolkit';
import toolbar from 'components/layout/toolbar/toolbarSlice';
import tester from 'features/aiTester/store/slice';
import aiWriter from 'features/aiWriter/store/slice';
import audiences from 'features/audiences/store/slice';
import { churnkeyReducer } from 'features/churnKey/churnkeySlice';
import { customer } from 'features/customer/store/reducer';
import { dimensions } from 'features/dimensions/store/reducer';
import models from 'features/embeddingModels/store/slice';
import explorer from 'features/explorer/store/slice';
import { group } from 'features/group/store/reducer';
import language from 'features/language/store/reducer';
import { lists } from 'features/lists/store/reducer';
import { maintenanceReducer } from 'features/maintenance/maintenanceSlice';
import pricing from 'features/pricing/store/reducer';
import subjectLines from 'features/subjectLines/store/slice';

const appReducer = combineReducers({
  aiWriter,
  customer,
  dimensions,
  explorer,
  group,
  language,
  lists,
  models,
  pricing,
  audiences,
  subjectLines,
  tester,
  toolbar,
  maintenance: maintenanceReducer,
  churnkey: churnkeyReducer
});

// TODO: Replace in code with store/RootState
export type RootState = ReturnType<typeof appReducer>;
export type RootReducerState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === 'customer/LOGOUT_SUCCESS') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
