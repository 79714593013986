import { Box, Button, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as LogoIcon } from 'assets/neuroflashLogo.svg';
import Dimmer from 'components/base/Dimmer';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import { SharedImageError } from 'features/aiImages/AiImagesPreviewPage/SharedImageError';
import { getHasToken } from 'features/customer/store/selectors';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { httpGetImage } from 'services/backofficeIntegration/http/endpoints/aiImages/httpGetImage';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export function AiImagesPreview(props: { hash: string }) {
  const { hash } = props;

  const decodedData = window.atob(hash);

  const { data, isLoading } = useQuery({
    enabled: !!decodedData,
    queryKey: httpGetImage.makeQueryKey(decodedData),
    queryFn: () => httpGetImage.callEndpoint(decodedData)
  });

  return (
    <Root>
      <Dimmer loader active={isLoading}>
        <Header />
        <Content>
          {data ? (
            <Box sx={{ position: 'relative' }}>
              <img style={{ width: '100%', height: '100%', borderRadius: 4 }} src={data} />
            </Box>
          ) : null}
          {!isLoading && !data && <SharedImageError />}
        </Content>
      </Dimmer>
    </Root>
  );
}

function Header() {
  const userHasToken = useAppSelector(getHasToken);
  const navigate = useNavigate();

  function handleClick() {
    const routeName = userHasToken ? 'aiImages' : 'free';
    navigate(getRoutePath(routeName));
  }

  return (
    <HeaderContainer>
      <LogoIcon width={230} height={42} />
      <FlexContainer direction="row" gap="medium" alignItems="center">
        <CTAExplanation>
          <FormattedMessage id="aiWriter.share.cta.explanation" />
        </CTAExplanation>
        <Button variant="contained" onClick={handleClick}>
          <FormattedMessage
            id={userHasToken ? 'aiWriter.share.cta.return_to_app' : 'aiWriter.share.cta.register'}
          />
        </Button>
      </FlexContainer>
    </HeaderContainer>
  );
}

const Root = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.medium};
`;

const HeaderContainer = styled(FlexContainer).attrs({
  justifyContent: 'space-between',
  direction: 'row',
  alignItems: 'center'
})`
  width: 100%;
`;

const CTAExplanation = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Content = styled(FlexContainer)`
  max-width: ${({ theme }) => theme.container.md};
  margin: ${({ theme }) => theme.spacings.xlarge} auto;
`;
