import { Typography } from '@mui/material';
import { Container } from 'components/Container';
import FlexContainer from 'components/FlexContainer';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type Props = {
  title: string;
  subtitle?: JSX.Element;
  isLoading?: boolean;
  autocomplete?: ReactNode;
  actions?: ReactNode;
  recentDocuments: ReactNode | null;
  workflows?: ReactNode;
  rightCornerButtons?: ReactNode;
  children?: ReactNode;
};

export function ProjectOverview({
  title,
  subtitle,
  actions,
  recentDocuments,
  rightCornerButtons,
  children
}: Props): ReactElement {
  return (
    <>
      <Root>
        <Content gap="four">
          <FlexContainer alignItems="center">
            <Typography variant="h4" fontWeight={400}>
              <FormattedMessage id={title} values={{ b: (chunks: string) => <b>{chunks}</b> }} />
            </Typography>
            {subtitle && <Typography variant="h6">{subtitle}</Typography>}
          </FlexContainer>

          {actions}

          {recentDocuments && (
            <FlexContainer gap="two">
              <Typography variant="subtitle1">
                <FormattedMessage id="common.recent_documents" />
              </Typography>

              <RecentProjectsGrid>{recentDocuments}</RecentProjectsGrid>
            </FlexContainer>
          )}

          {children}
        </Content>
      </Root>

      {rightCornerButtons}
    </>
  );
}

const Root = styled(Container).attrs({ size: 'lg' })`
  width: 100%;
`;

const Content = styled(FlexContainer)`
  margin: ${({ theme }) => `${theme.spacings.five} auto`};
`;

export const RecentProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${({ theme }) => theme.spacings.four};
`;
