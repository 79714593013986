import FileUploadIcon from '@mui/icons-material/FileUpload';
import { ReactComponent as NeuroflashLogo } from 'assets/neuroflashLogo.svg';
import { ColoredTypography } from 'components/ColoredTypography';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useField } from 'formik';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';
import { objectToCss } from 'utils/objectToCss';

type Props = {
  name: string;
  onFileSelect: (files: File[]) => void;
};

export function FileDropzone({ onFileSelect, name }: Props) {
  const [field, meta] = useField(name);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFileSelect(acceptedFiles);
    },
    [onFileSelect]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024 /* 5MB */,
    accept: {
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/pdf': ['.pdf']
    }
  });

  return (
    <Root>
      <Container
        {...getRootProps()}
        $isDragActive={isDragActive}
        $isError={!!meta.error && meta.touched}
      >
        <input {...field} {...getInputProps()} />
        <StaticBox $isDragActive={isDragActive}>
          <UploadIcon />
          <TextBox>
            <ColoredTypography variant="body1">
              <FormattedMessage
                id="information.uploadView.fileField.title"
                values={{
                  button: text => <BrowseButton>{text}</BrowseButton>
                }}
              />
            </ColoredTypography>
            <ColoredTypography variant="body2" color="blackDisabled">
              <FormattedMessage id="information.uploadView.fileField.subtitle" />
            </ColoredTypography>
          </TextBox>
        </StaticBox>

        <DragBox $isDragActive={isDragActive}>
          <ColoredTypography variant="h6">
            <FormattedMessage id="information.uploadView.fileField.hover" />
          </ColoredTypography>
          <NeuroflashLogo />
        </DragBox>
      </Container>

      <>
        {fileRejections?.map(fileRejection =>
          fileRejection?.errors?.map(error => (
            <ColoredTypography variant="caption" color="danger" key={error.code}>
              <FormattedMessage id={`information.uploadView.fileField.error.${error.code}`} />
            </ColoredTypography>
          ))
        )}
      </>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};
`;

const Container = styled.div<{ $isDragActive?: boolean; $isError?: boolean }>`
  position: relative;
  height: 10rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-width: ${({ $isError }) => ($isError ? '3px' : '1px')};
  border-style: dashed;
  border-radius: ${({ theme }) => theme.borderRadius.two};
  border-color: ${({ theme, $isError }) =>
    $isError ? theme.colors.errorMain : theme.colors.primaryColorMain};
  background-color: ${({ theme }) => theme.colors.palette.blue__50};

  outline: none;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const BrowseButton = styled.button`
  ${resetButton};
  padding: 0;

  ${({ theme }) => objectToCss(theme.typography.body1Bold)};

  color: ${({ theme }) => theme.colors.primaryColorMain};
`;

const UploadIcon = styled(FileUploadIcon).attrs({ color: 'primary' })`
  width: 3rem;
  height: 3rem;
`;

const DragBox = styled.div<{ $isDragActive?: boolean }>`
  position: absolute;
  display: ${({ $isDragActive }) => ($isDragActive ? 'flex' : 'none')};

  flex-direction: column;
  align-items: center;
`;

const StaticBox = styled.div<{ $isDragActive?: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacings.one};

  transition: opacity 0.3s ease;
  opacity: ${({ $isDragActive }) => ($isDragActive ? 0.3 : 1)};
`;
