import LocalStorageKey from 'config/localStorageKey';
import { getRoutePath } from 'config/routes';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { getStoredUserTonality } from 'features/aiWriter/tonality/getStoredUserTonality';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import getModelInitValue from 'features/embeddingModels/store/utils/getModelInitValue';
import { Form, Formik } from 'formik';
import { noop } from 'lodash';
import { ReactNode } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { useAppSelector } from 'store/hooks';

export const socialPostFromDocumentMenuRoute = `${getRoutePath('socialPostWorkflow')}#documentMenu`;

export enum SocialPlatform {
  LinkedIn = 'linkedin',
  Instagram = 'instagram',
  Twitter = 'twitter',
  Facebook = 'facebook',
  YouTube = 'youtube'
}

export type FormValues = {
  embeddingModelId: string;
  socialPlatforms: SocialPlatform[];
  systemTonality: SystemTonality[];
  userTonality: string[];
  personalityId: PersonalityDto | undefined | null;
  informationList?: InformationDto[];
  outline: string;

  showOnboardingHint: boolean;
};

export function SocialPostBuilderFormikProvider(props: { children: ReactNode }) {
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const defaultLanguage = getModelInitValue(models, LocalStorageKey.AiWriterModel);
  const { currentModelLanguage, currentModelCountry } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const currentLanguageModel = models.find(
    model => model.language === currentModelLanguage && model.country === currentModelCountry
  );

  const userTonality = getStoredUserTonality();

  const initialValues: FormValues = {
    embeddingModelId: currentLanguageModel?.id ?? defaultLanguage?.id ?? '',
    socialPlatforms: [],
    systemTonality: [],
    userTonality: userTonality ?? [],
    personalityId: undefined,
    informationList: undefined,
    outline: '',
    showOnboardingHint: false
  };

  return (
    <Formik<FormValues>
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={noop}
      initialValues={initialValues}
    >
      <Form>{props.children}</Form>
    </Formik>
  );
}
