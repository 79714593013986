import DimensionAPI from 'services/api/dimension';
import {
  DeleteDimensionResponse,
  Dimension,
  GetAllDimensionsResponse,
  NewDimension,
  UpdateDimensionResponse
} from 'services/api/dimension/types';
import { ApiExtendedResponse } from 'services/api/types';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { getType } from 'typesafe-actions';
import { reportErrors } from 'utils/reportErrors';
import { removeDuplicates } from 'utils/utils';

import Toast from '../../../components/toasts/Toast';
import * as actions from './actions';

function* getDimensionsSaga() {
  try {
    const response: GetAllDimensionsResponse = yield* call(DimensionAPI.getAll, {});

    if (response.status) {
      yield* put(actions.getDimensions.success(response.data.data));
    } else {
      yield* put(actions.getDimensions.failure());
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(actions.getDimensions.failure());
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* addDimensionSaga({ payload, meta }: ReturnType<typeof actions.addDimension.request>) {
  try {
    const response = yield* call(DimensionAPI.create, payload);

    if (response.status) {
      yield* put(actions.addDimension.success(response.data.id, meta));
      Toast.success('dimensions.created', { name: payload.label });
      yield* put(actions.getDimensions.request());
    } else {
      // TODO: Backend doesn't send form errors anymore
      yield* put(actions.addDimension.failure(undefined, meta));
    }
  } catch (error) {
    yield* put(actions.addDimension.failure(undefined, meta));
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* addToExistingDimensionSaga({
  payload,
  meta
}: ReturnType<typeof actions.addToExistingDimension.request>) {
  const { words, label }: Dimension = yield* select(
    state => state.dimensions.dimensions[payload.id]
  );
  const newDimension = {
    words: removeDuplicates(payload.words.concat(words ?? []))
  };

  try {
    const response: UpdateDimensionResponse = yield* call(
      DimensionAPI.update,
      payload.id,
      newDimension
    );

    if (response.status) {
      yield* put(actions.addToExistingDimension.success(payload.id, meta));

      Toast.success('dimensions.updated', { name: label });
      yield* put(actions.getDimensions.request());
    } else {
      yield* put(actions.addToExistingDimension.failure(undefined, meta));
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(actions.addToExistingDimension.failure(undefined, meta));
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* updateDimensionSaga({
  payload,
  meta
}: ReturnType<typeof actions.updateDimension.request>) {
  const { label }: Dimension = yield* select(state => state.dimensions.dimensions[payload.id]);

  const { id, ...updates } = payload;

  if (updates.label === label) {
    delete updates.label;
  }

  try {
    const response: UpdateDimensionResponse = yield* call(DimensionAPI.update, id, updates);

    if (response.status) {
      yield* put(actions.updateDimension.success(payload.id, meta));
      Toast.success('dimensions.updated', { name: label });
      yield* put(actions.getDimensions.request());
    } else {
      yield* put(actions.updateDimension.failure(undefined, meta));
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(actions.updateDimension.failure(undefined, meta));
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* combineDimensionsSaga({
  payload,
  meta
}: ReturnType<typeof actions.combineDimensions.request>) {
  const { words, label: sourceLabel }: Dimension = yield* select(
    state => state.dimensions.dimensions[payload.sourceId]
  );
  const {
    words: targetWords,
    description,
    label,
    language
  }: Dimension = yield* select(state => state.dimensions.dimensions[payload.targetId]);
  const newDimension: NewDimension = {
    words: removeDuplicates(words?.concat(targetWords ?? []) ?? []) ?? [],
    description: description || '',
    label,
    language
  };

  try {
    const requests = [call(DimensionAPI.update, payload.targetId, newDimension)];

    if (payload.deleteSource) {
      requests.push(call(DimensionAPI.delete, payload.sourceId));
    }

    const responses: UpdateDimensionResponse[] = yield* all(requests);

    if (responses.every(response => response.status)) {
      yield* put(actions.combineDimensions.success(payload.targetId, meta));

      Toast.success('dimensions.dimensions_merged', {
        sourceDimension: sourceLabel,
        targetDimension: label
      });

      yield getDimensionsSaga();
    } else {
      yield* put(actions.combineDimensions.failure(undefined, meta));
      responses.forEach(response => {
        if (!response.status) {
          Toast.backendError(handleCommonApiError(response.data.message));
        }
      });
    }
  } catch (error) {
    yield* put(actions.combineDimensions.failure(undefined, meta));
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* removeDimensionSaga({
  payload,
  meta
}: ReturnType<typeof actions.removeDimension.request>) {
  const { label }: Dimension = yield* select(state => state.dimensions.dimensions[payload]);
  try {
    const response: DeleteDimensionResponse = yield* call(DimensionAPI.delete, payload);

    if (response.status) {
      yield* put(actions.removeDimension.success(undefined, meta));
      Toast.success('dimensions.removed', { name: label });
      yield* put(actions.getDimensions.request());
    } else {
      yield* put(actions.removeDimension.failure(undefined, meta));
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(actions.removeDimension.failure(undefined, meta));
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* removeMultipleDimensionsSaga({
  payload,
  meta
}: ReturnType<typeof actions.removeMultipleDimensions.request>) {
  try {
    const removeCalls = payload.map(id => call(DimensionAPI.delete, id));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responses: Array<ApiExtendedResponse<any>> = yield* all(removeCalls);

    const successCount = responses.reduce<[number, number]>(
      (acc, curr) => {
        if (curr.status) {
          acc[0] += 1;
        } else {
          acc[1] += 1;
        }
        return acc;
      },
      [0, 0]
    );

    yield* put(actions.removeMultipleDimensions.success(undefined, meta));

    if (successCount[0]) {
      Toast.success('dimensions_mgmt.bulk_delete_success', { total: successCount[0] });
    }
    if (successCount[1]) {
      Toast.error('dimensions_mgmt.bulk_delete_failure', { total: successCount[1] });
    }

    yield* put(actions.getDimensions.request());
  } catch (error) {
    yield* put(actions.removeMultipleDimensions.failure(undefined, meta));
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

export const dimensionsSagas = [
  takeLatest(getType(actions.getDimensions.request), getDimensionsSaga),
  takeLatest(getType(actions.addDimension.request), addDimensionSaga),
  takeLatest(getType(actions.addToExistingDimension.request), addToExistingDimensionSaga),
  takeLatest(getType(actions.updateDimension.request), updateDimensionSaga),
  takeLatest(getType(actions.combineDimensions.request), combineDimensionsSaga),
  takeLatest(getType(actions.removeDimension.request), removeDimensionSaga),
  takeLatest(getType(actions.removeMultipleDimensions.request), removeMultipleDimensionsSaga)
];
