import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { reportErrors } from 'utils/reportErrors';

import Toast from '../../../components/toasts/Toast';
import WordEmbeddingAPI from '../../../services/api/wordEmbedding';
import { getActiveTab } from './selectors';
import { getInterconnectedness } from './slice';
import { I16s } from './types';

function* getInterconnectednessSaga() {
  const { data, embeddingModelId } = yield* select(getActiveTab);
  const words = data.filter(word => word.select).map(word => word.word);

  try {
    const response = yield* call(WordEmbeddingAPI.wordsInterconnectedness, {
      words,
      embeddingModelId
    });

    if (response.status) {
      const i16s = response.data.words.reduce<I16s>((acc, curr, idx) => {
        acc[curr.replace(/_/g, ' ')] = response.data.interconnectedness[idx].reduce<
          Record<string, number>
        >((iAcc, iCurr, iIdx) => {
          iAcc[response.data.words[iIdx].replace(/_/g, ' ')] = iCurr;
          return iAcc;
        }, {});
        return acc;
      }, {});

      yield* put(getInterconnectedness.success(i16s));
    } else {
      yield* put(getInterconnectedness.failure());
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(getInterconnectedness.failure());
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

export const explorerSagas = [
  takeLatest(getInterconnectedness.request.toString(), getInterconnectednessSaga)
];
