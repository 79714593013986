import { Search } from '@mui/icons-material';
import { TextField } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { TextTypesCategoriesMenu } from 'features/textGenerator/EnhancedOutputTypeAutocomplete/TextTypesCategoriesMenu';
import { TextTypesItemsSubmenu } from 'features/textGenerator/EnhancedOutputTypeAutocomplete/TextTypesItemsSubmenu';
import { useGetTextTypeLabel } from 'features/textGenerator/EnhancedOutputTypeAutocomplete/useGetTextTypeLabel';
import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';
import { useMenu } from 'utils/hooks/useMenu';

const MinimumQueryLength = 3;

export type TextTypeOption = {
  value: string;
  label: string;
  category: string;
  youTubeVideoId: string | undefined;
};

export type TextTypeDropdownComponentProps = {
  name: string;
  modelLanguage: string;
  modelCountry: string;
  isDisabled?: boolean;
  onSelect: (value: string) => void;
  onShowModal: () => void;
};

export const TextTypesDropdownComponent = ({
  name,
  modelLanguage,
  modelCountry,
  onSelect,
  onShowModal
}: TextTypeDropdownComponentProps) => {
  const [field, , helper] = useField<string>(name);

  const valueLabel = useGetTextTypeLabel(field.value, modelLanguage, modelCountry);
  const [inputValue, setInputValue] = useState(valueLabel ?? '');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setInputValue(valueLabel ?? '');
  }, [valueLabel]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(event.target.value);
    if (event.target.value.length >= MinimumQueryLength) {
      GAEvents.startedSearchInTextTypesMenu({ query: event.target.value });
    }
  };

  const {
    anchorEl: textTypesAnchorEl,
    isOpen: isTextTypesMenuOpen,
    onTriggerClick: onTextTypesTriggerClick,
    onMenuClose: onTexTypesMenuClose
  } = useMenu();

  const handleOnBlur = async () => {
    if (field.value !== '') {
      setInputValue(valueLabel ?? '');
    }
    // wait till the placeholder is set and clear the search value
    setTimeout(() => {
      setSearchValue('');
    }, 300);
  };

  const handleItemClicked = async (value: string, label: string) => {
    helper.setTouched(true);
    helper.setValue(value);
    await setSearchValue('');
    onSelect(value);
    setInputValue(label);
    onTexTypesMenuClose();
  };

  return (
    <FlexContainer>
      <StyledTextField
        value={searchValue}
        InputProps={{ startAdornment: <ColoredSearchIcon /> }}
        onChange={handleInputChange}
        onClick={event => {
          setInputValue('');
          onTextTypesTriggerClick(event);
        }}
        onBlur={handleOnBlur}
        placeholder={inputValue}
        sx={{
          'input::placeholder': {
            color: 'rgba(0, 0, 0, 0.87)',
            opacity: 1
          }
        }}
      />
      {searchValue.length >= MinimumQueryLength ? (
        <TextTypesItemsSubmenu
          open={isTextTypesMenuOpen}
          anchorEl={textTypesAnchorEl}
          categoryName="all"
          modelCountry={modelCountry}
          modelLanguage={modelLanguage}
          onTextTypeClick={handleItemClicked}
          onClose={onTexTypesMenuClose}
          searchQuery={searchValue}
        />
      ) : (
        <TextTypesCategoriesMenu
          isOpen={isTextTypesMenuOpen}
          anchorEl={textTypesAnchorEl}
          onMenuClose={onTexTypesMenuClose}
          onSelect={handleItemClicked}
          modelLanguage={modelLanguage}
          modelCountry={modelCountry}
          onShowModal={onShowModal}
        />
      )}
    </FlexContainer>
  );
};

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const ColoredSearchIcon = styled(Search)`
  color: ${({ theme }) => theme.colors.componentsIconActive};
`;
