import { isAiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { CommonWorkflowContainer } from 'features/workflow/CommonWorkflowContainer';
import { FormValues } from 'features/workflowSocialPostBuilder/SocialPostBuilderFormikProvider';
import {
  SocialPostBuilderStepContext,
  Steps
} from 'features/workflowSocialPostBuilder/SocialPostBuilderStepProvider';
import { useFormikContext } from 'formik';
import { ComponentProps, MouseEvent, ReactNode, useContext } from 'react';
import { APIModel } from 'services/api/embeddingModels/types';
import { useAppSelector } from 'store/hooks';

const stepCount = 3;

type Props = {
  nextButtonProps?: ComponentProps<typeof CommonWorkflowContainer>['nextButtonProps'];
  previousButtonProps?: ComponentProps<typeof CommonWorkflowContainer>['backButtonProps'];
  children: ReactNode;
};

const modelSupportsTonality = (model: APIModel) => {
  const languageAndCountryCode = `${model.language}_${model.country}`;

  return isAiWriterSupportedLanguageAndCountryCode(languageAndCountryCode);
};

export const SocialPostBuilderStepLayout = ({
  nextButtonProps,
  previousButtonProps,
  children
}: Props) => {
  const getModelById = useAppSelector(getEmbeddingModelDataSelector);
  const { currentStep, setCurrentStep } = useContext(SocialPostBuilderStepContext);
  const { values } = useFormikContext<FormValues>();

  const handleNextClick = (event: MouseEvent<HTMLButtonElement>) => {
    // Exception: If the selected model doesn't support tonalities, we'll skip step 2
    if (currentStep === Steps.Step1) {
      const model = getModelById(values.embeddingModelId);
      if (!modelSupportsTonality(model)) {
        setCurrentStep(Steps.Step3);

        nextButtonProps?.onClick?.(event);
      }
    }

    setCurrentStep(currentStep + 1);

    nextButtonProps?.onClick?.(event);
  };

  const handleBackClick = (event: MouseEvent<HTMLButtonElement>) => {
    setCurrentStep(currentStep - 1);

    previousButtonProps?.onClick?.(event);
  };

  return (
    <CommonWorkflowContainer
      activeStep={currentStep}
      stepCount={stepCount}
      hasNextButton={currentStep !== Steps.Step3}
      nextButtonProps={{
        ...nextButtonProps,
        onClick: handleNextClick
      }}
      hasBackButton={currentStep !== Steps.Step1}
      backButtonProps={{
        ...previousButtonProps,
        onClick: handleBackClick
      }}
    >
      {children}
    </CommonWorkflowContainer>
  );
};
