import useAudienceConfig from 'features/audiences/hooks/useAudienceConfig';
import { getGetEmbeddingModelLanguageAndCountryById } from 'features/embeddingModels/store/selectors';
import { scoreSubjectLines } from 'features/subjectLines/utils/scoreSubjectLines';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { generatedTextsFromGenerateTextOutputEntries } from 'features/textGenerator/utils/generateTextEntryMapper';
import { useGetNTimesByOutputTypeCached } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useAppSelector } from 'store/hooks';

export const useRewriteSubjectLine = ({ audienceId }: { audienceId: number }) => {
  const getEmbeddingModelLanguageAndCountryById = useAppSelector(
    getGetEmbeddingModelLanguageAndCountryById
  );
  const { model, audienceModelId } = useAudienceConfig({ audienceId });

  const { language: modelLanguage, country: modelCountry } =
    getEmbeddingModelLanguageAndCountryById(model.id);

  const outputType = 'variations';
  const nTimes = useGetNTimesByOutputTypeCached(modelLanguage, modelCountry, outputType);

  return async ({ existingSubjectLine }: { existingSubjectLine: string }) => {
    const { outputs } = await generateTexts(
      { audienceModelId, outputType, nTimes },
      { text: existingSubjectLine }
    );

    return scoreSubjectLines({
      inspirations: generatedTextsFromGenerateTextOutputEntries(outputs),
      embeddingModelId: model.id,
      audienceModelId
    });
  };
};
