import { Button } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import { AuthSummary } from 'components/auth/AuthSummary';
import { AuthSummarySection } from 'components/auth/AuthSummarySection';
import useRedirectWhenAuthenticated from 'components/auth/hooks/useRedirectWhenAuthenticated';
import { Ornament } from 'components/Ornament';
import { usePlanConfirmMutation } from 'components/profile/components/pricing-plan-card/usePlanConfirmMutation';
import { RegistrationFormContent } from 'components/register/RegistrationFormContent/RegistrationFormContent';
import LocalStorageKey from 'config/localStorageKey';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingSelect } from 'features/pricing/PricingSelect';
import { Formik } from 'formik';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ACLPricing } from 'services/api/customer/types';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';

type FormValues = {
  pricing: ACLPricing | undefined;
};

export function SocialRegisterPage() {
  const { mutate: confirmPlan, isLoading } = usePlanConfirmMutation();
  const { currentPricingPlan, currentSubscriptionState } = useSubscriptionState();
  const [, setSocialRegisterFlag] = useLocalStorage(LocalStorageKey.IsSocialRegisterRoute, false);

  const initialValues: FormValues = {
    pricing: undefined
  };

  const summary = useMemo(
    () => (
      <AuthSummary>
        <AuthSummarySection
          title="register.pricing_plan_step.auth_summary.basic.title"
          items={[
            'register.pricing_plan_step.auth_summary.basic.item_1',
            'register.pricing_plan_step.auth_summary.basic.item_2',
            'register.pricing_plan_step.auth_summary.basic.item_3'
          ]}
        />
        <AuthSummarySection
          title="register.pricing_plan_step.auth_summary.premium.title"
          items={[
            'register.pricing_plan_step.auth_summary.premium.item_1',
            'register.pricing_plan_step.auth_summary.premium.item_2',
            'register.pricing_plan_step.auth_summary.premium.item_3'
          ]}
        />
        <AuthSummarySection
          title="register.pricing_plan_step.auth_summary.business.title"
          items={[
            'register.pricing_plan_step.auth_summary.business.item_1',
            'register.pricing_plan_step.auth_summary.business.item_2',
            'register.pricing_plan_step.auth_summary.business.item_3'
          ]}
        />
      </AuthSummary>
    ),
    []
  );

  const actions = (
    <Button variant="contained" color="primary" type="submit">
      <FormattedMessage id="common.select" />
    </Button>
  );

  const handleSubmit = (values: FormValues) => {
    const pricingPlan = values.pricing;

    if (!pricingPlan) {
      return;
    }

    setSocialRegisterFlag(true);

    confirmPlan({
      pricingId: pricingPlan.id,
      subscriptionState: currentSubscriptionState ?? {
        status: 'not subscribed',
        paymentStatus: 'fulfilled'
      },
      isActivePlan: currentPricingPlan?.id === pricingPlan?.id,
      context: 'socialRegisterPage'
    });
  };

  useRedirectWhenAuthenticated();

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <RegistrationFormContent
        header="register.pricing_plan_step.header"
        isLoading={isLoading}
        actions={actions}
        summary={summary}
        ornaments={<Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />}
      >
        <PricingSelect pricingName="pricing" pricingContext="socialRegisterPage" />
      </RegistrationFormContent>
    </Formik>
  );
}
