import Toast from 'components/toasts/Toast';
import { getEditorText } from 'features/aiWriter/AiWriterTextEditor/utils/getEditorText';
import {
  setIsSaving,
  updateProjectInBackground
} from 'features/aiWriter/store/actions/project/actions';
import { renameCurrentTab } from 'features/aiWriter/store/actions/tabs/actions';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { AiWriterProject } from 'features/aiWriter/store/types';
import {
  aiWriterProjectFromApi,
  aiWriterProjectToApi
} from 'features/aiWriter/store/utils/mappers/aiWriterProjectMappers';
import { aiWriterTabToProject } from 'features/aiWriter/store/utils/mappers/aiWriterTabMappers';
import { getProjectShortText } from 'features/aiWriter/store/utils/mappers/aiWriterTextMappers';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import { debounce } from 'lodash';
import { Dispatch } from 'redux';
import AiWriterAPI from 'services/api/aiWriter';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { invalidateProjectsQuery } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjects';
import { AppThunk } from 'store/store';

export const updateCurrentProjectInBackgroundThunk = (): AppThunk<void> => (dispatch, getState) => {
  const project = aiWriterTabToProject(getActiveTab(getState()));
  debouncedUpdate({ project, dispatch });
};

const saveDelay = 1500; // ms;
let debounceMemory:
  | Readonly<{ projectId: string; debouncedUpdate: (props: UpdateProps) => void }>
  | undefined;

type UpdateProps = {
  project: AiWriterProject;
  dispatch: Dispatch;
};

function debouncedUpdate(props: UpdateProps) {
  const { project } = props;

  if (!debounceMemory || project.id !== debounceMemory.projectId) {
    debounceMemory = {
      projectId: project.id,
      debouncedUpdate: debounce(update, saveDelay)
    };
  }

  debounceMemory.debouncedUpdate(props);
}

async function update(props: UpdateProps) {
  const { project, dispatch } = props;
  dispatch(updateProjectInBackground.request());
  dispatch(setIsSaving(true));

  // Create a name for the project if it doesn't have one
  if (project.name === unnamed) {
    const editorText = getEditorText(project.text);
    if (editorText.length >= 20) {
      project.name = editorText.slice(0, 20);

      // Update current tab title
      dispatch(renameCurrentTab(project.name));
      // Ensure to request projects again
      invalidateProjectsQuery();
    }
  }

  // Build preview text
  project.short_text = getProjectShortText(project.text);

  try {
    const response = await AiWriterAPI.update(project.id, aiWriterProjectToApi(project));

    if (response.status) {
      dispatch(updateProjectInBackground.success(aiWriterProjectFromApi(response.data)));
    } else {
      const errorId = handleCommonApiError(response.data.message);

      Toast.backendError(errorId);
      dispatch(updateProjectInBackground.failure());
    }
    dispatch(setIsSaving(false));
  } catch (e) {
    Toast.apiError();
  }
}
