import { AxiosInstance } from 'axios';
import { create } from 'zustand';

type DataScienceClientStore = {
  client: AxiosInstance | null;
};

export const dataScienceClientStore = create<DataScienceClientStore>(() => ({
  client: null
}));
