import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Toast from 'components/toasts/Toast';
import { delayExecution } from 'utils/utils';

const isRateLimitError = (response: AxiosResponse<unknown> | undefined) => response?.status === 429;

const getRetryAfterSeconds = (response: AxiosResponse<unknown> | undefined) => {
  const delay = response?.headers['retry-after'];
  if (!delay) {
    return null;
  }

  const delayInSeconds = Number(delay);
  if (Number.isNaN(delayInSeconds)) {
    return null;
  }

  return delayInSeconds;
};

export const withRateLimitSupport = (client: AxiosInstance) => {
  client.interceptors.response.use(undefined, async (error: unknown) => {
    if (!axios.isAxiosError(error) || !isRateLimitError(error.response)) {
      throw error;
    }

    Toast.error('rate_limit.error_general');

    const delayInSeconds = getRetryAfterSeconds(error.response);
    if (delayInSeconds === null) {
      throw error;
    }

    // Wait for the rate limit to reset
    await delayExecution(delayInSeconds * 1000);

    // Retry request
    return client(error.config as AxiosRequestConfig);
  });
};
