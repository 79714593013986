import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { TemplateSharingPermission } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';

export type EditTemplateParams = {
  title: string;
  description: string;
  template: string;
  category_id: number;
  sharing_permission: TemplateSharingPermission;
  commandTemplateId: number;
  language?: string;
  country?: string;
};

export const httpEditTemplate = mutationEndpoint({
  callEndpoint: ({
    commandTemplateId,
    title,
    description,
    template,
    sharing_permission,
    category_id,
    language,
    country
  }: EditTemplateParams) =>
    backofficeClient
      .put(`text-generation/command-templates/${commandTemplateId}`, {
        title,
        description,
        template,
        sharing_permission,
        category_id,
        language,
        country
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        const errorCode = getErrorCodeFromAxiosError(error);
        if (errorCode) {
          Toast.backendError(handleCommonApiError(errorCode));
        }
      })
});
