import { ApiCommonErrorCode, ApiExtendedResponse } from '../types';
import AudienceAPI from '.';

export type AudienceDomain = 'audience';

export type AudienceApiEndpoint = keyof typeof AudienceAPI;

export type AudienceErrorCode =
  | 'ERROR_CANT_EDIT_AUDIENCES'
  | 'ERROR_CAN_ONLY_DELETE_AUDIENCES'
  | 'ERROR_AUDIENCE_NOT_FOUND'
  | 'ERROR_AUDIENCE_ACCESS_DENIED';

export type GetAllAudiencesResponse = ApiExtendedResponse<Audience[], ApiCommonErrorCode>;

export type AudienceCommon = {
  id: number;
  name: string;
  model_id?: string;
  country?: string;
  language?: string;
};

export type ValidAudienceModel = AudienceCommon & {
  model_id: string;
  language: string;
  country: string;
};

export type NewAudience = {
  name: string;
};

export type Audience = AudienceCommon & {
  is_generic: boolean;
};

export const isValidAudienceModel = (audience?: AudienceCommon): audience is ValidAudienceModel => {
  return !!audience && !!audience.language && !!audience.country;
};
