import {
  CampaignApiEndpoint,
  DeleteCampaignError,
  UpdateCampaignError
} from 'services/api/campaign/types';
import { ApiErrors } from 'services/api/types';
import { createApiErrorMessageHandler } from 'services/api/utils/createApiErrorMessageHandler';
import mkApiErrorMessage from 'services/api/utils/mkApiErrorMessage';

const mkCampaignApiErrors = (
  endpoint: CampaignApiEndpoint,
  errorCode: UpdateCampaignError | DeleteCampaignError
) => mkApiErrorMessage('campaign', endpoint, errorCode);

const updateCampaignErrors: ApiErrors<UpdateCampaignError> = {
  ERROR_CANT_EDIT_CAMPAIGN: mkCampaignApiErrors('update', 'ERROR_CANT_EDIT_CAMPAIGN')
};

const deleteCampaignErrors: ApiErrors<DeleteCampaignError> = {
  ERROR_CANT_EDIT_CAMPAIGN: mkCampaignApiErrors('delete', 'ERROR_CANT_EDIT_CAMPAIGN')
};

export const handleUpdateCampaignErrors = createApiErrorMessageHandler(updateCampaignErrors);
export const handleDeleteCampaignErrors = createApiErrorMessageHandler(deleteCampaignErrors);
