import { HelpOutlineRounded } from '@mui/icons-material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import UndoIcon from '@mui/icons-material/Undo';
import { Button, FormControl, InputLabel } from '@mui/material';
import { ExtendedPromptInput } from 'components/ExtendedPromptInput';
import FlexContainer from 'components/FlexContainer';
import { ImageQualitySelect } from 'features/aiImages/AiImagesPage/ImageQualitySelect';
import { NumberOfImagesSelect } from 'features/aiImages/AiImagesPage/NumberOfImagesSelect';
import { OptimizeButton } from 'features/aiImages/AiImagesPage/OptimizeButton';
import { ResolutionSelect } from 'features/aiImages/AiImagesPage/ResolutionSelect';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ChangeEvent } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';
import useTr from 'utils/hooks/useTr';

type Props = {
  handleTextChange: (text: string) => void;
  handleExampleButtonClick: () => void;
  optimizedPrompt: string | undefined;
  handleUndoClick: () => void;
  isImagesFetching: boolean;
  handleGenerateButtonClick: () => void;
  prompt: string;
};

export const GeneratorFormContent = ({
  handleTextChange,
  handleExampleButtonClick,
  optimizedPrompt,
  handleUndoClick,
  isImagesFetching,
  handleGenerateButtonClick,
  prompt
}: Props) => {
  const translate = useTr();

  const handleLearnPromptClick = () => {
    window.open(translate('ai_images.generator_page.learn_prompt.link'), '_blank');
  };

  return (
    <Root>
      <ExtendedPromptInput
        value={prompt}
        placeholder={translate('ai_images.generator_page.prompt_field.placeholder')}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleTextChange(event.target.value)}
        id={gtmIds.aiImages.generatorPage.promptInput}
        autoFocus={true}
        actions={
          <>
            <OptimizeButton size="small" />
            <Separator />
            <Button
              id={gtmIds.aiImages.generatorPage.exampleButton}
              onClick={handleExampleButtonClick}
              startIcon={<PrimaryLightbulb />}
              size="small"
            >
              {translate('ai_images.generator_page.example_button.label')}
            </Button>
          </>
        }
        footer={optimizedPrompt && <StyledUndoIcon onClick={handleUndoClick} />}
      />
      <FlexContainer alignItems="flex-end">
        <Button
          variant="text"
          startIcon={<HelpOutlineRounded />}
          onClick={handleLearnPromptClick}
          size="small"
        >
          <FormattedMessage id="ai_images.generator_page.learn_prompt.button" />
        </Button>
      </FlexContainer>
      <StyledButtonsContainer>
        <FormControl>
          <InputLabel>
            <FormattedMessage id="ai_images.generator_page.quality.label" />
          </InputLabel>

          <ImageQualitySelect
            onQualityChange={quality => GAEvents.sidebarImageQualityClicked({ quality })}
          />
        </FormControl>

        <FormControl>
          <InputLabel>
            <FormattedMessage id="ai_images.generator_page.images.ratio_size" />
          </InputLabel>

          <ResolutionSelect />
        </FormControl>

        <FormControl>
          <InputLabel>
            <FormattedMessage id="ai_images.generator_page.images.label" />
          </InputLabel>

          <NumberOfImagesSelect />
        </FormControl>

        <GenerateButton
          id={gtmIds.aiImages.generatorPage.generateButton}
          variant="contained"
          disabled={!prompt || isImagesFetching}
          onClick={handleGenerateButtonClick}
          size="large"
        >
          {translate('ai_images.generator_page.generate_button.label')}
        </GenerateButton>
      </StyledButtonsContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.two};

  margin-top: ${({ theme }) => theme.spacings.three};

  > * {
    flex: 1;
  }

  @media (max-width: ${mobileWindowWidthCSS}) {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
`;

const GenerateButton = styled(Button)``;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.inputBackground};
  height: 19px;
  width: 1px;
  margin: 0 5px;
`;

const StyledUndoIcon = styled(UndoIcon)`
  color: ${({ theme }) => theme.colors.blackInactive};
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  z-index: 1;
  width: 18px;
`;

const PrimaryLightbulb = styled(LightbulbIcon)`
  color: ${({ theme }) => theme.colors.primaryColorMain};
`;
