import { getGroupId, getUserName } from 'features/customer/store/selectors';
import { getGroupMembersList } from 'features/group/store/selectors';
import { GroupMember } from 'features/group/store/types';
import {
  handleDeleteCampaignErrors,
  handleUpdateCampaignErrors
} from 'services/api/campaign/errors';
import {
  CreateCampaignResponse,
  DeleteCampaignError,
  DeleteCampaignResponse,
  GetAllCampaignsResponse,
  NewCampaignRequestPayload,
  UpdateCampaignRequestPayload,
  UpdateCampaignResponse
} from 'services/api/campaign/types';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { reportErrors } from 'utils/reportErrors';
import { keysToCamel } from 'utils/utils';

import Toast from '../../../components/toasts/Toast';
import CampaignAPI from '../../../services/api/campaign';
import { createCampaign, deleteCampaigns, getAllCampaigns, updateCampaign } from './slice';
import { Campaign } from './types';
import { prepareUserName } from './utils';

function* getAllCampaignsSaga() {
  try {
    const response: GetAllCampaignsResponse = yield* call(CampaignAPI.getAll);

    if (response.status) {
      const groupId = yield* select(getGroupId);
      const userName: string = yield* select(getUserName);
      const teamMembersList: GroupMember[] = yield* select(getGroupMembersList);
      const teamMembersDict = teamMembersList.reduce<{ [id: number]: string }>(
        (membersDict, { id, name }) => {
          membersDict[id] = name;
          return membersDict;
        },
        {}
      );

      if (groupId) {
        const campaigns = response.data.map(
          ({
            subject_lines_ratings,
            deleted_subject_lines_ratings: deletedSubjectLinesRatings,
            customer_id,
            ...rest
          }) => {
            return {
              subjectLinesRatings: JSON.parse(subject_lines_ratings),
              deletedSubjectLinesRatings: deletedSubjectLinesRatings
                ? JSON.parse(deletedSubjectLinesRatings)
                : [],
              user: prepareUserName(teamMembersDict, groupId, customer_id, userName),
              customerId: customer_id,
              ...keysToCamel(rest)
            };
          }
        ) as Campaign[];
        yield* put(getAllCampaigns.success(campaigns));
        return;
      }
    } else {
      Toast.backendError(handleCommonApiError(response.data.message));
    }

    yield* put(getAllCampaigns.failure());
  } catch (error) {
    yield* put(getAllCampaigns.failure());
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* createCampaignSaga({
  payload: { subjectLinesRatings, deletedSubjectLinesRatings, keywords, emailBody, ...rest }
}: ReturnType<typeof createCampaign.request>) {
  const groupId: number | null = yield* select(getGroupId);

  try {
    let requestPayload: NewCampaignRequestPayload = {
      ...rest,
      keywords: keywords || undefined,
      emailBody: emailBody || undefined,
      deletedSubjectLinesRatings: JSON.stringify(deletedSubjectLinesRatings),
      subjectLinesRatings: JSON.stringify(subjectLinesRatings)
    };

    if (groupId) {
      requestPayload = { ...requestPayload, groupId };
    }

    const response: CreateCampaignResponse = yield* call(CampaignAPI.create, requestPayload);

    if (response.status) {
      const userName: string = yield* select(getUserName);
      const { subject_lines_ratings, ...rest } = response.data;
      const createdCampaign = {
        subjectLinesRatings: JSON.parse(subject_lines_ratings),
        user: userName,
        ...keysToCamel(rest)
      } as Campaign;
      yield* put(createCampaign.success(createdCampaign));
    } else {
      yield* put(createCampaign.failure());
      Toast.backendError(handleCommonApiError(response.data.message));
    }
  } catch (error) {
    yield* put(createCampaign.failure());
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* updateCampaignSaga({
  payload: {
    subjectLinesRatings,
    deletedSubjectLinesRatings,
    id,
    groupId,
    keywords,
    emailBody,
    ...rest
  }
}: ReturnType<typeof updateCampaign.request>) {
  try {
    let requestPayload: UpdateCampaignRequestPayload = {
      ...rest,
      emailBody: emailBody || undefined,
      keywords: keywords || undefined,
      deletedSubjectLinesRatings: JSON.stringify(deletedSubjectLinesRatings),
      subjectLinesRatings: JSON.stringify(subjectLinesRatings)
    };

    if (groupId) {
      requestPayload = { ...requestPayload, groupId };
    }

    const response: UpdateCampaignResponse = yield* call(CampaignAPI.update, id, requestPayload);
    if (response.status) {
      const { subject_lines_ratings, ...rest } = response.data;
      const updatedCampaign = {
        subjectLinesRatings: JSON.parse(subject_lines_ratings),
        ...keysToCamel(rest)
      } as Campaign;
      yield* put(updateCampaign.success(updatedCampaign));
    } else {
      yield* put(updateCampaign.failure());
      Toast.backendError(handleUpdateCampaignErrors(response.data.message));
    }
  } catch (error) {
    yield* put(updateCampaign.failure());
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

function* deleteCampaignSaga({ payload }: ReturnType<typeof deleteCampaigns.request>) {
  try {
    const deleteRequests = payload.map(id => {
      return call(CampaignAPI.delete, id);
    });
    const responses: DeleteCampaignResponse[] = yield* all(deleteRequests);
    if (responses.every(response => response.status)) {
      yield* put(deleteCampaigns.success(payload));
      Toast.info('subject_lines.campaigns.delete.message');
    } else {
      yield* put(deleteCampaigns.failure());

      const errorResponse = responses.find(r => !r.status);

      if (errorResponse?.status === false) {
        Toast.backendError(
          handleDeleteCampaignErrors(errorResponse.data.message as DeleteCampaignError)
        );
      }
    }
  } catch (error) {
    yield* put(deleteCampaigns.failure());
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

export const subjectLinesSagas = [
  takeLatest(getAllCampaigns.request.toString(), getAllCampaignsSaga),
  takeLatest(createCampaign.request.toString(), createCampaignSaga),
  takeLatest(updateCampaign.request.toString(), updateCampaignSaga),
  takeLatest(deleteCampaigns.request.toString(), deleteCampaignSaga)
];
