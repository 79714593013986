import Toast from 'components/toasts/Toast';
import useAudienceConfig from 'features/audiences/hooks/useAudienceConfig';
import { getGetEmbeddingModelLanguageAndCountryById } from 'features/embeddingModels/store/selectors';
import { scoreSubjectLines } from 'features/subjectLines/utils/scoreSubjectLines';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { generatedTextsFromGenerateTextOutputEntries } from 'features/textGenerator/utils/generateTextEntryMapper';
import { useGetNTimesByOutputTypeCached } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useCallback } from 'react';
import { useAppSelector } from 'store/hooks';

export const useGenerateSubjectLines = ({ audienceId }: { audienceId: number }) => {
  const getEmbeddingModelLanguageAndCountryById = useAppSelector(
    getGetEmbeddingModelLanguageAndCountryById
  );
  const { model, audienceModelId } = useAudienceConfig({ audienceId });

  const { language: modelLanguage, country: modelCountry } =
    getEmbeddingModelLanguageAndCountryById(model.id);

  const outputType = 'subject_lines';
  const nTimes = useGetNTimesByOutputTypeCached(modelLanguage, modelCountry, outputType);

  return useCallback(
    async ({
      emailBody,
      keywords,
      suggestions
    }: {
      emailBody?: string;
      keywords?: string;
      suggestions?: string[];
    }) => {
      try {
        const { outputs } = await generateTexts(
          { audienceModelId, outputType, nTimes },
          {
            text: emailBody,
            keywords,
            suggestions
          }
        );

        const scoredSubjectLines = await scoreSubjectLines({
          inspirations: generatedTextsFromGenerateTextOutputEntries(outputs),
          embeddingModelId: model.id,
          audienceModelId
        });

        return scoredSubjectLines;
      } catch (e) {
        Toast.apiError();
        return Promise.reject();
      }
    },
    [model, audienceModelId, nTimes]
  );
};
