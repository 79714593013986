import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Fab, Popper, Typography } from '@mui/material';
import { wiggleAnimation } from 'components/tour/utils/wiggleAnimation';
import { getRoutePath } from 'config/routes';
import { useGlobalConfettiStore } from 'features/global-confetti/useGlobalConfettiStore';
import { OnboardingChecklist } from 'features/onboardingJourney/checklist/OnboardingChecklist';
import { useFetchOnboardingChecklist } from 'features/onboardingJourney/checklist/useFetchOnboardingChecklist';
import { useOnboardingChecklistStore } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { useCurrentRoutePath } from 'utils/hooks/useCurrentRoutePath';
import { mobileWindowWidth } from 'utils/hooks/useMobileVersion';
import useWindowSize from 'utils/hooks/useWindowSize';

export type TaskPropsItem = {
  title: string;
  tooltipMessage: string;
  handleAction: () => void;
};

const forbiddenPaths = [
  getRoutePath('onboardingWelcome'),
  getRoutePath('onboardingSurvey'),
  getRoutePath('onboardingUsecase'),
  getRoutePath('socialRegister')
];

export const useShouldShowOnboardingChecklist = () => {
  const { isLoading, isError } = useFetchOnboardingChecklist();
  const isViewable = useOnboardingChecklistStore(state => state.isViewable);
  const pathname = useCurrentRoutePath();
  const { width } = useWindowSize();
  const isMobile = width && width < mobileWindowWidth;

  const isOnForbiddenPath = forbiddenPaths.includes(pathname);

  return !isError && !isLoading && isViewable && !isOnForbiddenPath && !isMobile;
};

export function ChecklistButton() {
  const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const shouldShowOnboardingChecklist = useShouldShowOnboardingChecklist();

  const finishedTasksCount = useOnboardingChecklistStore(state => state.finishedTaskCount);
  const taskCount = useOnboardingChecklistStore(state => state.taskCount);
  const isWiggling = useOnboardingChecklistStore(state => state.isWiggling);
  const isChecklistComplete = useOnboardingChecklistStore(state => state.isChecklistComplete);
  const isChecklistViewable = useOnboardingChecklistStore(state => state.isViewable);

  const setIsConfettiActive = useGlobalConfettiStore(state => state.setIsActive);
  const setConfettiSourceElement = useGlobalConfettiStore(state => state.setSourceElement);
  const setIsViewable = useOnboardingChecklistStore(state => state.setIsViewable);

  useEffect(() => {
    if (isChecklistComplete) {
      setPopperAnchorEl(buttonRef.current);

      // isChecklistComplete is true by default, therefore we need a second check
      if (isChecklistViewable) {
        setIsConfettiActive(true);
      }
    }
  }, [isChecklistComplete, setConfettiSourceElement, setIsConfettiActive, isChecklistViewable]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setPopperAnchorEl(null);
    setIsViewable(false);
  };

  const isPopperOpen = Boolean(popperAnchorEl);

  if (!shouldShowOnboardingChecklist) {
    return null;
  }

  return (
    <>
      <ActionButton
        {...withGtmInteraction(gtmIds.onboardingJourney.checklist.open)}
        ref={buttonRef}
        variant="extended"
        onClick={handleClick}
        $isWiggling={isWiggling}
      >
        <ButtonText>
          <FormattedMessage
            id="onboarding_checklist.button.text"
            values={{ step: `(${finishedTasksCount}/${taskCount})` }}
          />
        </ButtonText>
        <RocketLaunchIcon />
      </ActionButton>

      <Popper
        open={isPopperOpen}
        anchorEl={popperAnchorEl}
        placement="top-start"
        sx={{
          // We need a z-index to prevent overlapping by language tool highlight or input labels
          zIndex: 1
        }}
      >
        <OnboardingChecklist onClose={handleClose} />
      </Popper>
    </>
  );
}

const ActionButton = styled(Fab)<{ $isWiggling?: boolean }>`
  z-index: 99;
  position: fixed;
  bottom: 1.75rem;
  left: 5rem;
  height: 60px;

  display: flex;
  gap: ${({ theme }) => theme.spacings.two};

  background-color: ${({ theme }) => theme.colors.commonBrandColorsBrandOrange};
  color: white;

  text-transform: none;

  ${props => props.$isWiggling && wiggleAnimation};
  box-shadow: ${({ theme }) => theme.shadow.elevation6};
  &:hover {
    background-color: ${({ theme }) => theme.colors.commonBrandColorsBrandOrange};
  }
`;

const ButtonText = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.button.large.fontSize};
  font-weight: ${({ theme }) => theme.typography.button.large.fontWeight};
`;
