import { AudiencesState } from 'features/audiences/store/types';
import getAudienceModelIdById from 'features/audiences/utils/getAudienceModelIdById';
import { GenerateTextConfig } from 'features/textGenerator/store/types';

const getAudienceModelFromConfig = (
  state: AudiencesState,
  config: GenerateTextConfig | undefined
) => {
  if (config) {
    return getAudienceModelIdById(state, config.audienceId);
  }

  return undefined;
};

export default getAudienceModelFromConfig;
