/**
 * See https://docs.churnkey.co/installing-churnkey for more information
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'components/toasts/Toast';
import { appId } from 'features/churnKey/ChurnKeyLoader';
import { hideChurnKeyPauseWall, isOngoingSubscriptionPause } from 'features/churnKey/churnkeySlice';
import { logout } from 'features/customer/store/actions';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { useEffect, useState } from 'react';
import { httpGetChurnKeyHash } from 'services/backofficeIntegration/http/endpoints/customer/httpGetChurnKeyHash';
import { httpResumePausedSubscription } from 'services/backofficeIntegration/http/endpoints/customer/httpResumePausedSubscription';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isNotProd } from 'utils/isNotProd';

export const useChurnKeyPauseWall = () => {
  const dispatch = useAppDispatch();
  const { stripeId: stripeCustomerId } = useAppSelector(state => state.customer);
  const language = useAppSelector(getCurrentLanguage);
  const isSubscriptionPause = useAppSelector(isOngoingSubscriptionPause);
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const { mutate: resumePausedSub } = useMutation({
    mutationFn: () => httpResumePausedSubscription.callEndpoint(),
    onSuccess: () => {
      dispatch(hideChurnKeyPauseWall());
      location.reload(); // reload page to reload everything properly
    }
  });

  const {
    data: churnKeyHash,
    isLoading: isLoadingChurnKeyHash,
    error
  } = useQuery({
    enabled: !!stripeCustomerId && isSubscriptionPause,
    queryKey: httpGetChurnKeyHash.makeQueryKey(),
    queryFn: () => httpGetChurnKeyHash.callEndpoint()
  });

  useEffect(() => {
    if (!shouldShowModal || isLoadingChurnKeyHash) {
      return;
    }

    if (!churnKeyHash || error || !stripeCustomerId) {
      toast.commonError();
      return;
    }

    // #tech-debt: https://neuroflash.atlassian.net/browse/NAPP-559?atlOrigin=eyJpIjoiYjNiOWI1OGY2MWMwNDA0OGExMzc5MWM4NmU3OTc3MTUiLCJwIjoiaiJ9
    window.churnkey?.check?.('pause', {
      customerId: stripeCustomerId,
      authHash: churnKeyHash,
      appId,
      mode: isNotProd() ? 'test' : 'live',
      provider: 'stripe',
      i18n: {
        lang: language,
        messages: {
          de: {
            day: 'Tag | Tage',
            month: 'Monat | Monate',
            year: 'Jahr | Jahre'
          }
        }
      },

      onResume: () => resumePausedSub(),
      handleLogout: () => {
        dispatch(logout.request());
        window.churnkey.hidePauseWall();
      }
    });
  }, [
    churnKeyHash,
    stripeCustomerId,
    isLoadingChurnKeyHash,
    error,
    language,
    shouldShowModal,
    resumePausedSub,
    dispatch
  ]);

  useEffect(() => {
    if (isSubscriptionPause) {
      setShouldShowModal(true);
    }
  }, [isSubscriptionPause]);
};
