import { CustomerErrorCode } from 'services/api/customer/types';
import { ApiErrors } from 'services/api/types';
import { createApiErrorMessageHandler } from 'services/api/utils/createApiErrorMessageHandler';
import mkApiErrorMessage from 'services/api/utils/mkApiErrorMessage';

const createLoginErrorMessage = (errorCode: CustomerErrorCode) =>
  mkApiErrorMessage('customer', 'logIn', errorCode);

const createCheckoutErrorMessage = (errorCode: CustomerErrorCode) =>
  mkApiErrorMessage('customer', 'createCheckout', errorCode);

const customerErrorMessages: ApiErrors<CustomerErrorCode> = {
  ERROR_WRONG_EMAIL: createLoginErrorMessage('ERROR_WRONG_EMAIL'),
  ERROR_UNAUTHORIZED: createLoginErrorMessage('ERROR_UNAUTHORIZED'),
  ERROR_CUSTOMER_IS_NOT_ACTIVE: createLoginErrorMessage('ERROR_CUSTOMER_IS_NOT_ACTIVE'),
  ERROR_CUSTOMER_NOT_VERIFIED: createLoginErrorMessage('ERROR_CUSTOMER_NOT_VERIFIED'),
  ERROR_CUSTOMER_DISABLED: createLoginErrorMessage('ERROR_CUSTOMER_DISABLED'),
  ERROR_CUSTOMER_ALREADY_LOGGED_IN: createLoginErrorMessage('ERROR_CUSTOMER_ALREADY_LOGGED_IN'),
  ERROR_WRONG_SESSION: createLoginErrorMessage('ERROR_WRONG_SESSION'),
  ERROR_AUTH: createLoginErrorMessage('ERROR_AUTH'),

  // An older token, no longer thrown but kept for backwards compatibility
  ERROR_INITIALIZATION_ACCOUNT_ACTIVITY: createCheckoutErrorMessage(
    'ERROR_INITIALIZATION_ACCOUNT_ACTIVITY'
  ),
  ERROR_NOT_VALID_ACCOUNT_ACTIVITY: createCheckoutErrorMessage('ERROR_NOT_VALID_ACCOUNT_ACTIVITY'),
  ERROR_SUSPICIOUS_ACCOUNT_ACTIVITY: createCheckoutErrorMessage('ERROR_SUSPICIOUS_ACCOUNT_ACTIVITY')
};

export const handleCustomerError = createApiErrorMessageHandler(customerErrorMessages);
