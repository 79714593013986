import FlexContainer from 'components/FlexContainer';
import { EditorContinueWritingButton } from 'features/aiWriter/AiWriterTextEditor/EditorContinueWritingButton';
import styled from 'styled-components';

export const EditorBottomToolbar = () => {
  return (
    <Root>
      <EditorContinueWritingButton />
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({ direction: 'row', gap: 'one' })`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  // this value allows us to cover the options menu coming from image ("edit link" and "delete")
  z-index: 1001;
`;
