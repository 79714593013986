import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export const httpDeleteTemplate = mutationEndpoint({
  callEndpoint: ({ commandTemplateId }: { commandTemplateId: number }) =>
    backofficeClient
      .delete(`/text-generation/command-templates/${commandTemplateId}`)
      .then(response => {
        return response;
      })
      .catch(err => {
        const errorCode = getErrorCodeFromAxiosError(err);
        if (errorCode) {
          Toast.error(handleCommonApiError(errorCode));
        }
      })
});
