import filterWordAttributes from 'features/aiTester/store/utils/filters/filterWordAttributes';
import { getExtendWithScoreByOpenRate } from 'features/aiTester/store/utils/getters/getExtendWithScoreByOpenRate';
import prepareScoreTextConfig from 'features/aiTester/store/utils/preparators/prepareScoreTextConfig';
import getAudienceModelIdById from 'features/audiences/utils/getAudienceModelIdById';
import { scoreTexts as scoreGeneratedTexts } from 'features/textGenerator/actions/scoreTexts';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

import { rescoreSuggestions } from '../actions';

export const rescoreSuggestionsThunk = (): AppThunk<void> => async (dispatch, getState) => {
  const state = getState();
  const { tester: testerState, audiences: audiencesState } = state;
  const currentTab = getCurrentTab(testerState);
  if (!currentTab) {
    return;
  }

  const {
    id: tabId,
    embeddingModelId,
    mapperId,
    wordAttributes,
    dimensions,
    manualDimensions,
    suggestions,
    generateTextConfig
  } = currentTab;

  const extendWithScoreByOpenRate = getExtendWithScoreByOpenRate({ state });
  const audienceModelId = getAudienceModelIdById(audiencesState, generateTextConfig?.audienceId);
  const filteredWordAttributes = filterWordAttributes(wordAttributes, ['model_rank_score']);

  const { goalConfig, apiDimensions, mappers } = prepareScoreTextConfig(
    {
      audienceModelId,
      mapperId,
      wordAttributes: filteredWordAttributes,
      dimensions,
      manualDimensions
    },
    { extendWithScoreByOpenRate }
  );

  if (suggestions.length > 0) {
    dispatch(rescoreSuggestions.request());

    try {
      const scoredTexts = await scoreGeneratedTexts(
        { embeddingModelId: embeddingModelId, inspirations: suggestions },
        { goalConfig, dimensions: apiDimensions, mappers }
      );

      dispatch(rescoreSuggestions.success({ scoredTexts, tabId }));
    } catch (e) {
      dispatch(rescoreSuggestions.failure());
    }
  }
};
