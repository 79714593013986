import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';

export const defaultError = 'app.api_error';

export const createApiErrorMessageHandler =
  <T extends string>(errorCodes: Record<T, string | undefined>) =>
  (errorCode: T | undefined): string => {
    if (!errorCode) {
      return defaultError;
    }

    if (errorCode in errorCodes) {
      return errorCodes[errorCode] || defaultError;
    }

    return handleCommonApiError(errorCode);
  };
