import { refreshSuggestions } from 'features/aiTester/store/actions/suggestions/actions';
import { RefreshTextSuggestionsRequestPayload } from 'features/aiTester/store/actions/suggestions/types';
import filterWordAttributes from 'features/aiTester/store/utils/filters/filterWordAttributes';
import { getExtendWithScoreByOpenRate } from 'features/aiTester/store/utils/getters/getExtendWithScoreByOpenRate';
import prepareScoreTextConfig from 'features/aiTester/store/utils/preparators/prepareScoreTextConfig';
import { getGetAudienceById } from 'features/audiences/store/selectors';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { scoreTexts as scoreGeneratedTexts } from 'features/textGenerator/actions/scoreTexts';
import { generatedTextsFromGenerateTextOutputEntries } from 'features/textGenerator/utils/generateTextEntryMapper';
import { fetchPromptConfigNTimes } from 'features/textGenerator/utils/useGetPromptsByCountryAndLanguage';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { isValidAudienceModel } from 'services/api/audience/types';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

// The expected output type to use for generating suggestions
// Note: was "variations_s_m" before
const outputType = 'rewrite_s_m';

export const refreshSuggestionsThunk =
  (payload: RefreshTextSuggestionsRequestPayload): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const { tester: testerState } = getState();
    const currentTab = getCurrentTab(testerState);

    if (!currentTab) {
      return;
    }

    const {
      embeddingModelId,
      mapperId,
      generateTextConfig,
      id: tabId,
      wordAttributes,
      dimensions,
      manualDimensions,
      texts,
      suggestions
    } = currentTab;

    if (!generateTextConfig) {
      return;
    }

    const { audienceId } = generateTextConfig;
    const extendWithScoreByOpenRate = getExtendWithScoreByOpenRate({ state });
    const audienceModel = getGetAudienceById(state)(audienceId);
    const filteredWordAttributes = filterWordAttributes(wordAttributes, ['model_rank_score']);
    const { goalConfig, apiDimensions, mappers } = prepareScoreTextConfig(
      {
        audienceModelId: audienceModel?.model_id,
        mapperId,
        wordAttributes: filteredWordAttributes,
        dimensions,
        manualDimensions
      },
      { extendWithScoreByOpenRate }
    );
    const generatedText =
      payload.source === 'suggestions'
        ? suggestions.find(t => t.id === payload.id)
        : texts.find(t => t.id === payload.id);

    if (!isValidAudienceModel(audienceModel) || !generatedText) {
      return;
    }

    trackingWrapper.track(
      payload.source === 'suggestions' ? 'aiTesterMoreFromSuggestion' : 'aiTesterMoreFromText',
      {
        documentId: tabId,
        text: generatedText.text,
        textItemId: generatedText.textItemId,
        generationId: generatedText.generationId,
        audienceModelId: audienceModel.model_id
      }
    );

    dispatch(refreshSuggestions.request(payload));

    const nTimes = await fetchPromptConfigNTimes(
      audienceModel.language,
      audienceModel.country,
      outputType
    );

    try {
      const { outputs } = await generateTexts(
        { audienceModelId: audienceModel.model_id, outputType, nTimes },
        { text: generatedText.text }
      );

      const scoredTexts = await scoreGeneratedTexts(
        {
          embeddingModelId: embeddingModelId,
          audienceModelId: audienceModel.model_id,
          inspirations: generatedTextsFromGenerateTextOutputEntries(outputs)
        },
        { goalConfig, dimensions: apiDimensions, mappers }
      );

      dispatch(
        refreshSuggestions.success({
          tabId,
          textId: payload.id,
          source: payload.source,
          suggestions: scoredTexts
        })
      );
    } catch (e) {
      dispatch(refreshSuggestions.failure({ tabId, id: payload.id, source: payload.source }));
    }
  };
