import { useQuery } from '@tanstack/react-query';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { promptFromApi } from 'features/textGenerator/store/promptMapper';
import { Prompt } from 'features/textGenerator/store/types';
import { httpGetPromptsByLanguageCountry } from 'services/backofficeIntegration/http/endpoints/textGeneration/httpGetPromptsByLanguageCountry';
import { useAppSelector } from 'store/hooks';
import { create } from 'zustand';

export type LanguageCountryKey = string;

// TODO: Centralize the store of prompts somewhere else
type PromptsStoreState = {
  prompts: Record<LanguageCountryKey, Prompt[]>;
};

type PromptsStoreActions = {
  setPrompts: (key: LanguageCountryKey, prompts: Prompt[]) => void;
};

const usePromptsStore = create<PromptsStoreState & PromptsStoreActions>((set, get) => ({
  prompts: {},
  setPrompts: (key, prompts) => {
    set({ prompts: { ...get().prompts, [key]: prompts } });
  }
}));

const createCacheKey = (language: string, country: string): LanguageCountryKey =>
  `${language}_${country}`;

export const useGetPromptsByCountryAndLanguage = (language: string, country: string) => {
  const currentStoredPrompts = usePromptsStore(state => state.prompts);
  const updateStorePrompts = usePromptsStore(state => state.setPrompts);
  const countryAndLanguage = createCacheKey(language, country);

  const isMissingCacheForKey =
    !currentStoredPrompts[countryAndLanguage] ||
    currentStoredPrompts[countryAndLanguage].length === 0;

  const { data } = useQuery({
    enabled: isMissingCacheForKey,
    queryKey: httpGetPromptsByLanguageCountry.makeQueryKey(language, country),
    queryFn: () =>
      httpGetPromptsByLanguageCountry
        .callEndpoint(language, country)
        .then(res => res.map(promptFromApi)),
    onSuccess: data => {
      updateStorePrompts(countryAndLanguage, data);
    },
    staleTime: Infinity
  });

  const fetchedPrompts = data ?? [];
  if (isMissingCacheForKey) {
    return fetchedPrompts;
  }

  return currentStoredPrompts[countryAndLanguage] ?? [];
};

export const useIsOutputTypeSupported = (outputType: string) => {
  const { currentModelCountry, currentModelLanguage } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const prompts = useGetPromptsByCountryAndLanguage(currentModelLanguage, currentModelCountry);

  return !!prompts.find(p => p.outputType.id === outputType);
};

export const fetchAndCachePromptsByCountryAndLanguage = async (
  language: string,
  country: string
) => {
  const currentStoredPrompts = usePromptsStore.getState().prompts;
  const updateStorePrompts = usePromptsStore.getState().setPrompts;
  const countryAndLanguage = createCacheKey(language, country);

  if (currentStoredPrompts[countryAndLanguage]) {
    return currentStoredPrompts[countryAndLanguage];
  }

  const fetchedPrompts = await httpGetPromptsByLanguageCountry
    .callEndpoint(language, country)
    .then(res => res.map(promptFromApi));

  updateStorePrompts(countryAndLanguage, fetchedPrompts);

  return fetchedPrompts;
};

export const fetchPromptConfigNTimes = (language: string, country: string, outputType: string) =>
  fetchAndCachePromptsByCountryAndLanguage(language, country)
    .then(prompts => prompts.find(p => p.outputType.id === outputType))
    .then(promptConfig => promptConfig?.nTimes ?? 1);
