import { logReactError } from 'features/logging/logReactError';
import { ErrorFallback } from 'features/react-error-boundary/ErrorFallback';
import { isAutomaticPageTranslationActive } from 'features/react-error-boundary/utils/isAutomaticPageTranslationActive';
import { Component, ErrorInfo, ReactNode } from 'react';

const GeneralErrorFallback = () => <ErrorFallback message="error_boundary.error.general" />;
const TranslationErrorFallback = () => (
  <ErrorFallback message="error_boundary.error.automatic_page_translation" />
);

type Props = {
  children?: ReactNode;
};

type State = {
  hasError: boolean;
};

const initialState: State = {
  hasError: false
};

export class ErrorBoundary extends Component<Props, State> {
  public state: State;

  public constructor(props: Props) {
    super(props);

    this.state = initialState;
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  public componentDidCatch(error: unknown, errorInfo: ErrorInfo): void {
    logReactError(error, errorInfo);
  }

  public render(): ReactNode {
    const { children } = this.props;
    const state = this.state;

    if (state.hasError) {
      // Render a specific error message if automatic page translation is active
      if (isAutomaticPageTranslationActive()) {
        return <TranslationErrorFallback />;
      }

      return <GeneralErrorFallback />;
    }

    return children;
  }
}
