import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type FavoriteTemplate = {
  templateId: number;
};

export const httpFavoriteTemplate = mutationEndpoint({
  callEndpoint: (params: FavoriteTemplate) =>
    backofficeClient
      .post(`/text-generation/command-templates/${params.templateId}/favorites`)
      .then(response => {
        return response;
      })
      .catch(error => {
        const errorCode = getErrorCodeFromAxiosError(error);
        if (errorCode) {
          Toast.backendError(handleCommonApiError(errorCode));
        }
      })
});
