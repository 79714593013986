import { CircularProgress, TextField } from '@mui/material';
import type { Location } from '@remix-run/router';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import { savePersonalityId } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { PERSONALITY_DEFINITION_MAX_WORDS_LIMIT } from 'features/aiWriter/AiWriterSidebar/steps/chat/PersonalityAutomaticCreationView';
import { useCreatePersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useCreatePersonality';
import { invalidatePersonalitiesQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/usePersonalitiesQuery';
import { useSetDefaultChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useSetDefaultPersonality';
import { SharingPermission } from 'features/aiWriter/store/types';
import { fallbackModelCountry } from 'features/aiWriter/store/utils/defaults/fallbackModelCountry';
import { fallbackModelLanguage } from 'features/aiWriter/store/utils/defaults/fallbackModelLanguage';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useCompleteTask } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { OnboardingBrandVoiceTextExtractionLocationState } from 'features/onboardingJourney/OnboardingBrandVoiceCreation';
import { OnboardingFooter } from 'features/onboardingJourney/OnboardingFooter';
import { OnboardingRoot } from 'features/onboardingJourney/OnboardingRoot';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { isObject } from 'lodash';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useLocation, useNavigate } from 'react-router';
import { TASK_TYPE } from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';
import { getWordCount } from 'utils/getWordCount';
import { useTimeout } from 'utils/hooks/useTimeout';
import useTr from 'utils/hooks/useTr';
import useWindowSize from 'utils/hooks/useWindowSize';

const showConfettiSeconds = 2;

export const OnboardingBrandVoiceFinished = () => {
  const tr = useTr();
  const navigate = useNavigate();
  const { state: locationState } =
    useLocation() as Location<OnboardingBrandVoiceTextExtractionLocationState>;
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(true);

  useDisableSidebarOnMount();

  useEffect(() => {
    if (
      !locationState ||
      !isObject(locationState) ||
      !locationState.extractedText ||
      !locationState.audienceLanguage ||
      !locationState.audienceCountry
    ) {
      navigate(getRoutePath('onboardingBrandVoiceCreation'));
    }
  }, [locationState, navigate]);

  useTimeout(() => setShowConfetti(false), showConfettiSeconds * 1000);

  const [personality] = useState(locationState?.extractedText || '...');

  const textWordCount = getWordCount(personality);

  const { mutateAsync: createPersonality, isLoading: creationInProgress } = useCreatePersonality();

  const isFooterButtonDisabled =
    textWordCount > PERSONALITY_DEFINITION_MAX_WORDS_LIMIT || creationInProgress;

  const completePersonalityTask = useCompleteTask(TASK_TYPE.CREATE_PERSONALITY);
  const { mutate: setDefaultPersonality } = useSetDefaultChatPersonality();

  const { data: customerPreferences } = useCustomerPreferences();
  const preferredLanguage = customerPreferences?.preferredLanguage ?? fallbackModelLanguage;
  const preferredCountry = customerPreferences?.preferredLanguageCountry ?? fallbackModelCountry;

  const handleNextClick = async () => {
    GAEvents.onboardingPersonalitySaveClick();

    const creationResult = await createPersonality(
      {
        language: locationState.audienceLanguage ?? preferredLanguage,
        country: locationState.audienceCountry ?? preferredCountry,
        sharing_permission: SharingPermission.private,
        label: tr('onboarding.personality_creation.label.field'),
        description: tr('onboarding.personality_creation.description.field'),
        definition: personality,
        is_active: true
      },
      {
        onError: () => Toast.apiError()
      }
    );

    completePersonalityTask();
    invalidatePersonalitiesQuery();
    savePersonalityId(creationResult.id);
    setDefaultPersonality({
      language: customerPreferences?.preferredLanguage ?? fallbackModelLanguage,
      country: customerPreferences?.preferredLanguageCountry ?? fallbackModelCountry,
      personalityId: creationResult.id
    });

    navigate(getRoutePath('home'));
  };

  const trackResultClick = () => {
    GAEvents.onboardingPersonalityResultInputClick();
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <OnboardingRoot title={tr('onboarding.personality_creation.finished.title')}>
      <PersonalityInput>
        <TextField
          fullWidth={true}
          multiline={true}
          maxRows={16}
          variant="outlined"
          value={personality}
          InputProps={{
            readOnly: true
          }}
          onClick={trackResultClick}
        />
      </PersonalityInput>

      <Confetti width={width} height={height} numberOfPieces={1000} recycle={showConfetti} />

      <OnboardingFooter
        nextButtonProps={{
          onClick: handleNextClick,
          disabled: isFooterButtonDisabled,
          startIcon: creationInProgress ? <CircularProgress size={24} /> : undefined
        }}
        nextButtonChildren={<FormattedMessage id="onboarding.personality_creation.finished.next" />}
        skipButtonProps={
          creationInProgress
            ? undefined
            : {
                onClick: handleBackClick,
                disabled: isFooterButtonDisabled
              }
        }
        skipButtonChildren={<FormattedMessage id="onboarding.personality_creation.finished.back" />}
      />
    </OnboardingRoot>
  );
};

const PersonalityInput = styled(FlexContainer)`
  width: 720px;
`;
