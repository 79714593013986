import { useQuery } from '@tanstack/react-query';
import { httpGetActiveConversation } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetActiveConversation';
import { queryClient } from 'utils/ReactQueryClient';

export function useActiveConversationQuery(props: { projectId: string }) {
  const { projectId } = props;

  return useQuery({
    // Added here for safety, because in error logs this call was done without a projectId
    // and I couldn't find the root cause yet
    enabled: !!projectId,
    queryKey: httpGetActiveConversation.makeQueryKey({ projectId }),
    queryFn: () => httpGetActiveConversation.callEndpoint({ projectId })
  });
}

export function invalidateActiveConversationQuery(props: { projectId: string }) {
  const { projectId } = props;
  const queryKey = httpGetActiveConversation.makeQueryKey({ projectId });
  queryClient.invalidateQueries({ queryKey });
}
