import { useModal } from 'components/modals';
import { RootModalProps } from 'components/modals/types';
import { SetupModal } from 'features/aiWriter/modals/setup/SetupModal';
import { ComponentProps, useCallback } from 'react';

type ChangeableModalProps = RootModalProps & Omit<ComponentProps<typeof SetupModal>, 'closeModal'>;

export default function useShowSetupModal() {
  const { showModal } = useModal();

  return useCallback(
    (props?: ChangeableModalProps) => {
      showModal({
        modalType: 'AI_WRITER_SETUP_MODAL',
        modalProps: { ...props, size: 'fullscreen', uncloseable: true }
      });
    },
    [showModal]
  );
}
