import {
  getCustomerTeamId,
  getIsVerified,
  getPricing,
  getUserEmail,
  getUserId
} from 'features/customer/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { getGroupId, getGroupName } from 'features/group/store/selectors';
import {
  useGetOnboardingChecklistProgress,
  useGetOnboardingUseCase
} from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { useSurveyAnswers } from 'features/onboardingJourney/newUserSurvey/useSurveyAnswers';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo, useRef } from 'react';
import { TrackingEventUserDataAttributes } from 'services/tracking/types';
import { useAppSelector } from 'store/hooks';
import getBrowserLanguage from 'utils/getBrowserLanguage';
import getValueOrNull from 'utils/getValueOrNull';
import { isNotProd } from 'utils/isNotProd';

export default function useGtmDataLayerAttributes() {
  const userId = useAppSelector(getUserId);
  const groupName = useAppSelector(getGroupName);
  const groupId = useAppSelector(getGroupId);
  const teamId = useAppSelector(getCustomerTeamId);
  const pricingPlan = useAppSelector(getPricing);
  const isVerified = useAppSelector(getIsVerified);
  const email = useAppSelector(getUserEmail);
  const posthog = usePostHog();

  const posthogFeatureFlags = useRef<Record<string, unknown>>();

  const onboardingChecklistProgress = useGetOnboardingChecklistProgress();
  const selectedUseCase = useGetOnboardingUseCase();

  const surveyAnswers = useSurveyAnswers();
  const { data: customerPreferences } = useCustomerPreferences();
  const subscriptionState = useSubscriptionState();

  const dataLayerAttributes = useMemo<TrackingEventUserDataAttributes>(
    () => ({
      userId: getValueOrNull(userId),
      groupName: getValueOrNull(groupName),
      groupId: getValueOrNull(groupId),
      teamId: getValueOrNull(teamId),
      pricingPlanId: getValueOrNull(pricingPlan.id),
      pricingPlanName: getValueOrNull(pricingPlan.name),
      email: getValueOrNull(email),
      env: isNotProd() ? 'dev' : 'prod',
      browserLanguage: getBrowserLanguage(),
      subscriptionStatus: getValueOrNull(subscriptionState?.currentSubscriptionState?.status),
      isVerified: getValueOrNull(isVerified),
      onboardingChecklistProgress: `${onboardingChecklistProgress}%`,
      selectedUseCase: selectedUseCase,
      featureFlags: posthogFeatureFlags.current ?? null,
      selectedUseCaseAnswer: surveyAnswers.data?.content_type ?? null,
      selectedCompanySize: surveyAnswers.data?.company_size ?? null,
      selectedPurpose: surveyAnswers.data?.purpose ?? null,
      preferredLanguage: customerPreferences
        ? `${customerPreferences?.preferredLanguage}_${customerPreferences?.preferredLanguageCountry}`
        : null
    }),
    [
      userId,
      groupName,
      groupId,
      teamId,
      pricingPlan.id,
      pricingPlan.name,
      email,
      subscriptionState?.currentSubscriptionState?.status,
      isVerified,
      onboardingChecklistProgress,
      selectedUseCase,
      surveyAnswers.data?.content_type,
      surveyAnswers.data?.company_size,
      surveyAnswers.data?.purpose,
      customerPreferences
    ]
  );

  useEffect(() => {
    posthog?.onFeatureFlags((_flags, variants) => {
      posthogFeatureFlags.current = variants;
    });
  }, [posthog]);

  return dataLayerAttributes;
}
