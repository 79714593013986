import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { getCurrentUser, setSubscription } from 'features/customer/store/actions';
import { CustomerDataPayload, CustomerSubscriptionData } from 'features/customer/store/types';
import { getUserData } from 'features/customer/store/utils';
import posthog from 'posthog-js';
import CustomerAPI from 'services/api/customer';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch } from 'store/hooks';

export function useHandleSubscriptionPlanChangedNotification() {
  const dispatch = useAppDispatch();

  const fetchAndUpdateCustomerInLocalStore = async () => {
    const subscriptionResponse = await CustomerAPI.subscription();
    if (subscriptionResponse.status) {
      const subscriptionData: CustomerSubscriptionData = {
        subscription: {
          status: subscriptionResponse.data.subscription_status,
          // Note: The backend naming here is inconsistent
          //       It's not related to the subscription itself, but only to the payment of the subscription
          paymentStatus: subscriptionResponse.data.subscription_is_incomplete
            ? 'fulfilled'
            : 'pending'
        }
      };
      dispatch(setSubscription(subscriptionData));
      const customerResponse = await CustomerAPI.get();
      if (customerResponse.status) {
        const userData = getUserData(customerResponse.data);
        dispatch(getCurrentUser.success(userData));
        triggerTrackingEvents(userData);
      } else {
        const errorId = handleCommonApiError(customerResponse.data.message);
        Toast.backendError(errorId);
      }
    } else {
      const errorId = handleCommonApiError(subscriptionResponse.data.message);
      Toast.backendError(errorId);
    }
  };

  const triggerTrackingEvents = (customerData: CustomerDataPayload) => {
    const pricingData = customerData.pricing;

    GAEvents.purchase({
      pricingPlanId: pricingData.id,
      pricingPlanName: pricingData.name,
      duration: pricingData.periodName,
      coupon: null,
      value: pricingData.cost,
      email: customerData.email
    });

    posthog?.capture('purchase', {
      pricingPlanId: pricingData.id,
      pricingPlanName: pricingData.name,
      duration: pricingData.periodName,
      coupon: null,
      value: pricingData.cost,
      email: customerData.email
    });
  };

  const mutation = useMutation({
    mutationFn: fetchAndUpdateCustomerInLocalStore,
    onError: () => Toast.commonError()
  });

  return mutation;
}
