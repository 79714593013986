import { ellipsis } from 'polished';
import { MouseEvent } from 'react';
import styled from 'styled-components';

import Icon from '../base/Icon';
import Input from '../base/Input';

export const FlexTableWrapper = styled.div`
  display: block;
  overflow: auto;
  margin-bottom: 1rem;
`;

export const FlexTable = styled.div`
  display: block;
`;

export const Cell = styled.div`
  padding: 0.5rem;
  align-items: center;
  align-content: center;
  cursor: default;
  display: flex;
`;

export const HeaderCellContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HeaderMain = styled(Cell)`
  padding: 0;
  color: #9aa0ac;
  font-size: 0.875rem;
  font-weight: 400;
  justify-content: space-between;
`;

export const HeaderFilter = styled.div`
  width: 100%;
  padding-right: 1rem;
`;

export const HeaderText = styled.span<{ noEllipsis?: boolean }>`
  ${({ noEllipsis }) => !noEllipsis && ellipsis()}

  &:hover {
    cursor: pointer;
    color: #456;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
`;

export const HeaderRowContainer = styled.div<{ minWidth?: number }>`
  overflow-y: auto;
  overflow-x: hidden;
  min-width: ${({ minWidth }) => (typeof minWidth === 'number' ? `${minWidth}px` : minWidth)};
`;

export const RowsContainer = styled.div<{
  maxHeight?: number | string;
  height?: number | string;
  minHeight?: number | string;
  minWidth?: number | string;
}>`
  min-height: ${({ minHeight }) => (typeof minHeight === 'number' ? `${minHeight}px` : minHeight)};
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
  max-height: ${({ maxHeight }) => (typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight)};
  min-width: ${({ minWidth }) => (typeof minWidth === 'number' ? `${minWidth}px` : minWidth)};
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const TableRowElement = styled.div<{ selected?: boolean }>`
  display: flex;
  border-bottom: 1px solid rgba(0, 40, 100, 0.12);
  min-height: 43px;
  ${({ selected }) => selected && 'font-weight: bold;'}

  &:nth-of-type(odd) {
    background-color: transparent;
  }

  &:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.02);
  }

  ${RowsContainer} > &:last-child {
    border-bottom: 0;
  }
`;

export const FilterIcon = styled(Icon).attrs(() => ({
  name: 'filter'
}))`
  font-size: 1.25rem;
  vertical-align: -4px;
  color: ${({ active }) => (active ? '#456' : '#ccc')};

  &:hover {
    color: #456;
  }
`;

export const TableFilterInput = styled(Input)`
  height: 1.75rem;
  padding: 0.25rem;
  font-size: 0.875rem;
`;

export const TableFilterNumericInput = styled(TableFilterInput)`
  width: 3.5rem;
`;

export const SelectFilter = styled.select.attrs(() => ({
  className: 'custom-select'
}))`
  height: 1.75rem;
  padding: 0 0.25rem;
  font-size: 0.875rem;
  min-width: 6rem;
`;

export const SelectCheckboxContainer = styled.div.attrs(() => ({
  onClick: (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }
}))``;

export const ActionsBottom = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-wrap: wrap-reverse;
  }
`;

export const ActionsBottomSection = styled.div`
  display: flex;

  @media (max-width: 576px) {
    width: 100%;
  }
`;
