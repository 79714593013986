import Toast from 'components/toasts/Toast';
import { getProjectName } from 'features/aiWriter/AiWriterProjectOverview/getProjectName';
import { getEditorText } from 'features/aiWriter/AiWriterTextEditor/utils/getEditorText';
import { AiWriterProject, SharingPermission } from 'features/aiWriter/store/types';
import {
  aiWriterTextToApi,
  getProjectShortText
} from 'features/aiWriter/store/utils/mappers/aiWriterTextMappers';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { fetchDefaultOutputTypeByLanguageCountry } from 'features/textGenerator/utils/useGetPromptsOthers';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import AiWriterAPI from 'services/api/aiWriter';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { invalidateProjectsQuery } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjects';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

export const useCopyDocument = () => {
  const translate = useTr();
  const customerId = useAppSelector(state => state.customer.id);
  const getModelById = useAppSelector(getEmbeddingModelDataSelector);

  const copyDocument = async ({
    name,
    text,
    embeddingModelId,
    generateTextConfig
  }: AiWriterProject) => {
    try {
      const { language, country } = getModelById(embeddingModelId);
      const outputType = await fetchDefaultOutputTypeByLanguageCountry(language, country);
      const newName = getProjectName(name, getEditorText(text));

      const response = await AiWriterAPI.create({
        audience_id: generateTextConfig.audienceId,
        name: translate('aiWriter.project_overview.copy_name', {
          name: newName
        }),
        keywords: '',
        brand: '',
        text: aiWriterTextToApi(text),
        short_text: getProjectShortText(text),
        embedding_model_id: embeddingModelId,
        output_type: outputType,
        tonality: [],
        sharing_permission: SharingPermission.private,
        personality_id: null
      });
      if (!response.status) {
        const errorId = handleCommonApiError(response.data.message);

        Toast.backendError(errorId);
        return;
      }

      GAEvents.createdNewDocument({
        documentId: response.data.id,
        customerId
      });
      trackingWrapper.track('aiWriterNewDocument', {
        documentId: response.data.id,
        customerId
      });

      invalidateProjectsQuery();
    } catch (e) {
      Toast.apiError();
    }
  };

  return { copyDocument };
};
