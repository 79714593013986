import { AxiosInstance } from 'axios';
import { create } from 'zustand';

type BackofficeClientStore = {
  client: AxiosInstance | null;
};

/**
 * This store is used to keep the client instance
 * It's currently only used for accessing the client instance lazy in logError, which is also
 * used in the createBackofficeClient(), which would be a circular dependency if importing the
 * backofficeClient directly.
 *
 * Feel free to use the store instead of the client directly if you need to.
 */
export const backofficeClientStore = create<BackofficeClientStore>(() => ({
  client: null
}));
