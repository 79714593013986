import {
  AddCircleRounded,
  AdsClickRounded,
  ArticleRounded,
  CampaignRounded,
  DraftsRounded,
  LightbulbRounded,
  MergeRounded,
  NewReleasesRounded,
  NewspaperRounded,
  ThumbUpAltRounded
} from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { ReactComponent as AiImagesIcon } from 'assets/icons/icon-home-aiImages-tile.svg';
import { getRoutePath } from 'config/routes';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { withTestId } from 'utils/utils';

export type QuickActionCardProps = {
  name: string;
  title: ReactNode;
  subtitle: ReactNode;
  gtmId?: string;
  onClick: () => void;
  icon?: ReactNode;
};

export const QuickActionSteps = {
  blankDocument: 'blankDocument',
  socialMediaPost: 'socialMediaPost',
  blogArticle: 'blogArticle',
  SEOblogpost: 'SEOblogpost',
  AiImage: 'AiImage',
  rewriteInBrandVoice: 'rewriteInBrandVoice',
  contentIdeas: 'contentIdeas',
  marketingCampaign: 'marketingCampaign',
  recycleContent: 'recycleContent',
  productLandingPage: 'productLandingPage',
  newsletter: 'newsletter',
  taglines: 'taglines'
};

const workflowIds: Record<string, number> = {
  contentIdeas: 50,
  blogArticle: 55,
  rewriteInBrandVoice: 67,
  productLandingPage: 25,
  marketingCampaign: 48,
  recycleContent: 49,
  newsletter: 64,
  taglines: 68
};

export const useQuickActions = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNewDocumentClick = async () => {
    await navigate(getRoutePath('aiWriter'));
    trackingWrapper.track('aiWriterOverviewNewDocumentStarted');

    navigate(getRoutePath('newDocument'));
  };

  const handleBlogPostBuilderClick = async () => {
    trackingWrapper.track('aiWriterOverviewBlogPostBuilderStarted');

    navigate(getRoutePath('blogPostWorkflow'));
  };

  const handleSocialMediaBuilderClick = async () => {
    trackingWrapper.track('aiWriterOverviewSocialMediaBuilderStarted');

    navigate(getRoutePath('socialPostWorkflow'));
    dispatch(setInspirationsCurrentStep(undefined));
  };

  const handleImageFlashClick = () => {
    navigate(getRoutePath('aiImages'));
  };

  const handleWorkflowClick = (workflowName: string) => {
    const workflowId = workflowIds[workflowName];
    navigate(getRoutePath('modularWorkflowRun', { workflowId }));
  };

  const quickActions: QuickActionCardProps[][] = [
    [
      {
        name: QuickActionSteps.blankDocument,
        title: <FormattedMessage id="common.new_document" {...withTestId('blank-document-card')} />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.blank_document_description" />,
        onClick: handleNewDocumentClick,
        gtmId: gtmIds.aiWriter.createNewProject,
        icon: (
          <QuickCreateAvatar>
            <AddCircleIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.socialMediaPost,
        title: <FormattedMessage id="aiWriter.project_overview.social_post_builder.title" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.social_post_builder.subtitle" />,
        onClick: handleSocialMediaBuilderClick,
        gtmId: gtmIds.aiWriter.newSocialPostBuilder,
        icon: (
          <QuickCreateAvatar>
            <ThumbUpIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.blogArticle,
        title: <FormattedMessage id="aiWriter.project_overview.enhanced_blog" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.enhanced_blog_description" />,
        onClick: () => handleWorkflowClick(QuickActionSteps.blogArticle),
        icon: (
          <QuickCreateAvatar>
            <ArticleIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.SEOblogpost,
        title: <FormattedMessage id="aiWriter.project_overview.blog_post_builder" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.blog_post_builder_description" />,
        onClick: handleBlogPostBuilderClick,
        gtmId: gtmIds.aiWriter.newBlogPostBuilder,
        icon: (
          <QuickCreateAvatar>
            <ArticleIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.AiImage,
        title: <FormattedMessage id="aiWriter.project_overview.ai_images" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.ai_images_description" />,
        onClick: handleImageFlashClick,
        gtmId: gtmIds.aiWriter.newAIImages,
        icon: (
          <QuickCreateAvatar>
            <AiImagesIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.rewriteInBrandVoice,
        title: <FormattedMessage id="aiWriter.project_overview.rewrite_in_brand_voice" />,
        subtitle: (
          <FormattedMessage id="aiWriter.project_overview.rewrite_in_brand_voice_description" />
        ),
        onClick: () => handleWorkflowClick(QuickActionSteps.rewriteInBrandVoice),
        icon: (
          <QuickCreateAvatar>
            <CampaignIcon />
          </QuickCreateAvatar>
        )
      }
    ],
    [
      {
        name: QuickActionSteps.productLandingPage,
        title: <FormattedMessage id="aiWriter.project_overview.product_landing_page" />,
        subtitle: (
          <FormattedMessage id="aiWriter.project_overview.product_landing_page_description" />
        ),
        onClick: () => handleWorkflowClick(QuickActionSteps.productLandingPage),
        gtmId: gtmIds.aiWriter.newProductLandingPage,
        icon: (
          <QuickCreateAvatar>
            <ProductLandingPageIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.marketingCampaign,
        title: <FormattedMessage id="aiWriter.project_overview.marketing_campaign" />,
        subtitle: (
          <FormattedMessage id="aiWriter.project_overview.marketing_campaign_description" />
        ),
        onClick: () => handleWorkflowClick(QuickActionSteps.marketingCampaign),
        gtmId: gtmIds.aiWriter.newMarketingCampaign,
        icon: (
          <QuickCreateAvatar>
            <MarketingCampaignIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.recycleContent,
        title: <FormattedMessage id="aiWriter.project_overview.recycle_content" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.recycle_content_description" />,
        onClick: () => handleWorkflowClick(QuickActionSteps.recycleContent),
        icon: (
          <QuickCreateAvatar>
            <RecycleContentIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.newsletter,
        title: <FormattedMessage id="aiWriter.project_overview.newsletter" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.newsletter_description" />,
        onClick: () => handleWorkflowClick(QuickActionSteps.newsletter),
        gtmId: gtmIds.aiWriter.newNewsletter,
        icon: (
          <QuickCreateAvatar>
            <NewsletterIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.contentIdeas,
        title: <FormattedMessage id="aiWriter.project_overview.30_day_content" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.30_day_content_description" />,
        onClick: () => handleWorkflowClick(QuickActionSteps.contentIdeas),
        gtmId: gtmIds.aiWriter.newContentIdeas,
        icon: (
          <QuickCreateAvatar>
            <LightbulbIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.taglines,
        title: <FormattedMessage id="aiWriter.project_overview.taglines" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.taglines_description" />,
        onClick: () => handleWorkflowClick(QuickActionSteps.taglines),
        icon: (
          <QuickCreateAvatar>
            <TaglinesIcon />
          </QuickCreateAvatar>
        )
      }
    ]
  ];

  return quickActions;
};

const ArticleIcon = styled(ArticleRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const ThumbUpIcon = styled(ThumbUpAltRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const AddCircleIcon = styled(AddCircleRounded)`
  width: 35px;
  height: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const LightbulbIcon = styled(LightbulbRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const ProductLandingPageIcon = styled(NewspaperRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const MarketingCampaignIcon = styled(AdsClickRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const RecycleContentIcon = styled(MergeRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const NewsletterIcon = styled(DraftsRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const TaglinesIcon = styled(NewReleasesRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const CampaignIcon = styled(CampaignRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const QuickCreateAvatar = styled(Avatar)`
  && {
    background-color: ${({ theme }) => theme.colors.primaryColorMain};
    width: 56px;
    height: 56px;
  }
`;
