import { MenuItem } from '@mui/material';
import SelectField from 'components/forms/SelectField';
import { getGetAudienceByLanguageAndCountry } from 'features/audiences/store/selectors';
import { getGetCountryEmbeddingModelGroupById } from 'features/embeddingModels/store/selectors';
import { ReactElement, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';
import { isNotProd } from 'utils/isNotProd';

type Props = {
  name: string;
  embeddingModelId: string;

  onSelect?: (value: unknown) => void;
};

export const AudienceSelect = ({
  name,
  embeddingModelId,
  onSelect
}: Props): ReactElement | null => {
  const tr = useTr();

  const countryModelGroup = useAppSelector(getGetCountryEmbeddingModelGroupById)(embeddingModelId);
  const audiences = useAppSelector(getGetAudienceByLanguageAndCountry)(
    countryModelGroup?.language,
    countryModelGroup?.country
  );
  if (audiences.length === 0 && isNotProd()) {
    // eslint-disable-next-line no-console
    console.error(
      'Missing audience for language and country',
      countryModelGroup?.language,
      countryModelGroup?.country,
      {
        countryModelGroup
      }
    );
  }

  // Disable audience selection if only one is present (and auto use this)
  const isHidden = useMemo(() => audiences.length <= 1, [audiences.length]);
  if (isHidden) {
    return null;
  }

  return (
    <SelectField
      variant="outlined"
      name={name}
      fullWidth
      placeholder={tr('project.input.label')}
      onSelect={onSelect}
    >
      {audiences.map(audience => (
        <MenuItem key={audience.id} value={audience.id}>
          {audience.name}
        </MenuItem>
      ))}
    </SelectField>
  );
};
